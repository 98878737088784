import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { authRequest, publicRequest } from '../requestMethods';
import { useSelector } from 'react-redux';
import { successToast } from '../utils/toast';
import { Trans, useTranslation } from 'react-i18next';

const AddToGalleryModal = ({ selectedItem }) => {
  const [rooms, setRooms] = useState([]);
  const [addRoomIds, setAddRoomIds] = useState([]);
  const [removeRoomIds, setRemoveRoomIds] = useState([]);
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    //fetch rooms
    setAddRoomIds([]);
    setRemoveRoomIds([]);
    const getRooms = async () => {
      const res = await publicRequest.get(`/rooms/users/${user.id}`);
      setRooms(res.data);
    };
    getRooms();
  }, [selectedItem]);

  useEffect(() => {
    let tmpArray = [];
    rooms.forEach((room) => {
      if (room.items?.some((item) => item.id === selectedItem?.id)) {
        tmpArray.push(room.id);
        room.isAdded = true;
        // setAddRoomIds([...addRoomIds, room.id]);
      } else {
        room.isAdded = false;
        tmpArray = tmpArray.filter(function (item) {
          return item !== room.id;
        });
        // setAddRoomIds(
        //   addRoomIds.filter((roomId) => roomId !== parseInt(room.id))
        // );
      }
    });
    setAddRoomIds(tmpArray);
  }, [rooms]);

  const handleSubmit = async () => {
    try {
      const addedRooms = await authRequest.post('/rooms/add-item', {
        itemId: selectedItem?.id,
        roomIds: addRoomIds,
      });
      const removedRooms = await authRequest.post('/rooms/remove-item', {
        itemId: selectedItem?.id,
        roomIds: removeRoomIds,
      });

      addedRooms.data = addedRooms.data.filter((el) => {
        return el != null;
      });

      if (addedRooms.data.length > 0 || removedRooms.data.length > 0) {
        successToast(t('message.success.t1'), [
          ...addedRooms.data,
          ...removedRooms.data,
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <input type='checkbox' id='my-modal' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box max-w-2xl'>
          <h3 className='text-lg font-bold'>
            <Trans i18nKey={'btn.add-to-exhib'} />
          </h3>
          <form className='mt-8'>
            <div className='form-control mb-6'>
              <div>
                {rooms.map((room) => {
                  if (
                    !addRoomIds.includes(room.id) &&
                    !room?.isAdded &&
                    room.items.length >= 20
                  ) {
                    return (
                      <label
                        key={room.id}
                        className='mb-2 flex items-center gap-4 rounded-lg border bg-gray-50/30 px-4 py-2'
                      >
                        <input
                          type='checkbox'
                          className='checkbox-primary checkbox'
                          value={room.id}
                          checked={false}
                          disabled
                        />

                        <div className='flex items-center gap-4'>
                          <img
                            src={`${
                              room?.previewImg
                                ? room?.previewImg
                                : '/assets/img/gallery_dummy.png'
                            }`}
                            alt='photo'
                            className='h-10 w-10 rounded-lg object-cover'
                          />

                          <div className='flex flex-col'>
                            <span className='label-text font-bold text-[#6A6A6A]'>
                              {room.title}
                            </span>
                            <span className='items-num text-sm text-[#6A6A6A]'>
                              <span className='num'>
                                {room.items.length}&nbsp;
                              </span>
                              {room.items.length > 1 ? 'items' : 'item'}
                            </span>
                            <p className='mx-auto mt-1 w-full text-xs text-[#6A6A6A]'>
                              You cannot add more items because you have reached
                              the maximum limit of 20
                            </p>
                          </div>
                        </div>
                      </label>
                    );
                  } else {
                    return (
                      <label
                        key={room.id}
                        className='checklist-item mb-2 flex items-center gap-4 rounded-lg border px-4 py-2'
                      >
                        <input
                          type='checkbox'
                          className='check-gallery checkbox-primary checkbox'
                          value={room.id}
                          onChange={(e) => {
                            // add to list
                            if (e.target.checked) {
                              setAddRoomIds([
                                ...addRoomIds,
                                parseInt(e.target.value),
                              ]);
                              setRemoveRoomIds(
                                removeRoomIds.filter(
                                  (roomId) =>
                                    roomId !== parseInt(e.target.value)
                                )
                              );
                            } else {
                              // remove from list
                              setAddRoomIds(
                                addRoomIds.filter(
                                  (roomId) =>
                                    roomId !== parseInt(e.target.value)
                                )
                              );
                              setRemoveRoomIds([
                                ...removeRoomIds,
                                parseInt(e.target.value),
                              ]);
                            }
                          }}
                          checked={addRoomIds.includes(room.id)}
                        />

                        <div className='gallery-info flex items-center space-x-2'>
                          <img
                            src={`${
                              room?.previewImg
                                ? room?.previewImg
                                : '/assets/img/gallery_dummy.png'
                            }`}
                            alt='photo'
                            className='h-10 w-10 rounded-lg object-cover'
                          />

                          <div className='flex flex-col'>
                            <span className='label-text font-bold'>
                              {room.title}
                            </span>
                            <span className='items-num text-sm'>
                              <span className='num'>
                                {room.items.length}&nbsp;
                              </span>
                              {room.items.length > 1 ? 'items' : 'item'}
                            </span>
                          </div>
                        </div>
                      </label>
                    );
                  }
                })}
              </div>

              {rooms.length === 0 && (
                <p>
                  <span className='font-bold'>You have no exhibitions. </span>
                  <span className='font-bold text-primary'>
                    <Link to='/create-room'>Let’s create an exhibition!</Link>
                  </span>
                </p>
              )}
            </div>
            <div className='modal-action'>
              <label htmlFor='my-modal' className='btn'>
                <Trans i18nKey={'btn.cancel'} />
              </label>
              <label
                htmlFor='my-modal'
                className='btn btn-primary'
                onClick={handleSubmit}
              >
                <Trans i18nKey={'btn.save'} />
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddToGalleryModal;
