import { HeartIcon } from '@heroicons/react/24/outline';
import React from 'react';

const RoomPreviewCard = ({ title, img }) => {
  return (
    <div className='gallery max-w-sm rounded-lg border border-gray-300'>
      {img ? (
        <img
          src={img}
          alt=''
          className='aspect-[16/9] w-full flex-shrink-0 rounded-t-lg object-cover'
        />
      ) : (
        <div className='flex aspect-[16/9] w-full items-center justify-center rounded-t-lg bg-slate-200'>
          <p className='text-sm text-gray-500'>Rendering...</p>
        </div>
      )}
      <div className='rounded-b-lg bg-white p-3'>
        <div className='flex justify-between'>
          <p className='break-all font-bold'>{title ? title : 'Title'}</p>
        </div>
      </div>
    </div>
  );
};

export default RoomPreviewCard;
