import {
  PlayPauseIcon,
  PlayIcon,
  SparklesIcon,
  ArrowPathIcon,
  FolderArrowDownIcon,
  BookmarkIcon,
} from '@heroicons/react/24/outline';
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { CEILINGS } from '../../data/ceilings';
import { WALLS } from '../../data/walls';
import { FLOORS } from '../../data/floors';
import { Trans, useTranslation } from 'react-i18next';
import ResetConfirmModal from '../../components/ResetConfirmModal';
import MyPresetsModal from '../../components/MyPresetsModal';
import SavePresetModal from '../../components/SavePresetModal';
import SuggestedPresetsModal from '../../components/SuggestedPresetsModal';
import { FRAMES } from '../../data/frames';
import { useSelector } from 'react-redux';
import { CUSTOM_FRAMES } from '../../data/customFrames';

const SILHOUETTES = [
  {
    value: 1,
    label: 'Man 1',
    labelJA: '男性 1',
    isPro: false,
  },
  {
    value: 2,
    label: 'Man 2',
    labelJA: '男性 2',
    isPro: true,
  },
  {
    value: 3,
    label: 'Man 3',
    labelJA: '男性 3',
    isPro: true,
  },
  {
    value: 4,
    label: 'Woman 1',
    labelJA: '女性 1',
    isPro: false,
  },
  {
    value: 5,
    label: 'Woman 2',
    labelJA: '女性 2',
    isPro: true,
  },
  {
    value: 6,
    label: 'Woman 3',
    labelJA: '女性 3',
    isPro: true,
  },
  {
    value: 7,
    label: 'Kid 1',
    labelJA: '子ども 1',
    isPro: false,
  },
  {
    value: 8,
    label: 'Kid 2',
    labelJA: '子ども 2',
    isPro: true,
  },
  {
    value: 9,
    label: 'Kid 3',
    labelJA: '子ども 3',
    isPro: true,
  },
];

const DecorationSettings = ({
  saveRoomSettings,
  frame,
  setFrame,
  theme,
  setTheme,
  silhouette,
  setSilhouette,
  music,
  setMusic,
  randomMusic,
  setRandomMusic,
  light,
  setLight,
  setSnapshot,
  addedItems,
  lightColor,
  setLightColor,
  checkedSilhouettes,
  setCheckedSilhouettes,
  colorCode,
  setColorCode,
  ceiling,
  setCeiling,
  wall,
  setWall,
  floor,
  setFloor,
  objectLight,
  setObjectLight,
  directionalLightIntensity,
  setDirectionalLightIntensity,
  directionalLightColorTheme,
  setDirectionalLightColorTheme,
  directionalLightColorCode,
  setDirectionalLightColorCode,
  spotLightIntensity,
  setSpotLightIntensity,
  spotLightColorTheme,
  setSpotLightColorTheme,
  spotLightColorCode,
  setSpotLightColorCode,
  presetName,
  setPresetName,
  handleCreatePreset,
  resetSettings,
  loadPreset,
  frameColor,
  setFrameColor,
  matColor,
  setMatColor,
}) => {
  const { i18n } = useTranslation();
  const user = useSelector((state) => state.user.currentUser);
  const CAS1_ID = process.env.REACT_APP_STRIPE_CAS1_PRODUCT_ID;
  const CAS5_ID = process.env.REACT_APP_STRIPE_CAS5_PRODUCT_ID;

  const [music1Playing, setMusic1Playing] = useState(false);
  const [music2Playing, setMusic2Playing] = useState(false);
  const [music3Playing, setMusic3Playing] = useState(false);
  const [themeKind, setThemeKind] = useState('');
  const [tab, setTab] = useState('Ceiling');
  const [music1] = useState(new Audio('/assets/music/sample1.mp3'));
  const [music2] = useState(new Audio('/assets/music/sample2.mp3'));
  const [music3] = useState(new Audio('/assets/music/sample3.mp3'));
  const [filteredFrames, setFilteredFrames] = useState([]);
  const [filteredCustomFrames, setFilteredCustomFrames] = useState([]);
  const [filteredCeilings, setFilteredCeilings] = useState([]);
  const [filteredWalls, setFilteredWalls] = useState([]);
  const [filteredFloors, setFilteredFloors] = useState([]);
  const [filteredSilhouettes, setFilteredSilhouettes] = useState([]);
  const [plan, setPlan] = useState(null);
  const [frameType, setFrameType] = useState('PRESET');
  const checkBoxLightRef = useRef(null);
  const checkBoxInvisibleLightRef = useRef(null);
  const checkBoxSilhouetteRef = useRef(null);
  const checkBoxMusicRef = useRef(null);

  useEffect(() => {
    if (user?.userPlans?.length > 0) {
      // 初期値を設定するための変数
      let foundPlan = '';

      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === CAS1_ID) {
          foundPlan = 'CAS-1';
        } else if (el.SubscriptionPlans.productId === CAS5_ID) {
          foundPlan = 'CAS-5';
        }
      });

      // 設定したプラン名を反映
      if (foundPlan) {
        setPlan(foundPlan);
      } else {
        //CAS-1のトライアル状態
        setPlan('CAS-1');
      }
    }
  }, [user]);

  useEffect(() => {
    //先頭2つを取り除いた後に、新しいデータを先頭に追加
    const filteredFramesTmp =
      plan == 'CAS-5'
        ? FRAMES.slice(2) // 全てのフレームを取得
        : FRAMES.slice(2).filter((frame) => !frame.isPro); // isProがfalseのものだけをフィルタリング

    const tmpFrames = [
      {
        value: '',
        title: 'Unframed',
        isFree: true,
        imgUrl: '/assets/img/ban.png',
      },
      ...filteredFramesTmp,
    ];

    setFilteredFrames(tmpFrames);

    const filteredCustomFramesTmp =
      plan == 'CAS-5'
        ? CUSTOM_FRAMES // 全てのフレームを取得
        : CUSTOM_FRAMES.filter((frame) => !frame.isPro); // isProがfalseのものだけをフィルタリング

    setFilteredCustomFrames(filteredCustomFramesTmp);
  }, [FRAMES, plan, CUSTOM_FRAMES]);

  useEffect(() => {
    const filteredCeilingsTmp =
      plan == 'CAS-5' ? CEILINGS : CEILINGS.filter((ceiling) => !ceiling.isPro); // isProがfalseのものだけをフィルタリング

    setFilteredCeilings(filteredCeilingsTmp);
  }, [CEILINGS, plan]);

  useEffect(() => {
    const filteredWallsTmp =
      plan == 'CAS-5' ? WALLS : WALLS.filter((wall) => !wall.isPro); // isProがfalseのものだけをフィルタリング

    setFilteredWalls(filteredWallsTmp);
  }, [WALLS, plan]);

  useEffect(() => {
    const filteredFloorsTmp =
      plan == 'CAS-5' ? FLOORS : FLOORS.filter((floor) => !floor.isPro); // isProがfalseのものだけをフィルタリング

    setFilteredFloors(filteredFloorsTmp);
  }, [FLOORS, plan]);

  useEffect(() => {
    const filteredSilhouettesTmp =
      plan == 'CAS-5'
        ? SILHOUETTES
        : SILHOUETTES.filter((silhouette) => !silhouette.isPro); // isProがfalseのものだけをフィルタリング

    setFilteredSilhouettes(filteredSilhouettesTmp);
  }, [SILHOUETTES, plan]);

  useEffect(() => {
    if (['CYBERPUNK', 'CLASSIC', 'PILLAR', 'BLUE_PAINT'].includes(theme)) {
      setThemeKind('Preset');
      setLight(null);
      setWall(null);
      setFloor(null);
      setCeiling(null);
      setObjectLight(null);
    } else {
      setThemeKind('Color');
    }
  }, [theme]);

  const playMusic = (trackNumber) => {
    switch (trackNumber) {
      case 1:
        music2.pause();
        music3.pause();
        music1.play();
        setMusic2Playing(false);
        setMusic3Playing(false);
        setMusic1Playing(true);
        break;
      case 2:
        music1.pause();
        music3.pause();
        music2.play();
        setMusic1Playing(false);
        setMusic3Playing(false);
        setMusic2Playing(true);
        break;
      case 3:
        music1.pause();
        music2.pause();
        music3.play();
        setMusic1Playing(false);
        setMusic2Playing(false);
        setMusic3Playing(true);
        break;
    }
  };

  const pauseMusic = (trackNumber) => {
    switch (trackNumber) {
      case 1:
        music1.pause();
        setMusic1Playing(false);
        break;
      case 2:
        music2.pause();
        setMusic2Playing(false);
        break;
      case 3:
        music3.pause();
        setMusic3Playing(false);
        break;
    }
  };

  const location = useLocation();
  useLayoutEffect(() => {
    return () => {
      // ページから離れるときのクリーンアップ処理
      music1.pause();
      music2.pause();
      music3.pause();
    };
  }, [location]);

  const THEMES = [
    {
      kind: 'Preset',
      label: 'Special (non-editable)',
      themes: [
        {
          value: 'CYBERPUNK',
          title: 'CYBERPUNK',
          bgColor: 'bg-cyberpunk-grad',
          displayTitle: 'Cyberpunk',
        },
        {
          value: 'CLASSIC',
          title: 'CLASSIC',
          bgColor: 'bg-[#8d3047]',
          displayTitle: 'Classic',
        },
        {
          value: 'PILLAR',
          title: 'PILLAR',
          bgColor: 'bg-[#8d3047]',
          displayTitle: 'Pillar',
        },
        {
          value: 'BLUE_PAINT',
          title: 'BLUE_PAINT',
          bgColor: 'bg-[#8d3047]',
          displayTitle: 'Blue Paint',
        },
      ],
    },
    {
      kind: 'Color',
      label: 'Custom Room Theme',
      themes: [
        {
          value: 'DARK',
          title: 'DARK',
          bgColor: 'bg-black-grad',
          displayTitle: 'Default (Black)',
        },
        {
          value: 'CUSTOM',
          title: 'CUSTOM',
          bgColor: '',
          displayTitle: 'Custom (pick your color)',
        },
      ],
    },
  ];

  const LIGHTS = [
    {
      value: 1,
      title: 'Ceiling Square 1',
      isFree: true,
    },
    {
      value: 2,
      title: 'Ceiling Square 2',
      isFree: false,
    },
    {
      value: 3,
      title: 'Ceiling Circle',
      isFree: false,
    },
    {
      value: 4,
      title: 'Wall Square',
      isFree: false,
    },
    {
      value: 5,
      title: 'Wall Circle 1',
      isFree: false,
    },
    {
      value: 7,
      title: 'Wall Circle 2',
      isFree: false,
    },
    {
      value: 6,
      title: 'Wall Triangle',
      isFree: false,
    },
    {
      value: 8,
      title: 'Wall Bar 1',
      isFree: false,
    },
    {
      value: 9,
      title: 'Wall Bar 2',
      isFree: false,
    },
  ];

  const OBJECT_LIGHTS = [
    {
      value: 1,
      title: 'CEILING LAMP',
    },
    {
      value: 2,
      title: 'CEILING LIGHTS',
    },
    {
      value: 3,
      title: '1912 ELECTROLIER CEILING LAMPS',
    },
  ];

  const LIGHT_COLORS = [
    {
      value: 'WHITE',
      title: 'WHITE',
      bgColor: 'bg-gray-200',
      checkColor: 'text-gray',
    },
    {
      value: 'YELLOW',
      title: 'YELLOW',
      bgColor: 'bg-yellow-400',
      checkColor: 'text-white',
    },
    {
      value: 'RED',
      title: 'RED',
      bgColor: 'bg-red-400',
      checkColor: 'text-white',
    },
    {
      value: 'BLUE',
      title: 'BLUE',
      bgColor: 'bg-blue-400',
      checkColor: 'text-white',
    },
    {
      value: 'PINK',
      title: 'PINK',
      bgColor: 'bg-pink-400',
      checkColor: 'text-white',
    },
    {
      value: 'GREEN',
      title: 'GREEN',
      bgColor: 'bg-green-400',
      checkColor: 'text-white',
    },
  ];

  const LIGHTING_COLORS = [
    {
      value: 'WHITE',
      title: 'WHITE',
      colorCode: '#ffffff',
      bgColor: 'bg-white-grad',
      displayTitle: 'White',
    },
    {
      value: 'RED',
      title: 'RED',
      colorCode: '#ff0600',
      bgColor: 'bg-red-grad',
      displayTitle: 'Red',
    },
    {
      value: 'PINK',
      title: 'PINK',
      colorCode: '#ff00db',
      bgColor: 'bg-pink-grad',
      displayTitle: 'Pink',
    },
    {
      value: 'BLUE',
      title: 'BLUE',
      colorCode: '#0028ff',
      bgColor: 'bg-blue-grad',
      displayTitle: 'Blue',
    },
    {
      value: 'CUSTOM',
      title: 'CUSTOM',
      colorCode: '#ffffff',
      bgColor: '',
      displayTitle: 'Custom (pick your color)',
    },
  ];

  const music1ProgressBarRef = useRef(null);
  const music2ProgressBarRef = useRef(null);
  const music3ProgressBarRef = useRef(null);

  useEffect(() => {
    const music1ProgressBar = music1ProgressBarRef.current;
    const music2ProgressBar = music2ProgressBarRef.current;
    const music3ProgressBar = music3ProgressBarRef.current;
    const updateProgress = () => {
      if (music1Playing) {
        const currentTime = music1.currentTime;
        const duration = music1.duration;
        const progress = (currentTime / duration) * 100;
        if (progress == 100) {
          music1ProgressBar.style.width = `0%`;
          setMusic1Playing(false);
        } else {
          music1ProgressBar.style.width = `${progress}%`;
        }
      }
      if (music2Playing) {
        const currentTime = music2.currentTime;
        const duration = music2.duration;
        const progress = (currentTime / duration) * 100;
        if (progress == 100) {
          music2ProgressBar.style.width = `0%`;
          setMusic2Playing(false);
        } else {
          music2ProgressBar.style.width = `${progress}%`;
        }
      }
      if (music3Playing) {
        const currentTime = music3.currentTime;
        const duration = music3.duration;
        const progress = (currentTime / duration) * 100;
        if (progress == 100) {
          music3ProgressBar.style.width = `0%`;
          setMusic3Playing(false);
        } else {
          music3ProgressBar.style.width = `${progress}%`;
        }
      }
    };

    const progressInterval = setInterval(updateProgress, 100); // 100ミリ秒ごとに更新する

    return () => {
      clearInterval(progressInterval); // コンポーネントがアンマウントされたらインターバルをクリアする
    };
  }, [music1Playing, music2Playing, music3Playing]);

  const [toggleTheme, setToggleTheme] = useState({
    ceiling: false,
    walls: false,
    floor: false,
    objectLight: false,
    other: false,
  });
  const [toggleLighting, setToggleLighting] = useState(false);
  const [toggleBackgroundMusic, setToggleBackgroundMusic] = useState(false);

  const handleSilhouettesChange = (index) => {
    setCheckedSilhouettes((prevCheckedItems) => {
      const updatedCheckedItems = prevCheckedItems.includes(index)
        ? prevCheckedItems.filter((item) => item !== index)
        : [...prevCheckedItems, index];

      if (updatedCheckedItems.length === 0) {
        setSilhouette(false);
      }

      return updatedCheckedItems;
    });
  };

  const handleCeilingChange = (event) => {
    setCeiling(Number(event.target.value));
  };
  const handleWallChange = (event) => {
    setWall(Number(event.target.value));
  };
  const handleFloorChange = (event) => {
    setFloor(Number(event.target.value));
  };
  const handleObjectLightChange = (event) => {
    setObjectLight(Number(event.target.value));
  };

  useEffect(() => {
    if (ceiling) {
      setToggleTheme((prevTheme) => ({
        ...prevTheme,
        ceiling: true,
      }));

      if (light == 1 || light == 2 || light == 3) {
        setLight(null);
        setToggleLighting(false);
      }
    }
  }, [ceiling]);

  useEffect(() => {
    if (wall) {
      setToggleTheme((prevTheme) => ({
        ...prevTheme,
        walls: true,
      }));
    }
  }, [wall]);

  useEffect(() => {
    if (floor) {
      setToggleTheme((prevTheme) => ({
        ...prevTheme,
        floor: true,
      }));
    }
  }, [floor]);

  useEffect(() => {
    if (objectLight) {
      setToggleTheme((prevTheme) => ({
        ...prevTheme,
        objectLight: true,
      }));
    }
  }, [objectLight]);

  useEffect(() => {
    if (light) {
      setToggleLighting(true);
    }
  }, [light]);

  useEffect(() => {
    if (frame?.includes('custom')) {
      setFrameType('CUSTOM');
    } else {
      setFrameType('PRESET');
    }
  }, [frame]);

  return (
    <div className='flex flex-col gap-2'>
      {/* Picture Frame */}
      <div className='collapse collapse-arrow bg-base-200'>
        <input type='checkbox' className='peer' />
        <div className='collapse-title text-xl font-bold'>
          <Trans i18nKey={'gallery.decoration-frame'} />
        </div>
        <div className='collapse-content '>
          <p className='pb-5'>
            <Trans i18nKey={'gallery.decoration-frame-desc'} />
          </p>
          <div className='form-control'>
            <label className='label my-0 cursor-pointer justify-start gap-2 py-0'>
              <input
                type='radio'
                className='radio'
                // value={item.kind}
                onChange={(e) => setFrame('')}
                name='frame_type'
                checked={frameType === 'PRESET'}
              />
              <span className='label-text'>
                <Trans i18nKey={'label.select-from-presets'} />
              </span>
            </label>
          </div>
          <div
            className={`py-5 pl-8 pr-0 ${
              frameType == 'PRESET'
                ? 'pointer-events-auto opacity-100'
                : 'pointer-events-none opacity-20'
            }`}
          >
            <div className='grid max-h-[29rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll rounded-lg border border-base-300 bg-white/70 px-[1.62rem] py-[1.19rem]'>
              {filteredFrames.map((item, index) => (
                <div
                  key={`frame-${index}`}
                  className={`relative cursor-pointer`}
                  onClick={() => setFrame(item.value)}
                >
                  <img
                    src={item.imgUrl}
                    alt={item.title}
                    className={`h-auto w-full rounded ${
                      item.value == frame && 'border-[1.5px] border-black'
                    }`}
                  />
                  {item.value == '' && i18n.language === 'ja' ? (
                    <p className='mt-1 text-left text-sm'>額縁なし</p>
                  ) : (
                    <p className='mt-1 text-left text-sm'>{item.title}</p>
                  )}
                  {item.value == frame && (
                    <img
                      src='/assets/img/check_icon.png'
                      className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className='form-control'>
            <label className='label my-0 cursor-pointer justify-start gap-2 py-0'>
              <input
                type='radio'
                className='radio'
                // value={item.kind}
                onChange={(e) => setFrame('custom1')}
                name='frame_type'
                checked={frameType === 'CUSTOM'}
              />
              <span className='label-text'>
                <Trans i18nKey={'label.create-custom-frame'} />
              </span>
            </label>
          </div>
          <div
            className={`${
              frameType == 'CUSTOM'
                ? 'pointer-events-auto opacity-100'
                : 'pointer-events-none opacity-20'
            }`}
          >
            <p className={`pb-2 pl-8 pt-3 font-[600]`}>
              <Trans i18nKey={'label.select-frame-model'} />
            </p>
            <div className={`pl-8 pr-0`}>
              <div className='grid max-h-[29rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll rounded-lg border border-base-300 bg-white/70 px-[1.62rem] py-[1.19rem]'>
                {filteredCustomFrames.map((item, index) => (
                  <div
                    key={`custom-frame-${index}`}
                    className={`relative cursor-pointer`}
                    onClick={() => setFrame(item.value)}
                  >
                    <img
                      src={item.imgUrl}
                      alt={item.title}
                      className={`h-auto w-full rounded ${
                        item.value == frame && 'border-[1.5px] border-black'
                      }`}
                    />
                    <p className='mt-1 text-left text-xs'>{item.title}</p>
                    {item.value == frame && (
                      <img
                        src='/assets/img/check_icon.png'
                        className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <p className='pb-2 pl-8 pt-6 font-[600]'>
              <Trans i18nKey={'label.select-frame-color'} />
            </p>
            <div className='mt-2 pl-8'>
              <HexColorPicker color={frameColor} onChange={setFrameColor} />
              <div className='mt-2 '>
                <HexColorInput
                  color={frameColor}
                  onChange={setFrameColor}
                  className='input input-bordered w-[200px]'
                />
              </div>
            </div>
            <p className='pb-2 pl-8 pt-6 font-[600]'>
              <Trans i18nKey={'label.select-matte-color'} />
            </p>
            <div className='mt-2 pl-8'>
              <HexColorPicker color={matColor} onChange={setMatColor} />
              <div className='mt-2 '>
                <HexColorInput
                  color={matColor}
                  onChange={setMatColor}
                  className='input input-bordered w-[200px]'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Room Theme */}
      <div className='collapse collapse-arrow bg-base-200'>
        <input type='checkbox' className='peer' />
        <div className='collapse-title text-xl font-bold'>
          <Trans i18nKey={'gallery.decoration-room'} />
        </div>
        <div className='collapse-content '>
          <p>
            <Trans i18nKey={'gallery.decoration-room-desc'} />
          </p>
          {/* Preset & Color */}
          <div className='my-5'>
            {THEMES.map((item, index) => (
              <Fragment key={`theme-${index}`}>
                <div className='form-control'>
                  <label className='label cursor-pointer justify-start gap-2'>
                    <input
                      type='radio'
                      className='radio'
                      value={item.kind}
                      onChange={(e) => setThemeKind(e.target.value)}
                      name='kind'
                      checked={item.kind === themeKind}
                    />
                    <span className='label-text'>
                      {item.kind === 'Preset' && i18n.language === 'ja'
                        ? 'プリセット'
                        : item.kind === 'Color' && i18n.language === 'ja'
                        ? '色'
                        : item.label}
                    </span>
                  </label>
                </div>
                {item.kind === 'Color' && (
                  <div className='mb-3 ml-8 flex w-fit flex-col items-start rounded-xl bg-white p-5 shadow-md'>
                    <button
                      className='flex h-8 items-center gap-2 rounded-lg bg-neutral px-3 text-sm font-[600] text-neutral-content hover:opacity-70'
                      onClick={() =>
                        document
                          .getElementById('suggested_presets_modal')
                          .showModal()
                      }
                    >
                      <SparklesIcon className='h-5 w-5' />
                      <Trans
                        i18nKey={'gallery.decoration-room-preset-library'}
                      />
                    </button>
                    <button
                      className='mt-2 flex h-8 items-center gap-2 rounded-lg border border-base-content px-3 text-sm font-[600] text-base-content hover:opacity-70'
                      onClick={() =>
                        document.getElementById('my_presets_modal').showModal()
                      }
                    >
                      <FolderArrowDownIcon className='h-5 w-5' />
                      <Trans
                        i18nKey={'gallery.decoration-room-load-my-preset'}
                      />
                    </button>
                    <button
                      className='mt-6 flex h-8 items-center gap-2 rounded-lg bg-warning px-3 text-sm font-[600] text-warning-content hover:opacity-70'
                      onClick={() =>
                        document
                          .getElementById('reset_confirm_modal')
                          .showModal()
                      }
                    >
                      <ArrowPathIcon className='h-5 w-5' />
                      <Trans
                        i18nKey={'gallery.decoration-room-reset-setting'}
                      />
                    </button>
                  </div>
                )}

                <div className='pl-8'>
                  {item.themes.map((el, index) => {
                    return (
                      <Fragment key={`theme-sub-${index}`}>
                        {el.title === 'DARK' && (
                          <div className='mb-5 mt-10 font-semibold'>
                            <Trans
                              i18nKey={'gallery.decoration-background-texture'}
                            />
                          </div>
                        )}
                        <div className='form-control'>
                          <label className='label cursor-pointer justify-start gap-2'>
                            <input
                              type='radio'
                              className='radio'
                              id={el.title}
                              value={el.value}
                              onChange={(e) => setTheme(e.target.value)}
                              name='theme'
                              checked={el.value === theme}
                              disabled={themeKind !== item.kind}
                            />
                            <span
                              className={`label-text ${
                                themeKind !== item.kind ? 'text-opacity-30' : ''
                              }`}
                            >
                              {el.value === 'CUSTOM' && i18n.language === 'ja'
                                ? 'カスタム（好みの色を選択）'
                                : el.value === 'DARK' && i18n.language === 'ja'
                                ? 'デフォルト'
                                : el.displayTitle}
                            </span>
                          </label>
                        </div>
                        {el.title === 'CUSTOM' && el.value === theme && (
                          <div
                            className={`mt-2 pl-8 ${
                              wall ? 'pointer-events-none opacity-20' : ''
                            }`}
                          >
                            <HexColorPicker
                              color={colorCode}
                              onChange={setColorCode}
                            />
                            <div className='mt-2 '>
                              <HexColorInput
                                color={colorCode}
                                onChange={setColorCode}
                                className='input input-bordered w-[200px]'
                              />
                            </div>
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </Fragment>
            ))}
          </div>

          {!['CYBERPUNK', 'CLASSIC', 'PILLAR', 'BLUE_PAINT'].includes(
            theme
          ) && (
            <>
              <p className='mb-5 mt-10 pl-9'>
                <Trans i18nKey={'gallery.decoration-choose-texture'} />
              </p>
              <div
                role='tablist'
                className='tabs tabs-bordered m-0 w-full p-0 pl-8'
              >
                <a
                  role='tab'
                  className={`tab  w-full border-b-2 border-solid border-gray-200 !text-[#1F2A37] ${
                    tab === 'Ceiling' && 'tab-active'
                  }`}
                  onClick={() => {
                    setTab('Ceiling');
                  }}
                >
                  <Trans i18nKey={'gallery.decoration-room-ceiling'} />
                </a>
                <a
                  role='tab'
                  className={`tab w-full border-b-2 border-solid border-gray-200 !text-[#1F2A37] ${
                    tab === 'Wall' && 'tab-active'
                  }`}
                  onClick={() => {
                    setTab('Wall');
                  }}
                >
                  <Trans i18nKey={'gallery.decoration-room-wall'} />
                </a>
                <a
                  role='tab'
                  className={`tab w-full border-b-2 border-solid border-gray-200 !text-[#1F2A37] ${
                    tab === 'Floor' && 'tab-active'
                  }`}
                  onClick={() => {
                    setTab('Floor');
                  }}
                >
                  <Trans i18nKey={'gallery.decoration-room-floor'} />
                </a>
              </div>

              {tab == 'Ceiling' && (
                <div className='my-5 ml-8 rounded-lg border border-base-300 bg-white/70 p-5'>
                  <div className='grid max-h-[34rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll'>
                    <div
                      className={`relative cursor-pointer`}
                      onClick={() => {
                        setCeiling(null);
                        setObjectLight(null);
                      }}
                    >
                      <img
                        src={'/assets/img/ban.png'}
                        alt={'no-ceiling'}
                        className={`aspect-square w-full`}
                      />
                      <p className='mt-1 text-left text-xs capitalize'>
                        No Ceiling
                      </p>
                      {!ceiling && (
                        <img
                          src='/assets/img/check_icon.png'
                          className='absolute right-3 top-3 h-[1.375rem] w-[1.375rem]'
                        />
                      )}
                    </div>
                    {filteredCeilings.map((item, index) => (
                      <div
                        key={`ceiling-${index}`}
                        className={`relative cursor-pointer`}
                        onClick={() => setCeiling(Number(item.id))}
                      >
                        <img
                          src={item.thumbnail}
                          alt={item.title}
                          className={`aspect-square w-full`}
                        />
                        <p className='mt-1 text-left text-xs capitalize'>
                          {item.title.toLowerCase()}
                        </p>
                        {item.id == ceiling && (
                          <img
                            src='/assets/img/check_icon.png'
                            className='absolute right-3 top-3 h-[1.375rem] w-[1.375rem]'
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {tab == 'Wall' && (
                <div className='my-5 ml-8 rounded-lg border border-base-300 bg-white/70 p-5'>
                  <div className='grid max-h-[34rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll'>
                    <div
                      className={`relative cursor-pointer`}
                      onClick={() => {
                        setWall(null);
                      }}
                    >
                      <img
                        src={'/assets/img/ban.png'}
                        alt={'no-wall'}
                        className={`aspect-square w-full`}
                      />
                      <p className='mt-1 text-left text-xs capitalize'>
                        No Wall
                      </p>
                      {!wall && (
                        <img
                          src='/assets/img/check_icon.png'
                          className='absolute right-3 top-3 h-[1.375rem] w-[1.375rem]'
                        />
                      )}
                    </div>
                    {filteredWalls.map((item, index) => (
                      <div
                        key={`wall-${index}`}
                        className={`relative cursor-pointer`}
                        onClick={() => setWall(Number(item.id))}
                      >
                        <img
                          src={item.thumbnail}
                          alt={item.title}
                          className={`aspect-square w-full`}
                        />
                        <p className='mt-1 text-left text-xs capitalize'>
                          {item.title.toLowerCase()}
                        </p>
                        {item.id == wall && (
                          <img
                            src='/assets/img/check_icon.png'
                            className='absolute right-3 top-3 h-[1.375rem] w-[1.375rem]'
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {tab == 'Floor' && (
                <div className='my-5 ml-8 rounded-lg border border-base-300 bg-white/70 p-5'>
                  <div className='grid max-h-[34rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll'>
                    <div
                      className={`relative cursor-pointer`}
                      onClick={() => {
                        setFloor(null);
                      }}
                    >
                      <img
                        src={'/assets/img/ban.png'}
                        alt={'no-floor'}
                        className={`aspect-square w-full`}
                      />
                      <p className='mt-1 text-left text-xs capitalize'>
                        Default Floor
                      </p>
                      {!floor && (
                        <img
                          src='/assets/img/check_icon.png'
                          className='absolute right-3 top-3 h-[1.375rem] w-[1.375rem]'
                        />
                      )}
                    </div>

                    {filteredFloors.map((item, index) => (
                      <div
                        key={`floor-${index}`}
                        className={`relative cursor-pointer`}
                        onClick={() => {
                          // setFloor(null);
                          // setTimeout(() => {
                          //   setFloor(Number(item.id));
                          // }, 100);
                          setFloor(Number(item.id));
                        }}
                      >
                        <img
                          src={item.thumbnail}
                          alt={item.title}
                          className={`aspect-square w-full`}
                        />
                        <p className='mt-1 text-left text-xs capitalize'>
                          {item.title.toLowerCase()}
                        </p>
                        {item.id == floor && (
                          <img
                            src='/assets/img/check_icon.png'
                            className='absolute right-3 top-3 h-[1.375rem] w-[1.375rem]'
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}

          {!['CYBERPUNK', 'CLASSIC', 'PILLAR', 'BLUE_PAINT'].includes(
            theme
          ) && (
            <div className='flex flex-col gap-6 pl-8'>
              {/* Decorative Light */}
              {ceiling && (
                <label className='form-control w-full max-w-[200px] sm:max-w-xs'>
                  <div className='label justify-start gap-2'>
                    <span
                      className={`label-text text-base font-bold ${
                        themeKind === 'Preset' ? 'text-opacity-30' : ''
                      }`}
                    >
                      <Trans i18nKey={'gallery.decoration-room-deco-light'} />
                    </span>
                    <input
                      type='checkbox'
                      className='toggle'
                      onChange={() => {
                        setToggleTheme({
                          ...toggleTheme,
                          objectLight: !toggleTheme.objectLight,
                        });
                        if (objectLight) {
                          setObjectLight(null);
                        }
                      }}
                      checked={toggleTheme.objectLight}
                      disabled={themeKind === 'Preset'}
                    />
                  </div>
                  {toggleTheme.objectLight && (
                    <select
                      className='select select-bordered'
                      onChange={handleObjectLightChange}
                      value={objectLight ? objectLight : ''}
                      disabled={themeKind === 'Preset'}
                    >
                      <option disabled selected value={''}>
                        <Trans i18nKey={'gallery.pick'} />
                      </option>
                      {OBJECT_LIGHTS.map((el, index) => (
                        <option key={`object-light-${index}`} value={el.value}>
                          {el.title}
                        </option>
                      ))}
                    </select>
                  )}
                </label>
              )}

              {/* Other */}
              <label className='form-control hidden w-full max-w-xs'>
                <div className='label justify-start gap-2'>
                  <span className='label-text text-base font-bold'>Other</span>
                  <input
                    type='checkbox'
                    className='toggle'
                    onChange={() =>
                      setToggleTheme({
                        ...toggleTheme,
                        other: !toggleTheme.other,
                      })
                    }
                    disabled={themeKind === 'Preset'}
                  />
                </div>
                {toggleTheme.other && (
                  <select
                    className='select select-bordered'
                    disabled={themeKind === 'Preset'}
                  >
                    <option disabled selected>
                      <Trans i18nKey={'gallery.pick'} />
                    </option>
                    <option>Star Wars</option>
                    <option>Harry Potter</option>
                    <option>Lord of the Rings</option>
                    <option>Planet of the Apes</option>
                    <option>Star Trek</option>
                  </select>
                )}
              </label>
            </div>
          )}

          <button
            className='btn ml-8 mt-6 !bg-[#00B6FF] text-sm font-[600] hover:!bg-[#00B6FF] hover:opacity-70'
            onClick={() =>
              document.getElementById('save_preset_modal').showModal()
            }
          >
            <BookmarkIcon className='h-5 w-5' />
            <Trans i18nKey={'gallery.decoration-room-save-preset'} />
          </button>
        </div>
      </div>

      {/* Functional Light */}
      {theme != 'CYBERPUNK' &&
        theme != 'CLASSIC' &&
        theme != 'PILLAR' &&
        theme != 'BLUE_PAINT' && (
          <div className='collapse collapse-arrow bg-base-200'>
            <input type='checkbox' className='peer' ref={checkBoxLightRef} />
            <div className='collapse-title text-xl font-bold'>
              <Trans i18nKey={'gallery.decoration-func-light'} />
            </div>
            <div className='collapse-content'>
              <div className='label justify-start gap-2'>
                {/* <span className='label-text text-base'>Spotlight</span> */}
                <input
                  type='checkbox'
                  className='toggle'
                  checked={light}
                  onChange={() => {
                    if (light && toggleLighting) {
                      setToggleLighting(false);
                      setLight(null);
                      // アコーディオンを閉じる
                      if (checkBoxLightRef.current) {
                        checkBoxLightRef.current.click();
                      }
                    } else {
                      setToggleLighting(true);
                      if (ceiling != null) {
                        setLight(4);
                      } else {
                        setLight(1);
                      }
                    }
                  }}
                />
              </div>
              {(toggleLighting || light) && (
                <>
                  <div className='py-4 pl-6'>
                    {LIGHTS.map((item) => (
                      <div className='form-control' key={`light-${item.title}`}>
                        <label className='label cursor-pointer justify-start gap-2'>
                          <input
                            type='radio'
                            className='radio'
                            id={item.title}
                            value={item.value}
                            onChange={(e) => setLight(Number(e.target.value))}
                            name='light'
                            checked={item.value == light}
                            disabled={
                              (item.value == 1 ||
                                item.value == 2 ||
                                item.value == 3) &&
                              ceiling != null
                            }
                          />
                          <span
                            className={`label-text ${
                              (item.value == 1 ||
                                item.value == 2 ||
                                item.value == 3) &&
                              ceiling != null
                                ? 'text-opacity-30'
                                : ''
                            }`}
                          >
                            {item.title}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <p>
                    <Trans i18nKey={'gallery.decoration-func-light-color'} />
                  </p>
                  <div className='py-4 pl-6'>
                    {LIGHT_COLORS.map((item) => (
                      <div
                        className='form-control'
                        key={`light-color-${item.title}`}
                      >
                        <label className='label cursor-pointer justify-start gap-2'>
                          <input
                            type='radio'
                            className='radio'
                            id={item.title}
                            value={item.value}
                            onChange={(e) => setLightColor(e.target.value)}
                            name='light-color'
                            checked={item.value == lightColor}
                          />
                          <span className='label-text'>
                            {item.title.substring(0, 1).toUpperCase() +
                              item.title.substring(1).toLowerCase()}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}

      {/* Invisible Light Source */}
      {theme != 'CYBERPUNK' &&
        theme != 'CLASSIC' &&
        theme != 'PILLAR' &&
        theme != 'BLUE_PAINT' && (
          <div className='collapse collapse-arrow bg-base-200'>
            <input
              type='checkbox'
              className='peer'
              ref={checkBoxInvisibleLightRef}
            />
            <div className='collapse-title text-xl font-bold'>
              <Trans i18nKey={'gallery.decoration-light-source'} />
            </div>
            <div className='collapse-content'>
              <>
                <p className='font-[600]'>
                  <Trans i18nKey={'gallery.decoration-light-source-spot'} />
                </p>
                <div className='py-4 pl-6'>
                  <input
                    type='range'
                    min={0}
                    max='10'
                    step='1'
                    value={spotLightIntensity}
                    onChange={(e) =>
                      setSpotLightIntensity(Number(e.target.value))
                    }
                    className='range'
                  />
                  <div className='flex w-full justify-between px-2 text-xs'>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                  </div>
                  {LIGHTING_COLORS.map((el, index) => {
                    return (
                      <Fragment key={`spotlight-${index}`}>
                        <div className='form-control'>
                          <label className='label cursor-pointer justify-start gap-2'>
                            <input
                              type='radio'
                              className='radio'
                              id={el.title}
                              value={el.value}
                              onChange={(e) => {
                                setSpotLightColorTheme(e.target.value);
                                setSpotLightColorCode(el.colorCode);
                              }}
                              name='spotlight-color'
                              defaultChecked={el.value === spotLightColorTheme}
                            />
                            <span className={`label-text`}>
                              {el.value === 'CUSTOM' && i18n.language === 'ja'
                                ? 'カスタム（好みの色を選択）'
                                : el.displayTitle}
                            </span>
                          </label>
                        </div>
                        {el.title === 'CUSTOM' &&
                          el.value === spotLightColorTheme && (
                            <div className='mt-2 pl-8'>
                              <HexColorPicker
                                color={spotLightColorCode}
                                onChange={setSpotLightColorCode}
                              />
                              <div className='mt-2 '>
                                <HexColorInput
                                  color={spotLightColorCode}
                                  onChange={setSpotLightColorCode}
                                  className='input input-bordered w-[200px]'
                                />
                              </div>
                            </div>
                          )}
                      </Fragment>
                    );
                  })}
                </div>
                <p className='font-[600]'>
                  <Trans i18nKey={'gallery.decoration-light-source-direct'} />
                </p>
                <div className='py-4 pl-6'>
                  <input
                    type='range'
                    min={0}
                    max='10'
                    step='1'
                    value={directionalLightIntensity}
                    onChange={(e) =>
                      setDirectionalLightIntensity(Number(e.target.value))
                    }
                    className='range'
                  />
                  <div className='flex w-full justify-between px-2 text-xs'>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                  </div>
                  {LIGHTING_COLORS.map((el, index) => {
                    return (
                      <Fragment key={`directional-light-${index}`}>
                        <div className='form-control'>
                          <label className='label cursor-pointer justify-start gap-2'>
                            <input
                              type='radio'
                              className='radio'
                              id={el.title}
                              value={el.value}
                              onChange={(e) => {
                                setDirectionalLightColorTheme(e.target.value);
                                setDirectionalLightColorCode(el.colorCode);
                              }}
                              name='directionallight-color'
                              defaultChecked={
                                el.value === directionalLightColorTheme
                              }
                            />
                            <span className={`label-text`}>
                              {el.value === 'CUSTOM' && i18n.language === 'ja'
                                ? 'カスタム（好みの色を選択）'
                                : el.displayTitle}
                            </span>
                          </label>
                        </div>
                        {el.title === 'CUSTOM' &&
                          el.value === directionalLightColorTheme && (
                            <div className='mt-2 pl-8'>
                              <HexColorPicker
                                color={directionalLightColorCode}
                                onChange={setDirectionalLightColorCode}
                              />
                              <div className='mt-2 '>
                                <HexColorInput
                                  color={directionalLightColorCode}
                                  onChange={setDirectionalLightColorCode}
                                  className='input input-bordered w-[200px]'
                                />
                              </div>
                            </div>
                          )}
                      </Fragment>
                    );
                  })}
                </div>
              </>
            </div>
          </div>
        )}

      {/* Silhouette */}
      <div className='collapse collapse-arrow bg-base-200'>
        <input type='checkbox' className='peer' ref={checkBoxSilhouetteRef} />
        <div className='collapse-title text-xl font-bold'>
          <Trans i18nKey={'gallery.decoration-sil'} />
        </div>
        <div className='collapse-content '>
          <p className='mb-5'>
            <Trans i18nKey={'gallery.decoration-sil-desc'} />
          </p>
          <div className='label justify-start gap-2'>
            <span className='label-text text-base'>
              <Trans i18nKey={'gallery.decoration-sil-show'} />
            </span>
            <input
              checked={silhouette}
              type='checkbox'
              className='toggle'
              onChange={() => {
                if (silhouette) {
                  setCheckedSilhouettes([]);
                  // アコーディオンを閉じる
                  if (checkBoxSilhouetteRef.current) {
                    checkBoxSilhouetteRef.current.click();
                  }
                } else {
                  setCheckedSilhouettes([1, 2, 3, 4, 5, 6, 7, 8, 9]);
                }
                setSilhouette(!silhouette);
              }}
            />
          </div>
          {silhouette && (
            <>
              <p className='my-4 font-bold'>
                <Trans i18nKey={'gallery.decoration-sil-manage'} />
              </p>
              <div className='flex flex-col'>
                {filteredSilhouettes.map((el) => (
                  <div className='form-control' key={`silhouette-${el.value}`}>
                    <label className='label cursor-pointer justify-start gap-3'>
                      <input
                        type='checkbox'
                        className='checkbox'
                        checked={checkedSilhouettes.includes(el.value)}
                        onChange={() => handleSilhouettesChange(el.value)}
                      />
                      <span className='label-text'>
                        {i18n.language === 'ja' ? el.labelJA : el.label}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Music */}
      <div className='collapse collapse-arrow bg-base-200'>
        <input type='checkbox' className='peer' ref={checkBoxMusicRef} />
        <div className='collapse-title text-xl font-bold'>
          <Trans i18nKey={'gallery.decoration-music'} />
        </div>
        <div className='collapse-content '>
          <p className='mb-5'>
            <Trans i18nKey={'gallery.decoration-music-desc'} />
          </p>
          <div className='label justify-start gap-2'>
            <span className='label-text text-base'>
              <Trans i18nKey={'gallery.decoration-music-background'} />
            </span>
            <input
              type='checkbox'
              className='toggle'
              checked={music}
              onChange={() => {
                if (music) {
                  setMusic(null);
                  setToggleBackgroundMusic(false);
                  if (randomMusic) {
                    setRandomMusic(false);
                  }
                  // アコーディオンを閉じる
                  if (checkBoxMusicRef.current) {
                    checkBoxMusicRef.current.click();
                  }
                } else {
                  setToggleBackgroundMusic(true);
                  setMusic(1);
                }
              }}
            />
          </div>
          <div className='label justify-start gap-2'>
            <span className='label-text text-base'>
              <Trans i18nKey={'gallery.decoration-music-random'} />
            </span>
            <input
              type='checkbox'
              className='toggle'
              checked={randomMusic}
              onChange={() => {
                if (!randomMusic) {
                  setToggleBackgroundMusic(true);
                  setMusic(1);
                }
                setRandomMusic(!randomMusic);
              }}
            />
          </div>
          {((music && !randomMusic) ||
            (toggleBackgroundMusic && !randomMusic)) && (
            <div className='mt-5 flex flex-col gap-2'>
              <div className='flex justify-between'>
                <div className='flex gap-2'>
                  <input
                    type='radio'
                    name='music'
                    className='radio'
                    value={1}
                    onChange={(e) => setMusic(parseInt(e.target.value))}
                    checked={music === 1}
                  />
                  <p className='text-md font-bold'>
                    <Trans i18nKey={'gallery.decoration-music-sample'} /> 1
                  </p>
                </div>
                <div className='flex max-w-[248px] flex-grow items-center '>
                  {music1Playing ? (
                    <button className='ml-3 mr-2' onClick={() => pauseMusic(1)}>
                      <PlayPauseIcon className='h-5 w-5' />
                    </button>
                  ) : (
                    <button className='ml-3 mr-2' onClick={() => playMusic(1)}>
                      <PlayIcon className='h-5 w-5' />
                    </button>
                  )}
                  <div className='h-2.5 w-full rounded-full bg-[#808080]'>
                    <div
                      ref={music1ProgressBarRef}
                      className={`h-2.5 w-0 rounded-full bg-gray-700`}
                    ></div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between'>
                <div className='flex gap-2'>
                  <input
                    type='radio'
                    name='music'
                    className='radio'
                    value={2}
                    onChange={(e) => setMusic(parseInt(e.target.value))}
                    checked={music === 2}
                  />
                  <p className='text-md font-bold'>
                    <Trans i18nKey={'gallery.decoration-music-sample'} /> 2
                  </p>
                </div>
                <div className='flex max-w-[248px] flex-grow items-center'>
                  {music2Playing ? (
                    <button className='ml-3 mr-2' onClick={() => pauseMusic(2)}>
                      <PlayPauseIcon className='h-5 w-5' />
                    </button>
                  ) : (
                    <button className='ml-3 mr-2' onClick={() => playMusic(2)}>
                      <PlayIcon className='h-5 w-5' />
                    </button>
                  )}
                  <div className='h-2.5 w-full rounded-full bg-[#808080]'>
                    <div
                      ref={music2ProgressBarRef}
                      className='h-2.5 w-0 rounded-full bg-gray-700'
                    ></div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between'>
                <div className='flex gap-2'>
                  <input
                    type='radio'
                    name='music'
                    className='radio'
                    value={3}
                    onChange={(e) => setMusic(parseInt(e.target.value))}
                    checked={music === 3}
                  />
                  <p className='text-md font-bold'>
                    <Trans i18nKey={'gallery.decoration-music-sample'} /> 3
                  </p>
                </div>
                <div className='flex max-w-[248px] flex-grow items-center'>
                  {music3Playing ? (
                    <button className='ml-3 mr-2' onClick={() => pauseMusic(3)}>
                      <PlayPauseIcon className='h-5 w-5' />
                    </button>
                  ) : (
                    <button className='ml-3 mr-2' onClick={() => playMusic(3)}>
                      <PlayIcon className='h-5 w-5' />
                    </button>
                  )}
                  <div className='h-2.5 w-full rounded-full bg-[#808080]'>
                    <div
                      ref={music3ProgressBarRef}
                      className='h-2.5 w-0 rounded-full bg-gray-700'
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ResetConfirmModal resetSettings={resetSettings} />
      <SuggestedPresetsModal loadPreset={loadPreset} />
      <MyPresetsModal loadPreset={loadPreset} />
      <SavePresetModal
        presetName={presetName}
        setPresetName={setPresetName}
        handleCreatePreset={handleCreatePreset}
      />
    </div>
  );
};

export default DecorationSettings;
