import {
  BakeShadows,
  Environment,
  Image,
  OrbitControls,
  useGLTF,
} from '@react-three/drei';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import React, {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { TextureLoader } from 'three';
import PictureFrame1 from '../../components/r3f/frames/PictureFrame1';
import PictureFrame2 from '../../components/r3f/frames/PictureFrame2';
import PictureFrame3 from '../../components/r3f/frames/PictureFrame3';
import PictureFrame4 from '../../components/r3f/frames/PictureFrame4';
import PictureFrame5 from '../../components/r3f/frames/PictureFrame5';
import PictureFrame6 from '../../components/r3f/frames/PictureFrame6';
import PictureFrame17 from '../../components/r3f/frames/PictureFrame17';
import PictureFrame7 from '../../components/r3f/frames/PictureFrame7';
import PictureFrame8 from '../../components/r3f/frames/PictureFrame8';
import PictureFrame9 from '../../components/r3f/frames/PictureFrame9';
import PictureFrame10 from '../../components/r3f/frames/PictureFrame10';
import PictureFrame11 from '../../components/r3f/frames/PictureFrame11';
import PictureFrame12 from '../../components/r3f/frames/PictureFrame12';
import PictureFrame13 from '../../components/r3f/frames/PictureFrame13';
import PictureFrame14 from '../../components/r3f/frames/PictureFrame14';
import PictureFrame15 from '../../components/r3f/frames/PictureFrame15';
import PictureFrame16 from '../../components/r3f/frames/PictureFrame16';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import PictureFrame18 from '../../components/r3f/frames/PictureFrame18';
import PictureFrame19 from '../../components/r3f/frames/PictureFrame19';
import PictureFrame40 from '../../components/r3f/frames/PictureFrame40';
import PictureFrame20 from '../../components/r3f/frames/PictureFrame20';
import PictureFrame21 from '../../components/r3f/frames/PictureFrame21';
import PictureFrame22 from '../../components/r3f/frames/PictureFrame22';
import PictureFrame23 from '../../components/r3f/frames/PictureFrame23';
import PictureFrame24 from '../../components/r3f/frames/PictureFrame24';
import PictureFrame25 from '../../components/r3f/frames/PictureFrame25';
import PictureFrame26 from '../../components/r3f/frames/PictureFrame26';
import PictureFrame27 from '../../components/r3f/frames/PictureFrame27';
import PictureFrame28 from '../../components/r3f/frames/PictureFrame28';
import PictureFrame29 from '../../components/r3f/frames/PictureFrame29';
import PictureFrame30 from '../../components/r3f/frames/PictureFrame30';
import PictureFrame31 from '../../components/r3f/frames/PictureFrame31';
import PictureFrame32 from '../../components/r3f/frames/PictureFrame32';
import PictureFrame33 from '../../components/r3f/frames/PictureFrame33';
import PictureFrame34 from '../../components/r3f/frames/PictureFrame34';
import PictureFrame35 from '../../components/r3f/frames/PictureFrame35';
import PictureFrame36 from '../../components/r3f/frames/PictureFrame36';
import PictureFrame37 from '../../components/r3f/frames/PictureFrame37';
import PictureFrame38 from '../../components/r3f/frames/PictureFrame38';
import PictureFrame39 from '../../components/r3f/frames/PictureFrame39';
import PictureFrame41 from '../../components/r3f/frames/PictureFrame41';
import PictureFrame42 from '../../components/r3f/frames/PictureFrame42';
import PictureFrame43 from '../../components/r3f/frames/PictureFrame43';
import PictureFrame44 from '../../components/r3f/frames/PictureFrame44';
import PictureFrame45 from '../../components/r3f/frames/PictureFrame45';
import PictureFrame46 from '../../components/r3f/frames/PictureFrame46';
import { Perf } from 'r3f-perf';
import PictureFrame47 from '../../components/r3f/frames/PictureFrame47';
import PictureFrame48 from '../../components/r3f/frames/PictureFrame48';
import PictureFrame49 from '../../components/r3f/frames/PictureFrame49';
import PictureFrame50 from '../../components/r3f/frames/PictureFrame50';
import PictureFrame51 from '../../components/r3f/frames/PictureFrame51';
import PictureFrame52 from '../../components/r3f/frames/PictureFrame52';
import PictureFrame53 from '../../components/r3f/frames/PictureFrame53';
import PictureFrame54 from '../../components/r3f/frames/PictureFrame54';
import PictureFrame55 from '../../components/r3f/frames/PictureFrame55';
import PictureFrame56 from '../../components/r3f/frames/PictureFrame56';
import PictureFrame57 from '../../components/r3f/frames/PictureFrame57';
import PictureFrame58 from '../../components/r3f/frames/PictureFrame58';
import PictureFrame59 from '../../components/r3f/frames/PictureFrame59';
import PictureFrame60 from '../../components/r3f/frames/PictureFrame60';
import PictureFrame61 from '../../components/r3f/frames/PictureFrame61';
import PictureFrame62 from '../../components/r3f/frames/PictureFrame62';
import CustomFrame1 from '../../components/r3f/frames/CustomFrame1';
import CustomFrame2 from '../../components/r3f/frames/CustomFrame2';
import CustomFrame3 from '../../components/r3f/frames/CustomFrame3';
import CustomFrame4 from '../../components/r3f/frames/CustomFrame4';
import CustomFrame5 from '../../components/r3f/frames/CustomFrame5';
import PictureFrame63 from '../../components/r3f/frames/PictureFrame63.jsx';
import PictureFrame64 from '../../components/r3f/frames/PictureFrame64.jsx';
import PictureFrame65 from '../../components/r3f/frames/PictureFrame65.jsx';
import PictureFrame66 from '../../components/r3f/frames/PictureFrame66.jsx';
import PictureFrame67 from '../../components/r3f/frames/PictureFrame67.jsx';
import PictureFrame68 from '../../components/r3f/frames/PictureFrame68.jsx';
import PictureFrame69 from '../../components/r3f/frames/PictureFrame69.jsx';
import PictureFrame70 from '../../components/r3f/frames/PictureFrame70.jsx';
import PictureFrame71 from '../../components/r3f/frames/PictureFrame71.jsx';
import PictureFrame72 from '../../components/r3f/frames/PictureFrame72.jsx';
import PictureFrame73 from '../../components/r3f/frames/PictureFrame73.jsx';
import PictureFrame74 from '../../components/r3f/frames/PictureFrame74.jsx';
import PictureFrame75 from '../../components/r3f/frames/PictureFrame75.jsx';
import PictureFrame76 from '../../components/r3f/frames/PictureFrame76.jsx';

import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

const FramePreviewCamera = () => {
  const { camera } = useThree();

  // Log camera position every frame
  // useFrame(() => {
  //   console.log(camera);
  //   console.log(
  //     `Camera position: [${camera.position.x}, ${camera.position.y}, ${camera.position.z}]`
  //   );

  //   const direction = new THREE.Vector3();
  //   camera.getWorldDirection(direction);

  //   // Log the lookAt direction
  //   console.log(
  //     `Camera lookAt direction: [${direction.x}, ${direction.y}, ${direction.z}]`
  //   );
  // }, []);

  useEffect(() => {
    camera.lookAt(0, 0, 0);
    // camera.lookAt(0, 0.8, 1.1);
  }, [camera]);
  return null;
};

const FramePreview = ({ frame, itemUrl, frameColor, matColor }) => {
  const GOLDENRATIO = 1.61804498875;
  const imageMap = useLoader(TextureLoader, itemUrl);

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;
  const scaleValues = useMemo(() => {
    const scaleY = GOLDENRATIO;
    const scaleX = scaleY * aspectRatio;
    return { scaleX, scaleY };
  }, [aspectRatio]);
  const { scaleX, scaleY } = scaleValues;
  const canvasRef = useRef();

  useEffect(() => {
    // Canvasコンポーネントがアンマウントされたときにリソースを解放
    return () => {
      if (canvasRef.current) {
        const { gl } = canvasRef.current;
        if (gl) {
          gl.forceContextLoss();
          gl.dispose();
          canvasRef.current = null;
        }
      }

      useLoader.clear(TextureLoader, itemUrl);
    };
  }, []);

  return (
    <Canvas
      ref={canvasRef}
      dpr={[1, 1.5]}
      gl={{ preserveDrawingBuffer: true }}
      frameloop='demand'
      camera={{
        fov: 70,
        position: [0, 0, 2.74884740702664],
      }}
    >
      <FramePreviewCamera />
      {/* <OrbitControls /> */}
      <BakeShadows />
      <EffectComposer>
        <Bloom intensity={0.1} />
      </EffectComposer>
      <color attach='background' args={['#a8a8a8']} />
      <directionalLight intensity={2.5} />
      <ambientLight intensity={3} />

      <group position={[0, -0.6, 0]}>
        <mesh scale={[1, 1, 0.05]} position={[0, GOLDENRATIO / 2.5, 0]}>
          {frame == 'custom1' && (
            <CustomFrame1
              frameColor={frameColor}
              matColor={matColor}
              scale={[3.8, 2.9 / aspectRatio, 30]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.64]}
            />
          )}
          {frame == 'custom2' && (
            <CustomFrame2
              frameColor={frameColor}
              matColor={matColor}
              scale={[3.1, 2.32 / aspectRatio, 30]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.64]}
            />
          )}
          {frame == 'custom3' && (
            <CustomFrame3
              frameColor={frameColor}
              matColor={matColor}
              scale={[3.1, 2.32 / aspectRatio, 30]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.74]}
            />
          )}
          {frame == 'custom4' && (
            <CustomFrame4
              frameColor={frameColor}
              matColor={matColor}
              scale={[3.1, 2.32 / aspectRatio, 30]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.74]}
            />
          )}
          {frame == 'custom5' && (
            <CustomFrame5
              frameColor={frameColor}
              matColor={matColor}
              scale={[3.1, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.44]}
            />
          )}
          {frame === 'black' && (
            <PictureFrame17
              color={'#000'}
              scale={[2, 1.575 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
            />
          )}
          {frame === 'white' && (
            <PictureFrame17
              scale={[2, 1.575 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
              color={'#fff'}
            />
          )}

          {frame == 'gold' && (
            <PictureFrame1
              scale={[0.71, 0.71 / aspectRatio, 1.8]}
              position={[0, -GOLDENRATIO * 0.005 * aspectRatio, 0.68]}
              color='#af9615'
            />
          )}
          {frame == 'frame2' && (
            <PictureFrame2
              scale={[0.025, 0.017 / aspectRatio, 1.2]}
              position={[0, (-GOLDENRATIO * 0.4) / aspectRatio, 0.4]}
              color='gold'
            />
          )}
          {frame == 'frame3' && (
            <PictureFrame3
              scale={[1.41, 2.1 / aspectRatio, 1.5]}
              position={[0, (-GOLDENRATIO * 0.01) / aspectRatio, 0.59]}
              color='gold'
            />
          )}
          {frame == 'frame4' && (
            <PictureFrame4
              scale={[10.5, 10.5 / aspectRatio, 1.5]}
              position={[0, (GOLDENRATIO * 0.01) / aspectRatio, 0.69]}
              color='#a28d21'
            />
          )}
          {frame == 'frame5' && (
            <PictureFrame5
              scale={[0.014, 0.0115 / aspectRatio, 0.5]}
              position={[0, (-GOLDENRATIO * 0.001) / aspectRatio, 1.4]}
              color='#af9615'
            />
          )}
          {frame == 'frame6' && (
            <PictureFrame6
              scale={[2.88, 2.78 / aspectRatio, 1.6]}
              position={[
                -0.04,
                (GOLDENRATIO * 0.001) / aspectRatio + 0.035,
                0.65,
              ]}
              color='#af9615'
            />
          )}
          {frame == 'frame7' && (
            <PictureFrame7
              scale={[0.345, 0.345 / aspectRatio, 1]}
              position={[0.0, (GOLDENRATIO * 0.001) / aspectRatio - 0.01, 0.65]}
              color='black'
            />
          )}
          {frame == 'frame8' && (
            <PictureFrame8
              scale={[2, 1.58 / aspectRatio, 60]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.145,
                0.8,
              ]}
            />
          )}
          {frame == 'frame9' && (
            <PictureFrame9
              color={'#686b77'}
              scale={[2, 1.58 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
            />
          )}
          {frame == 'frame10' && (
            <PictureFrame10
              scale={[2, 1.575 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
            />
          )}
          {frame == 'frame11' && (
            <PictureFrame11
              scale={[2, 1.575 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
            />
          )}
          {frame == 'frame12' && (
            <PictureFrame12
              scale={[2, 1.575 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
              color='#0423a0'
            />
          )}
          {frame == 'frame13' && (
            <PictureFrame13
              scale={[2, 1.575 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
            />
          )}
          {frame == 'frame14' && (
            <PictureFrame14
              scale={[2, 1.575 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
            />
          )}
          {frame == 'frame15' && (
            <PictureFrame15
              scale={[2, 1.575 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
            />
          )}
          {frame == 'frame16' && (
            <PictureFrame16
              scale={[2, 1.575 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.8,
              ]}
            />
          )}
          {frame == 'frame18' && (
            <PictureFrame18
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
              color={'#00479c'}
            />
          )}
          {frame == 'frame19' && (
            <PictureFrame19
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
              color={'#00479c'}
            />
          )}
          {frame == 'frame20' && (
            <PictureFrame20
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame21' && (
            <PictureFrame21
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame22' && (
            <PictureFrame22
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame23' && (
            <PictureFrame23
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame24' && (
            <PictureFrame24
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame25' && (
            <PictureFrame25
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame26' && (
            <PictureFrame26
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame27' && (
            <PictureFrame27
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame28' && (
            <PictureFrame28
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame29' && (
            <PictureFrame29
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame30' && (
            <PictureFrame30
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame31' && (
            <PictureFrame31
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame32' && (
            <PictureFrame32
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame33' && (
            <PictureFrame33
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame34' && (
            <PictureFrame34
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame35' && (
            <PictureFrame35
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame36' && (
            <PictureFrame36
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame37' && (
            <PictureFrame37
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame38' && (
            <PictureFrame38
              scale={[3.9, 3.1 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                -0.7,
              ]}
            />
          )}
          {frame == 'frame39' && (
            <PictureFrame39
              scale={[3.6, 2.8 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.3,
              ]}
            />
          )}
          {frame == 'frame40' && (
            <PictureFrame40
              scale={[3.9, 2.8 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.4,
              ]}
            />
          )}
          {frame == 'frame41' && (
            <PictureFrame41
              scale={[3.9, 2.8 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.3,
              ]}
            />
          )}
          {frame == 'frame42' && (
            <PictureFrame42
              scale={[3.9, 2.8 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.42,
              ]}
            />
          )}
          {frame == 'frame43' && (
            <PictureFrame43
              scale={[3.9, 2.8 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.42,
              ]}
            />
          )}
          {frame == 'frame44' && (
            <PictureFrame44
              scale={[3.9, 2.8 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.42,
              ]}
            />
          )}
          {frame == 'frame45' && (
            <PictureFrame45
              scale={[3.9, 2.8 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.42,
              ]}
            />
          )}
          {frame == 'frame46' && (
            <PictureFrame46
              scale={[3.9, 2.8 / aspectRatio, 30]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.42,
              ]}
            />
          )}
          {frame == 'frame47' && (
            <PictureFrame47
              scale={[3, 2.3 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.4,
              ]}
            />
          )}
          {frame == 'frame48' && (
            <PictureFrame48
              scale={[3.3, 2.5 / aspectRatio, 20]}
              position={[
                -0.03,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.42,
              ]}
            />
          )}
          {frame == 'frame49' && (
            <PictureFrame49
              scale={[3.3, 2.5 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.42,
              ]}
            />
          )}
          {frame == 'frame50' && (
            <PictureFrame50
              scale={[2.7, 2.05 / aspectRatio, 10]}
              position={[
                0.007,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.48,
              ]}
            />
          )}
          {frame == 'frame51' && (
            <PictureFrame51
              scale={[2.68, 2.04 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.48]}
            />
          )}
          {frame == 'frame52' && (
            <PictureFrame52
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.01, 0.54]}
            />
          )}
          {frame == 'frame53' && (
            <PictureFrame53
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio + 0.01, 0.48]}
            />
          )}
          {frame == 'frame54' && (
            <PictureFrame54
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
            />
          )}
          {frame == 'frame55' && (
            <PictureFrame55
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
            />
          )}
          {frame == 'frame56' && (
            <PictureFrame56
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
            />
          )}
          {frame == 'frame57' && (
            <PictureFrame57
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
            />
          )}
          {frame == 'frame58' && (
            <PictureFrame58
              scale={[2.68, 2.04 / aspectRatio, 10]}
              position={[
                0.006,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.5,
              ]}
            />
          )}
          {frame == 'frame59' && (
            <PictureFrame59
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
            />
          )}
          {frame == 'frame60' && (
            <PictureFrame60
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
            />
          )}
          {frame == 'frame61' && (
            <PictureFrame61
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
            />
          )}
          {frame == 'frame62' && (
            <PictureFrame62
              scale={[3.2, 2.45 / aspectRatio, 10]}
              position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
            />
          )}
          {frame == 'frame63' && (
            <PictureFrame63
              scale={[3.3, 2.5 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.1,
              ]}
            />
          )}
          {frame == 'frame64' && (
            <PictureFrame64
              scale={[3.3, 2.5 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame65' && (
            <PictureFrame65
              scale={[3.3, 2.5 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame66' && (
            <PictureFrame66
              scale={[3.3, 2.5 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame67' && (
            <PictureFrame67
              scale={[3.3, 2.5 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame68' && (
            <PictureFrame68
              scale={[3.3, 2.5 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame69' && (
            <PictureFrame69
              scale={[3.2, 2.6 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame70' && (
            <PictureFrame70
              scale={[3.2, 2.6 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame71' && (
            <PictureFrame71
              scale={[3.2, 2.6 / aspectRatio, 20]}
              position={[
                0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame72' && (
            <PictureFrame72
              scale={[3.2, 2.6 / aspectRatio, 20]}
              position={[
                -0.05,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame73' && (
            <PictureFrame73
              scale={[3.2, 2.6 / aspectRatio, 20]}
              position={[
                -0.02,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}

          {frame == 'frame74' && (
            <PictureFrame74
              scale={[3.2, 2.6 / aspectRatio, 20]}
              position={[
                -0.005,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame75' && (
            <PictureFrame75
              scale={[3.2, 2.6 / aspectRatio, 20]}
              position={[
                -0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          {frame == 'frame76' && (
            <PictureFrame76
              scale={[3.2, 2.6 / aspectRatio, 20]}
              position={[
                -0.001,
                (GOLDENRATIO * 0.001) / aspectRatio - 0.001,
                0.25,
              ]}
            />
          )}
          <Image
            raycast={() => null}
            position={[0, 0, 0.7]}
            url={itemUrl}
            scale={[1.2, 1.2 / aspectRatio, 1]}
          />
        </mesh>
      </group>

      <Environment
        files={'https://data.curatedartshow.com/hdri/studio_small_07_1k.hdr'}
      />
    </Canvas>
  );
};

export default FramePreview;

function GoldFrame({ ...props }) {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/pictureFrame1.glb'
  );

  useLayoutEffect(() => {
    materials['06___Default'].color.set('gold');
    materials['06___Default'].metalness = 1;
    materials['06___Default'].roughness = 0.1;

    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });

    return () => {
      // マテリアルの解放
      Object.values(materials).forEach((material) => {
        material.dispose();
      });
      // dispose(scene);
      // dispose(materials);

      // シーンからオブジェクトの削除
      scene.traverse((obj) => {
        if (obj.isMesh) {
          obj.geometry.dispose();
          obj.material.dispose();
          scene.remove(obj);
        }
      });
    };
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={scene} {...props} />
    </Suspense>
  );
}
