import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { publicRequest, authRequest } from '../../../../../requestMethods';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../../../../../redux/userRedux';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { loadEnd, loadStart } from '../../../../../redux/loadRedux';
import { useTranslation } from 'react-i18next';

export const OnBoardingSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const { i18n, t } = useTranslation();
  const CAS1_ID = process.env.REACT_APP_STRIPE_CAS1_PRODUCT_ID;
  const CAS5_ID = process.env.REACT_APP_STRIPE_CAS5_PRODUCT_ID;
  const VIP_SUPPORT_ID = process.env.REACT_APP_STRIPE_CAS_VIP_PRODUCT_ID;

  const [plans, setPlans] = useState(null);
  const [searchParams] = useSearchParams();
  const ids = searchParams.getAll('id');
  const [currentPlan, setCurrentPlan] = useState(['']);

  const fetchPrices = async () => {
    try {
      const res = await publicRequest.get('/stripe/prices');
      setPlans(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (ids.length < 1) {
      // navigate('/404', { replace: true });
    } else {
      fetchPrices();
    }
  }, []);

  useEffect(() => {
    const signIn = async () => {
      try {
        const res = await authRequest.get(`/auth/latest`);
        // ログイン状態にする
        dispatch(loginSuccess(res.data));
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(loadEnd());
      }
    };
    dispatch(loadStart());
    //webhookより早い場合があるので意図的に遅らせる
    setTimeout(() => {
      signIn();
    }, 3000);
  }, [searchParams]);

  // 商品情報を取得し、クエリに一致するpriceIdのプランをセット
  useMemo(() => {
    if (plans && 0 < ids.length) {
      const currentPlans = plans.filter((plan) => ids.includes(plan.priceId));
      if (currentPlan.length < 1) {
        return navigate('/404', { replace: true });
      }
      return setCurrentPlan(currentPlans);
    }
  }, [plans]);

  const planName = useMemo(() => {
    return 0 < currentPlan.length && currentPlan[0].productName
      ? currentPlan[0].productName.replace('VIP', '').trim()
      : '';
  }, [currentPlan]);

  const price = useMemo(() => {
    if (0 < currentPlan.length && currentPlan[0].productName) {
      const plan = currentPlan.filter(
        (plan) => !plan.productName.includes('VIP')
      );
      return Number(plan[0].unitAmount) / 100;
    }
    return '';
  }, [currentPlan]);

  const VIPSupportText = useMemo(() => {
    if (0 < currentPlan.length && currentPlan[0].productName) {
      const vipPlan = currentPlan.filter((plan) =>
        plan.productName.includes('VIP')
      );
      return 0 < vipPlan.length
        ? `Selected ($${Number(vipPlan[0].unitAmount) / 100}/month)`
        : 'Not selected';
    }
    return '';
  });

  return (
    <>
      <div className='mx-auto w-full max-w-screen-sm px-4 py-10'>
        {0 < currentPlan.length ? (
          <div className='w-full flex-auto'>
            <h1>
              Thank you for subscribing to{' '}
              <span className='text-primary'>{planName}</span>
            </h1>
            <p>
              Your payment was successful, and your subscription to{' '}
              <span className='font-bold'>{planName}</span> is now active!
            </p>

            <div className='mt-12'>
              <div className='mt-10 overflow-x-auto'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Feature</th>
                      <th>{planName}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='font-bold'>Price</td>
                      <td>${price}/month</td>
                    </tr>
                    <tr>
                      <td className='font-bold'>Customization Limits</td>
                      <td>
                        <ul className='list-inside list-disc'>
                          {planName == 'VAU-1' ? (
                            <>
                              <li>5 picture frames</li>
                              <li>30 Walls/Floors/Ceilings</li>
                              <li>Music: Choose from 3 tracks</li>
                              <li>Visitor silhouettes: 3</li>
                            </>
                          ) : (
                            'No customization limits'
                          )}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className='font-bold'>
                        Concurrent Active Exhibitions
                      </td>
                      <td>{planName == 'VAU-1' ? '1' : '5'}</td>
                    </tr>
                    <tr>
                      <td className='font-bold'>Create/Save Exhibitions</td>
                      <td>{planName == 'VAU-1' ? '3' : '99'}</td>
                    </tr>
                    <tr>
                      <td className='font-bold'>VIP Support</td>
                      <td>{VIPSupportText}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='mt-10 flex flex-col gap-5 rounded-3xl bg-base-200 p-8 shadow-xl'>
                <p>
                  Start creating your exhibition by uploading your first artwork
                </p>
                <div className='flex flex-col gap-4'>
                  <Link
                    to={'/create-item'}
                    className='btn btn-primary btn-wide'
                  >
                    Upload Your Artwork
                  </Link>
                  <Link
                    to={'/dashboard'}
                    className='btn btn-outline btn-primary btn-wide'
                  >
                    Go to Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <span
            className='loading loading-dots loading-md absolute left-1/2 top-1/2 text-primary'
            style={{ transform: 'translate(50%, -200%)' }}
          ></span>
        )}
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default OnBoardingSuccess;
