import React, { useEffect, useRef, useState } from 'react';
import { logout } from '../redux/userRedux';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  ArchiveBoxIcon,
  ArrowRightStartOnRectangleIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  PencilSquareIcon,
  PhotoIcon,
  RectangleGroupIcon,
  BuildingLibraryIcon,
} from '@heroicons/react/24/outline';
import { authRequest } from '../requestMethods';
import { useOnClickOutside } from '../utils/useOnClickOutside';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/solid';
import { Trans, useTranslation } from 'react-i18next';
import { Footer } from '../components/Footer';
import { FooterSimple } from '../components/FooterSimple';

export const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  // プロフィール画像非表示パス
  const hideAvatarPath = [];
  const shouldHideAvatar = hideAvatarPath.some((path) =>
    pathname.includes(path)
  );
  // ヘッダー・フッター非表示パス
  const hideNavigationPath = ['galleries', 'lobby', 'admin', 'simulator'];
  const shouldHideNavigation = hideNavigationPath.some((path) =>
    pathname.includes(path)
  );
  // シンプルヘッダー・フッターパス
  const simpleNavigationPath = [
    'signin',
    'signup',
    'forgot-password',
    'reset-password',
    'resend-email',
    'create-room',
    'gallery',
    'galleries',
    'lobby',
  ];
  const shouldSimpleNavigation = simpleNavigationPath.some((path) =>
    pathname.includes(path)
  );
  // テキスト表示
  const createTitle =
    i18n.language === 'ja' ? '展示会を作成' : 'Create Exhibition';
  const editTitle = i18n.language === 'ja' ? '展示会を編集' : 'Edit Exhibition';
  const displayText = pathname.includes('create-room')
    ? createTitle
    : pathname.includes('/gallery/edit/')
    ? editTitle
    : null;
  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  const logoutHandler = async (e) => {
    e.preventDefault();
    try {
      await authRequest.post('/auth/logout');
      dispatch(logout());
      navigate('/signin');
    } catch (err) {
      console.log(err);
    }
  };

  const handleNavigate = (to) => {
    if (pathname == '/create-room' || pathname.startsWith('/gallery/edit/')) {
      const confirmMessage =
        i18n.language === 'ja'
          ? 'このページから移動してもよろしいですか？\n変更内容が保存されません。'
          : 'Are you sure you want to leave this page?\nChange you made not be saved.';
      if (window.confirm(confirmMessage)) {
        navigate(to);
      }
    } else {
      navigate(to);
    }
  };

  useEffect(() => {
    if (shouldSimpleNavigation) {
      // シンプルヘッダーの場合のみhtmlにheight:100%を付与
      // 付与すると iOS Safari でセーフエリアをタップしてもスクロールトップが効かなくなる
      document.documentElement.style.height = '100%';
    }
    return () => {
      document.documentElement.style.height = '';
    };
  }, [shouldSimpleNavigation]);

  return (
    <div id='layout' className={`h-full`}>
      <nav className='drawer h-full'>
        <input id='my-drawer-3' type='checkbox' className='drawer-toggle' />
        <div className='drawer-content flex h-full w-full flex-col'>
          {/* Navbar */}
          {!shouldHideNavigation && (
            <div className='navbar sticky top-0 z-50 min-h-[3.5rem] w-full border-b border-[#DADADA] bg-white/60 pl-4 backdrop-blur-[20px] md:min-h-[4rem]'>
              {/* Mobile Menu Bar */}
              {!shouldSimpleNavigation && (
                <div className='mr-1 flex-none md:hidden'>
                  <label
                    htmlFor='my-drawer-3'
                    aria-label='open sidebar'
                    className='btn btn-ghost px-1'
                  >
                    {user ? (
                      <div className='rounded-full'>
                        <img
                          src={
                            user.profileImageUrl
                              ? user.profileImageUrl
                              : '/assets/img/avatar/dummy_avatar.svg'
                          }
                          className='h-10 w-10 rounded-full object-cover'
                        />
                      </div>
                    ) : (
                      <Bars3BottomLeftIcon className='h-6 w-6 text-base-content' />
                    )}
                  </label>
                </div>
              )}
              {/* Title Logo */}
              <div className='flex-1'>
                <a href='/' className='flex items-center gap-4'>
                  <img
                    src='/assets/img/cas-logo.png'
                    alt='site-logo'
                    className='h-10 w-10'
                  />
                  <span className='!font-outfit text-xl font-semibold md:text-[1.75rem]'>
                    {displayText ? 'CAS' : 'Curated Art Show'}
                  </span>
                  {displayText && (
                    <p className='font-outfit text-xl font-bold text-primary md:text-2xl'>
                      {displayText}
                    </p>
                  )}
                </a>
              </div>
              {!shouldSimpleNavigation && (
                <div className='hidden flex-none md:block'>
                  <ul className='menu menu-horizontal flex gap-4 py-0'>
                    {!user && pathname === '/' && (
                      <>
                        <li>
                          <Link
                            to={'/simulator'}
                            className='btn btn-ghost font-semibold'
                          >
                            <Trans i18nKey={'header.simulator'} />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={'/faqs'}
                            className='btn btn-ghost font-semibold'
                          >
                            <Trans i18nKey={'header.faq'} />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={'/signin'}
                            className='btn btn-outline btn-accent font-semibold'
                          >
                            <Trans i18nKey={'header.signin'} />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={'/signup'}
                            className='btn btn-accent font-semibold'
                          >
                            <Trans i18nKey={'header.start-trial'} />
                          </Link>
                        </li>
                      </>
                    )}
                    {user && !shouldHideAvatar && (
                      <div className='dropdown dropdown-end' ref={dropdownRef}>
                        <label
                          tabIndex={0}
                          className='cursor-pointer rounded-full'
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                          {/* avatar */}
                          <img
                            className='hidden h-10 w-10 flex-shrink-0 rounded-full object-cover md:block'
                            src={`${
                              user?.profileImageUrl
                                ? user?.profileImageUrl
                                : '/assets/img/avatar/dummy_avatar.svg'
                            }`}
                            alt=''
                          />
                        </label>
                        {isDropdownOpen && (
                          <ul
                            tabIndex={0}
                            className='menu dropdown-content mt-4 w-64 rounded-box bg-base-100 p-2 shadow'
                          >
                            <li>
                              <a href='/settings/profile'>
                                <PencilSquareIcon className='h-6 w-6' />
                                <Trans i18nKey={'btn.edit-profile'} />
                              </a>
                            </li>
                            <li>
                              <a href='/account-settings'>
                                <Cog6ToothIcon className='h-6 w-6' />
                                <Trans i18nKey={'btn.account-settings'} />
                              </a>
                            </li>
                            <li>
                              <a href='/dashboard?tab=manage-subscription'>
                                <CreditCardIcon className='h-6 w-6' />
                                <Trans i18nKey={'btn.manage-subscription'} />
                              </a>
                            </li>
                            <div className='divider my-1' />
                            <li>
                              <a href='/dashboard?tab=artworks'>
                                <PhotoIcon className='h-6 w-6' />
                                <Trans i18nKey={'btn.artworks'} />
                              </a>
                            </li>

                            <li>
                              <a href='/dashboard?tab=galleries'>
                                <RectangleGroupIcon className='h-6 w-6' />
                                <Trans i18nKey={'btn.exhib'} />
                              </a>
                            </li>

                            <li>
                              <a href='/dashboard?tab=drafts'>
                                <ArchiveBoxIcon className='h-6 w-6' />
                                <Trans i18nKey={'btn.unpublished'} />
                              </a>
                            </li>
                            <div className='divider my-1' />
                            <li>
                              <Link
                                className='cursor-pointer'
                                onClick={logoutHandler}
                              >
                                <ArrowRightStartOnRectangleIcon className='h-6 w-6' />{' '}
                                <Trans i18nKey={'btn.signout'} />
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>
                    )}
                  </ul>
                </div>
              )}
            </div>
          )}
          {/* Page content here */}
          <main className='flex-auto'>
            <Outlet />
          </main>
          {/* Footer */}
          {!shouldHideNavigation &&
            (shouldSimpleNavigation ? <FooterSimple /> : <Footer />)}
        </div>
        <div className='drawer-side z-[60]'>
          <label
            htmlFor='my-drawer-3'
            aria-label='close sidebar'
            className='drawer-overlay'
          ></label>
          <ul className='menu min-h-full w-[300px] bg-base-200 px-4 py-6 text-base-content'>
            {user ? (
              <>
                {/* ログイン中 */}
                <li className='pb-4 pl-4 text-lg font-bold'>
                  {user.displayName}
                </li>
                <li className='text-sm'>
                  <a href={'/settings/profile'}>
                    <PencilSquareIcon className='h-4 w-4' />
                    <Trans i18nKey={'btn.edit-profile'} />
                  </a>
                </li>
                <li className='text-sm'>
                  <a href={'/account-settings'}>
                    <Cog6ToothIcon className='h-4 w-4' />
                    <Trans i18nKey={'btn.account-settings'} />
                  </a>
                </li>
                <li className='text-sm'>
                  <a href={'/dashboard?tab=manage-subscription'}>
                    <CreditCardIcon className='h-4 w-4' />
                    <Trans i18nKey={'btn.manage-subscription'} />
                  </a>
                </li>
                <li>
                  <div className='divider m-0'></div>
                </li>
                <li className='text-sm'>
                  <a href={'/dashboard?tab=artworks'}>
                    <PhotoIcon className='h-4 w-4' />
                    <Trans i18nKey={'btn.artworks'} />
                  </a>
                </li>
                <li className='text-sm'>
                  <a href={'/dashboard?tab=galleries'}>
                    <BuildingLibraryIcon className='h-4 w-4' />
                    <Trans i18nKey={'btn.exhib'} />
                  </a>
                </li>
                <li className='text-sm'>
                  <a href={'/dashboard?tab=drafts'}>
                    <ArchiveBoxIcon className='h-4 w-4' />
                    <Trans i18nKey={'btn.unpublished'} />
                  </a>
                </li>
                <li>
                  <div className='divider m-0'></div>
                </li>
                <li className='text-sm'>
                  <span onClick={logoutHandler}>
                    <ArrowRightStartOnRectangleIcon className='h-4 w-4' />
                    <Trans i18nKey={'btn.signout'} />
                  </span>
                </li>
              </>
            ) : (
              <>
                {/* ログアウト後 */}
                <li className='text-sm'>
                  <Link to={'/simulator'}>
                    <Trans i18nKey={'header.simulator'} />
                  </Link>
                </li>
                <li className='text-sm'>
                  <Link to={'/faqs'}>
                    <Trans i18nKey={'header.faq'} />
                  </Link>
                </li>
                <li className='text-sm'>
                  <Link to={'/signin'}>
                    <Trans i18nKey={'header.signin'} />
                  </Link>
                </li>
                <li className='inline'>
                  <Link to={'/signup'} className='btn btn-accent mt-5'>
                    <Trans i18nKey={'header.start-trial'} />
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};
