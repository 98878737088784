import {
  EyeSlashIcon,
  EyeIcon,
  PlusCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ItemModal from './ItemModal';
import { Trans } from 'react-i18next';

const ItemCardWithMenu = ({
  item,
  setAddToGalleryItem,
  setDraftItem,
  togglePublish,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  useEffect(() => {
    if (id == item.id) setShowModal(true);
  }, [id]);

  const openModal = () => {
    document.body.classList.add('is-scrollLocked');
    setShowModal(true);
  };
  return (
    <>
      <div className='card w-full bg-base-100 shadow-xl'>
        <figure className='aspect-[1/0.645] cursor-pointer' onClick={openModal}>
          <img
            src={item?.itemUrl}
            alt={item.title}
            className='h-full w-full object-cover'
          />
        </figure>
        <div className='card-body p-4'>
          <div className='flex justify-start'>
            <h2
              className='m-0 cursor-pointer break-all text-lg font-medium hover:underline'
              onClick={() => setShowModal(true)}
            >
              {item.title}
            </h2>
          </div>
          <div className='flex items-center gap-2'>
            <img
              className='h-6 w-6 flex-shrink-0 rounded-full object-cover'
              src={
                item?.creator?.profileImageUrl
                  ? item?.creator?.profileImageUrl
                  : '/assets/img/avatar/dummy_avatar.svg'
              }
              alt='profile'
            />
            <p className='text-xs'>{item?.creator?.displayName}</p>
          </div>
          <div className='flex flex-col items-end gap-2'>
            <div className='flex gap-2'>
              {setAddToGalleryItem ? (
                <label
                  className='btn btn-error btn-outline btn-sm'
                  onClick={() => {
                    setDraftItem(item);
                    document
                      .getElementById('draft_confirmation_modal')
                      .showModal();
                  }}
                >
                  <EyeSlashIcon className='h-5 w-5' />
                  <Trans i18nKey={'btn.unpublish'} />
                </label>
              ) : (
                <label
                  className='btn btn-sm !bg-accent'
                  onClick={() => togglePublish(item.id)}
                >
                  <EyeIcon className='h-5 w-5' />
                  <Trans i18nKey={'btn.publish'} />
                </label>
              )}
              <Link
                to={`/items/edit/${item?.id}`}
                className='btn btn-outline btn-sm normal-case'
              >
                <PencilSquareIcon className='h-5 w-5' />
                <Trans i18nKey={'btn.edit'} />
              </Link>
            </div>
            {setAddToGalleryItem && (
              <label
                htmlFor='my-modal'
                data-bs-toggle='modal'
                data-bs-target='#addGalleryModal'
                onClick={(e) => {
                  e.stopPropagation();
                  setAddToGalleryItem(item);
                }}
                className='btn btn-sm normal-case'
              >
                <PlusCircleIcon className='h-5 w-5' />
                <Trans i18nKey={'btn.add-to-exhib'} />
              </label>
            )}
          </div>
        </div>
      </div>
      {showModal && <ItemModal item={item} setShowModal={setShowModal} />}
    </>
  );
};

export default ItemCardWithMenu;
