import { BakeShadows, Environment } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useState } from 'react';

import CustomFloor from '../../components/r3f/floors/CustomFloor';
import CustomWall from '../../components/r3f/walls/CustomWall';
import CustomCeiling from '../../components/r3f/ceilings/CustomCeiling';
import Loader from '../../components/r3f/Loader';
import ItemOnPreview from '../../components/r3f/preview/ItemOnPreview';
import SimulatorOverlay from './components/SimulatorOverlay';
import SimulatorCamera from './components/SimulatorCamera';

const Simulator = () => {
  const bgColor = '#333333';
  const directLightIntensity = 1;
  const ambientLightIntensity = 1;
  const sampleItems = [
    {
      title: 'Sample Artwork',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      type: 1,
      itemUrl:
        'https://images.unsplash.com/photo-1531489956451-20957fab52f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fHBhaW50aW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
      coverImageUrl: '',
      createdYear: '2024',
      creatorId: 3,
      ownerId: 3,
      artworkUrl: 'https://www.google.com/',
      artworkUrlLabel: 'View artworks on site',
    },
    {
      title: 'Sample Artwork',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      type: 1,
      itemUrl:
        'https://images.unsplash.com/photo-1541961017774-22349e4a1262?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cGFpbnRpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
      coverImageUrl: '',
      createdYear: '2024',
      creatorId: 3,
      ownerId: 3,
      artworkUrl: 'https://www.google.com/',
      artworkUrlLabel: 'View artworks on site',
    },
    {
      title: 'Sample Artwork',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      type: 1,
      itemUrl:
        'https://images.unsplash.com/photo-1578059457703-850565b053f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      coverImageUrl: '',
      createdYear: '2024',
      creatorId: 3,
      ownerId: 3,
      artworkUrl: 'https://www.google.com/',
      artworkUrlLabel: 'View artworks on site',
    },
  ];

  const [ceiling, setCeiling] = useState(1);
  const [wall, setWall] = useState(58);
  const [floor, setFloor] = useState(10);
  const [frame, setFrame] = useState(null);

  return (
    <div className='relative h-full w-full'>
      <SimulatorOverlay
        ceiling={ceiling}
        setCeiling={setCeiling}
        wall={wall}
        setWall={setWall}
        floor={floor}
        setFloor={setFloor}
        frame={frame}
        setFrame={setFrame}
      />

      <div className={`fixed h-full w-full overflow-hidden`}>
        <Canvas
          shadows
          dpr={1}
          frameloop='demand'
          camera={{
            fov: 70,

            position: [0, 1.3893446629166666, 6.4],
          }}
          gl={{ preserveDrawingBuffer: true }}
        >
          <SimulatorCamera />
          {/* <OrbitControls /> */}
          <BakeShadows />
          <EffectComposer></EffectComposer>
          <Suspense fallback={<Loader />}>
            <color attach='background' args={[`${bgColor}`]} />
            <fog attach='fog' args={['#191920', 4, 15]} />

            <directionalLight
              position={[0, 2, 20]}
              target-position={[0, 2, 0]}
              intensity={directLightIntensity}
              castShadow
            />
            <ambientLight intensity={ambientLightIntensity} color={'#ffffff'} />

            {/* ceiling */}
            {ceiling && <CustomCeiling id={ceiling} />}

            {/* wall */}
            {wall && <CustomWall id={wall} />}

            {/* floor */}
            {floor && <CustomFloor id={floor} />}

            {sampleItems.map((item, index) => (
              <ItemOnPreview
                position={[-3 + 3 * index, 1.2, 3]}
                item={item}
                frame={frame}
                frameColor={'#ffffff'}
                matColor={'#ffffff'}
              />
            ))}

            <Environment
              files={
                'https://data.curatedartshow.com/hdri/studio_small_07_1k.hdr'
              }
            />
          </Suspense>
        </Canvas>
      </div>
    </div>
  );
};

export default Simulator;
