import { Clone, useAnimations, useGLTF } from '@react-three/drei';
import { useEffect, useLayoutEffect } from 'react';

const Woman2 = ({ ...props }) => {
  const { scene, materials, animations } = useGLTF(
    'https://data.curatedartshow.com/models/woman2_non_animation.glb'
  );
  const { actions } = useAnimations(animations, scene);

  useEffect(() => {
    return () => {
      //リソースのクリーンアップ処理
      useGLTF.clear(
        'https://data.curatedartshow.com/models/woman2_non_animation.glb'
      );
    };
  }, []);

  useLayoutEffect(() => {
    // console.log(materials);

    materials['Autumn.001'].roughness = 1;
    materials['Autumn.001'].metalness = 1;
    materials['Autumn.001'].opacity = 1;
    materials['Autumn.001'].transparent = true;
    materials['Autumn.001'].color.set('#000');

    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });

    return () => {
      // マテリアルの解放
      Object.values(materials).forEach((material) => {
        material.dispose();
      });

      // シーンからオブジェクトの削除
      scene.traverse((obj) => {
        if (obj.isMesh) {
          obj.geometry.dispose();
          obj.material.dispose();
          scene.remove(obj);
        }
      });
    };
  }, []);
  return <primitive object={scene} {...props} />;
};

export default Woman2;
