import React, {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { dispose, useLoader, useThree } from '@react-three/fiber';
import {
  Environment,
  useGLTF,
  Image,
  useTexture,
  Decal,
  BakeShadows,
  useHelper,
  OrbitControls,
} from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

import Light1 from '../lights/Light1.js';
import Light2 from '../lights/Light2.js';
import Light3 from '../lights/Light3.js';
import Light4 from '../lights/Light4.js';
import Light5 from '../lights/Light5.js';
import Light6 from '../lights/Light6.js';
import Light7 from '../lights/Light7.js';
import Light8 from '../lights/Light8.js';
import Light9 from '../lights/Light9.js';
import Man1 from '../models/Man1.jsx';
import * as THREE from 'three';
import BasicFloorPreview from '../floors/BasicFloorPreview.jsx';

import CustomCeiling from '../ceilings/CustomCeiling.jsx';
import CustomWall from '../walls/CustomWall.jsx';
import CustomFloor from '../floors/CustomFloor.jsx';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import ObjectLight1 from '../models/objectLights/ObjectLight1.jsx';
import ObjectLight2 from '../models/objectLights/ObjectLight2.jsx';
import ObjectLight3 from '../models/objectLights/ObjectLight3.jsx';
import SpotLightOnPreview from '../lights/SpotLightOnPreview.js';

import ItemOnPreview from './ItemOnPreview.jsx';

const GOLDENRATIO = 1.61804498875;
const boxGeometry = new THREE.BoxGeometry(1, GOLDENRATIO, 0.05);

const BasicRoomPreview = ({
  theme,
  frame,
  showSilhouette,
  setSnapshot,
  light,
  items,
  lightColor,
  ceiling,
  wall,
  floor,
  objectLight,
  colorCode,
  directionalLightIntensity,
  directionalLightColorCode,
  spotLightIntensity,
  spotLightColorCode,
  frameColor,
  matColor,
}) => {
  const GOLDENRATIO = 1.61803398875;
  const { gl, camera } = useThree();
  const [bgColor, setBgColor] = useState('#191920');
  const [floorColor, setFloorColor] = useState('#050505');

  const directLightIntensity =
    floor == 4 || floor == 6 || floor == 11 ? 1 : 3.5;
  const ambientLightIntensity = 1;

  useEffect(() => {
    switch (theme) {
      case 'DARK':
        setBgColor('#191920');
        setFloorColor('#333333');
        break;
      case 'LIGHT':
        setBgColor('#a8a8a8');
        setFloorColor('#333333');
        break;
      case 'PINK':
        setBgColor('#ff3399');
        setFloorColor('#331122');
        break;
      case 'BLUE':
        setBgColor('#2222ff');
        setFloorColor('#111122');
        break;
      case 'RED':
        setBgColor('#ff2222');
        setFloorColor('#221111');
        break;
      case 'CUSTOM':
        setBgColor(colorCode);
        setFloorColor('#333333');
        break;
      default:
        setBgColor('#191920');
        setFloorColor('#050505');
        break;
    }
  }, [theme, colorCode]);

  const imageMap = useLoader(
    TextureLoader,
    items.length > 0 ? items[0]?.itemUrl : '/assets/img/sample_item_image.png'
  );

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;
  const scaleValues = useMemo(() => {
    const scaleY = GOLDENRATIO;
    const scaleX = scaleY * aspectRatio;
    return { scaleX, scaleY };
  }, [aspectRatio]);
  const { scaleX, scaleY } = scaleValues;

  useEffect(() => {
    return () => {
      gl.dispose();
      // imageMap.dispose();
      useLoader.clear(
        TextureLoader,
        items.length > 0
          ? items[0]?.itemUrl
          : '/assets/img/sample_item_image.png'
      );
    };
  }, [gl]);

  useEffect(() => {
    camera.lookAt(0, 0.8, 1.1);
  }, [camera]);

  //lightのdebug用
  // const dirLight = useRef(null);
  // useHelper(dirLight, THREE.DirectionalLightHelper, 'red');

  return (
    <>
      {/* <OrbitControls /> */}
      <BakeShadows />
      <EffectComposer>
        <Bloom intensity={0.1} />
      </EffectComposer>
      <color attach='background' args={[`${bgColor}`]} />
      <fog attach='fog' args={[`${bgColor}`, 6, 15]} />

      <directionalLight
        // ref={dirLight}
        position={[0, 2, 20]}
        target-position={[0, 2.5, 0]}
        intensity={
          directionalLightIntensity != null
            ? directionalLightIntensity * 0.5
            : directLightIntensity
        }
        color={
          directionalLightColorCode && directionalLightIntensity > 0
            ? directionalLightColorCode
            : '#ffffff'
        }
        // castShadow
      />
      <ambientLight
        intensity={
          directionalLightIntensity != null
            ? directionalLightIntensity * 0.1
            : ambientLightIntensity
        }
        color={
          directionalLightColorCode && directionalLightIntensity > 0
            ? directionalLightColorCode
            : '#ffffff'
        }
      />

      {light == 1 && <Light1 color={lightColor} intensity={0.5} />}
      {light == 2 && <Light2 color={lightColor} intensity={0.5} />}
      {light == 3 && <Light3 color={lightColor} intensity={0.5} />}
      {light == 4 && <Light4 color={lightColor} intensity={0.3} />}
      {light == 5 && <Light5 color={lightColor} intensity={0.3} />}
      {light == 6 && <Light6 color={lightColor} intensity={0} />}
      {light == 7 && <Light7 color={lightColor} intensity={0.1} />}
      {light == 8 && <Light8 color={lightColor} intensity={0.1} />}
      {light == 9 && <Light9 color={lightColor} intensity={0.1} />}

      {objectLight == 1 &&
        Array.from({ length: 2 }, (_, index) => (
          <ObjectLight1
            key={index}
            position={[-4 + index * 8, 1.7, 0.6]}
            scale={1}
          />
        ))}
      {objectLight == 2 &&
        Array.from({ length: 2 }, (_, index) => (
          <ObjectLight2
            key={index}
            position={[-4 + index * 8, 4, 0.2]}
            scale={1.2}
          />
        ))}
      {objectLight == 3 &&
        Array.from({ length: 2 }, (_, index) => (
          <ObjectLight3
            key={index}
            position={[-4 + index * 8, 4.4, 0.12]}
            scale={1}
          />
        ))}
      {spotLightIntensity > 0 && (
        <SpotLightOnPreview
          opacity={
            spotLightIntensity != null ? 0.1 * spotLightIntensity : 0.1 * 2
          }
          anglePower={0}
          angle={0.5}
          position={[-0.1, 3.2, 4]}
          targetPosition={[0, -2, 1]}
          color={spotLightColorCode}
        />
      )}

      <group position={[0, -0.5, 0]}>
        {items.length == 1 && (
          <ItemOnPreview
            position={[-0.2, 0.85, 3]}
            item={items[0]}
            frame={frame}
            frameColor={frameColor}
            matColor={matColor}
          />
        )}
        {items.length == 2 && (
          <>
            <ItemOnPreview
              position={[-1.3, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[1.3, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {items.length >= 3 && (
          <>
            <ItemOnPreview
              position={[-3.6, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[-0.2, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[3.6, 0.85, 3]}
              item={items[2]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {showSilhouette && (
          <Man1
            key={showSilhouette}
            position={[-0.5, 0, 3.8]}
            scale={0.45}
            rotation-y={Math.PI}
          />
        )}
        {/* ceiling */}
        {ceiling && <CustomCeiling id={ceiling} />}
        {/* wall */}
        {wall && <CustomWall id={wall} />}
        {/* floor */}
        {floor ? (
          <CustomFloor id={floor} />
        ) : (
          <BasicFloorPreview floorColor={floorColor} />
        )}
      </group>
      <Environment
        files={'https://data.curatedartshow.com/hdri/studio_small_07_1k.hdr'}
      />
    </>
  );
};

export default BasicRoomPreview;
