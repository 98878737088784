import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import ReactGA from 'react-ga4';

import Discovery from './pages/Discovery';
import Lobby from './pages/Lobby';

import Signin from './pages/user/Signin';
import Signup from './pages/user/signup';
import Room from './pages/room/Room';
import Dashboard from './pages/dashboard/Dashboard';
import { useSelector } from 'react-redux';
import EditProfile from './pages/user/EditProfile';
import CreateItem from './pages/item/CreateItem';

import CreateRoom from './pages/room/CreateRoom';
import EditRoom from './pages/room/EditRoom';
import EditItem from './pages/item/EditItem';
import TermsPrivacy from './pages/legal/TermsPrivacy';
import CookiePolicy from './pages/legal/CookiePolicy';
import AdminLogin from './pages/admin/login/Login';

import { Toaster } from 'react-hot-toast';
import ForgotPassword from './pages/user/ForgotPassword';
import ResetPassword from './pages/user/ResetPassword';

import NotFound from './pages/NotFound';

import { useEffect } from 'react';
import ResendEmail from './pages/user/ResendMail';

import { Helmet } from 'react-helmet-async';
import i18n from './i18n/config';
import { I18nextProvider, useTranslation } from 'react-i18next';
import AdminUserList from './pages/admin/user/UserList';
import { OnBoarding } from './pages/user/signup/onboarding';
import Contact from './pages/contact';
import SubscriptionUpgrade from './pages/subscription/upgrade';
import SubscriptionDowngrade from './pages/subscription/downgrade';
import AdminLayout from './pages/admin/Layout';
import AdminCouponCodeList from './pages/admin/coupon/CouponCodeList';
import AdminCouponCodeCreate from './pages/admin/coupon/CouponCodeCreate';
import AdminCouponCodeEdit from './pages/admin/coupon/CouponCodeEdit';
import AdminArtworkList from './pages/admin/artwork/ArtworkList';
import AdminExhibitionList from './pages/admin/exhibition/ExhibitionList';
import AdminArtworkReports from './pages/admin/artwork-reports/ArtworkReports';
import Exhibitions from './pages/exhibitions';
import AccountSettings from './pages/account-settings';
import NewEmailConfirmation from './pages/new-email-confirmation';
import AdminDeletedArtworkList from './pages/admin/artwork/DeletedArtworkList';
import { Layout } from './pages/Layout';
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query';
import Subscription from './pages/subscription';
import OnboardingSubscription from './pages/user/signup/onboarding/subscription';
import OnboardingSuccess from './pages/user/signup/onboarding/success';
import Simulator from './pages/simulator';
import { AdminFaqList } from './pages/admin/faq/FaqList';
import { AdminFaqCreate } from './pages/admin/faq/FaqCreate';
import { AdminFaqDetails } from './pages/admin/faq/FaqDetails';
import { AdminFaqEdit } from './pages/admin/faq/FaqEdit';
import { Faqs } from './pages/faqs';

const queryCacheOnError = (err, query) => {
  if (!err?.response) {
    return;
  }
  const status = err.response.status;
  // meta.errCodeごとにqueryCache内で処理を分岐させる
  if (
    query.meta?.errCode.includes('ADMIN') &&
    (status === 403 || status === 401)
  ) {
    window.location.replace('/admin/login');
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: queryCacheOnError,
  }),
});

function App() {
  useTranslation();
  const user = useSelector((state) => state.user.currentUser);

  window.addEventListener('storage', function (event) {
    if (event.key === 'i18nextLng') {
      const newLang = event.newValue;
      updateLanguage(newLang);
    }
  });

  function updateLanguage(lang) {
    if (lang === 'ja') {
      document.documentElement.classList.add('lang-ja');
      document.documentElement.classList.remove('lang-en');
    } else {
      document.documentElement.classList.add('lang-en');
      document.documentElement.classList.remove('lang-ja');
    }
  }

  // 初期ロード時の言語設定
  updateLanguage(localStorage.getItem('i18nextLng'));

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <InnerComponent />
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route
                exact
                path='/'
                element={
                  <>
                    <Helmet>
                      {process.env.REACT_APP_ENV == 'development' ? (
                        <meta name='robots' content='noindex, nofollow' />
                      ) : (
                        <meta name='robots' content='index, nofollow' />
                      )}
                    </Helmet>
                    <Discovery />
                  </>
                }
              />
              <Route
                path='/exhibitions'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <Exhibitions />
                  </>
                }
              />
              <Route
                path='/galleries/:id'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <Room />
                  </>
                }
              />
              <Route
                path='/gallery/edit/:id'
                element={
                  user ? (
                    <>
                      <Helmet>
                        <meta name='robots' content='noindex, nofollow' />
                      </Helmet>
                      <EditRoom />
                    </>
                  ) : (
                    <Navigate to='/signin' />
                  )
                }
              />
              <Route
                path='/create-room'
                element={
                  user ? (
                    <>
                      <Helmet>
                        <meta name='robots' content='noindex, nofollow' />
                      </Helmet>
                      <CreateRoom />
                    </>
                  ) : (
                    <Navigate to='/signin' />
                  )
                }
              />

              <Route
                path='/lobby/:username'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <Lobby />
                  </>
                }
              />
              <Route
                path='/account-settings'
                element={
                  user ? (
                    <>
                      <Helmet>
                        <meta name='robots' content='noindex, nofollow' />
                      </Helmet>
                      <AccountSettings />
                    </>
                  ) : (
                    <Navigate to='/signin' />
                  )
                }
              />
              <Route
                path='/email-confirmation'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <NewEmailConfirmation />
                  </>
                }
              />
              <Route
                path='/settings/profile'
                element={
                  user ? (
                    <>
                      <Helmet>
                        <meta name='robots' content='noindex, nofollow' />
                      </Helmet>
                      <EditProfile />
                    </>
                  ) : (
                    <Navigate to='/signin' />
                  )
                }
              />
              <Route
                path='/create-item'
                element={
                  user ? (
                    <>
                      <Helmet>
                        <meta name='robots' content='noindex, nofollow' />
                      </Helmet>
                      <CreateItem />
                    </>
                  ) : (
                    <Navigate to='/signin' />
                  )
                }
              />
              <Route
                path='/items/edit/:id'
                element={
                  user ? (
                    <>
                      <Helmet>
                        <meta name='robots' content='noindex, nofollow' />
                      </Helmet>
                      <EditItem />
                    </>
                  ) : (
                    <Navigate to='/signin' />
                  )
                }
              />
              <Route
                path='/dashboard'
                element={
                  user ? (
                    <>
                      <Helmet>
                        <meta name='robots' content='noindex, nofollow' />
                      </Helmet>
                      <Dashboard />
                    </>
                  ) : (
                    <Navigate to='/signin' />
                  )
                }
              />
              <Route
                path='/subscription'
                element={
                  user ? (
                    <>
                      <Helmet>
                        <meta name='robots' content='noindex, nofollow' />
                      </Helmet>
                      <Subscription />
                    </>
                  ) : (
                    <Navigate to='/signin' />
                  )
                }
              />
              {/* <Route
            path='/subscription/upgrade'
            element={
              user ? (
                <>
                  <Helmet>
                    <meta name='robots' content='noindex, nofollow' />
                  </Helmet>
                  <SubscriptionUpgrade />
                </>
              ) : (
                <Navigate to='/signin' />
              )
            }
          /> */}
              {/* <Route
            path='/subscription/downgrade'
            element={
              user ? (
                <>
                  <Helmet>
                    <meta name='robots' content='noindex, nofollow' />
                  </Helmet>
                  <SubscriptionDowngrade />
                </>
              ) : (
                <Navigate to='/signin' />
              )
            }
          /> */}
              <Route
                path='/signin'
                element={
                  user ? (
                    <Navigate to='/dashboard' />
                  ) : (
                    <>
                      <Helmet>
                        <meta name='robots' content='noindex, nofollow' />
                      </Helmet>
                      <Signin />
                    </>
                  )
                }
              />
              <Route
                path='/signup'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <Signup />
                  </>
                }
              />
              <Route
                path='/signup/onboarding'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <OnBoarding />
                  </>
                }
              />
              <Route
                path='/signup/onboarding/subscription'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <OnboardingSubscription />
                  </>
                }
              />
              <Route
                path='/signup/onboarding/success'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <OnboardingSuccess />
                  </>
                }
              />

              <Route
                path='/forgot-password'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <ForgotPassword />
                  </>
                }
              />
              <Route
                path='/resend-email'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <ResendEmail />
                  </>
                }
              />
              <Route
                path='/reset-password'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <ResetPassword />
                  </>
                }
              />
              <Route
                path='/terms-privacy'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <TermsPrivacy />
                  </>
                }
              />
              <Route
                path='/cookie-policy'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <CookiePolicy />
                  </>
                }
              />
              <Route
                path='/contact'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <Contact />
                  </>
                }
              />
              <Route
                path='/faqs'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <Faqs />
                  </>
                }
              />
              <Route
                path='/simulator'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <Simulator />
                  </>
                }
              />
              {/* admin */}
              <Route
                path='/admin/login'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <AdminLogin />
                  </>
                }
              />
              <Route path='/admin' element={<AdminLayout user={user} />}>
                <Route path='coupon-codes' element={<AdminCouponCodeList />} />
                <Route
                  path='coupon-code-create'
                  element={<AdminCouponCodeCreate />}
                />
                <Route
                  path='coupon-code-edit/:id'
                  element={<AdminCouponCodeEdit />}
                />
                <Route path='users' element={<AdminUserList />} />
                <Route path='artworks' element={<AdminArtworkList />} />
                <Route
                  path='deleted-artworks'
                  element={<AdminDeletedArtworkList />}
                />
                <Route path='exhibitions' element={<AdminExhibitionList />} />
                <Route
                  path='artwork-reports'
                  element={<AdminArtworkReports />}
                />
              </Route>
              <Route
                path='/not-found'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <NotFound />
                  </>
                }
              />
              <Route
                path='*'
                element={
                  <>
                    <Helmet>
                      <meta name='robots' content='noindex, nofollow' />
                    </Helmet>
                    <NotFound />
                  </>
                }
              />
            </Route>
            {/* admin Login */}
            <Route path='/admin/login' element={<AdminLogin />} />
            {/* admin */}
            <Route
              path='/admin'
              element={
                <>
                  <Helmet>
                    <meta name='robots' content='noindex, nofollow' />
                  </Helmet>
                  <AdminLayout user={user} />
                </>
              }
            >
              <Route path='coupon-codes' element={<AdminCouponCodeList />} />
              <Route
                path='coupon-code-create'
                element={<AdminCouponCodeCreate />}
              />
              <Route
                path='coupon-code-edit/:id'
                element={<AdminCouponCodeEdit />}
              />
              <Route path='users' element={<AdminUserList />} />
              <Route path='artworks' element={<AdminArtworkList />} />
              <Route
                path='deleted-artworks'
                element={<AdminDeletedArtworkList />}
              />
              <Route path='exhibitions' element={<AdminExhibitionList />} />
              <Route path='artwork-reports' element={<AdminArtworkReports />} />
              <Route path='faqs' element={<AdminFaqList />} />
              <Route path='faqs-create' element={<AdminFaqCreate />} />
              <Route path='faqs-edit/:id' element={<AdminFaqEdit />} />
              <Route path='faqs/:id' element={<AdminFaqDetails />} />
            </Route>
          </Routes>
          <Toaster />
        </I18nextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;

function InnerComponent() {
  const location = useLocation();

  useEffect(() => {
    document.body.classList.remove('is-scrollLocked');
    window.scrollTo(0, 0);
    if (
      ReactGA.isInitialized &&
      process.env.REACT_APP_ENV != 'development' &&
      process.env.NODE_ENV == 'production'
    ) {
      // console.log('ga init done');
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
        title: location.pathname,
      });
    }
    // else {
    //   console.log('ga not init yet');
    // }
  }, [location]);
}
