import { BakeShadows, Environment } from '@react-three/drei';

import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense } from 'react';
import Loader from '../Loader';
import Items from '../Items';
// import FluorescentLamp from '../lights/FluorescentLamp';
// import CeilingLight from '../lights/CeilingLight';
import Man2 from '../models/Man2';
import Man3 from '../models/Man3';
import Man4 from '../models/Man4';

import Woman1 from '../models/Woman1';
import Man1 from '../models/Man1';
import Floor from '../floors/Floor';
import ClassicWall from '../walls/ClassicWall';
import ClassicFloor from '../floors/ClassicFloor';
import MovingSpotLight from '../lights/MovingSpotLight';
import Woman2 from '../models/Woman2';
import Woman3 from '../models/Woman3';
import Kids3 from '../models/Kids3';
import Kids2 from '../models/Kids2';
import Kids1 from '../models/Kids1';
import CustomCeiling from '../ceilings/CustomCeiling';
import CustomWall from '../walls/CustomWall';
import CustomFloor from '../floors/CustomFloor';
import ObjectLight1 from '../models/objectLights/ObjectLight1';
import ObjectLight2 from '../models/objectLights/ObjectLight2';
import ObjectLight3 from '../models/objectLights/ObjectLight3';

const ClassicRoom = ({
  room,
  roomSettings,
  items,
  currentItemId,
  setCurrentItemId,
  isActive,
  setIsActive,
  setSelectedItem,
  setCurrentItem,
  setIsLoading,
  zoomItemImageUrl,
  setZoomItemImageUrl,
  showInfo,
  selectedItem,
  inquiryItem,
  setInquiryItem,
  mode,
}) => {
  const directLightIntensity =
    roomSettings?.floor == 4 ||
    roomSettings?.floor == 6 ||
    roomSettings?.floor == 11
      ? 1
      : 5;
  const ambientLightIntensity = 0.1;

  return (
    <>
      <BakeShadows />
      <EffectComposer>
        <Bloom intensity={0} />
      </EffectComposer>
      <Suspense fallback={<Loader />}>
        {/* lights */}
        <color attach='background' args={[`#191920`]} />

        <directionalLight intensity={directLightIntensity} castShadow />

        <ambientLight intensity={ambientLightIntensity} />
        {/* {roomSettings?.light === 1 && <CeilingLight />} */}
        {/* {roomSettings?.light === 2 && <FluorescentLamp />} */}
        {/* {roomSettings?.light === 3 && (
          <fog attach='fog' args={[`#fff`, 10, 30]} />
        )} */}

        {/*
      <CeilingLightTorus /> */}
        {roomSettings?.objectLight == 1 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight1
              key={index}
              position={[-1.5 + index * 8, 1.7, 0.6]}
              scale={1}
            />
          ))}
        {roomSettings?.objectLight == 2 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight2
              key={index}
              position={[-1.5 + index * 8, 4, 0.2]}
              scale={1.2}
            />
          ))}
        {roomSettings?.objectLight == 3 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight3
              key={index}
              position={[-1.5 + index * 8, 4.4, 0.12]}
              scale={1}
            />
          ))}

        {mode == 'HIGH' && currentItemId != null && (
          <MovingSpotLight
            currentItemId={currentItemId}
            opacity={0}
            anglePower={0}
          />
        )}

        <group position={[0.2, -0.5, 0]}>
          <Items
            items={items}
            currentItemId={currentItemId}
            setCurrentItemId={setCurrentItemId}
            isActive={isActive}
            setIsActive={setIsActive}
            setSelectedItem={setSelectedItem}
            setCurrentItem={setCurrentItem}
            roomSettings={roomSettings}
            zoomItemImageUrl={zoomItemImageUrl}
            setZoomItemImageUrl={setZoomItemImageUrl}
            showInfo={showInfo}
            selectedItem={selectedItem}
            inquiryItem={inquiryItem}
            setInquiryItem={setInquiryItem}
          />
          {items.length > 0 && roomSettings?.silhouette && (
            <>
              {roomSettings?.silhouettes.includes(1) && (
                <Man1
                  position={[1, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(2) && (
                <Man2
                  position={[20, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(3) && (
                <Man3
                  position={[41.5, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(4) && (
                <Woman1
                  position={[32, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(5) && (
                <Woman2
                  position={[27, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(6) && (
                <Woman3
                  position={[7.5, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(7) && (
                <Kids1
                  position={[8, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(8) && (
                <Kids2
                  position={[11, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(9) && (
                <Kids3
                  position={[36.5, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
            </>
          )}

          {/* ceiling */}
          {roomSettings?.ceiling && (
            <CustomCeiling id={roomSettings?.ceiling} />
          )}

          {/* wall */}
          {roomSettings?.wall ? (
            <CustomWall id={roomSettings?.wall} />
          ) : (
            <ClassicWall />
          )}
          {/* floor */}
          {roomSettings?.floor ? (
            <CustomFloor id={roomSettings?.floor} />
          ) : (
            <ClassicFloor />
          )}
        </group>

        <Environment
          files={'https://data.curatedartshow.com/hdri/potsdamer_platz_1k.hdr'}
        />
      </Suspense>
    </>
  );
};

export default ClassicRoom;
