import { useState, useEffect } from 'react';
import { authRequest } from '../../../requestMethods';
import { TrashIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import DeleteModal from '../components/DeleteModal';
import { useDispatch } from 'react-redux';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import { successToast } from '../../../utils/toast';
import { useNavigate } from 'react-router-dom';
import { fetchPublishedExhibitions } from '../../../services/Admin';
import { useQuery } from '@tanstack/react-query';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/outline';

const ExhibitionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [exhibitionTitleSort, setExhibitionTitleSort] = useState('');
  const [dateCreatedSort, setDateCreatedSort] = useState('');
  const [filteredExhibitions, setFilteredExhibitions] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');

  const {
    data: publishedExhibitions,
    isLoading: isPublishedExhibitionsLoading,
    refetch: refetchPublishedExhibitions,
  } = useQuery({
    queryKey: ['fetch-published-exhibitions'],
    queryFn: () => fetchPublishedExhibitions(),
    retry: (failureCount, error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        return false; // 再認証が必要な場合はリトライしない
      }
    },
    meta: { errCode: 'ADMIN_FETCH_PUBLISHED_EXHIBITIONS' },
  });

  const handleDelete = async () => {
    dispatch(loadStart());
    try {
      await authRequest.post(`/admin/delete-exhibition/${deleteId}`);
      successToast('Exhibition deleted successfully!');
      document.getElementById('delete_modal').close();
      refetchPublishedExhibitions();
    } catch (err) {
      console.error(err);
      if (
        err.response.data.statusCode == 401 ||
        err.response.data.statusCode == 403
      ) {
        navigate('/admin/login');
      }
    } finally {
      dispatch(loadEnd());
    }
  };

  const getNextSort = (sort) => {
    if (sort === '') {
      return 'asc';
    }
    if (sort === 'asc') {
      return 'desc';
    }
    return '';
  };

  const handleSortByExhibitionTitle = () => {
    const nextSort = getNextSort(exhibitionTitleSort);
    setExhibitionTitleSort(nextSort);
    if (nextSort === '') {
      return setFilteredExhibitions(publishedExhibitions);
    }
    const sorted =
      nextSort === 'desc'
        ? filteredExhibitions.toSorted((a, b) =>
            a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
          )
        : filteredExhibitions.toSorted((a, b) =>
            a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1
          );
    setFilteredExhibitions(sorted);
  };

  const handleSortByDateCreated = () => {
    const nextSort = getNextSort(dateCreatedSort);
    setDateCreatedSort(nextSort);
    if (nextSort === '') {
      return setFilteredExhibitions(publishedExhibitions);
    }
    const sorted =
      nextSort === 'desc'
        ? filteredExhibitions.toSorted((a, b) =>
            a.createdAt < b.createdAt ? -1 : 1
          )
        : filteredExhibitions.toSorted((a, b) =>
            a.createdAt < b.createdAt ? 1 : -1
          );
    setFilteredExhibitions(sorted);
  };

  useEffect(() => {
    if (searchKeyword) {
      const filtered = publishedExhibitions.filter((exhibition) =>
        exhibition.title.toLowerCase().includes(searchKeyword)
      );
      setFilteredExhibitions(filtered);
    } else {
      setFilteredExhibitions(publishedExhibitions);
    }
  }, [publishedExhibitions, searchKeyword]);

  const handleToggleShowOnHome = async (id) => {
    try {
      await authRequest.post(`/admin/toggle-show-home/${id}`);
      refetchPublishedExhibitions();
    } catch (err) {
      console.error(err);
      if (
        err.response.data.statusCode == 401 ||
        err.response.data.statusCode == 403
      ) {
        navigate('/admin/login');
      }
    }
  };

  return (
    <>
      <h2 className='text-3xl font-extrabold'>Manage Exhibitions</h2>
      <label className='input input-bordered mt-8 flex w-[22.5rem] items-center gap-2'>
        <input
          type='search'
          className='grow'
          placeholder='Type exhibition title'
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value.toLowerCase())}
        />
      </label>
      <div className='mt-10 overflow-x-auto'>
        {isPublishedExhibitionsLoading ? (
          <span className='loading loading-spinner text-primary'></span>
        ) : (
          <table className='table table-auto'>
            {/* head */}
            <thead>
              <tr>
                <th>Id</th>
                <th>Image</th>
                <th>
                  <div
                    className='flex cursor-pointer items-center gap-[0.625rem]'
                    onClick={handleSortByExhibitionTitle}
                  >
                    <span>Exhibition Title</span>
                    {exhibitionTitleSort === '' ? (
                      <ChevronUpDownIcon className='h-4 w-4' />
                    ) : exhibitionTitleSort === 'desc' ? (
                      <ChevronDownIcon className='h-4 w-4' />
                    ) : (
                      <ChevronUpIcon className='h-4 w-4' />
                    )}
                  </div>
                </th>
                <th>
                  <div
                    className='flex cursor-pointer items-center gap-[0.625rem]'
                    onClick={handleSortByDateCreated}
                  >
                    <span>Date Created</span>
                    <span>
                      {dateCreatedSort === '' ? (
                        <ChevronUpDownIcon className='h-4 w-4' />
                      ) : dateCreatedSort === 'desc' ? (
                        <ChevronDownIcon className='h-4 w-4' />
                      ) : (
                        <ChevronUpIcon className='h-4 w-4' />
                      )}
                    </span>
                  </div>
                </th>
                <th>Show on Home</th>
                <th>User Display Setting</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredExhibitions?.map((item) => (
                <tr key={`exhibition-${item.id}`}>
                  <th>{item.id}</th>
                  <td>
                    <img
                      className='h-24 w-24 object-cover'
                      src={item.previewImg}
                      alt={item.title}
                    />
                  </td>
                  <td className='max-w-24'>{item.title}</td>
                  <td>{dayjs(item.createdAt).format('MMM D, YYYY')}</td>
                  <td className='text-center'>
                    <input
                      type='checkbox'
                      className='checkbox'
                      checked={item.forceShowOnHome}
                      onChange={() => {
                        handleToggleShowOnHome(Number(item.id));
                      }}
                    />
                  </td>
                  <td className='text-sm font-bold'>
                    {item.showOnHome ? (
                      <span>ON</span>
                    ) : (
                      <span className='text-gray-400'>OFF</span>
                    )}
                  </td>
                  <td>
                    <a
                      href={`/galleries/${item.roomId}`}
                      target='_blank'
                      className='btn btn-sm'
                    >
                      View Detail
                      <ArrowTopRightOnSquareIcon className='h-5 w-5' />
                    </a>
                  </td>
                  <td>
                    <button
                      className='btn btn-outline btn-error btn-sm'
                      onClick={() => {
                        setDeleteId(item.id);
                        document.getElementById('delete_modal').showModal();
                      }}
                    >
                      <TrashIcon className='h-5 w-5' />
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <DeleteModal itemName='exhibition' confirmDelete={handleDelete} />
    </>
  );
};

export default ExhibitionList;
