import { authRequest, publicRequest } from '../requestMethods';

export const fetchActiveUsers = async (plan) => {
  try {
    const queryString = plan ? `?plan=${plan}` : '';
    const response = await authRequest.get(`/admin/users${queryString}`);
    return response.data;
  } catch (error) {
    console.error(`Failed on fetchActiveUsers request:`, error);
    throw error;
  }
};

export const fetchPublishedArtworks = async () => {
  try {
    //全ての公開済み作品を取得
    const response = await publicRequest.get('/items');
    return response.data;
  } catch (error) {
    console.error(`Failed on fetchPublishedArtworks request:`, error);
    throw error;
  }
};

export const fetchDeletedArtworks = async () => {
  try {
    //全てのinactive状態の作品を取得
    const response = await authRequest.get('/admin/inactive-items');
    return response.data;
  } catch (error) {
    console.error(`Failed on fetchDeletedArtworks request:`, error);
    throw error;
  }
};

export const fetchPublishedExhibitions = async () => {
  try {
    //全ての公開済み展示会を取得
    const response = await publicRequest.get('/rooms');
    return response.data;
  } catch (error) {
    console.error(`Failed on fetchPublishedExhibitions request:`, error);
    throw error;
  }
};

export const getFaqById = async (id) => {
  try {
    const { data } = await publicRequest.get(`/faq/${id}`);
    return data;
  } catch (error) {
    console.error(`Failed on getFaqById request:`, error);
    throw error;
  }
};

export const deleteFaqById = async (id) => {
  try {
    const { data } = await authRequest.delete(`/faq/${id}`);
    return data;
  } catch (error) {
    console.error(`Failed on deleteFaqById request:`, error);
    throw error;
  }
};

export const getAllPublishedFaqs = async () => {
  try {
    const { data } = await publicRequest.get(`/faq/published`);
    return data;
  } catch (error) {
    console.error(`Failed on getAllPublishedFaqs request:`, error);
    throw error;
  }
};

export const getAllFaqCategories = async (language) => {
  try {
    const { data } = await publicRequest.get(
      `/faq/category-with-faq?language=${language}`
    );
    return data;
  } catch (error) {
    console.error(`Failed on getAllFaqCategories request:`, error);
    throw error;
  }
};

export const createFaq = async (input) => {
  try {
    const { data } = await authRequest.post(`/faq`, input);
    return data;
  } catch (error) {
    console.error(`Failed on createFaq  request:`, error);
    throw error;
  }
};

export const updateFaq = async (id, input) => {
  try {
    const { data } = await authRequest.patch(`/faq/${id}`, input);
    return data;
  } catch (error) {
    console.error(`Failed on updateFaq  request:`, error);
    throw error;
  }
};
