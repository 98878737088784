import { PlusCircleIcon, Square2StackIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Rooms from './Rooms';
import Drafts from './Drafts';
import Items from './Items';
import { authRequest, publicRequest } from '../../requestMethods';
import { Trans, useTranslation } from 'react-i18next';
import ManageSubscription from './ManageSubscription';
import Analytics from './Analytics';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { successToast } from '../../utils/toast';

const Dashboard = () => {
  const maxItemUpload = 1000;
  const [isReachedMaxUpload, setIsReachedMaxUpload] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [selectedItem, setSelectedItem] = useState(null);
  const [planName, setPlanName] = useState('');
  const [hasVipSupport, setHasVipSupport] = useState(false);
  const CAS1_ID = process.env.REACT_APP_STRIPE_CAS1_PRODUCT_ID;
  const CAS5_ID = process.env.REACT_APP_STRIPE_CAS5_PRODUCT_ID;
  const VIP_SUPPORT_ID = process.env.REACT_APP_STRIPE_CAS_VIP_PRODUCT_ID;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const checkIsReachedMaxUpload = async () => {
      try {
        const publishedItems = await publicRequest.get(
          `/items/creator/${user.id}`
        );
        const draftItems = await authRequest.get(`/items/draft`);
        if (
          publishedItems.data.length + draftItems.data.length >=
          maxItemUpload
        ) {
          setIsReachedMaxUpload(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    checkIsReachedMaxUpload();
  }, []);

  useEffect(() => {
    if (user?.userPlans?.length > 0) {
      // 初期値を設定するための変数
      let foundPlan = '';
      let vipSupportFound = false;

      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === CAS1_ID) {
          foundPlan = 'CAS-1';
        } else if (el.SubscriptionPlans.productId === CAS5_ID) {
          foundPlan = 'CAS-5';
        }

        // "CAS VIP Support" があるか確認
        if (el.SubscriptionPlans.productId === VIP_SUPPORT_ID) {
          vipSupportFound = true;
        }
      });

      // 設定したプラン名を反映
      if (foundPlan) {
        setPlanName(foundPlan);
      }
      // VIP Supportが見つかったかどうかで状態を更新
      setHasVipSupport(vipSupportFound);
    } else {
      setHasVipSupport(false);
    }
  }, [user]);

  const lobbyLink = useMemo(() => {
    return user?.username
      ? `https://curatedartshow.com/lobby/${user.username}`
      : '';
  }, [user?.username]);

  return (
    <>
      <div className='mx-auto max-w-7xl'>
        <div className='flex flex-col justify-between gap-3 px-4 py-10 md:flex-row'>
          <div className='flex items-center gap-3 md:items-center md:gap-6'>
            <img
              className='h-[4.625rem] w-[4.625rem] rounded-full object-cover'
              src={`${
                user?.profileImageUrl
                  ? user?.profileImageUrl
                  : '/assets/img/avatar/dummy_avatar.svg'
              }`}
              alt='profile image'
            />
            <div className='flex flex-col gap-3'>
              <div className='flex flex-col justify-center gap-2 md:flex-row md:items-center md:gap-3'>
                <div className='text-2xl font-bold md:text-4xl'>
                  {user.displayName ? user.displayName : user.username}
                </div>

                <div className='flex flex-shrink-0 items-center gap-2'>
                  <div className='badge badge-outline badge-lg text-xs md:text-base'>
                    {i18n.language === 'ja' ? (
                      <>
                        {planName ? `${planName} プラン` : 'フリートライアル'}
                      </>
                    ) : (
                      <>{planName ? `${planName} Plan` : 'Free Trial'}</>
                    )}
                  </div>
                  {hasVipSupport && (
                    <div className='bg-custom-gradient-purple badge badge-outline badge-lg border-purple-700'>
                      <CheckBadgeIcon className='mr-1 h-5 w-5 shrink-0 text-purple-700 md:h-6 md:w-6' />
                      <p className='text-xs text-purple-700 md:text-base'>
                        VIP Support
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <button
                className='btn btn-sm'
                onClick={() => {
                  navigator.clipboard.writeText(lobbyLink);
                  successToast(t('message.success.t24'));
                }}
                disabled={!user?.username}
              >
                <Square2StackIcon className='h-5 w-5' />

                <Trans i18nKey={'dashboard.copy-lobby-link'} />
              </button>
            </div>
          </div>
          <Link to='/create-item' className='btn btn-primary'>
            <PlusCircleIcon className='h-6 w-6' />
            <Trans i18nKey={'dashboard.upload-art'} />
          </Link>
        </div>
      </div>

      <section id='tabs-container' className='z-10 w-full'>
        <div
          role='tablist'
          className='no-scrollbar mx-auto max-w-7xl overflow-x-scroll whitespace-nowrap'
        >
          <Link
            to='/dashboard?tab=artworks'
            role='tab'
            className={`tab-bordered tab ${tab === 'artworks' && 'tab-active'}`}
          >
            <Trans i18nKey={'dashboard.artworks'} />
          </Link>

          <Link
            to='/dashboard?tab=galleries'
            role='tab'
            className={`tab-bordered tab ${
              (!tab || tab === 'galleries') && 'tab-active'
            }`}
          >
            <Trans i18nKey={'dashboard.exhibitions'} />
          </Link>

          <Link
            to='/dashboard?tab=drafts'
            role='tab'
            className={`tab-bordered tab ${tab === 'drafts' && 'tab-active'}`}
          >
            <Trans i18nKey={'dashboard.unpublished'} />
          </Link>
          <Link
            to='/dashboard?tab=analytics'
            role='tab'
            className={`tab-bordered tab ${
              tab === 'analytics' && 'tab-active'
            }`}
          >
            <Trans i18nKey={'dashboard.analytics'} />
          </Link>
          <Link
            to='/dashboard?tab=manage-subscription'
            role='tab'
            className={`tab-bordered tab ${
              tab === 'manage-subscription' && 'tab-active'
            }`}
          >
            <Trans i18nKey={'dashboard.manage-subscription'} />
          </Link>
        </div>
      </section>

      <div className='mx-auto mb-20 mt-10 max-w-7xl px-4 xl:px-0'>
        {tab === 'artworks' && <Items setSelectedItem={setSelectedItem} />}
        {(!tab || tab === 'galleries') && <Rooms />}
        {tab === 'drafts' && <Drafts setSelectedItem={setSelectedItem} />}
        {tab === 'analytics' && <Analytics />}
        {tab === 'manage-subscription' && <ManageSubscription />}
      </div>
    </>
  );
};

export default Dashboard;
