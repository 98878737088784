import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const ExhibitionCard = ({ item }) => {
  return (
    <div className='card w-full bg-base-100 shadow-xl' key={item.id}>
      <figure className='aspect-[1/0.645] '>
        <img
          src={item.previewImg}
          alt={item.title}
          className='h-full w-full object-cover'
        />
      </figure>
      <div className='card-body p-4'>
        <h2 className='card-title m-0 line-clamp-3 min-h-[72px] break-words text-base'>
          {item.title}
        </h2>
        <div className='flex items-center gap-2'>
          <img
            className='h-6 w-6 flex-shrink-0 rounded-full object-cover'
            src={
              item?.user?.profileImageUrl
                ? item?.user?.profileImageUrl
                : '/assets/img/avatar/dummy_avatar.svg'
            }
            alt='exhibitions'
          />
          <p className='line-clamp-1'>{item.user.displayName}</p>
        </div>
        <div className='card-actions justify-end'>
          <Link
            target='_blank'
            to={`/galleries/${item.roomId}`}
            role='button'
            className='btn btn-primary'
          >
            <Trans i18nKey={'btn.enter'} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionCard;
