import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

export const DiscardExhibitionModal = ({ title, desc }) => {
  return (
    <>
      <dialog id='discard_exhibition_modal' className='modal'>
        <div className='modal-box'>
          <h3 className='text-lg font-bold'>{title}</h3>
          <p className='py-4'>{desc}</p>
          <div className='modal-action'>
            <form method='dialog'>
              {/* if there is a button in form, it will close the modal */}
              <button className='btn btn-circle btn-ghost btn-sm absolute right-2 top-2'>
                <XMarkIcon className='h-5 w-5' />
              </button>
              <button className='btn btn-outline btn-primary'>
                <Trans i18nKey={'btn.cancel'} />
              </button>
            </form>
            <Link to={'/dashboard'} className='btn btn-primary'>
              <Trans i18nKey={'btn.discard'} />
            </Link>
          </div>
        </div>
      </dialog>
    </>
  );
};
