import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  signupFailure,
  signupStart,
  signupSuccess,
} from '../../../redux/userRedux';
import { publicRequest } from '../../../requestMethods';
import {
  MinusIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import { validateUsername } from '../../../utils/validation';
import { errorToast } from '../../../utils/toast';
import { useTranslation, Trans } from 'react-i18next';

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numericValid, setNumericValid] = useState(false);
  const [charValid, setCharValid] = useState(false);
  const [lengthValid, setLengthValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const { i18n } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isConfirmed || !passwordValid || username === '' || email === '') {
      return;
    }
    setEmailError('');
    setUsernameError('');
    dispatch(loadStart());
    dispatch(signupStart());
    try {
      await publicRequest.post(`/auth/signup`, {
        username,
        email,
        password,
        language: i18n.language,
      });
      dispatch(loadEnd());
      dispatch(signupSuccess());
      setSuccess(true);
    } catch (err) {
      console.log(err);
      const message = err.response.data.message;
      if (message.includes('lobby ID')) {
        setUsernameError(message);
      } else if (message.includes('email')) {
        setEmailError(message);
      } else {
        errorToast(message);
      }
      dispatch(signupFailure());
      dispatch(loadEnd());
    }
  };

  const reflectUsername = (username) => {
    const result = validateUsername(username);
    result.isValid ? setUsernameError('') : setUsernameError(result.message);
    setUsername(username);
  };

  const passwordValidation = (password) => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;

    const numbers = /[0-9]/g;
    const characters = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/g;

    if (password.match(lowerCaseLetters)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }

    if (password.match(upperCaseLetters)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
    if (password.match(numbers)) {
      setNumericValid(true);
    } else {
      setNumericValid(false);
    }
    if (password.match(characters)) {
      setCharValid(true);
    } else {
      setCharValid(false);
    }

    if (password.length >= 8) {
      setLengthValid(true);
    } else {
      setLengthValid(false);
    }
  };

  useEffect(() => {
    if (
      uppercaseValid &&
      lowercaseValid &&
      numericValid &&
      lengthValid &&
      charValid
    ) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [uppercaseValid, lowercaseValid, numericValid, lengthValid, charValid]);

  return (
    <div className='relative mx-auto h-full pb-20 pt-10 md:flex md:p-0'>
      <div className='flex w-full flex-col items-start md:w-1/2 md:pb-20 md:pt-16'>
        <div className='mx-auto w-full max-w-[30rem]'>
          {success ? (
            <div className='mt-8'>
              <p className='text-3xl font-extrabold'>
                <Trans i18nKey={'signup.top.success'} />
              </p>
              <div className='mt-8 flex justify-center'>
                <img
                  src='/assets/img/message-sent.png'
                  className='h-60 w-60'
                  alt=''
                />
              </div>
              <p className='text-center'>
                <Trans i18nKey={'signup.top.message'} />
              </p>
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                {i18n.language === 'ja' ? (
                  <p className='px-4 pb-6 text-left text-sm lg:px-0'>
                    すでにアカウントをお持ちですか？{' '}
                    <Link className='underline' to='/signin'>
                      サインイン
                    </Link>
                  </p>
                ) : (
                  <p className='px-4 pb-6 text-left text-sm lg:px-0'>
                    Already have an account?{' '}
                    <Link className='underline' to='/signin'>
                      Sign in
                    </Link>
                  </p>
                )}
                <h1 className='px-4 pb-8 text-3xl font-extrabold lg:px-0'>
                  <Trans i18nKey={'signup.top.ttl'} />
                </h1>
                <p className='px-4 lg:px-0'>
                  <Trans i18nKey={'signup.top.sub-ttl'} />
                </p>
                <div className='bg-custom-gradient-purple mt-8 flex w-full flex-col gap-[0.625rem] px-4 py-6 md:hidden'>
                  <div className='inline-flex items-center gap-2 bg-violet-600 px-2 py-1'>
                    {i18n.language === 'ja' ? (
                      <a
                        href='/#pricing'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm font-extrabold text-white'
                      >
                        CAS-1の30日間フリートライアルの詳細を見る
                      </a>
                    ) : (
                      <a
                        href='/#pricing'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-sm font-extrabold text-white'
                      >
                        See details of 30-day free trial of CAS-1
                      </a>
                    )}

                    <ArrowTopRightOnSquareIcon className='h-4 w-4 shrink-0 stroke-2 text-white' />
                  </div>
                  <p className='text-xl font-black text-purple-700'>
                    <Trans i18nKey={'signup.top.ttl-2'} />
                  </p>
                  <p className='text-sm text-purple-700'>
                    <Trans i18nKey={'signup.top.desc'} />
                  </p>
                </div>
                <div className='mt-8 flex flex-col gap-6 px-4 lg:px-0'>
                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text font-medium'>
                        <Trans i18nKey={'signup.top.username'} />
                      </span>
                    </div>
                    <input
                      className={`input input-bordered w-full text-sm placeholder:text-sm ${
                        usernameError && 'input-error'
                      }`}
                      maxLength='20'
                      type='text'
                      placeholder='Lobby ID'
                      onChange={(e) => reflectUsername(e.target.value)}
                    />
                    <div className='label flex-col items-start'>
                      <span className='label-text-alt text-error'>
                        {usernameError}
                      </span>
                      <p className='label-text-alt'>
                        <Trans i18nKey={'signup.top.lobby-id-desc'} />
                      </p>
                    </div>
                  </label>

                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text font-medium'>
                        <Trans i18nKey={'signup.top.email'} />
                      </span>
                    </div>
                    <input
                      className={`input input-bordered w-full ${
                        emailError && 'input-error'
                      }`}
                      maxLength='320'
                      type='email'
                      placeholder='Email'
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {!!emailError && (
                      <div className='label'>
                        <span className='label-text-alt text-error'>
                          {emailError}
                        </span>
                      </div>
                    )}
                  </label>

                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text font-medium'>
                        <Trans i18nKey={'signup.top.password'} />
                      </span>
                    </div>
                    <div className='relative'>
                      <input
                        className={`input input-bordered w-full ${
                          !passwordValid && 'input-error'
                        }`}
                        maxLength='20'
                        type={`${showPassword ? 'text' : 'password'}`}
                        placeholder='Password'
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={(e) => passwordValidation(e.target.value)}
                        required
                      />
                      <span
                        className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer text-sm'
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        Show
                      </span>
                    </div>
                  </label>

                  <div className='flex flex-col gap-2'>
                    <div
                      className={`flex items-center gap-2 text-xs ${
                        lowercaseValid ? 'text-success' : 'text-error'
                      }`}
                    >
                      <MinusIcon className={`h-4 w-4`} />
                      <Trans i18nKey={'signup.top.lowercase'} />
                    </div>
                    <div
                      className={`flex items-center gap-2 text-xs ${
                        uppercaseValid ? 'text-success' : 'text-error'
                      }`}
                    >
                      <MinusIcon className={`h-4 w-4`} />
                      <Trans i18nKey={'signup.top.uppercase'} />
                    </div>
                    <div
                      className={`flex items-center gap-2 text-xs ${
                        numericValid ? 'text-success' : 'text-error'
                      }`}
                    >
                      <MinusIcon className={`h-4 w-4`} />
                      <Trans i18nKey={'signup.top.number'} />
                    </div>
                    <div
                      className={`flex items-center gap-2 text-xs ${
                        charValid ? 'text-success' : 'text-error'
                      }`}
                    >
                      <MinusIcon className={`h-4 w-4`} />
                      <Trans i18nKey={'signup.top.special-character'} />
                    </div>
                    <div
                      className={`flex items-center gap-2 text-xs ${
                        lengthValid ? 'text-success' : 'text-error'
                      }`}
                    >
                      <MinusIcon className={`h-4 w-4`} />
                      <Trans i18nKey={'signup.top.8-20-letters'} />
                    </div>
                  </div>
                </div>
                <div className='form-control mt-8 px-4 lg:px-0'>
                  {i18n.language === 'ja' ? (
                    <label className='label flex cursor-pointer items-start gap-3'>
                      <input
                        type='checkbox'
                        className='checkbox-primary checkbox'
                        onClick={() => setIsConfirmed(!isConfirmed)}
                      />
                      <span className='label-text text-xs font-medium'>
                        私は、CASの利用およびCASでの展示が、CASの
                        <Link
                          className='underline'
                          to='/terms-privacy'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          利用規約とプライバシーポリシー
                        </Link>
                        、ならびに
                        <Link
                          className='underline'
                          to='/cookie-policy'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          クッキーポリシー
                        </Link>
                        に準拠しなければならないことを理解し、これに同意します。また、Yuyosoft
                        Innovations
                        Inc.が独自の裁量により、コンテンツを「成人向けコンテンツ」として指定したり、削除したりする場合があることを承知しています。さらに、規約違反が繰り返された場合、通知や返金なしにアカウントが閉鎖される可能性があることを認識し、これを受け入れます。コミュニティの基準およびこのサイトを利用する子供たちを保護するために、本規約に同意いたします。
                      </span>
                    </label>
                  ) : (
                    <label className='label flex cursor-pointer items-start gap-3'>
                      <input
                        type='checkbox'
                        className='checkbox-primary checkbox'
                        onClick={() => setIsConfirmed(!isConfirmed)}
                      />
                      <span className='label-text text-xs font-medium'>
                        I understand and agree that my use of CAS and my CAS
                        exhibitions must comply with the{' '}
                        <Link
                          className='link-hover link underline'
                          to='/terms-privacy'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          CAS Terms of Use, Privacy Policy
                        </Link>{' '}
                        and{' '}
                        <Link
                          className='link-hover link underline'
                          to='/cookie-policy'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Cookie Policy
                        </Link>
                        . I understand that Yuyosoft Innovations Inc. may at
                        their sole discretion mark content as "mature content"
                        or remove it. I accept that repeated violations may
                        result in the closure of my account, without notice or
                        refund. I agree to these terms, many of which are
                        intended to protect community standards and the children
                        using this site.
                      </span>
                    </label>
                  )}
                </div>

                <div className='mt-6 flex justify-center'>
                  <button
                    type='submit'
                    className={`btn btn-primary btn-wide ${
                      (!passwordValid || usernameError || email === '') &&
                      'non-active-filled'
                    }`}
                    disabled={
                      !isConfirmed ||
                      !passwordValid ||
                      usernameError ||
                      email === ''
                    }
                  >
                    <Trans i18nKey={'signup.top.ttl'} />
                  </button>
                </div>
              </form>
            </>
          )}
        </div>

        {isLoading && (
          <>
            <div className='md:invisible'>
              <LoadingSpinner isHalf={false} />
            </div>
            <div className='hidden md:block'>
              <LoadingSpinner isHalf={true} />
            </div>
          </>
        )}
      </div>
      <div
        className='hidden h-full w-1/2 items-center justify-center bg-cover bg-no-repeat md:flex'
        style={{
          backgroundImage: 'url("/assets/img/signin_image.png")',
        }}
      >
        <div className='flex w-[30rem] flex-col gap-6 px-4 lg:px-0'>
          <div className='bg-white px-3 py-2'>
            {i18n.language === 'ja' ? (
              <p className='text-3xl font-extrabold text-purple-700 lg:text-3xl'>
                <a
                  className='link'
                  href='/#pricing'
                  target='_blank'
                  rel='noopener noreferrer'
                  smooth
                >
                  CAS-1
                </a>
                の30日間フリートライアルの詳細を見る
                <ArrowTopRightOnSquareIcon className='ml-2 inline-block h-7 w-7 stroke-[3] text-purple-700' />
              </p>
            ) : (
              <p className='text-3xl font-extrabold text-purple-700 lg:text-3xl'>
                See details of 30-day free trial of{' '}
                <a
                  className='link'
                  href='/#pricing'
                  target='_blank'
                  rel='noopener noreferrer'
                  smooth
                >
                  CAS-1
                </a>
                <ArrowTopRightOnSquareIcon className='ml-2 inline-block h-7 w-7 stroke-[3] text-purple-700' />
              </p>
            )}
          </div>
          <h2 className='text-[3.75rem] font-black leading-[3.75rem] text-white'>
            <Trans i18nKey={'signup.top.ttl-2'} />
          </h2>
          <p className='text-2xl font-medium text-white'>
            <Trans i18nKey={'signup.top.desc'} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
