import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useEffect, useState } from 'react';
import { authRequest } from '../../../requestMethods';
import { errorToast, successToast } from '../../../utils/toast';
import { useTranslation } from 'react-i18next';
import { CheckIcon, MinusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { loadEnd, loadStart } from '../../../redux/loadRedux';

const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numericValid, setNumericValid] = useState(false);
  const [charValid, setCharValid] = useState(false);
  const [minLengthValid, setMinLengthValid] = useState(false);
  const [maxLengthValid, setMaxLengthValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [typed, setTyped] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadStart());
    try {
      await authRequest.post(`/auth/change-password`, {
        currentPassword,
        newPassword,
        confirmPassword: newPassword,
      });
      document.getElementById('change_password_modal').close();
      successToast(t('message.success.t5-1'));
    } catch (err) {
      console.error(err);
      switch (err.response.data.message) {
        case 'Wrong Password':
          setCurrentPasswordError(t('message.error.t12'));
          break;
        default:
          errorToast(t('message.error.t1'));
          break;
      }
    } finally {
      dispatch(loadEnd());
    }
  };

  const passwordValidation = () => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;

    const numbers = /[0-9]/g;
    const characters = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/g;

    if (newPassword.match(lowerCaseLetters)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }

    if (newPassword.match(upperCaseLetters)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
    if (newPassword.match(numbers)) {
      setNumericValid(true);
    } else {
      setNumericValid(false);
    }
    if (newPassword.match(characters)) {
      setCharValid(true);
    } else {
      setCharValid(false);
    }

    if (newPassword.length >= 8) {
      setMinLengthValid(true);
    } else {
      setMinLengthValid(false);
    }
    if (newPassword.length <= 12) {
      setMaxLengthValid(true);
    } else {
      setMaxLengthValid(false);
    }
  };

  useEffect(() => {
    if (
      uppercaseValid &&
      lowercaseValid &&
      numericValid &&
      minLengthValid &&
      maxLengthValid &&
      charValid
    ) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [
    uppercaseValid,
    lowercaseValid,
    numericValid,
    minLengthValid,
    maxLengthValid,
    charValid,
  ]);

  useEffect(() => {
    const modal = document.getElementById('change_password_modal');

    // モーダルの属性を監視
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'open'
        ) {
          if (!modal.hasAttribute('open')) {
            setTyped(false);
            setCurrentPassword('');
            setCurrentPasswordError('');
            setNewPassword('');
            setNewPasswordError('');
            setPasswordValid(false);
            setLowercaseValid(false);
            setUppercaseValid(false);
            setNumericValid(false);
            setCharValid(false);
            setMinLengthValid(false);
            setMaxLengthValid(false);
            setShowCurrentPassword(false);
            setShowNewPassword(false);
          }
        }
      }
    });

    // 監視の開始
    observer.observe(modal, { attributes: true });

    // クリーンアップ関数で監視を停止
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <dialog id='change_password_modal' className='modal'>
      <div className='modal-box !max-w-[32rem] !p-6'>
        <h3 className='mb-6 mt-0 text-lg font-bold'>Password</h3>

        <div>
          <div className='label'>
            <span className='label-text text-sm font-medium'>
              Current password
            </span>
          </div>
          <label
            className={`input input-bordered flex items-center gap-2 ${
              currentPasswordError && '!input-error'
            }`}
          >
            <input
              value={currentPassword}
              type={showCurrentPassword ? 'text' : 'password'}
              className='grow'
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
            <button
              className='text-sm font-medium'
              onClick={() => {
                setShowCurrentPassword(!showCurrentPassword);
              }}
            >
              {showCurrentPassword ? 'Hide' : 'Show'}
            </button>
          </label>
          <div className='label'>
            <span className='label-text-alt text-sm font-medium text-error'>
              {currentPasswordError}
            </span>
          </div>
        </div>

        <div>
          <div className='label'>
            <span className='label-text text-sm font-medium'>New password</span>
          </div>
          <label
            className={`input input-bordered flex items-center gap-2 ${
              newPasswordError && '!input-error'
            }`}
          >
            <input
              value={newPassword}
              type={showNewPassword ? 'text' : 'password'}
              className='grow'
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              onKeyUp={() => {
                passwordValidation();
                setTyped(true);
              }}
            />
            <button
              className='text-sm font-medium'
              onClick={() => {
                setShowNewPassword(!showNewPassword);
              }}
            >
              {showNewPassword ? 'Hide' : 'Show'}
            </button>
          </label>
          <div className='label'>
            <span className='label-text-alt text-sm font-medium text-error'>
              {newPasswordError}
            </span>
          </div>
        </div>

        <div className='flex flex-col gap-2'>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed
                ? '!text-neutral'
                : lowercaseValid
                ? 'text-success'
                : 'text-error'
            }`}
          >
            {!typed ? (
              <MinusIcon className={`h-4 w-4`} />
            ) : lowercaseValid ? (
              <CheckIcon className='h-4 w-4' />
            ) : (
              <XMarkIcon className='h-4 w-4' />
            )}
            One lowercase character
          </div>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed
                ? '!text-neutral'
                : uppercaseValid
                ? 'text-success'
                : 'text-error'
            }`}
          >
            {!typed ? (
              <MinusIcon className={`h-4 w-4`} />
            ) : uppercaseValid ? (
              <CheckIcon className='h-4 w-4' />
            ) : (
              <XMarkIcon className='h-4 w-4' />
            )}
            One uppercase character
          </div>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed
                ? '!text-neutral'
                : numericValid
                ? 'text-success'
                : 'text-error'
            }`}
          >
            {!typed ? (
              <MinusIcon className={`h-4 w-4`} />
            ) : numericValid ? (
              <CheckIcon className='h-4 w-4' />
            ) : (
              <XMarkIcon className='h-4 w-4' />
            )}{' '}
            One number
          </div>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed
                ? '!text-neutral'
                : charValid
                ? 'text-success'
                : 'text-error'
            }`}
          >
            {!typed ? (
              <MinusIcon className={`h-4 w-4`} />
            ) : charValid ? (
              <CheckIcon className='h-4 w-4' />
            ) : (
              <XMarkIcon className='h-4 w-4' />
            )}{' '}
            One special Character
          </div>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed
                ? '!text-neutral'
                : minLengthValid
                ? 'text-success'
                : 'text-error'
            }`}
          >
            {!typed ? (
              <MinusIcon className={`h-4 w-4`} />
            ) : minLengthValid ? (
              <CheckIcon className='h-4 w-4' />
            ) : (
              <XMarkIcon className='h-4 w-4' />
            )}
            8 characters minimum
          </div>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed
                ? '!text-neutral'
                : maxLengthValid
                ? 'text-success'
                : 'text-error'
            }`}
          >
            {!typed ? (
              <MinusIcon className={`h-4 w-4`} />
            ) : maxLengthValid ? (
              <CheckIcon className='h-4 w-4' />
            ) : (
              <XMarkIcon className='h-4 w-4' />
            )}
            12 characters maximum
          </div>
        </div>

        <div className='modal-action mb-0 mt-6'>
          <form method='dialog'>
            <button className='btn btn-ghost mr-2'>Cancel</button>
            <button
              className='btn btn-primary'
              onClick={handleSubmit}
              disabled={!newPassword || !currentPassword || !passwordValid}
            >
              Save
            </button>
          </form>
        </div>
      </div>

      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default ChangePasswordModal;
