import {
  BakeShadows,
  Environment,
  OrbitControls,
  useHelper,
} from '@react-three/drei';

import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense } from 'react';
import Loader from '../Loader';
import Items from '../Items';

import Woman1 from '../models/Woman1';
import Kids1 from '../models/Kids1';
import Woman2 from '../models/Woman2';
import Woman3 from '../models/Woman3';
import Man1 from '../models/Man1';
import Man2 from '../models/Man2';
import Man3 from '../models/Man3';
import Kids2 from '../models/Kids2';
import Kids3 from '../models/Kids3';

import WhitePillar from '../models/WhitePillar';
import PillarRoomFloor from '../floors/PillarRoomFloor';
import PillarRoomWall from '../walls/PillarRoomWall';
import MovingSpotLight from '../lights/MovingSpotLight';

const PillarRoom = ({
  bgColor,
  room,
  roomSettings,
  items,
  currentItemId,
  setCurrentItemId,
  isActive,
  setIsActive,
  setSelectedItem,
  setCurrentItem,
  setIsLoading,
  zoomItemImageUrl,
  setZoomItemImageUrl,
  showInfo,
  selectedItem,
  inquiryItem,
  setInquiryItem,
  mode,
}) => {
  const directLightIntensity = 1;
  const ambientLightIntensity = 1;

  //lightのdebug用
  // const dirLight = useRef(null);
  // useHelper(dirLight, THREE.DirectionalLightHelper, '#fff');

  return (
    <>
      {/* <OrbitControls /> */}
      <BakeShadows />
      <EffectComposer></EffectComposer>
      <Suspense fallback={<Loader />}>
        <color attach='background' args={[`${bgColor}`]} />

        <directionalLight
          // ref={dirLight}
          position={[0, 2, 20]}
          target-position={[0, 2.5, 0]}
          intensity={directLightIntensity}
          color={'#ffffff'}
          castShadow
        />
        <ambientLight intensity={ambientLightIntensity} color={'#ffffff'} />
        {mode == 'HIGH' && currentItemId != null && (
          <MovingSpotLight
            currentItemId={currentItemId}
            opacity={
              roomSettings?.spotLightIntensity != null
                ? 0.1 * roomSettings.spotLightIntensity
                : 0.1 * 2
            }
            anglePower={1}
            angle={0.35}
            color={roomSettings?.spotLightColorCode}
          />
        )}

        <WhitePillar scale={[6, 7, 6]} position={[6, -1, 2.4]} />
        <WhitePillar scale={[6, 7, 6]} position={[6 + 1 * 15, -1, 2.4]} />
        <WhitePillar scale={[6, 7, 6]} position={[6 + 2 * 15, -1, 2.4]} />
        <WhitePillar scale={[6, 7, 6]} position={[44, -1, 2.4]} />

        <group position={[0.2, -0.5, 0]}>
          <Items
            items={items}
            currentItemId={currentItemId}
            setCurrentItemId={setCurrentItemId}
            isActive={isActive}
            setIsActive={setIsActive}
            setSelectedItem={setSelectedItem}
            setCurrentItem={setCurrentItem}
            roomSettings={roomSettings}
            zoomItemImageUrl={zoomItemImageUrl}
            setZoomItemImageUrl={setZoomItemImageUrl}
            showInfo={showInfo}
            selectedItem={selectedItem}
            inquiryItem={inquiryItem}
            setInquiryItem={setInquiryItem}
          />
          {items.length > 0 && roomSettings?.silhouette && (
            <>
              {roomSettings?.silhouettes.includes(1) && (
                <Man1
                  position={[1, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(2) && (
                <Man2
                  position={[20, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(3) && (
                <Man3
                  position={[41.5, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(4) && (
                <Woman1
                  position={[32, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(5) && (
                <Woman2
                  position={[27, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(6) && (
                <Woman3
                  position={[7.5, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(7) && (
                <Kids1
                  position={[8, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(8) && (
                <Kids2
                  position={[11, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(9) && (
                <Kids3
                  position={[36.5, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
            </>
          )}

          {/* wall */}
          <PillarRoomWall />
          {/* floor */}
          <PillarRoomFloor />
        </group>
        <Environment
          files={'https://data.curatedartshow.com/hdri/studio_small_07_1k.hdr'}
        />
      </Suspense>
    </>
  );
};

export default PillarRoom;
