import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, refreshToken } from '../../../../redux/userRedux';
import axios from 'axios';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { loadEnd, loadStart } from '../../../../redux/loadRedux';
import SignupSuccessModal from '../components/SignupSuccessModal';
import { successToast } from '../../../../utils/toast';
import { useTranslation } from 'react-i18next';

export const OnBoarding = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isLoading } = useSelector((state) => state.isLoading);

  const token = searchParams.get('token');
  const id = searchParams.get('id');

  const confirmEmail = async () => {
    try {
      dispatch(loadStart());
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/auth/email-confirm`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.user?.trialEndAt) {
        // ログイン状態にする
        dispatch(loginSuccess(res.data));

        //success modal表示
        document.getElementById('signup_success_modal').showModal();
      } else {
        //trialEndAtがない場合は有料のサブスクリプション加入へ
        //ログイン状態にはしないがauthrequestを行うため、tokenをセット
        dispatch(refreshToken(res.data.tokens));
        navigate(`/signup/onboarding/subscription?id=${res.data.user.id}`);

        successToast(t('message.success.t7'));
      }
    } catch (err) {
      //失効していた場合はsigninに戻す
      navigate(`/resend-email?id=${id}`);
      console.log(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  useEffect(() => {
    // profile登録debug時コメントアウト
    if (token && id) {
      confirmEmail();
    }
  }, [token, id]);

  return (
    <div className='flex h-full min-h-full flex-col'>
      <div className='w-full flex-auto px-4'>
        {isLoading && <LoadingSpinner />}
      </div>
      <SignupSuccessModal />
    </div>
  );
};
