import { Filter } from 'bad-words';
import i18n from 'i18next';
import { z } from 'zod';

/**
 * @typedef {Object} ValidationResult
 * @property {boolean} isValid - Result of validation
 * @property {string} message - Error message
 */
const validateRequired = (value, label) => {
  const message = !!value
    ? ''
    : i18n.language == 'ja'
    ? `${label}は必須です`
    : `${label} is required.`;
  return {
    isValid: !!value,
    message,
  };
};

const validateAlphanumeric = (value, label) => {
  // 半角英数字、半角スペースを許容
  const rule = /^[a-zA-Z0-9 ]*$/;
  const message = rule.test(value)
    ? ''
    : i18n.language == 'ja'
    ? `${label}は半角英数字で入力してください`
    : `${label} can only contain alphanumeric characters (letters A-Z, numbers 0-9).`;
  return {
    isValid: rule.test(value),
    message,
  };
};

const validateAlphanumericWithDash = (value, label) => {
  const rule = /^[a-zA-Z0-9-]+$/;
  const message = rule.test(value)
    ? ''
    : i18n.language == 'ja'
    ? `${label}は半角英数字とダッシュ(-)、スペースなしで入力してください`
    : `${label} can only contain alphanumeric characters (letters A-Z, numbers 0-9) and dashes (-), without spaces.`;
  return {
    isValid: rule.test(value),
    message,
  };
};

const validateLowercaseAlphanumericWithDash = (value, label) => {
  const rule = /^[a-z0-9-]+$/;
  const message = rule.test(value)
    ? ''
    : i18n.language == 'ja'
    ? `${label}は小文字のアルファベット、数字、ダッシュ（-）、スペースなしで入力してください`
    : `${label} can only contain lowercase alphanumeric characters (letters a-z, numbers 0-9) and dashes (-), without spaces.`;
  return {
    isValid: rule.test(value),
    message,
  };
};

const validateMinStrLen = (value, min, label) => {
  const message =
    min <= value.length
      ? ''
      : i18n.language == 'ja'
      ? `${label}は${min}文字以上で入力してください`
      : `${label} must be longer than ${min} characters.`;
  return {
    isValid: min <= value.length,
    message,
  };
};

const validateMaxStrLen = (value, max, label) => {
  const message =
    value.length <= max
      ? ''
      : i18n.language == 'ja'
      ? `${label}は${max}文字以下で入力してください`
      : `${label} must be ${max} characters or less.`;
  return {
    isValid: value.length <= max,
    message,
  };
};

const validateMaxNumber = (value, max, label) => {
  const message =
    value <= max
      ? ''
      : i18n.language == 'ja'
      ? `${label}は${max}以下で入力してください`
      : `${label} must be ${max} or less.`;
  return {
    isValid: value.length <= max,
    message,
  };
};

const validateEmailFormat = (email) => {
  const format =
    /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  const message = format.test(email)
    ? ''
    : i18n.language == 'ja'
    ? '有効なメールアドレスを入力してください'
    : 'Please enter a valid email address.';
  return {
    isValid: format.test(email),
    message,
  };
};

const validateURLFormat = (url) => {
  const format = /^https?:\/\/[\w!\?\/\+\-_~=;\.,\*&@#\$%\(\)'\[\]]+$/;
  const message = format.test(url)
    ? ''
    : i18n.language == 'ja'
    ? '有効なURLを入力してください'
    : 'Please enter a valid URL.';
  return {
    isValid: format.test(url),
    message,
  };
};

const validateCouponCodeFormat = (couponCode) => {
  // 大文字のアルファベットと数字のみを許可する正規表現
  const format = /^[A-Z0-9]+$/;

  // 正規表現にマッチするかをチェックし、メッセージを設定
  const message = format.test(couponCode)
    ? ''
    : i18n.language === 'ja'
    ? 'クーポンコードは大文字の英字と数字のみ使用可能です。'
    : 'Coupon codes can only contain uppercase letters and numbers.';

  return {
    isValid: format.test(couponCode),
    message,
  };
};

const maskBadWord = (word) => {
  // 1文字目と最後の文字を残して、中間の文字を '*' に置き換える
  if (word.length <= 2) return word; // 短すぎる場合はそのまま返す
  return word[0] + '*'.repeat(word.length - 2) + word[word.length - 1];
};

const validateBadwords = (text, label) => {
  // 不適切な単語のフィルタを初期化
  const filter = new Filter();

  // 不適切な単語の検出
  if (filter.isProfane(text)) {
    const words = text.split(' ');
    // 不適切な単語を検出し、マスクする
    //shitならs**tのような感じ
    const badWords = words
      .filter((word) => filter.isProfane(word))
      .map((word) => maskBadWord(word));

    return {
      isValid: false,
      message:
        i18n.language === 'ja'
          ? `${label}には不適切な単語が含まれています`
          : `This ${label} contains inappropriate word`,
    };
  }

  // フォーマットと不適切な単語の両方が問題なければOK
  return {
    isValid: true,
    message: '',
  };
};

/**
 * Validate displayname
 * @param {string} displayname
 * @return {ValidationResult}
 */
export const validateDisplayname = (displayname) => {
  const label = i18n.language == 'ja' ? 'ロビータイトル' : 'Lobby title';
  const resultRequired = validateRequired(displayname, label);
  const resultMaxLen = validateMaxStrLen(displayname, 46, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate username
 * @param {string} username
 * @return {ValidationResult}
 */
export const validateUsername = (username) => {
  const label = i18n.language == 'ja' ? 'ロビーID' : 'Lobby ID';
  const resultRequired = validateRequired(username, label);
  const resultMinLen = validateMinStrLen(username, 3, label);
  const resultMaxLen = validateMaxStrLen(username, 20, label);

  const resultAlphanumericWithDash = validateAlphanumericWithDash(
    username,
    label
  );
  const resultBadwords = validateBadwords(username, label);
  const errors = [
    resultRequired.message,
    resultMinLen.message,
    resultMaxLen.message,
    resultAlphanumericWithDash.message,
    resultBadwords.message,
  ].filter((message) => message !== '');
  return {
    isValid:
      resultRequired.isValid &&
      resultMinLen.isValid &&
      resultMaxLen.isValid &&
      resultAlphanumericWithDash.isValid &&
      resultBadwords.isValid,
    message: errors[0],
  };
};

/**
 * Validate exhibition name
 * @param {string} name
 * @return {ValidationResult}
 */
export const validateGalleryName = (name) => {
  const label = i18n.language == 'ja' ? '展示会名' : 'Exhibition title';
  const resultRequired = validateRequired(name, label);
  const resultMaxLen = validateMaxStrLen(name, 100, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate exhibition description
 * @param {string} description
 * @return {ValidationResult}
 */
export const validateGalleryDescription = (description) => {
  const label = i18n.language == 'ja' ? '展示会の概要' : 'Exhibition overview';
  const resultRequired = validateRequired(description, label);
  const resultMaxLen = validateMaxStrLen(description, 1000, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate room ID
 * @param {string} id
 * @return {ValidationResult}
 */
export const validateRoomId = (id) => {
  const label = i18n.language == 'ja' ? '展示会のID' : 'Exhibition ID';
  const resultRequired = validateRequired(id, label);
  const resultAlphanumericWithDash = validateLowercaseAlphanumericWithDash(
    id,
    label
  );
  const resultMaxLen = validateMaxStrLen(id, 30, label);
  const resultBadwords = validateBadwords(id, label);
  const errors = [
    resultRequired.message,
    resultAlphanumericWithDash.message,
    resultMaxLen.message,
    resultBadwords.message,
  ].filter((message) => message !== '');
  return {
    isValid:
      resultRequired.isValid &&
      resultAlphanumericWithDash.isValid &&
      resultMaxLen.isValid &&
      resultBadwords.isValid,
    message: errors[0],
  };
};

/**
 * Validate artwork title
 * @param {string} title
 * @return {ValidationResult}
 */
export const validateArtworkTitle = (title) => {
  const label = i18n.language == 'ja' ? 'タイトル、題名' : 'Title';
  const resultRequired = validateRequired(title, label);
  const resultMaxLen = validateMaxStrLen(title, 300, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate artwork description
 * @param {string} description
 * @return {ValidationResult}
 */
export const validateArtworkDescription = (description) => {
  const label = i18n.language == 'ja' ? '説明、摘要、詳細' : 'Description';
  const resultRequired = validateRequired(description, label);
  const resultMaxLen = validateMaxStrLen(description, 30000, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate artwork created year
 * @param {string} year
 * @return {ValidationResult}
 */
export const validateArtworkCreatedYear = (year) => {
  const label = i18n.language == 'ja' ? '制作年（月日）' : 'Year of production';
  const resultMaxLen = validateMaxStrLen(year, 50, label);
  const errors = [resultMaxLen.message].filter((message) => message !== '');
  return {
    isValid: resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate artwork URL
 * @param {string} url
 * @return {ValidationResult}
 */
export const validateArtworkUrl = (url) => {
  if (!url) {
    return {
      isValid: true,
      message: '',
    };
  }
  const label = i18n.language == 'ja' ? '作品URL' : 'Artwork URL';
  const resultURLFormat = validateURLFormat(url);
  const resultMaxLen = validateMaxStrLen(url, 256, label);
  const errors = [resultURLFormat.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultURLFormat.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate artwork URL button label
 * @param {string} btnLabel
 * @return {ValidationResult}
 */
export const validateArtworkUrlLabel = (btnLabel) => {
  if (!btnLabel) {
    return {
      isValid: true,
      message: '',
    };
  }
  const label =
    i18n.language == 'ja'
      ? '作品URLボタンのラベル'
      : 'Artwork URL Button Label';
  const resultRequired = validateRequired(btnLabel, label);
  const resultMaxLen = validateMaxStrLen(btnLabel, 40, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate artwork other images title
 * @param {string} title
 * @return {ValidationResult}
 */
export const validateOtherImagesTitle = (title) => {
  if (!title) {
    return {
      isValid: true,
      message: '',
    };
  }
  const label = i18n.language == 'ja' ? 'タイトル、題名' : 'Title';
  const resultMaxLen = validateMaxStrLen(title, 100, label);
  return {
    isValid: resultMaxLen.isValid,
    message: resultMaxLen.message,
  };
};

/**
 * Validate email
 * @param {string} email
 * @return {ValidationResult}
 */
export const validateEmail = (email) => {
  const label = i18n.language == 'ja' ? 'メールアドレス' : 'Email';
  const resultRequired = validateRequired(email, label);
  const resultMaxStrLen = validateMaxStrLen(email, 255, label);
  const resultEmailFormat = validateEmailFormat(email);
  const errors = [
    resultRequired.message,
    resultMaxStrLen.message,
    resultEmailFormat.message,
  ].filter((message) => message !== '');
  return {
    isValid:
      resultRequired.isValid &&
      resultMaxStrLen.isValid &&
      resultEmailFormat.isValid,
    message: errors[0],
  };
};

/**
 * Validate email
 * @param {string} email
 * @return {ValidationResult}
 */
export const validateInquiryEmail = (email) => {
  const label = i18n.language == 'ja' ? 'メールアドレス' : 'Email';
  const resultMaxStrLen = validateMaxStrLen(email, 255, label);
  const resultEmailFormat = validateEmailFormat(email);
  const errors = [resultMaxStrLen.message, resultEmailFormat.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultMaxStrLen.isValid && resultEmailFormat.isValid,
    message: errors[0],
  };
};

/**
 * Validate about
 * @param {string} about
 * @return {ValidationResult}
 */
export const validateAboutOnLobby = (about) => {
  const label = i18n.language == 'ja' ? 'あなたのギャラリーについて' : 'About';

  const resultMaxStrLen = validateMaxStrLen(about, 3000, label);
  const errors = [resultMaxStrLen.message].filter((message) => message !== '');
  return {
    isValid: resultMaxStrLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate city
 * @param {string} city
 * @return {ValidationResult}
 */
export const validateCity = (city) => {
  const label = i18n.language == 'ja' ? 'Your city' : 'Your city';
  const resultRequired = validateRequired(city, label);
  const errors = [resultRequired.message].filter((message) => message !== '');
  return {
    isValid: resultRequired.isValid,
    message: errors[0],
  };
};

/**
 * Validate website URL
 * @param {string} url
 * @return {ValidationResult}
 */
export const validateWebsiteURL = (url) => {
  if (!url) {
    return {
      isValid: true,
      message: '',
    };
  }
  const label = i18n.language == 'ja' ? 'ウェブサイトURL' : 'Website URL';
  const resultMaxStrLen = validateMaxStrLen(url, 255, label);
  const resultURLFormat = validateURLFormat(url);
  const errors = [resultMaxStrLen.message, resultURLFormat.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultMaxStrLen.isValid && resultURLFormat.isValid,
    message: errors[0],
  };
};

/**
 * Validate Name of purchase decision maker in About Page
 * @param {string} name
 * @return {ValidationResult}
 */
export const validateNameOfPurchaseDecisionMaker = (name) => {
  const label =
    i18n.language == 'ja' ? '担当者名' : 'Name of purchase decision maker';
  const resultRequired = validateRequired(name, label);
  const resultMaxLen = validateMaxStrLen(name, 50, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate Remarks in About Page
 * @param {string} remarks
 * @return {ValidationResult}
 */
export const validateRemarks = (remarks) => {
  const label = i18n.language == 'ja' ? '備考' : 'Remarks';
  const resultRequired = validateRequired(remarks, label);
  const resultMaxLen = validateMaxStrLen(remarks, 500, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate inquiryName
 * @param {string} name
 * @return {ValidationResult}
 */
export const validateInquiryName = (name) => {
  const label = i18n.language == 'ja' ? '名前' : 'Name';
  const resultRequired = validateRequired(name, label);
  const resultMaxLen = validateMaxStrLen(name, 46, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

export const validateInquiryMessage = (message) => {
  const label = i18n.language == 'ja' ? 'メッセージ' : 'Message';
  const resultRequired = validateRequired(message, label);
  const resultMaxLen = validateMaxStrLen(message, 500, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate Gallery, museum or studio name
 * @param {string} name
 * @return {ValidationResult}
 */
export const validateStudioName = (name) => {
  const label =
    i18n.language == 'ja'
      ? 'ギャラリー、美術館やスタジオの名前'
      : 'Gallery, museum or studio name';
  const resultRequired = validateRequired(name, label);
  const resultMaxLen = validateMaxStrLen(name, 100, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate preset name
 * @param {string} name
 * @return {ValidationResult}
 */
export const validatePresetName = (name) => {
  const label = i18n.language == 'ja' ? 'プリセット名' : 'Preset Name';
  const resultRequired = validateRequired(name, label);
  const resultMaxLen = validateMaxStrLen(name, 100, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate contact name
 * @param {string} title
 * @return {ValidationResult}
 */
export const validateContactName = (title) => {
  const label = i18n.language == 'ja' ? 'お名前' : 'Name';
  const resultRequired = validateRequired(title, label);
  const resultMaxLen = validateMaxStrLen(title, 300, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate contact message
 * @param {string} title
 * @return {ValidationResult}
 */
export const validateContactMessage = (title) => {
  const label = i18n.language == 'ja' ? 'お問い合わせ内容' : 'Message';
  const resultRequired = validateRequired(title, label);
  const resultMaxLen = validateMaxStrLen(title, 1000, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate coupon title
 * @param {string} title
 * @return {ValidationResult}
 */
export const validateCouponTitle = (title) => {
  const label = i18n.language == 'ja' ? 'クーポンタイトル' : 'Coupon Title';
  const resultRequired = validateRequired(title, label);
  const resultMaxLen = validateMaxStrLen(title, 46, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate coupon code
 * @param {string} code
 * @return {ValidationResult}
 */
export const validateCouponCode = (code) => {
  const label = i18n.language == 'ja' ? 'クーポンコード' : 'Coupon Code';
  const resultRequired = validateRequired(code, label);
  const resultMaxLen = validateMaxStrLen(code, 8, label);
  const resultCodeFormat = validateCouponCodeFormat(code);
  const errors = [
    resultRequired.message,
    resultMaxLen.message,
    resultCodeFormat.message,
  ].filter((message) => message !== '');
  return {
    isValid:
      resultRequired.isValid &&
      resultMaxLen.isValid &&
      resultCodeFormat.isValid,
    message: errors[0],
  };
};

/**
 * Validate coupon note
 * @param {string}note
 * @return {ValidationResult}
 */
export const validateCouponNote = (note) => {
  const label = i18n.language == 'ja' ? '備考' : 'Usage Notes';
  const resultMaxLen = validateMaxStrLen(note, 3000, label);
  const errors = [resultMaxLen.message].filter((message) => message !== '');
  return {
    isValid: resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate coupon duration
 * @param {number}duration
 * @return {ValidationResult}
 */
export const validateCouponDuration = (duration) => {
  const label = i18n.language == 'ja' ? '有効期間' : 'Discount Duration';
  const resultRequired = validateRequired(duration, label);
  const resultMaxLen = validateMaxNumber(duration, 730, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate exhibition password
 * @param {string}password
 * @return {ValidationResult}
 */
export const validateExhibitionPassword = (password) => {
  const label = i18n.language == 'ja' ? 'パスワード' : 'Password';
  const resultRequired = validateRequired(password, label);
  const resultMaxLen = validateMaxStrLen(password, 20, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/**
 * Validate report content in ReportItemModal
 * @param {string} content
 * @return {ValidationResult}
 */
export const validateReportContent = (content) => {
  const label = i18n.language == 'ja' ? '内容' : 'Content';
  const resultRequired = validateRequired(content, label);
  const resultMaxLen = validateMaxStrLen(content, 500, label);
  const errors = [resultRequired.message, resultMaxLen.message].filter(
    (message) => message !== ''
  );
  return {
    isValid: resultRequired.isValid && resultMaxLen.isValid,
    message: errors[0],
  };
};

/* スキーマとバリデーション内容を定義 */
export const CreateFaqSchema = z.object({
  question: z.string().min(0),
  questionJa: z.string().min(0),
  answer: z.string().min(0),
  answerJa: z.string().min(0),
  categoryId: z.number(),
  isPublished: z.boolean(),
  isActive: z.boolean(),
  order: z.number(),
});
