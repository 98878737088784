import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const PromotionCardSmall = () => {
  return (
    <Link to={'/signup'}>
      <div className='flex w-full flex-col gap-3 rounded-2xl bg-white p-5 text-[#00100E]'>
        <button className='btn bg-accent text-sm text-[#00100E]'>
          <Trans i18nKey={'btn.start-90day-free-trial'} />
        </button>
        <p className='text-center text-sm font-medium text-[#00100E]'>
          <Trans i18nKey={'top.promotion-card'} />
        </p>
      </div>
    </Link>
  );
};

export default PromotionCardSmall;
