import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import * as THREE from 'three';

const Snapshot = ({
  theme,
  frame,
  showSilhouette,
  setSnapshot,
  light,
  items,
  lightColor,
  ceiling,
  wall,
  floor,
  objectLight,
  colorCode,
  directionalLightIntensity,
  directionalLightColorCode,
  spotLightIntensity,
  spotLightColorCode,
  frameColor,
  matColor,
}) => {
  const { gl, scene, camera } = useThree();

  const takeSnapshot = () => {
    // スクリーンショットを取得
    const prevSize = gl.getSize(new THREE.Vector2());
    const prevPixelRatio = gl.getPixelRatio();

    // 解像度とサイズをsnapshot用にセット
    gl.setPixelRatio(1);
    gl.setSize(1280, 720);

    // シーンをレンダリングしてスナップショットをキャプチャ
    gl.render(scene, camera);

    setSnapshot(gl.domElement.toDataURL('image/jpeg'));
    // 解像度とサイズを元に戻す
    gl.setPixelRatio(prevPixelRatio);
    gl.setSize(prevSize.x, prevSize.y);
  };

  useEffect(() => {
    setTimeout(() => {
      takeSnapshot();
    }, 1000);
  }, [
    theme,
    frame,
    showSilhouette,
    lightColor,
    ceiling,
    wall,
    floor,
    objectLight,
    colorCode,
    directionalLightIntensity,
    directionalLightColorCode,
    spotLightIntensity,
    spotLightColorCode,
    frameColor,
    matColor,
    items,
    light,
  ]);

  useEffect(() => {
    setTimeout(() => {
      takeSnapshot();
    }, 1000);
  }, [items]);

  return null;
};

export default Snapshot;
