import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatMMMDYYYY } from '../utils/DateUtils';

const AvailableItemsModal = ({
  availableItems,
  addedItems,
  setAddedItems,
  setAvailableItems,
}) => {
  const MAX_SELECT_ARTWORK = 20;
  const [selectedItemIds, setSelectedItemIds] = useState(new Set());
  const [keyword, setKeyword] = useState('');
  const { t } = useTranslation();

  // 作品IDを追加
  const addId = (item) => {
    setSelectedItemIds((prevItems) => {
      const newItems = new Set(prevItems);
      newItems.add(item);
      return newItems;
    });
  };

  // 作品IDを削除
  const removeId = (item) => {
    setSelectedItemIds((prevItems) => {
      const newItems = new Set(prevItems);
      newItems.delete(item);
      return newItems;
    });
  };

  const handleChange = (itemId) => {
    selectedItemIds.has(itemId) ? removeId(itemId) : addId(itemId);
  };

  const handleAddItems = () => {
    const ids = Array.from(selectedItemIds);
    // 新規追加作品
    const newAddedItems = availableItems.filter((item) => {
      return ids.includes(String(item.id));
    });
    // 追加した作品を除外
    const newAvailableItems = availableItems.filter((item) => {
      return !ids.includes(String(item.id));
    });
    setAvailableItems(newAvailableItems);
    setAddedItems([...addedItems, ...newAddedItems]);
    setSelectedItemIds(new Set());
  };

  const handleInputChange = (event) => {
    setKeyword(event.target.value);
  };

  const filteredItems = availableItems
    .filter((item) => {
      return (
        item?.title.toLowerCase().includes(keyword.toLowerCase()) ||
        item?.creator?.displayName.toLowerCase().includes(keyword.toLowerCase())
      );
    })
    .sort((a, b) => a.id - b.id);

  return (
    <dialog id='available_item_modal' className='modal'>
      <div className='modal-box flex h-auto max-h-[45rem] max-w-5xl flex-col gap-6 overflow-y-hidden'>
        <h3 className='text-lg font-bold'>
          <Trans i18nKey={'available-items-modal.ttl'} />
        </h3>
        <p className='mb-5 text-xs sm:text-base'>
          <Trans i18nKey={'available-items-modal.desc'} />
        </p>
        <label className='input input-bordered flex w-full max-w-sm flex-shrink-0 items-center gap-2 lg:max-w-none'>
          <MagnifyingGlassIcon className='h-4 w-4 text-[#9CA3AF]' />
          <input
            type='search'
            className='grow text-sm placeholder:text-sm'
            placeholder={t('available-items-modal.placeholder')}
            onChange={handleInputChange}
            value={keyword}
          />
        </label>
        {/* PC表示 */}
        <div className='hidden overflow-scroll lg:block'>
          <table className='table table-pin-rows'>
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>
                  <Trans i18nKey={'available-items-modal.artwork'} />
                </th>
                <th>
                  <Trans i18nKey={'available-items-modal.title'} />
                </th>
                <th>
                  <Trans i18nKey={'available-items-modal.lobby-title'} />
                </th>
                <th>
                  <Trans i18nKey={'available-items-modal.year'} />
                </th>
                <th>
                  <Trans i18nKey={'available-items-modal.added'} />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((item) => {
                return (
                  <tr key={`AvailableItemsModal_${item.id}_pc`}>
                    <th>
                      <label>
                        <input
                          type='checkbox'
                          className='checkbox'
                          value={item.id}
                          onChange={(e) => handleChange(e.target.value)}
                        />
                      </label>
                    </th>
                    <td>
                      <div
                        className='h-20 w-20 rounded-lg border border-base-300 bg-contain bg-center bg-no-repeat'
                        style={{ backgroundImage: `url(${item.itemUrl})` }}
                      ></div>
                    </td>
                    <th>{item.title}</th>
                    <td>{item.creator.displayName}</td>
                    <td>{item.createdYear}</td>
                    <td>{formatMMMDYYYY(item.createdAt)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* SP表示 */}
        <div className='no-scrollbar overflow-scroll lg:hidden'>
          {filteredItems.map((item, index) => {
            return (
              <div key={`AvailableItemsModal_${item.id}_sp`}>
                <label className='label flex cursor-pointer justify-start'>
                  <div className='px-2'>
                    <input
                      type='checkbox'
                      className='checkbox'
                      value={item.id}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  </div>
                  <div className='px-2'>
                    <div
                      className='h-20 w-20 rounded-lg border border-base-300 bg-contain bg-center bg-no-repeat'
                      style={{ backgroundImage: `url(${item.itemUrl})` }}
                    ></div>
                  </div>
                  <div className='px-2 text-xs'>
                    <div className='font-bold'>{item.title}</div>
                    <div>{item.creator.displayName}</div>
                    <div>{item.createdYear}</div>
                    <div>
                      <span className='font-semibold text-[#1F2A3799]/60'>
                        Added:
                      </span>
                      {formatMMMDYYYY(item.createdAt)}
                    </div>
                  </div>
                </label>
                {filteredItems.length - 1 !== index && (
                  <div className='divider m-0'></div>
                )}
              </div>
            );
          })}
        </div>
        <div className='modal-action mt-0'>
          <form method='dialog' className='flex gap-2'>
            {/* if there is a button in form, it will close the modal */}
            <button className='btn' onClick={() => setKeyword('')}>
              <Trans i18nKey={'btn.close'} />
            </button>
            {0 < availableItems.length && (
              <button
                className='btn btn-primary'
                onClick={handleAddItems}
                disabled={
                  MAX_SELECT_ARTWORK < addedItems.length + selectedItemIds.size
                }
              >
                <Trans i18nKey={'btn.save'} />
              </button>
            )}
          </form>
        </div>
        {MAX_SELECT_ARTWORK < addedItems.length + selectedItemIds.size && (
          <p className='mx-auto mt-3 text-left text-xs text-[#6A6A6A]'>
            <Trans i18nKey={'available-items-modal.max-reach'} />
          </p>
        )}
      </div>
    </dialog>
  );
};

export default AvailableItemsModal;
