import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CEILING_LIST = [
  {
    id: 1,
    title: 'CEILING DROP TILES',
    thumbnail:
      'https://data.curatedartshow.com/texture/ceilings/ceiling1/thumbnail.png',
  },
  {
    id: 9,
    title: 'WOOD CEILING COFFERS',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling9/basecolor.jpg`,
  },
  {
    id: 2,
    title: 'CHARRED WOOD PLANKS',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling2/thumbnail.png`,
  },
  {
    id: 8,
    title: 'WAFFLE SLAB CONCRETE GRID 2',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling8/basecolor.jpg`,
  },

  {
    id: 13,
    title: 'PLASTER CEILING',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall11/basecolor.jpg`,
  },
  {
    id: 16,
    title: 'PORTUGUESE TILES',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/basecolor.jpg`,
  },
];

const WALL_LIST = [
  {
    id: 58,
    title: 'White Paint',
    thumbnail: `https://data.curatedartshow.com/texture/walls/whitepaint/basecolor.png`,
  },
  {
    id: 52,
    title: 'Wood 11',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor005/basecolor.jpg`,
  },
  {
    id: 47,
    title: 'Metal 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal028/thumbnail.png`,
  },
  {
    id: 14,
    title: 'STUCCO',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/Stucco34/basecolor.jpg`,
  },
  {
    id: 11,
    title: 'PLASTER WALL',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall11/basecolor.jpg`,
  },
  {
    id: 15,
    title: 'PORTUGUESE TILES',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/basecolor.jpg`,
  },
];
const FLOOR_LIST = [
  {
    id: 10,
    title: 'OAK FLOOR PANELS',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor10/basecolor.jpg`,
  },
  {
    id: 22,
    title: 'Wood 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood007/basecolor.jpg`,
  },
  {
    id: 23,
    title: 'Wood 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood008/basecolor.jpg`,
  },
  {
    id: 67,
    title: 'Concrete 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Concrete020/basecolor.jpg`,
  },
  {
    id: 7,
    title: 'GROUND FOREST',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor7/basecolor.jpg`,
  },
  {
    id: 17,
    title: 'PORTUGUESE TILES',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/basecolor.jpg`,
  },
];

const FRAME_LIST = [
  {
    value: null,
    title: 'Unframed',
    imgUrl: '/assets/img/ban.png',
  },
  {
    value: 'black',
    title: 'Black',
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/new_black.png',
  },
  {
    value: 'gold',
    title: 'Classic',
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/classic.png',
  },
  {
    value: 'frame59',
    title: 'Slimframe 7',
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe7.png',
  },
];

const SimulatorOverlay = ({
  ceiling,
  setCeiling,
  wall,
  setWall,
  floor,
  setFloor,
  frame,
  setFrame,
}) => {
  const { i18n } = useTranslation();

  const [activeList, setActiveList] = useState('ceiling');

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(true);

  const handleDrawerChange = (e) => {
    setIsDrawerOpen(e.target.checked);
  };
  const toggleMobileDrawer = (e) => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  };

  const mobileDrawerRef = useRef(null);

  // 外側をクリックした場合にメニューを閉じる
  useEffect(() => {
    const handleClickOutside = (event) => {
      // クリックした場所がメニュー外かどうかを確認
      if (
        mobileDrawerRef.current &&
        !mobileDrawerRef.current.contains(event.target)
      ) {
        // 外をクリックした場合はメニューを閉じる
        setIsMobileDrawerOpen(false);
      }
    };

    // マウスクリックイベントを監視
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobileDrawerRef]);

  return (
    <div>
      {/* mobile用page title */}
      <div className='fixed left-5 top-5  z-10 flex items-center md:hidden'>
        <img
          src='/assets/img/cas-logo.png'
          alt='site-logo'
          className='h-10 w-10'
        />
        <p className='font-outfit text-xl font-semibold text-[#2B2C34]'>
          <Trans i18nKey={'top.simulator-ttl'} />
        </p>
      </div>

      {/* closeボタン */}
      <Link
        to={'/'}
        className='fixed right-5 top-5 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-base-200 px-4 md:top-10 md:h-12 md:w-12 md:py-[1.62rem]'
      >
        <XMarkIcon className='h-5 w-5 shrink-0 md:h-6 md:w-6' />
      </Link>

      {/* desktop用menu */}
      <div className='drawer absolute z-10 hidden md:block'>
        <input
          id='my-drawer'
          type='checkbox'
          className='drawer-toggle'
          onChange={handleDrawerChange}
          checked={isDrawerOpen}
        />
        <div className='drawer-content'>
          <label
            htmlFor='my-drawer'
            className='drawer-button fixed top-1/2 flex h-[5rem] w-10 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-r-xl bg-base-200 px-3 py-[1.62rem]'
          >
            <ArrowRightIcon className='h-5 w-5 shrink-0 text-[#0F172A]' />
          </label>
        </div>
        <div className='drawer-side min-h-full'>
          <label
            htmlFor='my-drawer'
            aria-label='close sidebar'
            className='drawer-overlay opacity-0'
          ></label>
          <div className='menu min-h-full w-[24.3125rem] gap-6 border border-base-300 bg-base-200 px-8 pb-10 pt-4 text-base-content'>
            {isDrawerOpen && (
              <label
                htmlFor='my-drawer'
                className='drawer-button fixed -right-[2.58rem] top-1/2 flex h-[5rem] w-10 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-r-xl bg-base-200 px-3 py-[1.62rem]'
              >
                <ArrowLeftIcon className='h-5 w-5 shrink-0 text-[#0F172A]' />
              </label>
            )}
            <div className='flex items-center gap-2'>
              <img
                src='/assets/img/cas-logo.png'
                alt='site-logo'
                className='h-10 w-10'
              />
              <p className='font-outfit text-xl font-semibold text-[#2B2C34]'>
                <Trans i18nKey={'top.simulator-ttl'} />
              </p>
            </div>
            {/* ceiling */}
            <div>
              <p className='pb-3 text-xl font-semibold'>
                <Trans i18nKey={'gallery.decoration-room-ceiling'} />
              </p>
              <div className='grid max-h-[34rem] grid-cols-3 gap-3 overflow-y-scroll'>
                {CEILING_LIST.map((item, index) => (
                  <div
                    key={`ceiling-${index}`}
                    className={`relative cursor-pointer`}
                    onClick={() => setCeiling(Number(item.id))}
                  >
                    <img
                      src={item.thumbnail}
                      alt={item.title}
                      className={`h-auto w-full rounded ${
                        item.id == ceiling && 'border-[1.5px] border-black'
                      }`}
                    />
                    <p className='mt-1 text-left text-xs '>
                      {item.title.toLowerCase()}
                    </p>
                    {item.id == ceiling && (
                      <img
                        src='/assets/img/check_icon.png'
                        className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* wall */}
            <div>
              <p className='pb-3 text-xl font-semibold'>
                <Trans i18nKey={'gallery.decoration-room-wall'} />
              </p>
              <div className='grid max-h-[34rem] grid-cols-3 gap-3 overflow-y-scroll'>
                {WALL_LIST.map((item, index) => (
                  <div
                    key={`wall-${index}`}
                    className={`relative cursor-pointer`}
                    onClick={() => setWall(Number(item.id))}
                  >
                    <img
                      src={item.thumbnail}
                      alt={item.title}
                      className={`h-auto w-full rounded ${
                        item.id == wall && 'border-[1.5px] border-black'
                      }`}
                    />
                    <p className='mt-1 text-left text-xs '>
                      {item.title.toLowerCase()}
                    </p>
                    {item.id == wall && (
                      <img
                        src='/assets/img/check_icon.png'
                        className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* floor */}
            <div>
              <p className='pb-3 text-xl font-semibold'>
                <Trans i18nKey={'gallery.decoration-room-floor'} />
              </p>
              <div className='grid max-h-[34rem] grid-cols-3 gap-3 overflow-y-scroll'>
                {FLOOR_LIST.map((item, index) => (
                  <div
                    key={`floor-${index}`}
                    className={`relative cursor-pointer`}
                    onClick={() => setFloor(Number(item.id))}
                  >
                    <img
                      src={item.thumbnail}
                      alt={item.title}
                      className={`h-auto w-full rounded ${
                        item.id == floor && 'border-[1.5px] border-black'
                      }`}
                    />
                    <p className='mt-1 text-left text-xs '>
                      {item.title.toLowerCase()}
                    </p>
                    {item.id == floor && (
                      <img
                        src='/assets/img/check_icon.png'
                        className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* frame */}
            <div>
              <p className='pb-3 text-xl font-semibold'>
                <Trans i18nKey={'gallery.decoration-frame'} />
              </p>
              <div className='grid max-h-[34rem] grid-cols-3 gap-3 overflow-y-scroll'>
                {FRAME_LIST.map((item, index) => (
                  <div
                    key={`frame-${index}`}
                    className={`relative cursor-pointer`}
                    onClick={() => setFrame(item.value)}
                  >
                    <img
                      src={item.imgUrl}
                      alt={item.title}
                      className={`h-auto w-full rounded ${
                        item.value == frame && 'border-[1.5px] border-black'
                      }`}
                    />

                    {item.value == '' && i18n.language === 'ja' ? (
                      <p className='mt-1 text-left text-xs'>額縁なし</p>
                    ) : (
                      <p className='mt-1 text-left text-xs'>{item.title}</p>
                    )}
                    {item.value == frame && (
                      <img
                        src='/assets/img/check_icon.png'
                        className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='fixed bottom-4 right-4 z-10 hidden flex-col gap-1 text-right md:flex'>
        <p className='text-xs text-base-content'>
          <Trans i18nKey={'simulator.note'} />
        </p>
        <p className='text-xs text-base-content'>
          Powered by <span className='font-bold'>CAS</span>
        </p>
      </div>
      {/* mobile用menu */}
      <div className='md:hidden'>
        <button
          className='fixed bottom-0 left-1/2 z-10 flex h-10 w-20 -translate-x-1/2 transform cursor-pointer items-center justify-center rounded-t-xl bg-base-200 px-3 py-[1.62rem]'
          onClick={toggleMobileDrawer}
        >
          <ArrowUpIcon className='h-4 w-4 shrink-0 text-[#0F172A]' />
        </button>

        <div
          ref={mobileDrawerRef}
          className={`fixed left-0 z-10 flex w-full flex-col gap-6 border border-base-300 bg-base-200 p-6 transition-all duration-300 ease-in-out ${
            isMobileDrawerOpen ? 'bottom-0' : '-bottom-96'
          }`}
        >
          <button
            className='absolute bottom-[18.05rem] left-1/2 z-10 flex h-10 w-20 -translate-x-1/2 transform cursor-pointer items-center justify-center rounded-t-xl bg-base-200 px-3 py-[1.62rem]'
            onClick={toggleMobileDrawer}
          >
            <ArrowDownIcon className='h-4 w-4 shrink-0 text-[#0F172A]' />
          </button>
          <div
            role='tablist'
            className='tabs-boxed tabs w-fit bg-white p-1 text-sm font-bold'
          >
            <button
              role='tab'
              className={`tab w-20 px-4 py-0 ${
                activeList == 'ceiling' && 'tab-active'
              }`}
              onClick={() => {
                setActiveList('ceiling');
              }}
            >
              <Trans i18nKey={'gallery.decoration-room-ceiling'} />
            </button>
            <button
              role='tab'
              className={`tab w-20 px-4 py-0 ${
                activeList == 'wall' && 'tab-active'
              }`}
              onClick={() => {
                setActiveList('wall');
              }}
            >
              <Trans i18nKey={'gallery.decoration-room-wall'} />
            </button>
            <button
              role='tab'
              className={`tab w-20 px-4 py-0 ${
                activeList == 'floor' && 'tab-active'
              }`}
              onClick={() => {
                setActiveList('floor');
              }}
            >
              <Trans i18nKey={'gallery.decoration-room-floor'} />
            </button>
            <button
              role='tab'
              className={`tab w-fit px-4 py-0 ${
                activeList == 'frame' && 'tab-active'
              }`}
              onClick={() => {
                setActiveList('frame');
              }}
            >
              <Trans i18nKey={'gallery.decoration-frame-short'} />
            </button>
          </div>
          {activeList == 'ceiling' && (
            <div className='flex max-h-[34rem] gap-3 overflow-x-scroll'>
              {CEILING_LIST.map((item, index) => (
                <div
                  key={`ceiling-${index}`}
                  className={`relative cursor-pointer`}
                  onClick={() => setCeiling(Number(item.id))}
                >
                  <img
                    src={item.thumbnail}
                    alt={item.title}
                    className={`h-auto w-[4.5rem] rounded ${
                      item.id == ceiling && 'border-[1.5px] border-black'
                    }`}
                  />
                  <p className='mt-1 h-[3rem] w-[4.5rem] text-left text-xs'>
                    {item.title.toLowerCase()}
                  </p>
                  {item.id == ceiling && (
                    <img
                      src='/assets/img/check_icon.png'
                      className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          {activeList == 'wall' && (
            <div className='flex max-h-[34rem] gap-3 overflow-x-scroll'>
              {WALL_LIST.map((item, index) => (
                <div
                  key={`wall-${index}`}
                  className={`relative cursor-pointer`}
                  onClick={() => setWall(Number(item.id))}
                >
                  <img
                    src={item.thumbnail}
                    alt={item.title}
                    className={`h-auto w-[4.5rem] rounded ${
                      item.id == wall && 'border-[1.5px] border-black'
                    }`}
                  />
                  <p className='mt-1 h-[3rem] w-[4.5rem] text-left text-xs'>
                    {item.title.toLowerCase()}
                  </p>
                  {item.id == wall && (
                    <img
                      src='/assets/img/check_icon.png'
                      className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          {activeList == 'floor' && (
            <div className='flex max-h-[34rem] gap-3 overflow-x-scroll'>
              {FLOOR_LIST.map((item, index) => (
                <div
                  key={`floor-${index}`}
                  className={`relative cursor-pointer`}
                  onClick={() => setFloor(Number(item.id))}
                >
                  <img
                    src={item.thumbnail}
                    alt={item.title}
                    className={`h-auto w-[4.5rem] rounded ${
                      item.id == floor && 'border-[1.5px] border-black'
                    }`}
                  />
                  <p className='mt-1 h-[3rem] w-[4.5rem] text-left text-xs'>
                    {item.title.toLowerCase()}
                  </p>
                  {item.id == floor && (
                    <img
                      src='/assets/img/check_icon.png'
                      className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          {activeList == 'frame' && (
            <div className='flex max-h-[34rem] gap-3 overflow-x-scroll'>
              {FRAME_LIST.map((item, index) => (
                <div
                  key={`frame-${index}`}
                  className={`relative cursor-pointer`}
                  onClick={() => setFrame(item.value)}
                >
                  <img
                    src={item.imgUrl}
                    alt={item.title}
                    className={`h-auto w-[4.5rem] rounded ${
                      item.value == frame && 'border-[1.5px] border-black'
                    }`}
                  />

                  {item.value == '' && i18n.language === 'ja' ? (
                    <p className='mt-1 h-[3rem] w-[4.5rem] text-left text-xs'>
                      額縁なし
                    </p>
                  ) : (
                    <p className='mt-1 h-[3rem] w-[4.5rem] text-left text-xs'>
                      {item.title}
                    </p>
                  )}
                  {item.value == frame && (
                    <img
                      src='/assets/img/check_icon.png'
                      className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          <div className='flex flex-col gap-1 pt-3'>
            <p className='text-center text-xs text-base-content'>
              <Trans i18nKey={'simulator.note'} />
            </p>
            <p className='text-center text-xs text-base-content'>
              Powered by <span className='font-bold'>CAS</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimulatorOverlay;
