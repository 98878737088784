import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { WALLS } from '../../../data/walls';
import { invalidate } from '@react-three/fiber';

// images: {
//   map: `/assets/textures/Blue Slate/basecolor.png`,
//   normalMap: `/assets/textures/Blue Slate/normal.png`,
//   roughnessMap: `https://data.curatedartshow.com/texture/floors/Concrete020/roughness.jpg`,
//   displacementMap: `/assets/textures/Blue Slate/height.png`,
//   metalnessMap: `/assets/textures/Blue Slate/metallic.png`,
// },
// settings: {
//   displacementScale: 0.5,
//   aoMapIntensity: 1.2,
//   roughness: 1,
//   normalScale: new THREE.Vector2(1, 3),
// },

const BluePaintRoomWall = ({ id }) => {
  const textureProps = useTexture({
    map: `https://data.curatedartshow.com/texture/walls/BluePaintRolled/basecolor.png`,
    normalMap: `https://data.curatedartshow.com/texture/walls/BluePaintRolled/normal.png`,
    roughnessMap: `https://data.curatedartshow.com/texture/walls/BluePaintRolled/roughness.png`,
    metalnessMap: `https://data.curatedartshow.com/texture/walls/BluePaintRolled/metallic.png`,
    displacementMap: `https://data.curatedartshow.com/texture/walls/BluePaintRolled/height.png`,
  });

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(28, 28);
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current]);

  return (
    <mesh receiveShadow ref={mesh} position={[0, 0, -0.5]}>
      <planeGeometry args={[200, 200]} />
      <meshStandardMaterial
        displacementScale={0.5}
        aoMapIntensity={1}
        roughness={3}
        normalScale={new THREE.Vector2(1, 1)}
        metalness={0.0}
        color={'#a4a4a4'}
        {...textureProps}
      />
    </mesh>
  );
};

export default BluePaintRoomWall;
