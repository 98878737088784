import { useState, useEffect } from 'react';
import { authRequest, publicRequest } from '../../../requestMethods';
import { ArrowUturnLeftIcon, TrashIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import ArtworkModal from '../components/ArtworkModal';
import DeleteModal from '../components/DeleteModal';
import { errorToast, successToast } from '../../../utils/toast';
import { useDispatch } from 'react-redux';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import { useNavigate } from 'react-router-dom';
import MatureContentModal from '../components/MatureContentModal';
import { fetchDeletedArtworks } from '../../../services/Admin';
import { useQuery } from '@tanstack/react-query';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/outline';

const AdminDeletedArtworkList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [artworkModalItem, setArtworkModalItem] = useState(null);
  const [matureContentId, setMatureContentId] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredArtworks, setFilteredArtworks] = useState([]);
  const [artworkTitleSort, setArtworkTitleSort] = useState('');
  const [ownerSort, setOwnerSort] = useState('');
  const [dateCreatedSort, setDateCreatedSort] = useState('');
  const [dateDeletedSort, setDateDeletedSort] = useState('');

  const openModal = (item) => {
    setArtworkModalItem(item);
    document.body.classList.add('is-scrollLocked');
    setShowModal(true);
  };

  const {
    data: artworks,
    isLoading: isArtworksLoading,
    refetch: refetchArtworks,
  } = useQuery({
    queryKey: ['fetch-deleted-artworks'],
    queryFn: () => fetchDeletedArtworks(),
    retry: (failureCount, error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        return false; // 再認証が必要な場合はリトライしない
      }
    },
    meta: { errCode: 'ADMIN_FETCH_DELETED_ARTWORKS' },
  });

  const handleUndelete = async (id) => {
    dispatch(loadStart());
    try {
      await authRequest.post(`/admin/undelete-artwork/${id}`);
      successToast('Artwork undeleted successfully!');
      refetchArtworks();
    } catch (err) {
      console.error(err);
      if (err.response.data.statusCode == 403) {
        navigate('/admin/login');
      } else {
        errorToast('Something went wrong');
      }
    } finally {
      dispatch(loadEnd());
    }
  };

  const handleOpenMatureContentModal = (id) => {
    setMatureContentId(id);
    document.getElementById('mature_content_modal').showModal();
  };

  const handleMarkAsMatureContent = async () => {
    try {
      await authRequest.post(`/admin/mark-mature/${matureContentId}`);
      document.getElementById('mature_content_modal').close();
      refetchArtworks();
      setShowModal(false);
      successToast(
        'The artwork has been successfully marked as mature content'
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getNextSort = (sort) => {
    if (sort === '') {
      return 'asc';
    }
    if (sort === 'asc') {
      return 'desc';
    }
    return '';
  };

  const handleSortByArtworkTitle = () => {
    const nextSort = getNextSort(artworkTitleSort);
    setArtworkTitleSort(nextSort);
    if (nextSort === '') {
      return setFilteredArtworks(artworks);
    }
    const sorted =
      nextSort === 'desc'
        ? filteredArtworks.toSorted((a, b) =>
            a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
          )
        : filteredArtworks.toSorted((a, b) =>
            a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1
          );
    setFilteredArtworks(sorted);
  };

  const handleSortByOwner = () => {
    const nextSort = getNextSort(ownerSort);
    setOwnerSort(nextSort);
    if (nextSort === '') {
      return setFilteredArtworks(artworks);
    }
    const sorted =
      nextSort === 'desc'
        ? filteredArtworks.toSorted((a, b) =>
            a.owner.displayName.toLowerCase() <
            b.owner.displayName.toLowerCase()
              ? -1
              : 1
          )
        : filteredArtworks.toSorted((a, b) =>
            a.owner.displayName.toLowerCase() <
            b.owner.displayName.toLowerCase()
              ? 1
              : -1
          );
    setFilteredArtworks(sorted);
  };

  const handleSortByDateCreated = () => {
    const nextSort = getNextSort(dateCreatedSort);
    setDateCreatedSort(nextSort);
    if (nextSort === '') {
      return setFilteredArtworks(artworks);
    }
    const sorted =
      nextSort === 'desc'
        ? filteredArtworks.toSorted((a, b) =>
            a.createdAt < b.createdAt ? -1 : 1
          )
        : filteredArtworks.toSorted((a, b) =>
            a.createdAt < b.createdAt ? 1 : -1
          );
    setFilteredArtworks(sorted);
  };

  const handleSortByDateDeleted = () => {
    const nextSort = getNextSort(dateDeletedSort);
    setDateDeletedSort(nextSort);
    if (nextSort === '') {
      return setFilteredArtworks(artworks);
    }
    const sorted =
      nextSort === 'desc'
        ? filteredArtworks.toSorted((a, b) =>
            a.updatedAt < b.updatedAt ? -1 : 1
          )
        : filteredArtworks.toSorted((a, b) =>
            a.updatedAt < b.updatedAt ? 1 : -1
          );
    setFilteredArtworks(sorted);
  };

  const removeMatureContentLabel = async (id) => {
    try {
      await authRequest.post(`/admin/remove-mature/${id}`);
      refetchArtworks();
      setShowModal(false);
      successToast('The mature content label has been successfully removed');
    } catch (err) {
      console.error(err);
      errorToast('Something went wrong');
    }
  };

  useEffect(() => {
    if (searchKeyword) {
      const filtered = artworks.filter((artwork) =>
        artwork.title.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      setFilteredArtworks(filtered);
    } else {
      setFilteredArtworks(artworks);
    }
  }, [artworks, searchKeyword]);

  return (
    <>
      <h2 className='text-3xl font-extrabold'>Manage Deleted Artworks</h2>
      <label className='input input-bordered mt-8 flex w-[22.5rem] items-center gap-2'>
        <input
          type='search'
          className='grow'
          placeholder='Type artwork title'
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
      </label>

      <div className='mt-10 overflow-x-auto'>
        <table className='table table-auto'>
          {/* head */}
          <thead>
            <tr>
              <th>Id</th>
              <th>Image</th>
              <th>
                <div
                  className='flex cursor-pointer items-center gap-[0.625rem]'
                  onClick={handleSortByArtworkTitle}
                >
                  <span>Artwork Title</span>
                  <span>
                    {artworkTitleSort === '' ? (
                      <ChevronUpDownIcon className='h-4 w-4' />
                    ) : artworkTitleSort === 'desc' ? (
                      <ChevronDownIcon className='h-4 w-4' />
                    ) : (
                      <ChevronUpIcon className='h-4 w-4' />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div
                  className='flex cursor-pointer items-center gap-[0.625rem]'
                  onClick={handleSortByOwner}
                >
                  <span>Owner</span>
                  <span>
                    {ownerSort === '' ? (
                      <ChevronUpDownIcon className='h-4 w-4' />
                    ) : ownerSort === 'desc' ? (
                      <ChevronDownIcon className='h-4 w-4' />
                    ) : (
                      <ChevronUpIcon className='h-4 w-4' />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div
                  className='flex cursor-pointer items-center gap-[0.625rem]'
                  onClick={handleSortByDateCreated}
                >
                  <span>Date Created</span>
                  <span>
                    {dateCreatedSort === '' ? (
                      <ChevronUpDownIcon className='h-4 w-4' />
                    ) : dateCreatedSort === 'desc' ? (
                      <ChevronDownIcon className='h-4 w-4' />
                    ) : (
                      <ChevronUpIcon className='h-4 w-4' />
                    )}
                  </span>
                </div>
              </th>
              <th>
                <div
                  className='flex cursor-pointer items-center gap-[0.625rem]'
                  onClick={handleSortByDateDeleted}
                >
                  <span>Date Deleted</span>
                  <span>
                    {dateDeletedSort === '' ? (
                      <ChevronUpDownIcon className='h-4 w-4' />
                    ) : dateDeletedSort === 'desc' ? (
                      <ChevronDownIcon className='h-4 w-4' />
                    ) : (
                      <ChevronUpIcon className='h-4 w-4' />
                    )}
                  </span>
                </div>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredArtworks?.map((item) => (
              <tr key={`item-${item.id}`}>
                <th>{item.id}</th>
                <td>
                  <img
                    className='h-24 w-24 object-cover'
                    src={item.itemUrl}
                    alt=''
                  />
                </td>
                <td className='max-w-48'>
                  {item.isMatureContent && (
                    <div className='badge !badge-warning'>Mature Content</div>
                  )}
                  <br />
                  {item.title}
                </td>
                <td className='max-w-56'>{item.owner.displayName}</td>
                <td>{dayjs(item.createdAt).format('MMM D, YYYY')}</td>
                <td>{dayjs(item.updatedAt).format('MMM D, YYYY')}</td>
                <td className='min-w-40'>
                  <button
                    className='btn btn-sm'
                    onClick={() => openModal(item)}
                  >
                    View Detail
                  </button>
                </td>
                <td className='min-w-36'>
                  <button
                    className='btn btn-outline btn-error btn-sm'
                    onClick={() => {
                      handleUndelete(item.id);
                    }}
                  >
                    <ArrowUturnLeftIcon className='h-5 w-5' />
                    Undelete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <ArtworkModal
          item={artworkModalItem}
          setShowModal={setShowModal}
          openMatureContentModal={handleOpenMatureContentModal}
          removeMatureContentLabel={removeMatureContentLabel}
        />
      )}
      <MatureContentModal confirmMark={() => handleMarkAsMatureContent()} />
    </>
  );
};

export default AdminDeletedArtworkList;
