import { EnvelopeIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { publicRequest } from '../../requestMethods';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import { Trans, useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { isLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    try {
      await publicRequest.post('/auth/forgot-password', { email });
      dispatch(loadEnd());
      setSuccess(true);
    } catch (err) {
      dispatch(loadEnd());
      console.log(err);
      setErrorMessage(t('message.error.t'));
    }
  };

  return (
    <>
      <div className='relative mx-auto h-full md:flex'>
        <div className='w-full px-4 pt-10 md:pt-20'>
          <div className='mx-auto flex max-w-[30rem] flex-col gap-8'>
            {!success ? (
              <>
                <h2 className='text-3xl font-extrabold'>
                  <Trans i18nKey={'forgot-password.ttl'} />
                </h2>
                <div className='text-sm'>
                  <Trans i18nKey={'forgot-password.desc'} />
                </div>
                <form
                  onSubmit={handleSubmit}
                  className='flex w-full flex-col gap-8'
                >
                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text'>
                        <Trans i18nKey={'forgot-password.email'} />
                      </span>
                    </div>
                    <input
                      type='email'
                      className='input input-bordered w-full'
                      placeholder={
                        i18n.language === 'ja' ? 'メールアドレス' : 'Email'
                      }
                      maxLength='320'
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {!!errorMessage && (
                      <div className='label'>
                        <span className='text-error'>{errorMessage}</span>
                      </div>
                    )}
                  </label>

                  <div className='flex flex-col items-center gap-4'>
                    <button
                      type='submit'
                      className='btn btn-primary btn-wide'
                      disabled={!email}
                    >
                      <Trans i18nKey={'forgot-password.send-email'} />
                    </button>
                    {i18n.language === 'ja' ? (
                      <Link
                        className='text-center text-sm underline'
                        to='/signin'
                      >
                        サインインに戻る
                      </Link>
                    ) : (
                      <Link
                        className='text-center text-sm underline'
                        to='/signin'
                      >
                        Back to Sign in
                      </Link>
                    )}
                  </div>
                </form>
              </>
            ) : (
              <>
                <h2 className='text-3xl font-extrabold'>
                  <Trans i18nKey={'forgot-password.message-1'} />
                </h2>
                <div className='mt-8 flex justify-center'>
                  <img
                    src='/assets/img/message-sent.png'
                    alt=''
                    className='h-60 w-60'
                  />
                </div>
                <p className='text-center'>
                  <Trans i18nKey={'forgot-password.message-2'} />
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner isHalf={false} />}
    </>
  );
};

export default ForgotPassword;
