import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/userRedux';
import axios from 'axios';
import { errorToast, successToast } from '../../utils/toast';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Trans, useTranslation } from 'react-i18next';
import { MinusIcon } from '@heroicons/react/24/outline';

const ResetPassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numericValid, setNumericValid] = useState(false);
  const [charValid, setCharValid] = useState(false);
  const [lengthValid, setLengthValid] = useState(false);
  const [confirmValid, setConfirmValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/auth/reset-password`,
        { password: newPassword, confirmPassword: confirmNewPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('res', res);
      dispatch(logout());
      navigate('/signin');
      successToast(t('message.success.t5'));
      dispatch(loadEnd());
    } catch (err) {
      dispatch(loadEnd());
      console.log(err);
    }
  };

  const passwordValidation = () => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;

    const numbers = /[0-9]/g;
    const characters = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/g;

    if (newPassword.match(lowerCaseLetters)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }

    if (newPassword.match(upperCaseLetters)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
    if (newPassword.match(numbers)) {
      setNumericValid(true);
    } else {
      setNumericValid(false);
    }
    if (newPassword.match(characters)) {
      setCharValid(true);
    } else {
      setCharValid(false);
    }

    if (newPassword.length >= 8) {
      setLengthValid(true);
    } else {
      setLengthValid(false);
    }

    if (newPassword === confirmNewPassword) {
      setConfirmValid(true);
    } else {
      setConfirmValid(false);
    }
  };

  useEffect(() => {
    const linkValidation = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASEURL}/auth/reset-token-validation`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('res', res);
      } catch (err) {
        //失効していた場合はsigninに戻す
        navigate('/signin');
        errorToast(t('message.error.t4'));
        console.log(err);
      }
    };
    linkValidation();
  }, []);

  useEffect(() => {
    if (
      uppercaseValid &&
      lowercaseValid &&
      numericValid &&
      lengthValid &&
      charValid &&
      confirmValid
    ) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [
    uppercaseValid,
    lowercaseValid,
    numericValid,
    lengthValid,
    charValid,
    confirmValid,
  ]);

  return (
    <>
      <div className='relative mx-auto h-full'>
        <div className='mx-auto max-w-[30rem] px-4 pb-20 pt-[4.5rem] sm:px-0'>
          <h1 className='text-3xl font-extrabold'>Reset password</h1>
          <form onSubmit={handleSubmit} className='mt-8'>
            <div className='flex flex-col gap-6'>
              {/* New Password */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'signin.password'} />
                  </span>
                </div>
                <div className='relative'>
                  <input
                    className='undefined input input-bordered w-full pr-10 text-sm placeholder:text-sm'
                    type={`${showNewPassword ? 'text' : 'password'}`}
                    placeholder='New Password'
                    maxLength='46'
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyUp={passwordValidation}
                  />
                  <span
                    className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer text-sm'
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    Show
                  </span>
                </div>
              </label>
              {/* Confirm New Password */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    Confirm New Password
                  </span>
                </div>
                <div className='relative'>
                  <input
                    className='undefined input input-bordered w-full pr-10 text-sm placeholder:text-sm'
                    type={`${showConfirmNewPassword ? 'text' : 'password'}`}
                    placeholder='Confirm New Password'
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    onKeyUp={passwordValidation}
                  />
                  <span
                    className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer text-sm'
                    onClick={() =>
                      setShowConfirmNewPassword(!showConfirmNewPassword)
                    }
                  >
                    Show
                  </span>
                </div>
              </label>
              <div className='flex flex-col gap-2'>
                <div
                  className={`flex items-center gap-2 text-xs ${
                    lowercaseValid ? 'text-success' : 'text-error'
                  }`}
                >
                  <MinusIcon className={`h-4 w-4`} />
                  <Trans i18nKey={'signup.top.lowercase'} />
                </div>
                <div
                  className={`flex items-center gap-2 text-xs ${
                    uppercaseValid ? 'text-success' : 'text-error'
                  }`}
                >
                  <MinusIcon className={`h-4 w-4`} />
                  <Trans i18nKey={'signup.top.uppercase'} />
                </div>
                <div
                  className={`flex items-center gap-2 text-xs ${
                    numericValid ? 'text-success' : 'text-error'
                  }`}
                >
                  <MinusIcon className={`h-4 w-4`} />
                  <Trans i18nKey={'signup.top.number'} />
                </div>
                <div
                  className={`flex items-center gap-2 text-xs ${
                    charValid ? 'text-success' : 'text-error'
                  }`}
                >
                  <MinusIcon className={`h-4 w-4`} />
                  <Trans i18nKey={'signup.top.special-character'} />
                </div>
                <div
                  className={`flex items-center gap-2 text-xs ${
                    lengthValid ? 'text-success' : 'text-error'
                  }`}
                >
                  <MinusIcon className={`h-4 w-4`} />
                  <Trans i18nKey={'signup.top.8-20-letters'} />
                </div>
                <div
                  className={`flex items-center gap-2 text-xs ${
                    confirmValid ? 'text-success' : 'text-error'
                  }`}
                >
                  <MinusIcon className={`h-4 w-4`} />
                  Matches old password
                </div>
              </div>
            </div>

            <div className='mt-10 flex flex-col items-center gap-4'>
              <button
                type='submit'
                className='btn btn-primary btn-wide'
                disabled={!newPassword || !confirmNewPassword || !passwordValid}
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>

      {isLoading && <LoadingSpinner isHalf={false} />}
    </>
  );
};

export default ResetPassword;
