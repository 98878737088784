import * as THREE from 'three';

export const CEILINGS = [
  {
    id: 1,
    title: 'CEILING DROP TILES',
    thumbnail:
      'https://data.curatedartshow.com/texture/ceilings/ceiling1/thumbnail.png',
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling1/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling1/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling1/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling1/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling1/height.png`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 3,
      roughness: 0.5,
      normalScale: new THREE.Vector2(3, 1),
      metalness: 0.6,
    },
    isPro: true,
  },
  {
    id: 2,
    title: 'CHARRED WOOD PLANKS',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling2/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling2/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling2/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling2/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling2/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling2/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: true,
  },
  {
    id: 3,
    title: 'CONCRETE 1',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling3/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling3/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling3/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling3/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling3/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling3/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.2,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.6,
    },
    isPro: false,
  },
  {
    id: 4,
    title: 'CONCRETE 2',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling4/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling4/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling4/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling4/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling4/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling4/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.6,
    },
    isPro: false,
  },
  {
    id: 5,
    title: 'OFFICE CEILING',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling5/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling5/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling5/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling5/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling5/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling5/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.6,
    },
    isPro: false,
  },
  {
    id: 6,
    title: 'STRIPED CONCRETE',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling6/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling6/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling6/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling6/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling6/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling6/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 7,
    title: 'WAFFLE SLAB CONCRETE GRID',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling7/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling7/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling7/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling7/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling7/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling7/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: true,
  },
  {
    id: 8,
    title: 'WAFFLE SLAB CONCRETE GRID 2',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling8/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling8/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling8/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling8/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling8/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling8/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: true,
  },
  {
    id: 9,
    title: 'WOOD CEILING COFFERS',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling9/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling9/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling9/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling9/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling9/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling9/height.png`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: true,
  },
  {
    id: 10,
    title: 'WOOD ORNATE PANEL',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling10/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling10/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: true,
  },
  {
    id: 11,
    title: 'WOODEN FENCE',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling11/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling11/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling11/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling11/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling11/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling11/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 12,
    title: 'WOODEN CEILING MATERIAL',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling12/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling12/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling12/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling12/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling12/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling12/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 13,
    title: 'PLASTER CEILING',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall11/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall11/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall11/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall11/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall11/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall11/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.6,
    },
    isPro: false,
  },
  {
    id: 14,
    title: 'LOG CEILING',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall7/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall7/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall7/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall7/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall7/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall7/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 15,
    title: 'STUCCO',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/Stucco34/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/Stucco34/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/Stucco34/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/Stucco34/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/Stucco34/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/Stucco34/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 0,
      roughness: 0.3,
      normalScale: new THREE.Vector2(1, 2),
      metalness: 0.6,
    },
    isPro: false,
  },
  {
    id: 16,
    title: 'PORTUGUESE TILES',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: true,
  },
  {
    id: 17,
    title: 'ALUMINIUM FOIL',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/AluminiumFoil01/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/AluminiumFoil01/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/AluminiumFoil01/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/AluminiumFoil01/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/AluminiumFoil01/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/ceilings/AluminiumFoil01/metallic.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 1,
      roughness: 0.5,
      normalScale: new THREE.Vector2(10, 10),
    },
    isPro: false,
  },
  {
    id: 18,
    title: 'STONE MEDALLION PAVEMENT',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/StoneMedallionPavement/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/StoneMedallionPavement/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/StoneMedallionPavement/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/StoneMedallionPavement/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/StoneMedallionPavement/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/StoneMedallionPavement/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.5,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.4,
    },
    isPro: true,
  },
  {
    id: 19,
    title: 'Wood 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood017/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood017/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood017/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood017/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood017/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.6,
    },
    isPro: false,
  },
  {
    id: 20,
    title: 'Wood 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood023/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood023/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood023/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood023/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood023/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 21,
    title: 'Wood 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood035/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood035/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood035/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood035/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood035/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 22,
    title: 'Wood 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood037/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood037/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood037/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood037/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood037/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 23,
    title: 'Wood 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood038/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood038/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood038/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood038/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood038/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 24,
    title: 'Wood 6',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood042/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood042/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood042/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood042/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood042/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 25,
    title: 'Wood 7',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood043/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood043/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood043/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood043/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood043/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 26,
    title: 'Leather 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather016/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather016/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather016/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather016/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather016/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Leather016/ao.jpg`,
      alphaMap: `https://data.curatedartshow.com/texture/floors/Leather016/alpha.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2.5,
      roughness: 1,
      normalScale: new THREE.Vector2(3, 1),
    },
    isPro: false,
  },
  {
    id: 27,
    title: 'Leather 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather020/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather020/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Leather020/ao.jpg`,
      alphaMap: `https://data.curatedartshow.com/texture/floors/Leather020/alpha.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2.5,
      roughness: 1,
      normalScale: new THREE.Vector2(3, 1),
    },
    isPro: false,
  },
  {
    id: 28,
    title: 'Leather 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather021/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather021/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather021/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather021/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather021/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 1,
      normalScale: new THREE.Vector2(3, 1),
    },
    isPro: false,
  },
  {
    id: 29,
    title: 'Leather 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather024/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather024/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather024/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather024/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather024/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 2,
      normalScale: new THREE.Vector2(3, 1),
    },
    isPro: true,
  },
  {
    id: 30,
    title: 'Leather 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather026/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather026/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather026/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather026/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather026/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 2,
      normalScale: new THREE.Vector2(3, 1),
    },
    isPro: true,
  },
  {
    id: 31,
    title: 'Fabric 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric020/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric020/height.jpg`,
      alphaMap: `https://data.curatedartshow.com/texture/floors/Fabric020/alpha.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 32,
    title: 'Fabric 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric022/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric022/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric022/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric022/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric022/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 33,
    title: 'Fabric 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric026/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric026/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric026/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric026/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric026/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 34,
    title: 'Fabric 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric060/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric060/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric060/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric060/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric060/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Fabric060/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 3,
      roughness: 0.6,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.9,
    },
    isPro: false,
  },
  {
    id: 35,
    title: 'Marble 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble006/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble006/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble006/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble006/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble006/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
    },
    isPro: false,
  },
  {
    id: 36,
    title: 'Marble 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble008/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble008/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble008/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble008/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble008/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
    },
    isPro: false,
  },
  {
    id: 37,
    title: 'Marble 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble010/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble010/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble010/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble010/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble010/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 1),
    },
    isPro: true,
  },
  {
    id: 38,
    title: 'Metal 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal011/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal011/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal011/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal011/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal011/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal011/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 1),
    },
    isPro: false,
  },
  {
    id: 39,
    title: 'Metal 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal015/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal015/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal015/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal015/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal015/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal015/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: false,
  },
  {
    id: 40,
    title: 'Metal 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal028/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal028/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal028/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal028/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal028/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal028/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 2,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 41,
    title: 'Metal 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal032/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal032/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal032/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal032/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal032/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal032/metalness.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 42,
    title: 'Metal 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal035/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal035/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal035/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal035/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal035/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal035/metalness.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 43,
    title: 'Metal 6',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal036/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal036/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal036/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal036/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal036/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal036/metalness.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 44,
    title: 'Metal 7',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal039/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal039/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal039/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal039/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal039/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal039/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      metalness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 45,
    title: 'Wood 8',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor005/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor005/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor005/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor005/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor005/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 46,
    title: 'Wood 9',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor019/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor019/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor019/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor019/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor019/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/WoodFloor019/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 47,
    title: 'Wood 10',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor028/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor028/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor028/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor028/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor028/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/WoodFloor028/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      normalScale: new THREE.Vector2(1, 1),
      metalness: 0.5,
    },
    isPro: false,
  },
  {
    id: 48,
    title: 'Concrete 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Concrete004/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Concrete004/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Concrete004/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Concrete004/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Concrete004/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Concrete004/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 3,
      roughness: 0.5,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.5,
    },
    isPro: true,
  },
  {
    id: 49,
    title: 'Concrete 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Concrete020/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Concrete020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Concrete020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Concrete020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Concrete020/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 1.2,
      roughness: 1,
      normalScale: new THREE.Vector2(5, 1),
    },
    isPro: true,
  },
  {
    id: 50,
    title: 'White Paint',
    thumbnail: `https://data.curatedartshow.com/texture/walls/whitepaint/basecolor.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/whitepaint/basecolor.png`,
      normalMap: `https://data.curatedartshow.com/texture/walls/whitepaint/normal.png`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/whitepaint/roughness.png`,
      metalnessMap: `https://data.curatedartshow.com/texture/walls/whitepaint/metallic.png`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/whitepaint/height.png`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 1,
      roughness: 1,
      normalScale: new THREE.Vector2(2, 2),
      metalness: 0,
    },
    isPro: true,
  },
];
