import React, {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { useRef } from 'react';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { useCursor, Image, Html, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import parse from 'html-react-parser';
import { InformationCircleIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import ReactGA from 'react-ga4';
import PictureFrame1 from './frames/PictureFrame1';
import PictureFrame2 from './frames/PictureFrame2';
import PictureFrame3 from './frames/PictureFrame3';
import PictureFrame4 from './frames/PictureFrame4';
import PictureFrame5 from './frames/PictureFrame5';
import PictureFrame6 from './frames/PictureFrame6';
import PictureFrame7 from './frames/PictureFrame7';
import PictureFrame8 from './frames/PictureFrame8';
import PictureFrame9 from './frames/PictureFrame9';
import PictureFrame10 from './frames/PictureFrame10';
import PictureFrame11 from './frames/PictureFrame11';
import PictureFrame12 from './frames/PictureFrame12';
import PictureFrame13 from './frames/PictureFrame13';
import PictureFrame14 from './frames/PictureFrame14';
import PictureFrame15 from './frames/PictureFrame15';
import PictureFrame16 from './frames/PictureFrame16';
import PictureFrame17 from './frames/PictureFrame17';
import PictureFrame18 from './frames/PictureFrame18';
import PictureFrame19 from './frames/PictureFrame19';
import PictureFrame20 from './frames/PictureFrame20';
import PictureFrame21 from './frames/PictureFrame21';
import PictureFrame22 from './frames/PictureFrame22';
import PictureFrame23 from './frames/PictureFrame23';
import PictureFrame24 from './frames/PictureFrame24';
import PictureFrame25 from './frames/PictureFrame25';
import PictureFrame26 from './frames/PictureFrame26';
import PictureFrame27 from './frames/PictureFrame27';
import PictureFrame28 from './frames/PictureFrame28';
import PictureFrame29 from './frames/PictureFrame29';
import PictureFrame30 from './frames/PictureFrame30';
import PictureFrame31 from './frames/PictureFrame31';
import PictureFrame32 from './frames/PictureFrame32';
import PictureFrame33 from './frames/PictureFrame33';
import PictureFrame34 from './frames/PictureFrame34';
import PictureFrame35 from './frames/PictureFrame35';
import PictureFrame36 from './frames/PictureFrame36';
import PictureFrame37 from './frames/PictureFrame37';
import PictureFrame38 from './frames/PictureFrame38';
import PictureFrame39 from './frames/PictureFrame39';
import PictureFrame40 from './frames/PictureFrame40';
import PictureFrame41 from './frames/PictureFrame41';
import PictureFrame42 from './frames/PictureFrame42';
import PictureFrame43 from './frames/PictureFrame43';
import PictureFrame44 from './frames/PictureFrame44';
import PictureFrame45 from './frames/PictureFrame45';
import PictureFrame46 from './frames/PictureFrame46';
import PictureFrame47 from './frames/PictureFrame47';
import PictureFrame48 from './frames/PictureFrame48';
import PictureFrame49 from './frames/PictureFrame49';
import PictureFrame50 from './frames/PictureFrame50';
import PictureFrame51 from './frames/PictureFrame51';
import PictureFrame52 from './frames/PictureFrame52';
import PictureFrame53 from './frames/PictureFrame53';
import PictureFrame54 from './frames/PictureFrame54';
import PictureFrame55 from './frames/PictureFrame55';
import PictureFrame56 from './frames/PictureFrame56';
import PictureFrame57 from './frames/PictureFrame57';
import PictureFrame58 from './frames/PictureFrame58';
import PictureFrame59 from './frames/PictureFrame59';
import PictureFrame60 from './frames/PictureFrame60';
import PictureFrame61 from './frames/PictureFrame61';
import PictureFrame62 from './frames/PictureFrame62';
import CustomFrame1 from './frames/CustomFrame1';
import CustomFrame2 from './frames/CustomFrame2';
import CustomFrame3 from './frames/CustomFrame3';
import CustomFrame4 from './frames/CustomFrame4';
import CustomFrame5 from './frames/CustomFrame5';
import PictureFrameGroup from './frames/PictureFrameGroup';

const Item = ({
  item,
  index,
  setSelectedItem,
  roomSettings,
  setFullScreenItemUrl,
  zoomItemId,
  setZoomItemId,
  setCurrentItem,
  zoomItemImageUrl,
  setZoomItemImageUrl,
  setInquiryItem,
}) => {
  const image = useRef();

  const frame = useRef();
  const itemRef = useRef();

  const [hovered, hover] = useState(false);
  const [htmlPosition, setHtmlPosition] = useState([0, 0, 0]);

  const name = index.toString();

  const GOLDENRATIO = 1.61803398875;
  const scaleAdjustment = 0.95;

  useCursor(hovered);

  const imageMap = useLoader(TextureLoader, item?.itemUrl);
  const w = imageMap.source.data.width;
  const h = imageMap.source.data.height;
  // const colorMap = useLoader(TextureLoader, '/assets/img/geometry_orange.png');

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;
  const scaleValues = useMemo(() => {
    const scaleY = GOLDENRATIO;
    const scaleX = scaleY * aspectRatio;
    return { scaleX, scaleY };
  }, [aspectRatio]);
  const { scaleX, scaleY } = scaleValues;

  const sendEvent = (actionType) => {
    if (ReactGA.isInitialized) {
      // Page　Viewイベントを送信
      ReactGA.send({
        hitType: 'pageview',
        page: `/galleries/${roomSettings.roomId}`,
        title: `${item.title}`,
      });
      // カスタムイベントを送信
      ReactGA.event({
        category: 'Artwork',
        action: actionType,
        label: `${item.title}`,
        value: item.id,
      });
      console.log('send');
    } else {
      console.log('GA Not Initialized');
    }
  };

  useEffect(() => {
    if (h / w >= 1.7) {
      // 9:16(1.77)
      setHtmlPosition([0.8 * 0.6, ((-(h / w) * 2) / 10) * 0.8, 0]);
    } else if (h / w >= 1.4) {
      // 2:3(1.5)
      setHtmlPosition([0.8 * 0.6, ((-(h / w) * 2) / 10) * 0.4, 0]);
    } else if (h / w >= 1.3) {
      // 3:4(1.33)
      setHtmlPosition([0.8 * 0.6, ((-(h / w) * 2) / 10) * 0.01, 0]);
    } else if (h / w >= 1.2) {
      setHtmlPosition([0.8 * 0.6, 0.01, 0]);
    } else if (h / w >= 1) {
      setHtmlPosition([0.8 * 0.6, 0.18, 0]);
    } else if (h / w >= 0.7) {
      // 4:3(0.75)
      setHtmlPosition([0.8 * 0.6, 0.32, 0]);
    } else if (h / w >= 0.6) {
      // 3:2(0.66)
      setHtmlPosition([0.8 * 0.6, 0.4, 0]);
    } else if (h / w >= 0.5) {
      // 16:9(0.56)
      setHtmlPosition([0.8 * 0.6, 0.46, 0]);
    }
  }, []);
  const [clickDisabled, setClickDisabled] = useState(false);

  return (
    <group
      onPointerEnter={() => hover(true)}
      onPointerOut={() => hover(false)}
      position={[2 * index * 1.3, 0.85, 3]}
      onClick={(e) => {
        if (clickDisabled) return; // Exit if click is disabled

        setClickDisabled(true); // Disable click
        setTimeout(() => setClickDisabled(false), 3000);
        e.stopPropagation();

        // setSelectedItem(item);
        if (zoomItemId == item.id) {
          setZoomItemId(null);
          setZoomItemImageUrl(null);
          setCurrentItem(null);
        } else {
          setZoomItemId(item.id);
          setCurrentItem(item);
          setZoomItemImageUrl(item.itemUrl);
          sendEvent('enter_fullscreen');
          // setTimeout(() => {
          // }, 3000);
        }
      }}
    >
      <mesh
        name={name}
        scale={[1, 1, 0.05]}
        position={[0, GOLDENRATIO / 2.5, 0]}
      >
        <PictureFrameGroup
          item={item}
          frame={roomSettings?.frame}
          GOLDENRATIO={GOLDENRATIO}
          aspectRatio={aspectRatio}
          frameColor={roomSettings?.frameColor}
          matColor={roomSettings?.matColor}
        />

        {/* need one geometry to be able to Clicked */}
        <mesh
          scale={[
            aspectRatio < 1 ? scaleAdjustment : 1.2,
            aspectRatio < 1 ? scaleAdjustment / aspectRatio : 1.2 / aspectRatio,
            1,
          ]}
        >
          <boxGeometry />
        </mesh>
        <mesh>
          <Image
            raycast={() => null}
            ref={image}
            position={[0, 0, 0.7]}
            url={item?.itemUrl}
            scale={[
              aspectRatio < 1 ? scaleAdjustment : 1.2,
              aspectRatio < 1
                ? scaleAdjustment / aspectRatio
                : 1.2 / aspectRatio,
              1,
            ]}
            toneMapped={false}
          />
        </mesh>
      </mesh>
      {!zoomItemId && (
        <Html
          wrapperClass='!fixed !z-0 w-[100px]'
          position={[0.9, (GOLDENRATIO * 0.2) / (h / w), 0]}
        >
          <div
            className={`bg-gallery-ui-2 hidden w-40 cursor-pointer flex-col rounded border-[1.5px] border-[#868686] p-2 backdrop-blur-[32px] md:flex ${
              roomSettings.theme === 'LIGHT'
                ? 'border-gray-300'
                : 'border-gray-400'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              sendEvent('open_modal');
            }}
          >
            <p
              className={`left-4 line-clamp-3 text-left font-inter text-[0.5rem] font-bold ${
                roomSettings.theme === 'LIGHT' ? 'text-dark' : 'text-white'
              }`}
            >
              {item?.title}
            </p>
          </div>
        </Html>
      )}

      {!zoomItemId && (
        <Html
          wrapperClass='!fixed !z-0 hidden md:block'
          position={[1.15, (GOLDENRATIO * 0.2) / (h / w) - 0.3, 0]}
        >
          <button
            className={`absolute -left-14 -top-3 rounded-full border p-2 before:absolute before:left-0 before:top-0 before:z-[-1] before:h-full before:w-full before:overflow-hidden before:rounded-full before:backdrop-blur-3xl before:content-[""] hover:border-gray-50
          ${
            roomSettings.theme === 'LIGHT'
              ? 'before:bg-gallery-ui-light border-gray-300'
              : 'before:bg-gallery-ui border-gray-400'
          }`}
            onClick={(e) => {
              e.stopPropagation();
              // setFullScreenItemUrl(item?.itemUrl);
              setSelectedItem(item);
              sendEvent('open_modal');
            }}
          >
            <InformationCircleIcon
              className={`h-5 w-5 ${
                roomSettings.theme === 'LIGHT' ? 'text-gray-700' : 'text-white'
              }`}
            />
          </button>
          {item.creator?.inquiryEmail && (
            <button
              className={`absolute -left-3 -top-3 rounded-full border p-2 before:absolute before:left-0 before:top-0 before:z-[-1] before:h-full before:w-full before:overflow-hidden before:rounded-full before:backdrop-blur-3xl before:content-[""] hover:border-gray-50
          ${
            roomSettings.theme === 'LIGHT'
              ? 'before:bg-gallery-ui-light border-gray-300'
              : 'before:bg-gallery-ui border-gray-400'
          }`}
              onClick={(e) => {
                e.stopPropagation();
                setInquiryItem(item);
                document.getElementById('inquiry_item_modal').showModal();
              }}
            >
              <EnvelopeIcon
                className={`h-5 w-5 ${
                  roomSettings.theme === 'LIGHT'
                    ? 'text-gray-700'
                    : 'text-white'
                }`}
              />
            </button>
          )}
        </Html>
      )}
    </group>
  );
};

export default Item;
