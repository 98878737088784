import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';

export const ExhibitionViewsByLocation = ({
  number,
  GA4ExhibitionData,
  isLoading,
  isVIP,
}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [rankedData, setRankedData] = useState([]);
  const [filteredRankedData, setFilteredRankedData] = useState([]);

  useEffect(() => {
    if (GA4ExhibitionData) {
      // 国選択を設定
      const countries = GA4ExhibitionData.map((item) => item.country);
      setCountryOptions(countries);
      // 表示データを設定
      const flatData = [];
      GA4ExhibitionData.forEach((item) => {
        item.exhibitions.forEach((exhibition) => {
          exhibition.cities.forEach((city) => {
            // 必要に応じてフィルタリング
            if (city.totalViews > 0 || city.totalUsers > 0) {
              flatData.push({
                country: item.country,
                exhibitionTitle: exhibition.title,
                cityName: city.city,
                regionName: exhibition.region,
                totalViews: city.totalViews,
                totalUsers: city.totalUsers,
              });
            }
          });
        });
      });
      // ビュー数でソート（降順）
      const sortedData = flatData.sort((a, b) => b.totalViews - a.totalViews);
      // ランクを割り当て
      const ranked = sortedData.map((item, index) => ({
        rank: index + 1,
        ...item,
      }));
      setRankedData(ranked);
      setFilteredRankedData(ranked);
    }
  }, [GA4ExhibitionData]);

  useEffect(() => {
    const newData =
      selectedCountry === ''
        ? rankedData
        : rankedData.filter((item) => item.country === selectedCountry);
    setFilteredRankedData(newData);
  }, [selectedCountry, rankedData]);

  return (
    <div>
      <div className='flex items-center gap-2'>
        <span className='text-xl font-bold'>{number}.</span>
        <select
          className='select select-bordered w-full max-w-xs'
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          <option value=''>
            <Trans i18nKey={'analytics.country'} />
          </option>
          {countryOptions.map((item) => {
            return (
              <option value={item} key={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
      <div className='mt-6 overflow-x-auto'>
        <table className='table'>
          {/* head */}
          <thead>
            <tr>
              <th>
                <Trans i18nKey={'analytics.rank'} />
              </th>
              <th className='min-w-[180px]'>
                <Trans i18nKey={'analytics.exhibition'} />
              </th>
              <th>
                <Trans i18nKey={'analytics.city-region'} />
              </th>
              <th>
                <Trans i18nKey={'analytics.views'} />
              </th>
              {isVIP && (
                <th>
                  <Trans i18nKey={'analytics.uu'} />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              filteredRankedData.length > 0 ? (
                filteredRankedData.map((row, index) => {
                  return (
                    <tr
                      key={`ExhibitionViewsByLocation-${row.exhibitionTitle}-${row.cityName}_${index}`}
                    >
                      <td className='font-bold'>{row.rank}</td>
                      <td className='font-bold'>{row.exhibitionTitle}</td>
                      <td>{`${row.cityName}, ${row.regionName}`}</td>
                      <td>{row.totalViews.toLocaleString()}</td>
                      {isVIP && <td>{row.totalUsers.toLocaleString()}</td>}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className='text-center'>
                    <Trans i18nKey={'analytics.no-data'} />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td className='loading loading-spinner loading-xs text-primary'></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
