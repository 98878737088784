import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { WALLS } from '../../../data/walls';
import { invalidate } from '@react-three/fiber';

const CustomWall = ({ id }) => {
  const [texturePaths, setTexturePaths] = useState({});
  const [wall, setWall] = useState(null);
  useEffect(() => {
    const selectedWall = WALLS.find((wall) => wall.id === id);
    setWall(selectedWall);

    if (selectedWall) {
      const newTexturePaths = {};

      if (selectedWall.images.map)
        newTexturePaths.map = selectedWall.images.map;
      if (selectedWall.images.normalMap)
        newTexturePaths.normalMap = selectedWall.images.normalMap;
      if (selectedWall.images.roughnessMap)
        newTexturePaths.roughnessMap = selectedWall.images.roughnessMap;
      if (selectedWall.images.aoMap)
        newTexturePaths.aoMap = selectedWall.images.aoMap;
      if (selectedWall.images.displacementMap)
        newTexturePaths.displacementMap = selectedWall.images.displacementMap;
      if (selectedWall.images.metalnessMap)
        newTexturePaths.metalnessMap = selectedWall.images.metalnessMap;
      if (selectedWall.images.alphaMap)
        newTexturePaths.alphaMap = selectedWall.images.alphaMap;

      setTexturePaths(newTexturePaths);
    }
  }, [id]);

  const textureProps = useTexture(texturePaths);

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(120, 120);
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current, wall]);

  useEffect(() => {
    invalidate(); // idが変わるたびに再レンダリングを強制する
  }, [id]);

  if (!wall) {
    return null; // wallがまだロードされていないときは何も表示しない
  }

  return (
    <mesh
      key={`wall-${wall.title}`}
      receiveShadow
      ref={mesh}
      position={[0, 0, -0.5]}
    >
      <planeGeometry args={[200, 200]} />
      <meshStandardMaterial
        displacementScale={wall?.settings.displacementScale}
        aoMapIntensity={wall?.settings.aoMapIntensity}
        roughness={wall?.settings.roughness}
        normalScale={
          wall?.settings.normalScale
            ? wall?.settings.normalScale
            : new THREE.Vector2(1, 1)
        }
        metalness={wall?.settings?.metalness ? wall?.settings.metalness : 0.0}
        {...textureProps}
      />
    </mesh>
  );
};

export default CustomWall;
