import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import SkeletonItemCard from '../../components/item/SkeletonItemCard';
import { Trans } from 'react-i18next';
import { publicRequest } from '../../requestMethods';
import { useSelector } from 'react-redux';
import ExhibitionCard from '../../components/room/ExhibitionCard';

const Exhibitions = () => {
  const user = useSelector((state) => state.user.currentUser);

  const [exhibitions, setExhibitions] = useState(null);
  const [loading, setLoading] = useState(true);

  const getExhibitions = async () => {
    try {
      const publishedExhibitions = await publicRequest.get('/rooms/published');
      setExhibitions(publishedExhibitions.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getExhibitions();
  }, []);

  return (
    <>
      <div className='mx-auto max-w-[56.75rem] px-4 pt-[6.5rem]'>
        <Link to={'/'} className='btn btn-ghost mb-6'>
          <ArrowLeftIcon className='h-6 w-6' />
          <Trans i18nKey={'exhibitions.home'} />
        </Link>
        <p className='mb-8 text-3xl font-[900]'>
          <Trans i18nKey={'exhibitions.ttl'} />
        </p>
        <p className='mb-8 text-base font-normal'>
          <Trans i18nKey={'exhibitions.desc'} />
        </p>
        <div className='mb-20 mt-8 grid gap-6 sm:grid-cols-2 sm:!gap-5 lg:grid-cols-3'>
          {loading ? (
            <>
              {Array(6)
                .fill()
                .map((_, index) => (
                  <SkeletonItemCard key={index} />
                ))}
            </>
          ) : (
            <>
              {exhibitions.map((item, index) => {
                return <ExhibitionCard item={item} key={item.id} />;
              })}
            </>
          )}
        </div>
        {!user && (
          <div className='mx-auto flex w-full justify-center'>
            <Link to={'/signup'} className='btn btn-primary btn-wide'>
              Sign up
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Exhibitions;
