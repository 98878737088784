import React, { useEffect, useMemo, useState } from 'react';
import { authRequest, publicRequest } from '../../requestMethods';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import BasicInfo from './BasicInfo';
import TransitionAnimationSettings from './TransitionAnimationSettings';
import DecorationSettings from './DecorationSettings';
import { errorToast, successToast } from '../../utils/toast';
import InactiveRoomModal from '../../components/room/InactiveRoomModal';
import LoadingSpinner from '../../components/LoadingSpinner';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import { PlusIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import {
  validateGalleryName,
  validateGalleryDescription,
  validateRoomId,
  validateExhibitionPassword,
} from '../../utils/validation';
import AvailableItemsModal from '../../components/AvailableItemsModal';
import Preview from '../../components/r3f/Preview';
import { ReactSortable } from 'react-sortablejs';
import RoomPreviewCard from '../../components/room/RoomPreviewCard';
import { Trans, useTranslation } from 'react-i18next';
import { formatMMMDYYYY } from '../../utils/DateUtils';
import { usePreventLeave } from '../../hooks/usePreventLeave';
import { DiscardExhibitionModal } from '../../components/item/DiscardExhibitionModal';

const EditRoom = () => {
  const [hasVipSupport, setHasVipSupport] = useState(false);
  const {
    REACT_APP_STRIPE_CAS1_PRODUCT_ID: CAS1_ID,
    REACT_APP_STRIPE_CAS5_PRODUCT_ID: CAS5_ID,
    REACT_APP_STRIPE_CAS_VIP_PRODUCT_ID: VIP_SUPPORT_ID,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_CAS1: MAX_PUBLISHED_EXHIBITION_CAS1,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_CAS5: MAX_PUBLISHED_EXHIBITION_CAS5,
  } = process.env;

  // Demo版 必須
  const [plan, setPlan] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [room, setRoom] = useState(null);
  const [published, setPublished] = useState(true);
  const [frame, setFrame] = useState(null);
  const [theme, setTheme] = useState('DARK');
  const [lightColor, setLightColor] = useState('WHITE');
  const [silhouette, setSilhouette] = useState(true);
  const [light, setLight] = useState(null);
  const [music, setMusic] = useState('');
  const [snapshot, setSnapshot] = useState('');
  const [animationType, setAnimationType] = useState(1);
  const [newRoomId, setNewRoomId] = useState(null);
  const [checkedSilhouettes, setCheckedSilhouettes] = useState([]);
  const [colorCode, setColorCode] = useState('#ffffff');
  const [ceiling, setCeiling] = useState(null);
  const [wall, setWall] = useState(null);
  const [floor, setFloor] = useState(null);
  const [objectLight, setObjectLight] = useState(null);
  const [randomMusic, setRandomMusic] = useState(false);
  const [directionalLightIntensity, setDirectionalLightIntensity] = useState(1);
  const [directionalLightColorTheme, setDirectionalLightColorTheme] =
    useState('WHITE');
  const [directionalLightColorCode, setDirectionalLightColorCode] =
    useState('#ffffff');
  const [spotLightIntensity, setSpotLightIntensity] = useState(1);
  const [spotLightColorTheme, setSpotLightColorTheme] = useState('WHITE');
  const [spotLightColorCode, setSpotLightColorCode] = useState('#ffffff');
  const [hasReachedMaxPublish, setHasReachedMaxPublish] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [presetName, setPresetName] = useState('');
  const [showOnHome, setShowOnHome] = useState(false);
  const [isAgreedTerms, setIsAgreedTerms] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [frameColor, setFrameColor] = useState('#525252');
  const [matColor, setMatColor] = useState('#525252');

  // エラー
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [newRoomIdError, setNewRoomIdError] = useState(null);
  const [roomIdError, setGalleryIdError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [addedItems, setAddedItems] = useState([]);
  const [availableItems, setAvailableItems] = useState([]);
  const { isLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  usePreventLeave();

  const location = useLocation();
  const roomId = location.pathname.split('/')[3];
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.currentUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const { i18n, t } = useTranslation();

  const checkIsReachedLimit = async () => {
    //debug用
    // 初期値を設定するための変数
    let foundPlan = '';
    let vipSupportFound = false;

    if (user?.userPlans?.length > 0) {
      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === CAS1_ID) {
          foundPlan = 'CAS-1';
        } else if (el.SubscriptionPlans.productId === CAS5_ID) {
          foundPlan = 'CAS-5';
        }

        // "CAS VIP Support" があるか確認
        if (el.SubscriptionPlans.productId === VIP_SUPPORT_ID) {
          vipSupportFound = true;
        }
      });
      setHasVipSupport(vipSupportFound);
    } else {
      setHasVipSupport(false);
    }

    const galleryData = await publicRequest.get(`/rooms/users/${user.id}`);
    const draftRoomData = await authRequest.get(`/rooms/drafts/${user?.id}`);

    //publishの上限に達しているか確認
    if (
      ((foundPlan == 'CAS-1' || !foundPlan) &&
        galleryData.data.length >= Number(MAX_PUBLISHED_EXHIBITION_CAS1)) ||
      (foundPlan == 'CAS-5' &&
        galleryData.data.length >= Number(MAX_PUBLISHED_EXHIBITION_CAS5))
    ) {
      setHasReachedMaxPublish(true);
    }
    setPlan(foundPlan ? foundPlan : 'Free Trial');
  };

  useEffect(() => {
    //get room data by id
    const getRoomById = async () => {
      const roomData = await authRequest.get(
        `/rooms/roomId/${roomId}/password`
      );
      if (
        roomData.data.isActive &&
        !roomData.data.disabled &&
        roomData.data.userId == user.id
      ) {
        setRoom(roomData.data);
        setPublished(roomData.data.published);
        setTitle(roomData.data.title);
        setDescription(roomData.data.description);
        setAnimationType(roomData.data?.animationType);
        setFrame(roomData.data.roomSettings?.frame);
        setTheme(roomData.data.roomSettings?.theme);
        setSilhouette(roomData.data.roomSettings?.silhouette);
        setLight(roomData.data.roomSettings?.light);
        setLightColor(roomData.data.roomSettings?.lightColor);
        setMusic(
          roomData.data.roomSettings?.music
            ? roomData.data.roomSettings?.music
            : ''
        );
        setRandomMusic(roomData.data.roomSettings?.music === 100);
        setNewRoomId(roomData.data.roomId);
        setColorCode(
          roomData.data.roomSettings?.colorCode
            ? roomData.data.roomSettings?.colorCode
            : '#ffffff'
        );
        setCeiling(roomData.data.roomSettings?.ceiling);
        setWall(roomData.data.roomSettings?.wall);
        setFloor(roomData.data.roomSettings?.floor);
        setObjectLight(roomData.data.roomSettings?.objectLight);

        setSpotLightColorCode(
          roomData.data.roomSettings?.spotLightColorCode
            ? roomData.data.roomSettings?.spotLightColorCode
            : '#ffffff'
        );
        setSpotLightColorTheme(
          roomData.data.roomSettings?.spotLightColorTheme
            ? roomData.data.roomSettings?.spotLightColorTheme
            : 'WHITE'
        );
        setSpotLightIntensity(
          roomData.data.roomSettings?.spotLightIntensity != null
            ? roomData.data.roomSettings?.spotLightIntensity
            : 1
        );
        setDirectionalLightColorCode(
          roomData.data.roomSettings?.directionalLightColorCode
            ? roomData.data.roomSettings?.directionalLightColorCode
            : '#ffffff'
        );
        setDirectionalLightColorTheme(
          roomData.data.roomSettings?.directionalLightColorTheme
            ? roomData.data.roomSettings?.directionalLightColorTheme
            : 'WHITE'
        );
        setDirectionalLightIntensity(
          roomData.data.roomSettings?.directionalLightIntensity != null
            ? roomData.data.roomSettings?.directionalLightIntensity
            : 1
        );
        setShowOnHome(roomData.data.showOnHome);
        setIsPrivate(roomData.data.isPrivate);
        setPassword(roomData.data.password);
        if (roomData.data.roomSettings?.frameColor) {
          setFrameColor(roomData.data.roomSettings?.frameColor);
        }
        if (roomData.data.roomSettings?.matColor) {
          setMatColor(roomData.data.roomSettings?.matColor);
        }
        //sort by displayOrder
        setAddedItems(
          roomData.data.items.sort((a, b) => a.displayOrder - b.displayOrder)
        );
        //get owned items
        const itemData = await publicRequest.get(`/items/owner/${user?.id}`);

        //filter with addedItems
        roomData.data.items.forEach((item) => {
          itemData.data = itemData.data.filter((eachItem) => {
            return eachItem.id !== item.id;
          });
        });

        // 重複を除いたアイテムデータを作成
        const uniqueItems = [...itemData.data].reduce((result, item) => {
          // アイテムのIDが既に存在する追加しない
          if (!result.find((existingItem) => existingItem.id === item.id)) {
            result.push(item);
          }
          return result;
        }, []);

        //set availableItems
        setAvailableItems(uniqueItems);

        //silhouettesデータを同期
        const indices = roomData.data.roomSettings.silhouettes?.map(
          (silhouette) => silhouette.index
        );
        setCheckedSilhouettes(indices);
      } else {
        navigate('/not-found');
      }
    };

    checkIsReachedLimit();
    getRoomById();
  }, []);

  const patchRoomData = async () => {
    dispatch(loadStart());
    try {
      const s3PreviewImgRes = await publicRequest.post(
        `/s3/base64`,
        {
          file: snapshot,
          key: `${user.id}/previewImg/`,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const addedItemIds = [];
      addedItems.forEach((addedItem) => {
        addedItemIds.push(addedItem.id);
      });

      await authRequest.patch(`/rooms/edit-items/${room.id}`, {
        items: addedItemIds,
      });

      const roomUpdateData = {
        title,
        description,
        previewImg: s3PreviewImgRes.data.replace(
          'curatedartshow-data.s3.amazonaws.com',
          'data.curatedartshow.com'
        ),
        animationType,
        published: true,
        roomId: newRoomId,
        isPrivate,
        // newPassword がある場合は newPassword を、hasPassword が false の場合は既存の password をセット
        ...(newPassword
          ? { password: newPassword }
          : !room.password && { password }),
      };

      // リクエスト送信
      await authRequest.patch(`/rooms/${room.id}`, roomUpdateData);

      await authRequest.patch(`/rooms/settings/${room.id}`, {
        frame,
        theme,
        silhouette,
        music: randomMusic ? 100 : music ? music : null,
        light,
        lightColor,
        ceiling,
        wall,
        floor,
        objectLight,
        directionalLightIntensity,
        directionalLightColorTheme,
        directionalLightColorCode,
        spotLightIntensity,
        spotLightColorTheme,
        spotLightColorCode,
        frameColor,
        matColor,
      });

      dispatch(loadEnd());
      navigate('/dashboard?tab=galleries');
      successToast(t('message.success.t12'));
    } catch (err) {
      dispatch(loadEnd());
      console.error(err);
      const data = err.response.data;
      if (data.statusCode === 400) {
        errorToast(data.message);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const result = validateRoomId(newRoomId);
    if (!result.isValid) {
      setNewRoomIdError(result.message);
      errorToast(result.message);
      navigate(`/gallery/edit/${roomId}?tab=basic-info`);
      return null;
    } else {
      setNewRoomIdError('');
    }

    if (published) {
      if (!title) {
        setTitleError('Exhibition title is required.');
        errorToast(t('message.error.t6'));
      } else {
        setTitleError('');
      }
      if (!description) {
        setDescriptionError('Exhibition description is required.');
        errorToast(t('message.error.t7'));
      } else {
        setDescriptionError('');
      }
      if (!title || !description || !result.isValid) {
        navigate(`/gallery/edit/${roomId}?tab=basic-info`);
        return null;
      }
    }
    dispatch(loadStart());
    try {
      const s3PreviewImgRes = await publicRequest.post(
        `/s3/base64`,
        {
          file: snapshot,
          key: `${user.id}/previewImg/`,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const addedItemIds = [];
      addedItems.forEach((addedItem) => {
        addedItemIds.push(addedItem.id);
      });

      await authRequest.patch(`/rooms/edit-items/${room.id}`, {
        items: addedItemIds,
      });

      const roomUpdateData = {
        title,
        description,
        previewImg: s3PreviewImgRes.data.replace(
          'curatedartshow-data.s3.amazonaws.com',
          'data.curatedartshow.com'
        ),
        animationType: Number(animationType),
        roomId: newRoomId,
        isPrivate,
        // newPassword がある場合は newPassword を、hasPassword が false の場合は既存の password をセット
        ...(newPassword
          ? { password: newPassword }
          : !room.password && { password }),
        showOnHome,
      };

      // リクエスト送信
      await authRequest.patch(`/rooms/${room.id}`, roomUpdateData);

      await authRequest.patch(`/rooms/settings/${room.id}`, {
        frame,
        theme,
        silhouette,
        music: randomMusic ? 100 : music ? music : null,
        light,
        lightColor,
        silhouettes: checkedSilhouettes,
        colorCode,
        ceiling,
        wall,
        floor,
        objectLight,
        directionalLightIntensity,
        directionalLightColorTheme,
        directionalLightColorCode,
        spotLightIntensity,
        spotLightColorTheme,
        spotLightColorCode,
        frameColor,
        matColor,
      });

      dispatch(loadEnd());
      navigate('/dashboard?tab=galleries');
      successToast(t('message.success.t12'));
    } catch (err) {
      dispatch(loadEnd());
      console.error(err);
      const data = err.response.data;
      if (data.statusCode === 400) {
        errorToast(data.message);
      }
    }
  };

  const unpublish = async () => {
    try {
      await authRequest.post(`/rooms/unpublish/${room?.id}`);
      setPublished(!published);
      successToast(t('message.success.t16'));
      navigate('/dashboard?tab=drafts');
    } catch (err) {
      console.log(err);
    }
  };

  const handlePublish = async () => {
    // required check before publish
    const result = validateRoomId(roomId);
    if (!result.isValid) {
      setNewRoomIdError(`${result.message} Please update it before publish.`);
      errorToast(`${result.message} ` + t('message.error.t9'));
      navigate(`/gallery/edit/${roomId}?tab=basic-info`);
      return null;
    } else {
      setNewRoomIdError('');
    }
    if (!title) {
      setTitleError(
        'Exhibition title is required. Please update it before publish'
      );
      errorToast(t('message.error.t6') + ' ' + t('message.error.t9'));
    } else {
      setTitleError('');
    }
    if (!description) {
      setDescriptionError(
        'Exhibition description is required. Please update it before publish'
      );
      errorToast(t('message.error.t7') + ' ' + t('message.error.t9'));
    } else {
      setDescriptionError('');
    }
    if (!title || !description) {
      navigate(`/gallery/edit/${roomId}?tab=basic-info`);
      return null;
    }
    try {
      await authRequest.post(`/rooms/publish/${room?.id}`);
      setPublished(!published);
      successToast(t('message.success.t15'));
      navigate('/dashboard?tab=galleries');
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreatePreset = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    try {
      const s3PreviewImgRes = await publicRequest.post(
        `/s3/base64`,
        {
          file: snapshot,
          key: `${user.id}/previewImg/`,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      await authRequest.post(`/presets`, {
        userId: user?.id,
        frame,
        theme,
        silhouette,
        music: randomMusic ? 100 : music ? music : null,
        light,
        lightColor,
        silhouettes: checkedSilhouettes,
        colorCode,
        ceiling,
        wall,
        floor,
        objectLight,
        directionalLightIntensity,
        directionalLightColorTheme,
        directionalLightColorCode,
        spotLightIntensity,
        spotLightColorTheme,
        spotLightColorCode,
        name: presetName,
        imageUrl: s3PreviewImgRes.data.replace(
          'curatedartshow-data.s3.amazonaws.com',
          'data.curatedartshow.com'
        ),
        status: 'PRIVATE',
        frameColor,
        matColor,
      });
      dispatch(loadEnd());
      successToast(t('message.success.t18'));
      setPresetName('');
      document.getElementById('save_preset_modal').close();
    } catch (err) {
      dispatch(loadEnd());
      console.error(err);
      const data = err.response.data;
      if (data.statusCode === 400) {
        errorToast(data.message);
      }
    }
  };

  const resetSettings = () => {
    setFrame('');
    setTheme('CYBERPUNK');
    setSilhouette(false);
    setLight(null);
    setLightColor('WHITE');
    setMusic('');
    setSnapshot('');
    setCheckedSilhouettes([]);
    setColorCode('#ffffff');
    setCeiling(null);
    setWall(null);
    setFloor(null);
    setObjectLight(null);
    setRandomMusic(false);
    setDirectionalLightIntensity(1);
    setDirectionalLightColorTheme('WHITE');
    setDirectionalLightColorCode('#ffffff');
    setSpotLightIntensity(1);
    setSpotLightColorTheme('WHITE');
    setSpotLightColorCode('#ffffff');
  };

  const loadPreset = (preset) => {
    setFrame(preset.frame);
    setTheme(preset.theme);
    setSilhouette(preset.silhouette);
    setLight(preset.light);
    setLightColor(preset.lightColor);
    setMusic(preset.music);

    setCheckedSilhouettes([]);
    setColorCode(preset.colorCode);
    setCeiling(preset.ceiling);
    setWall(preset.wall);
    setFloor(preset.floor);
    setObjectLight(preset.objectLight);
    setRandomMusic(false);
    setDirectionalLightIntensity(preset.directionalLightIntensity);
    setDirectionalLightColorTheme(preset.directionalLightColorTheme);
    setDirectionalLightColorCode(preset.directionalLightColorCode);
    setSpotLightIntensity(preset.spotLightIntensity);
    setSpotLightColorTheme(preset.spotLightColorTheme);
    setSpotLightColorCode(preset.spotLightColorCode);

    //silhouettesデータを同期
    const indices = preset.PresetSilhouettes?.map(
      (silhouette) => silhouette.index
    );
    setCheckedSilhouettes(indices);
  };

  const reflectGalleryName = (name) => {
    const result = validateGalleryName(name);
    result.isValid ? setTitleError('') : setTitleError(result.message);
    setTitle(name);
  };

  const reflectGalleryDescription = (description) => {
    const result = validateGalleryDescription(description);
    result.isValid
      ? setDescriptionError('')
      : setDescriptionError(result.message);
    setDescription(description);
  };

  const reflectRoomId = (id) => {
    const result = validateRoomId(id);
    result.isValid ? setGalleryIdError('') : setGalleryIdError(result.message);
    setNewRoomId(id);
  };

  const reflectPassword = (text) => {
    const result = validateExhibitionPassword(text);
    result.isValid ? setPasswordError('') : setPasswordError(result.message);
    setPassword(text);
  };

  const reflectNewPassword = (text) => {
    const result = validateExhibitionPassword(text);
    result.isValid
      ? setNewPasswordError('')
      : setNewPasswordError(result.message);
    setNewPassword(text);
  };

  const hasError = useMemo(() => {
    // requiredを満たしているか
    const isFilled =
      !!title &&
      !!description &&
      !!newRoomId &&
      ((isPrivate && !!password) || !isPrivate);
    const hasErrorMessage =
      !!titleError ||
      !!descriptionError ||
      !!roomIdError ||
      (isPrivate && !!passwordError);
    return hasErrorMessage || !isFilled ? true : false;
  }, [title, description, newRoomId, password, isPrivate]);

  const removeItem = (item) => {
    // 選択した作品を削除
    const newAddedItems = addedItems.filter(
      (addedItem) => addedItem.id !== item.id
    );
    setAddedItems(newAddedItems);
    setAvailableItems([...availableItems, item]);
  };

  return (
    <div className='flex h-full flex-col'>
      <div className='flex flex-auto flex-col-reverse lg:flex-row'>
        {/* 左カラム */}
        <div className='flex w-full flex-grow flex-col px-5 pt-5 lg:max-w-[517px]'>
          <div
            role='tablist'
            className='no-scrollbar tabs tabs-bordered overflow-x-scroll whitespace-nowrap'
          >
            <Link
              role='tab'
              className={`tab border-b-2 border-solid border-gray-200 text-[#1F2A37] ${
                (!tab || tab === 'basic-info') && 'tab-active'
              }`}
              to={`?tab=basic-info`}
            >
              <Trans i18nKey={'gallery.tab-basic'} />
            </Link>
            <Link
              className={`tab border-b-2 border-solid border-gray-200 text-[#1F2A37] ${
                tab === 'artworks' && 'tab-active'
              }`}
              to={`?tab=artworks`}
            >
              <Trans i18nKey={'gallery.tab-artworks'} />
            </Link>
            <Link
              className={`tab border-b-2 border-solid border-gray-200 text-[#1F2A37] ${
                tab === 'decoration' && 'tab-active'
              }`}
              to={`?tab=decoration`}
            >
              <Trans i18nKey={'gallery.tab-decoration'} />
            </Link>
            <Link
              className={`tab border-b-2 border-solid border-gray-200 text-[#1F2A37] ${
                tab === 'animation' && 'tab-active'
              }`}
              to={`?tab=animation`}
            >
              <Trans i18nKey={'gallery.tab-animation'} />
            </Link>
          </div>
          <div className='mt-5 flex-auto'>
            {(!tab || tab === 'basic-info') && (
              <BasicInfo
                title={title}
                setTitle={reflectGalleryName}
                titleError={titleError}
                description={description}
                setDescription={reflectGalleryDescription}
                descriptionError={descriptionError}
                roomId={newRoomId}
                setRoomId={reflectRoomId}
                roomIdError={roomIdError}
                isPrivate={isPrivate}
                setIsPrivate={setIsPrivate}
                hasVipSupport={hasVipSupport}
                password={password}
                setPassword={reflectPassword}
                passwordError={passwordError}
                editMode={true}
                hasPassword={room?.password ? true : false}
                newPassword={newPassword}
                setNewPassword={reflectNewPassword}
                newPasswordError={newPasswordError}
                setNewPasswordError={setNewPasswordError}
              />
            )}
            {(!tab || tab === 'basic-info') &&
              hasReachedMaxPublish &&
              !published && (
                <div role='alert' className='alert mt-6'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    className='h-6 w-6 shrink-0 stroke-info'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                    ></path>
                  </svg>
                  {i18n.language == 'ja' ? (
                    <span>
                      {plan == 'CAS-5'
                        ? `現在のプラン（CAS-5）で同時に公開できる展示会の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にすることを検討してください。CAS-5では5つの展示会の同時公開をサポートしていますが、さらに多くの展示会を公開したい場合は、お問い合わせフォームよりお問い合わせください。`
                        : `現在のプラン（${
                            plan == 'Free Trial' ? 'フリートライアル' : plan
                          }）での同時公開展示の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にするか、５つの展示会を同時に公開できるCAS-5にアップグレードしましょう。`}
                    </span>
                  ) : (
                    <span>
                      {plan == 'CAS-5'
                        ? `You have reached the limit for the number of concurrent published exhibitions under your plan: CAS-5.You may wish to unpublish this exhibition and publish one of your unpublished exhibitions. CAS-5 supports five concurrent published exhibitions, but if you need even more, please contact us.`
                        : `You have reached the limit for the number of concurrent published exhibitions under your plan: ${plan}.
You may wish to unpublish this exhibition and publish one of your unpublished exhibitions, or upgrade to CAS-5, which supports five concurrent published exhibitions.`}
                    </span>
                  )}
                </div>
              )}
            {(!tab || tab === 'basic-info') && (
              <div className='my-4'>
                <p className='mb-5 mt-16 text-sm text-error'>
                  <Trans i18nKey={'gallery.basic-explanation'} />
                </p>
                <div className='form-control'>
                  <label className='label flex cursor-pointer items-start justify-start gap-3'>
                    <input
                      checked={showOnHome}
                      type='checkbox'
                      onChange={() => setShowOnHome(!showOnHome)}
                      className='checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20'
                    />
                    <p className='text-xs font-normal'>
                      <Trans
                        i18nKey={'exhibition-agreement'}
                        components={{
                          link1: (
                            <a
                              href='/'
                              target='_blank'
                              className='underline'
                            ></a>
                          ),
                        }}
                      />
                    </p>
                  </label>
                </div>
                <div className='form-control'>
                  <label className='label flex cursor-pointer items-start gap-3'>
                    <input
                      checked={isAgreedTerms}
                      type='checkbox'
                      onChange={() => setIsAgreedTerms(!isAgreedTerms)}
                      className='checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20'
                    />
                    <p className='text-xs font-normal'>
                      <Trans
                        i18nKey={'exhibition-term'}
                        components={{
                          link1: (
                            <a
                              href='/terms-privacy'
                              target='_blank'
                              className='underline'
                            ></a>
                          ),
                        }}
                      />
                    </p>
                  </label>
                </div>
              </div>
            )}

            {tab === 'artworks' && (
              <div className='flex h-full'>
                <p className='m-auto hidden w-full max-w-60 text-center text-sm md:block'>
                  <Trans i18nKey={'gallery.artworks-note'} />
                </p>
                <p className='m-auto block w-full max-w-60 text-center text-sm md:hidden'>
                  <Trans i18nKey={'gallery.artworks-note'} />
                </p>
              </div>
            )}
            {tab === 'decoration' && (
              <DecorationSettings
                saveRoomSettings={handleUpdate}
                frame={frame}
                setFrame={setFrame}
                theme={theme}
                setTheme={setTheme}
                silhouette={silhouette}
                setSilhouette={setSilhouette}
                music={music}
                setMusic={setMusic}
                randomMusic={randomMusic}
                setRandomMusic={setRandomMusic}
                light={light}
                setLight={setLight}
                snapshot={snapshot}
                setSnapshot={setSnapshot}
                addedItems={addedItems}
                lightColor={lightColor}
                setLightColor={setLightColor}
                checkedSilhouettes={checkedSilhouettes}
                setCheckedSilhouettes={setCheckedSilhouettes}
                colorCode={colorCode}
                setColorCode={setColorCode}
                ceiling={ceiling}
                setCeiling={setCeiling}
                wall={wall}
                setWall={setWall}
                floor={floor}
                setFloor={setFloor}
                objectLight={objectLight}
                setObjectLight={setObjectLight}
                directionalLightIntensity={directionalLightIntensity}
                setDirectionalLightIntensity={setDirectionalLightIntensity}
                directionalLightColorTheme={directionalLightColorTheme}
                setDirectionalLightColorTheme={setDirectionalLightColorTheme}
                directionalLightColorCode={directionalLightColorCode}
                setDirectionalLightColorCode={setDirectionalLightColorCode}
                spotLightIntensity={spotLightIntensity}
                setSpotLightIntensity={setSpotLightIntensity}
                spotLightColorTheme={spotLightColorTheme}
                setSpotLightColorTheme={setSpotLightColorTheme}
                spotLightColorCode={spotLightColorCode}
                setSpotLightColorCode={setSpotLightColorCode}
                presetName={presetName}
                setPresetName={setPresetName}
                handleCreatePreset={handleCreatePreset}
                resetSettings={resetSettings}
                loadPreset={loadPreset}
                frameColor={frameColor}
                setFrameColor={setFrameColor}
                matColor={matColor}
                setMatColor={setMatColor}
              />
            )}
            {tab === 'animation' && (
              <TransitionAnimationSettings
                animationType={animationType}
                setAnimationType={setAnimationType}
              />
            )}
          </div>

          <div
            className={`mb-4 mt-14 flex flex-col-reverse gap-3 md:flex-row md:justify-between md:gap-0`}
          >
            <button
              className='btn'
              onClick={() =>
                document.getElementById('discard_exhibition_modal').showModal()
              }
            >
              <Trans i18nKey={'btn.cancel'} />
            </button>
            <>
              {!tab || tab === 'basic-info' ? (
                <div className='flex flex-col-reverse  gap-3 md:flex-row md:gap-2'>
                  {published ? (
                    <button
                      onClick={unpublish}
                      className='md:btn-fit btn btn-accent'
                    >
                      <Trans i18nKey={'btn.unpublish'} />
                    </button>
                  ) : (
                    <button
                      onClick={handlePublish}
                      className='md:btn-fit btn btn-accent'
                      disabled={
                        hasError || hasReachedMaxPublish || !isAgreedTerms
                      }
                    >
                      <Trans i18nKey={'btn.publish'} />
                    </button>
                  )}
                  <button
                    className='btn btn-primary !capitalize'
                    onClick={handleUpdate}
                    disabled={hasError}
                  >
                    <Trans i18nKey={'btn.save'} />
                  </button>
                </div>
              ) : (
                <div className='flex flex-col-reverse gap-4 md:flex-row'>
                  <button
                    className='btn btn-primary !capitalize'
                    onClick={handleUpdate}
                    disabled={hasError}
                  >
                    <Trans i18nKey={'btn.save'} />
                  </button>
                  {/* <Link
                    to={proceedLink}
                    className='btn btn-primary !capitalize'
                  >
                    <Trans i18nKey={'btn.continue'} />
                    <ArrowRightIcon className='h-6 w-6' />
                  </Link> */}
                </div>
              )}
            </>
          </div>
          {(!tab || tab === 'basic-info') && (
            <>
              <div className='divider !my-2.5' />
              <label
                htmlFor='delete-modal'
                className='btn-fit btn btn-ghost mx-auto mb-6 w-fit !capitalize'
              >
                <TrashIcon className='h-6 w-6' />
                <Trans i18nKey={'btn.delete'} />
              </label>
            </>
          )}
        </div>

        {/* 右カラム */}
        <div className='w-full bg-base-300 px-4 py-10 lg:max-w-[calc(100%-440px)] lg:px-6 lg:py-12 lg:pb-16 xl:px-10'>
          {tab === 'artworks' && 0 < addedItems.length && (
            <>
              {i18n.language == 'ja' ? (
                <div className='mb-3 flex flex-col gap-1 text-sm lg:mb-5 lg:gap-5 lg:text-base'>
                  <p>
                    この展示会には
                    <span className='font-bold'>{addedItems.length}</span>
                    点の作品があります
                  </p>
                  <p>
                    作品をドラッグすると、展示会での並び順を変更することができます。
                  </p>
                </div>
              ) : (
                <div className='mb-3 flex flex-col gap-1 text-sm lg:mb-5 lg:gap-5 lg:text-base'>
                  <p>
                    <span className='font-bold'>{addedItems.length}</span>{' '}
                    artworks in this exhibition.
                  </p>
                  <p>Click and drag to change order.</p>
                </div>
              )}
            </>
          )}
          {(tab === 'basic-info' || !tab) && (
            <>
              <div className='mb-4 text-center text-xl font-bold text-gray-500 sm:text-left lg:mb-6 lg:text-2xl'>
                <Trans i18nKey={'gallery.preview'} />
              </div>
              <RoomPreviewCard img={snapshot} title={title} />
            </>
          )}

          {tab === 'artworks' && (
            <>
              {addedItems.length === 0 ? (
                <div className='rounded-lg bg-base-200 px-4 py-10'>
                  <div className='m-auto max-w-[640px] rounded-lg border border-dashed border-[#2B3440] bg-white p-10'>
                    <p className='text-center'>
                      <Trans i18nKey={'label.artworks-empty'} />
                    </p>
                    <div className='mt-6 flex items-center justify-center'>
                      {availableItems.length > 0 && (
                        <button
                          className='btn btn-primary'
                          onClick={() =>
                            document
                              .getElementById('available_item_modal')
                              .showModal()
                          }
                        >
                          <Trans i18nKey={'btn.add-artwork'} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex max-h-[650px] flex-col gap-4 rounded-lg bg-base-200 px-4 py-6'>
                  <ReactSortable
                    list={addedItems}
                    setList={setAddedItems}
                    handle='.sort-handle'
                    className='flex h-full flex-col gap-[0.625rem] overflow-scroll'
                  >
                    {addedItems.map((item) => (
                      <div
                        className='flex items-center justify-between gap-2 rounded-xl border border-gray-200 bg-white p-[1rem]'
                        key={item.id}
                      >
                        <div className='flex w-full items-center gap-1 overflow-scroll whitespace-nowrap lg:max-w-[calc(100%-110px)] lg:gap-4'>
                          <div className='sort-handle relative cursor-grab'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g id='Drag Icon'>
                                <g id='Vector'>
                                  <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M13.5 6C13.5 5.17157 14.1716 4.5 15 4.5C15.8284 4.5 16.5 5.17157 16.5 6C16.5 6.82843 15.8284 7.5 15 7.5C14.1716 7.5 13.5 6.82843 13.5 6ZM13.5 12C13.5 11.1716 14.1716 10.5 15 10.5C15.8284 10.5 16.5 11.1716 16.5 12C16.5 12.8284 15.8284 13.5 15 13.5C14.1716 13.5 13.5 12.8284 13.5 12ZM13.5 18C13.5 17.1716 14.1716 16.5 15 16.5C15.8284 16.5 16.5 17.1716 16.5 18C16.5 18.8284 15.8284 19.5 15 19.5C14.1716 19.5 13.5 18.8284 13.5 18Z'
                                    fill='#1F2A37'
                                  />
                                  <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M7 6C7 5.17157 7.67157 4.5 8.5 4.5C9.32843 4.5 10 5.17157 10 6C10 6.82843 9.32843 7.5 8.5 7.5C7.67157 7.5 7 6.82843 7 6ZM7 12C7 11.1716 7.67157 10.5 8.5 10.5C9.32843 10.5 10 11.1716 10 12C10 12.8284 9.32843 13.5 8.5 13.5C7.67157 13.5 7 12.8284 7 12ZM7 18C7 17.1716 7.67157 16.5 8.5 16.5C9.32843 16.5 10 17.1716 10 18C10 18.8284 9.32843 19.5 8.5 19.5C7.67157 19.5 7 18.8284 7 18Z'
                                    fill='#1F2A37'
                                  />
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className='h-16 w-16 flex-shrink-0 lg:h-20 lg:w-20'>
                            <Link to={`/items/edit/${item.id}`} target='_blank'>
                              <img
                                src={item.itemUrl}
                                alt={item.title}
                                className='mx-auto h-full max-h-[5rem] w-full max-w-[5rem] object-contain'
                              />
                            </Link>
                          </div>
                          {/* SP表示 */}
                          <div className='lg:hidden'>
                            <p className='text-xs font-bold'>{item.title}</p>
                            <p className='text-xs'>
                              {item?.creator?.displayName}
                            </p>
                            <p className='text-xs'>{item?.createdYear}</p>
                            <p className='flex gap-2 text-xs'>
                              <span className='font-semibold text-[#1F2A3799]/60'>
                                Added:
                              </span>
                              {formatMMMDYYYY(item.createdAt)}
                            </p>
                          </div>
                          {/* PC表示 */}
                          <p className='hidden text-sm font-bold lg:block'>
                            {item.title}
                          </p>
                          <p className='hidden text-sm lg:block'>
                            {item?.creator?.displayName}
                          </p>
                          <p className='hidden text-sm lg:block'>
                            {item?.createdYear}
                          </p>
                          <p className='hidden text-sm lg:block'>
                            {formatMMMDYYYY(item.createdAt)}
                          </p>
                        </div>

                        <button
                          type='button'
                          className='btn btn-circle btn-sm lg:w-auto lg:px-3'
                          onClick={() => removeItem(item)}
                        >
                          <TrashIcon className='h-5 w-5' />
                          <span className='hidden lg:block'>
                            <Trans i18nKey={'btn.remove'} />
                          </span>
                        </button>
                      </div>
                    ))}
                  </ReactSortable>
                  <button
                    className='btn btn-outline btn-neutral btn-sm self-center lg:btn-md lg:self-start'
                    onClick={() =>
                      document
                        .getElementById('available_item_modal')
                        .showModal()
                    }
                  >
                    <PlusIcon className='h-4 w-4' />
                    <Trans i18nKey={'btn.add-artwork'} />
                  </button>
                </div>
              )}
            </>
          )}

          {tab === 'animation' && (
            <div className='relative'>
              <div className='mb-4 text-center text-xl font-bold text-gray-500 lg:mb-6 lg:text-2xl'>
                <Trans i18nKey={'gallery.preview'} />
              </div>
              {animationType == 0 ? (
                <div className='flex aspect-video items-center justify-center bg-black text-center'>
                  <p className='font-bold text-white'>
                    Loading<span className='animate-dot-1'>.</span>
                    <span className='animate-dot-2'>.</span>
                    <span className='animate-dot-3'>.</span>
                  </p>
                </div>
              ) : (
                <video
                  src={`/assets/transition_animation/cyberpunk${animationType}_faster.mp4`}
                  className='object-cover'
                  autoPlay
                  loop
                  playsInline
                  controls={false}
                  preload='auto'
                  muted
                >
                  not support
                </video>
              )}
            </div>
          )}

          {tab === 'decoration' && (
            <div className='relative'>
              <div className='mb-4 text-center text-xl font-bold text-gray-500 lg:mb-6 lg:text-2xl'>
                <Trans i18nKey={'gallery.preview'} />
              </div>
              <Preview
                theme={theme}
                frame={frame}
                showSilhouette={silhouette}
                setSnapshot={setSnapshot}
                light={light}
                items={addedItems}
                lightColor={lightColor}
                ceiling={ceiling}
                wall={wall}
                floor={floor}
                objectLight={objectLight}
                colorCode={colorCode}
                directionalLightIntensity={directionalLightIntensity}
                directionalLightColorCode={directionalLightColorCode}
                spotLightIntensity={spotLightIntensity}
                spotLightColorCode={spotLightColorCode}
                frameColor={frameColor}
                matColor={matColor}
              />
            </div>
          )}

          <div className='absolute top-0 z-[-1] opacity-0'>
            <Preview
              theme={theme}
              frame={frame}
              showSilhouette={silhouette}
              setSnapshot={setSnapshot}
              light={light}
              items={addedItems}
              lightColor={lightColor}
              ceiling={ceiling}
              wall={wall}
              floor={floor}
              objectLight={objectLight}
              colorCode={colorCode}
              directionalLightIntensity={directionalLightIntensity}
              directionalLightColorCode={directionalLightColorCode}
              spotLightIntensity={spotLightIntensity}
              spotLightColorCode={spotLightColorCode}
              frameColor={frameColor}
              matColor={matColor}
            />
          </div>
        </div>
      </div>
      <AvailableItemsModal
        availableItems={availableItems}
        addedItems={addedItems}
        setAddedItems={setAddedItems}
        setAvailableItems={setAvailableItems}
      />
      <DiscardExhibitionModal
        title={
          <Trans i18nKey={'gallery-modal.discard-create-exhibition-ttl'} />
        }
        desc={<Trans i18nKey={'gallery-modal.discard-desc'} />}
      />
      {isLoading && <LoadingSpinner />}
      <InactiveRoomModal roomId={room?.id} />
    </div>
  );
};

export default EditRoom;
