import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useEffect, useState } from 'react';
import { authRequest } from '../../../requestMethods';
import { successToast } from '../../../utils/toast';
import { Trans, useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { validateEmail } from '../../../utils/validation';
import { loadEnd, loadStart } from '../../../redux/loadRedux';

const ChangeEmailModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const user = useSelector((state) => state.user.currentUser);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    setEmail(user?.email);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadStart());
    try {
      await authRequest.post('/auth/change-email', { newEmail: email });
      setSuccess(true);
      setSuccessMessage(t('message.success.t22'));
      setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  const resendEmail = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadStart());
    try {
      await authRequest.post('/auth/change-email', { newEmail: email });
      setSuccessMessage(t('message.success.t22'));
      setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  const reflectEmail = (email) => {
    const result = validateEmail(email);
    result.isValid ? setEmailError('') : setEmailError(result.message);
    setEmail(email);
  };

  useEffect(() => {
    const modal = document.getElementById('change_email_modal');

    // モーダルの属性を監視
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'open'
        ) {
          if (!modal.hasAttribute('open')) {
            setEmail(user.email);
            setEmailError('');
            setSuccess(false);
            setSuccessMessage('');
          }
        }
      }
    });

    // 監視の開始
    observer.observe(modal, { attributes: true });

    // クリーンアップ関数で監視を停止
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <dialog id='change_email_modal' className='modal'>
      {success ? (
        <div className='modal-box !max-w-[32rem] !p-6'>
          <h3 className='mb-6 mt-0 font-bold text-lg'>
            Please verify your email
          </h3>
          <div className='flex flex-col items-center'>
            <div className='flex max-w-60 flex-col items-center text-center text-sm font-medium'>
              <p className=''>We have sent a verification email to</p>
              <p className='my-2 font-bold'>{email}</p>
              <p>
                If you don’t see it, you may need to{' '}
                <span className='font-bold'>check your spam</span> folder.
              </p>
            </div>
            <button className='btn btn-primary mb-4 mt-6' onClick={resendEmail}>
              Resend verification email
            </button>
            <button
              className='btn-link text-sm text-dark'
              onClick={() => {
                setSuccess(false);
              }}
            >
              Change email
            </button>
          </div>
          <div className='modal-action mb-0 mt-6'>
            <form method='dialog'>
              <button className='btn'>Close</button>
            </form>
          </div>
        </div>
      ) : (
        <div className='modal-box !max-w-[32rem] !p-6'>
          <h3 className='mb-6 mt-0 font-bold text-lg'>Change email</h3>
          <label className='form-control w-full'>
            <div className='label'>
              <span className='label-text text-sm font-medium'>Email</span>
            </div>
            <input
              value={email}
              type='email'
              placeholder='name@mail.com'
              className={`input input-bordered w-full ${
                emailError && '!input-error'
              }`}
              onChange={(e) => {
                reflectEmail(e.target.value);
              }}
            />
            <div className='label'>
              <span className='label-text-alt text-sm font-medium text-error'>
                {emailError}
              </span>
            </div>
          </label>
          <div className='modal-action mb-0 mt-6'>
            <form method='dialog'>
              <button className='btn btn-ghost mr-2'>Cancel</button>
              <button
                className='btn btn-primary'
                onClick={handleSubmit}
                disabled={email == user?.email || !email}
              >
                Save
              </button>
            </form>
          </div>
        </div>
      )}
      {successMessage && (
        <div
          className='alert alert-success absolute bottom-20 w-11/12 gap-2 !bg-emerald-400 p-3 shadow-lg md:w-1/2 md:gap-4 md:p-4'
          style={{ display: 'flex' }}
        >
          <CheckCircleIcon className='h-6 min-w-6' />
          <span>{successMessage}</span>
        </div>
      )}
      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default ChangeEmailModal;
