import { useEffect, useState } from 'react';
import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { publicRequest } from '../requestMethods';
import { Trans, useTranslation } from 'react-i18next';

const SubscriptionPlan = ({
  plans,
  getStarted,
  codeError,
  applyCoupon,
  userPlans,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.currentUser);
  const [addVIPSupport, setAddVIPSupport] = useState(false);
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const CAS1 = plans?.find(
    (item) => item.product == process.env.REACT_APP_STRIPE_CAS1_PRODUCT_ID
  );
  const CAS5 = plans?.find(
    (item) => item.product == process.env.REACT_APP_STRIPE_CAS5_PRODUCT_ID
  );
  const VIP = plans?.find(
    (item) => item.product == process.env.REACT_APP_STRIPE_CAS_VIP_PRODUCT_ID
  );
  const {
    REACT_APP_MAX_PUBLISHED_EXHIBITION_CAS1: MAX_PUBLISHED_EXHIBITION_CAS1,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_CAS5: MAX_PUBLISHED_EXHIBITION_CAS5,
    REACT_APP_MAX_EXHIBITION_CAS1: MAX_EXHIBITION_CAS1,
    REACT_APP_MAX_EXHIBITION_CAS5: MAX_EXHIBITION_CAS5,
  } = process.env;

  const fetchUserSubscription = async (subscriptionId) => {
    const res = await publicRequest.get(`/stripe/${subscriptionId}`);

    if (res.data.status == 'trialing') {
      setIsFreeTrial(true);
    } else {
      setIsFreeTrial(false);
    }
  };

  useEffect(() => {
    if (user?.userSubscriptionId) {
      fetchUserSubscription(user.userSubscriptionId);
    }
  }, [user]);

  return (
    <>
      <p className='mb-8 text-3xl font-extrabold'>
        <Trans i18nKey={'signup.choose-plan.ttl'} />
      </p>
      <p>
        <Trans i18nKey={'signup.choose-plan.desc'} />
      </p>

      {CAS1 && CAS5 && VIP ? (
        <div className='mt-5'>
          <div className='flex w-full flex-col gap-5 md:flex-row'>
            {/* cas-1 */}
            <div className='relative flex-1 rounded-2xl border-[1.5px] border-accent bg-[#ECFDF5] p-8 shadow-lg'>
              <div className='tranform badge absolute -top-3 left-1/2 -translate-x-1/2 !border-[#00D8BF] !bg-[#00D8BF] text-sm'>
                <Trans i18nKey={'price-card.most-popular'} />
              </div>
              <div className='mb-6 flex flex-col justify-between gap-4'>
                <div>
                  <p className='text-xl font-bold'>{CAS1?.productName}</p>
                  <p className='text-xs font-bold text-zinc-600'>
                    <Trans i18nKey={'price-card.cas1-for'} />
                  </p>
                </div>
                <div>
                  <p className='text-3xl font-[900]'>
                    {t('price-card.cas-price-variable', {
                      price: Number(CAS1?.unitAmount) / 100,
                    })}
                  </p>
                </div>
              </div>
              <div className='flex  flex-col gap-2'>
                <button
                  className={`btn w-fit ${
                    userPlans?.length > 0
                      ? 'non-active-filled'
                      : '!bg-[#00D8BF]'
                  }`}
                  onClick={() => getStarted([CAS1.priceId], true)}
                  disabled={userPlans?.length > 0 || disabled}
                >
                  {userPlans?.length > 0
                    ? 'Current plan'
                    : t('btn.get-started-with', {
                        name: CAS1.productName,
                      })}
                  {userPlans?.length == 0 && (
                    <ArrowTopRightOnSquareIcon className='h-6 w-6' />
                  )}
                </button>
                {userPlans?.length == 0 && (
                  <>
                    <p className='text-xs font-normal text-gray-500'>
                      <Trans i18nKey={'price-card.secure-checkout-note'} />
                    </p>
                  </>
                )}
              </div>
              <div className='mt-6 flex flex-col gap-1'>
                <div className='flex items-center gap-2 text-base-content'>
                  <CheckIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    {t('price-card.cas1-feature1', {
                      cas1_max_publish: MAX_PUBLISHED_EXHIBITION_CAS1,
                    })}
                  </p>
                </div>
                <div className='flex items-center gap-2 text-base-content'>
                  <CheckIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    {t('price-card.cas1-feature2', {
                      cas1_max_exhibition: MAX_EXHIBITION_CAS1,
                    })}
                  </p>
                </div>
                <div className='flex items-center gap-2 text-base-content'>
                  <CheckIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    <Trans i18nKey={'price-card.cas1-feature3'} />
                  </p>
                </div>
                <div className='flex items-center gap-2 text-base-content'>
                  <CheckIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    <Trans i18nKey={'price-card.cas1-feature4'} />
                  </p>
                </div>
                <div className='flex items-center gap-2 text-base-content'>
                  <CheckIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    <Trans i18nKey={'price-card.cas1-feature5'} />
                  </p>
                </div>
                <div className='flex items-center gap-2 text-base-content'>
                  <CheckIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    <Trans i18nKey={'price-card.cas1-feature6'} />
                  </p>
                </div>
                <div className='flex items-center gap-2 text-base-content'>
                  <CheckIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    <Trans i18nKey={'price-card.cas1-feature7'} />
                  </p>
                </div>
                <div className='flex items-center gap-2 text-base-content'>
                  <CheckIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    <Trans i18nKey={'price-card.cas1-feature8'} />
                  </p>
                </div>
                <div className='flex items-center gap-2 text-base-content'>
                  <XMarkIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    <Trans i18nKey={'price-card.cas1-feature9'} />
                  </p>
                </div>
              </div>
            </div>
            {/* cas-5 */}
            <div className='flex-1'>
              <div className='rounded-2xl bg-sky-50 p-8 shadow-lg'>
                <div className='flex flex-col gap-4 md:justify-between'>
                  <div>
                    <p className='text-xl font-bold'>{CAS5?.productName}</p>
                    <p className='text-xs font-bold text-zinc-600'>
                      <Trans i18nKey={'price-card.cas5-for'} />
                    </p>
                  </div>

                  <p className='text-3xl font-[900]'>
                    {t('price-card.cas-price-variable', {
                      price: Number(CAS5?.unitAmount) / 100,
                    })}
                  </p>
                </div>

                <div className='mt-6 flex flex-col gap-2'>
                  <button
                    className='btn btn-primary w-fit'
                    onClick={() => {
                      addVIPSupport
                        ? getStarted([CAS5.priceId, VIP.priceId])
                        : getStarted([CAS5.priceId]);
                    }}
                    disabled={isFreeTrial || disabled}
                  >
                    {t('btn.get-started-with', {
                      name: CAS5.productName,
                    })}
                    <ArrowTopRightOnSquareIcon className='h-6 w-6' />
                  </button>
                  {userPlans?.length == 0 && (
                    <p className='text-xs font-normal text-gray-500'>
                      <Trans i18nKey={'price-card.secure-checkout-note'} />
                    </p>
                  )}
                </div>

                <div className='mt-6 flex flex-col gap-1'>
                  <div className='flex items-center gap-2 text-base-content'>
                    <CheckIcon className='h-5 w-5 shrink-0' />
                    <p className='text-sm'>
                      {t('price-card.cas5-feature1', {
                        cas5_max_publish: MAX_PUBLISHED_EXHIBITION_CAS5,
                      })}
                    </p>
                  </div>
                  <div className='flex items-center gap-2 text-base-content'>
                    <CheckIcon className='h-5 w-5 shrink-0' />
                    <p className='text-sm'>
                      {t('price-card.cas5-feature2', {
                        cas5_max_exhibition: MAX_EXHIBITION_CAS5,
                      })}
                    </p>
                  </div>
                  <div className='flex items-center gap-2 text-base-content'>
                    <CheckIcon className='h-5 w-5 shrink-0' />
                    <p className='text-sm'>
                      <span className='font-bold text-error'>
                        <Trans i18nKey={'price-card.cas5-feature3-1'} />
                      </span>{' '}
                      <Trans i18nKey={'price-card.cas5-feature3-2'} />
                    </p>
                  </div>
                  <div className='flex items-center gap-2 text-base-content'>
                    <CheckIcon className='h-5 w-5 shrink-0' />
                    <p className='text-sm'>
                      <Trans i18nKey={'price-card.cas5-feature4'} />
                    </p>
                  </div>
                </div>
              </div>

              {/* VIP */}
              <div className='bg-custom-gradient-purple relative  flex flex-col gap-4 rounded-2xl border border-violet-500 p-8'>
                <div className='absolute left-1/2 top-0 flex  h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-violet-500 px-4 py-[1.62rem]'>
                  <PlusIcon className='h-6 w-6 shrink-0 text-white' />
                </div>
                <p className='text-xl font-extrabold text-violet-600'>
                  <Trans i18nKey={'vip-support.ttl'} />
                </p>

                <div className='form-control py-0'>
                  <label className='label flex cursor-pointer justify-start gap-2 py-0'>
                    <input
                      disabled={isFreeTrial || disabled}
                      type='checkbox'
                      className='checkbox h-[1.875rem] w-[1.875rem] bg-white'
                      checked={addVIPSupport}
                      onChange={() => setAddVIPSupport(!addVIPSupport)}
                    />
                    <span className='text-2xl font-black'>
                      <Trans i18nKey={'vip-support.price'} />
                    </span>
                  </label>
                </div>
                <div>
                  <p>
                    <Trans i18nKey={'vip-support.includes'} />
                  </p>
                  <ul className='list-outside list-decimal pl-4'>
                    <li>
                      <Trans i18nKey={'vip-support.includes-1'} />
                    </li>
                    <li>
                      <Trans i18nKey={'vip-support.includes-2'} />
                    </li>
                    <li>
                      <Trans i18nKey={'vip-support.includes-3'} />
                    </li>
                    <li>
                      <Trans i18nKey={'vip-support.includes-4'} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <p className='mt-6 pl-4 text-xs font-normal text-gray-600'>
            <Trans i18nKey={'vip-support.note-1'} />
            <br />
            <Trans i18nKey={'vip-support.note-2'} />
          </p>
        </div>
      ) : (
        <div className='flex items-center justify-center pt-20'>
          <span className='loading loading-dots loading-md text-primary'></span>
        </div>
      )}
    </>
  );
};

export default SubscriptionPlan;
