import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../components/LoadingSpinner';

const DeleteModal = ({ itemName, confirmDelete }) => {
  const { isLoading } = useSelector((state) => state.isLoading);
  return (
    <dialog id='delete_modal' className='modal'>
      <div className='modal-box max-w-lg'>
        <h3 className='m-0 font-bold text-lg'>Delete confirmation</h3>
        <p className='py-4'>
          Are you sure you want to delete this {itemName}?
        </p>
        <div className='modal-action'>
          <form method='dialog'>
            <button className='btn'>Close</button>
          </form>
          <button className='btn !btn-error' onClick={confirmDelete}>
            Delete
          </button>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default DeleteModal;
