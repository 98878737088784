import { BakeShadows, Environment, Image, useGLTF } from '@react-three/drei';
import { useLoader, useThree } from '@react-three/fiber';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useEffect, useLayoutEffect, useMemo } from 'react';

import Man1 from '../models/Man1.jsx';

import { TextureLoader } from 'three';
import SpotLightOnPreview from '../lights/SpotLightOnPreview.js';
import PillarRoomWall from '../walls/PillarRoomWall.jsx';
import PillarRoomFloor from '../floors/PillarRoomFloor.jsx';
import WhitePillar from '../models/WhitePillar.jsx';
import ItemOnPreview from './ItemOnPreview.jsx';

const PillarRoomPreview = ({
  theme,
  frame,
  showSilhouette,
  setSnapshot,
  items,
  frameColor,
  matColor,
}) => {
  const GOLDENRATIO = 1.61803398875;
  const { camera, gl } = useThree();

  const imageMap = useLoader(
    TextureLoader,
    items.length > 0 ? items[0]?.itemUrl : '/assets/img/sample_item_image.png'
  );

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;
  const scaleValues = useMemo(() => {
    const scaleY = GOLDENRATIO;
    const scaleX = scaleY * aspectRatio;
    return { scaleX, scaleY };
  }, [aspectRatio]);
  const { scaleX, scaleY } = scaleValues;

  const directLightIntensity = 1;
  const ambientLightIntensity = 1;

  useEffect(() => {
    return () => {
      gl.dispose();
      // imageMap.dispose();
      useLoader.clear(
        TextureLoader,
        items.length > 0
          ? items[0]?.itemUrl
          : '/assets/img/sample_item_image.png'
      );
    };
  }, [gl]);

  useEffect(() => {
    camera.lookAt(0, 0.8, 1.1);
  }, [camera]);

  return (
    <>
      <BakeShadows />
      <EffectComposer></EffectComposer>
      {/* lights */}

      <color attach='background' args={[`#191920`]} />

      <directionalLight
        // ref={dirLight}
        position={[0, 2, 20]}
        target-position={[0, 2.5, 0]}
        intensity={directLightIntensity}
        color={'#ffffff'}
        castShadow
      />
      <ambientLight intensity={ambientLightIntensity} color={'#ffffff'} />
      {Array.from({ length: 2 }, (_, index) => (
        <WhitePillar
          key={`pillar-${index}`}
          scale={[6, 7.5, 6]}
          position={[-7.5 + index * 9, -1, 2.4]}
        />
      ))}

      <group position={[0, -0.5, 0]}>
        {items.length == 1 && (
          <ItemOnPreview
            position={[-0.2, 0.85, 3]}
            item={items[0]}
            frame={frame}
            frameColor={frameColor}
            matColor={matColor}
          />
        )}
        {items.length == 2 && (
          <>
            <ItemOnPreview
              position={[-1.3, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[1.3, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {items.length >= 3 && (
          <>
            <ItemOnPreview
              position={[-3.6, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[-0.2, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[3.6, 0.85, 3]}
              item={items[2]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {showSilhouette && (
          <Man1 position={[-0.5, 0, 3.8]} scale={0.45} rotation-y={Math.PI} />
        )}

        {/* wall */}
        <PillarRoomWall />
        {/* floor */}
        <PillarRoomFloor />
      </group>

      <Environment
        files={'https://data.curatedartshow.com/hdri/studio_small_07_1k.hdr'}
      />
    </>
  );
};

export default PillarRoomPreview;
