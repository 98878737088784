import React, { useEffect, useState, useMemo, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import { publicRequest } from '../requestMethods';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import parse from 'html-react-parser';
import LoadingSpinner from '../components/LoadingSpinner';
import PasswordModal from '../components/PasswordModal';
import { errorToast, successToast } from '../utils/toast';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Square2StackIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@tanstack/react-query';
import { fetchLobbyUser } from '../services/User';
import { fetchGalleriesByUsername } from '../services/Exhibition';

const Lobby = () => {
  const [isLocked, setIsLocked] = useState(false);
  const [copiedMessage, setCopiedMessage] = useState('');
  const { username } = useParams();
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const { i18n, t } = useTranslation();

  // 閲覧パスワードをユーザー情報から取得
  const { data: lobbyUser, isLoading: isLobbyUserLoading } = useQuery({
    queryFn: () => fetchLobbyUser(username),
    queryKey: ['fetch-lobby-user', username],
    enabled: !!username,
  });

  // 展示会取得
  const { data: galleries, isLoading: isGalleriesLoading } = useQuery({
    queryFn: () => fetchGalleriesByUsername(username),
    queryKey: ['fetch-galleries-by-username', lobbyUser],
    enabled: !!lobbyUser,
  });

  useEffect(() => {
    if (lobbyUser && !isLobbyUserLoading) {
      if (lobbyUser.isSuspended) {
        return navigate('/404');
      }
    }
  }, [lobbyUser, isLobbyUserLoading]);

  const openModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

  const logoImg = useMemo(() => {
    if (galleries && 0 < galleries.length) {
      const filtered = galleries.filter((element) => element.user.logoImg);
      return filtered.length > 0 ? filtered[0].user.logoImg : null;
    }
  }, [galleries]);

  const handleUnLockPassword = async (password) => {
    try {
      await publicRequest.post(`/auth/unlock-page`, {
        username,
        password,
      });
      const casUnlocked = localStorage.getItem('cas-unlocked');
      const lobbyUserId = lobbyUser.id;

      // すでにlocalStorageある場合とない場合
      if (casUnlocked) {
        // JSONをパースしてオブジェクトに変換
        const casUnlockedObj = JSON.parse(casUnlocked);
        casUnlockedObj[lobbyUserId] = 1;
        localStorage.setItem('cas-unlocked', JSON.stringify(casUnlockedObj));
      } else {
        localStorage.setItem(
          'cas-unlocked',
          JSON.stringify({
            [lobbyUser.id]: 1,
          })
        );
      }
      setIsLocked(false);
    } catch (err) {
      console.error(err);
      errorToast(`${err.response.data.message}`);
    }
  };

  const handleCopyLink = (isInModal = false) => {
    const currentUrl = window.location.href; // 現在のページURLを取得
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        if (isInModal) {
          //backdropの上に表示できないのでカスタム
          setCopiedMessage(t('message.success.t24'));
          setTimeout(() => {
            setCopiedMessage('');
          }, 3000);
        } else {
          successToast(t('message.success.t24'));
        }
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <main className='flex h-full flex-col'>
      <div className='flex flex-auto'>
        {/* {isLocked ? (
          <>
            <PasswordModal
              isShow={true}
              title='The lobby is locked'
              onSubmit={handleUnLockPassword}
            />
          </>
        ) : ( */}
        <>
          {isLobbyUserLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className='relative w-full lg:flex'>
                <div className='px-4 pb-14 pt-10 lg:w-2/3 lg:bg-[#F1F5F9] lg:!px-10 lg:pb-6'>
                  <h1 className='text-3xl font-extrabold text-base-content'>
                    {i18n.language === 'ja'
                      ? `ロビー: ${lobbyUser.displayName}`
                      : `Lobby:  ${lobbyUser.displayName}`}
                  </h1>
                  <p
                    className='mt-8 font-bold underline lg:hidden'
                    onClick={openModal}
                  >
                    {i18n.language === 'ja'
                      ? `${lobbyUser.displayName}について`
                      : `About ${lobbyUser.displayName}`}
                  </p>
                  <div className='mb-20 mt-8 grid gap-6 sm:grid-cols-2 sm:!gap-5 lg:grid-cols-3'>
                    {!isGalleriesLoading && galleries.length > 0 ? (
                      galleries.map((item) => {
                        return (
                          <div
                            className='card w-full bg-base-100 shadow-xl'
                            key={item.id}
                          >
                            <figure className='aspect-[1/0.645] '>
                              <img
                                src={item.previewImg}
                                alt={item.title}
                                className='h-full w-full object-cover'
                              />
                            </figure>
                            <div className='card-body'>
                              <h2 className='card-title m-0 break-words text-base'>
                                {item.title}
                              </h2>
                              <p className='line-clamp-3'>{item.description}</p>
                              <div className='card-actions justify-end'>
                                <Link
                                  to={`/galleries/${item.roomId}`}
                                  role='button'
                                  className='btn btn-primary'
                                >
                                  <Trans i18nKey={'btn.enter'} />
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p>
                        {i18n.language === 'ja'
                          ? 'まだ公開されている展覧会はありません'
                          : 'No exhibition published yet.'}
                      </p>
                    )}
                  </div>
                  <div className='absolute bottom-6 left-10 right-10 flex items-center justify-center lg:!justify-start'>
                    <Link to={'/'} className='text-xs' target='_blank'>
                      Powered by <span className='font-bold'>CAS</span>
                    </Link>
                  </div>
                </div>
                <div className='hidden w-1/3 flex-col gap-6 p-10 lg:flex'>
                  {logoImg && (
                    <div className='p-6'>
                      <img
                        src={logoImg}
                        alt='logo'
                        className='w-full max-w-60'
                      />
                    </div>
                  )}
                  <p className='text-2xl font-bold'>
                    {i18n.language === 'ja'
                      ? `${lobbyUser.displayName}について`
                      : `About ${lobbyUser.displayName}`}
                  </p>
                  {lobbyUser.websiteUrl && (
                    <a
                      href={lobbyUser.websiteUrl}
                      target='_blank'
                      className='text-sm underline'
                    >
                      {i18n.language === 'ja'
                        ? `${lobbyUser.displayName}のサイトに移動`
                        : `Jump to ${lobbyUser.displayName} site`}
                    </a>
                  )}
                  <p>
                    {lobbyUser?.bio &&
                      parse(lobbyUser?.bio?.replaceAll('\n', '<br />'))}
                  </p>
                  <button
                    className='btn btn-outline btn-sm w-fit'
                    onClick={() => handleCopyLink(false)}
                  >
                    <Square2StackIcon className='h-5 w-5' />
                    <Trans i18nKey={'btn.copy-lobby-link'} />
                  </button>
                </div>
              </div>
              <dialog ref={modalRef} id='my_modal_1' className='modal'>
                <div className='modal-box flex max-h-[90%] flex-col gap-6'>
                  {logoImg && (
                    <div className='p-6'>
                      <img
                        src={logoImg}
                        alt={lobbyUser.displayName}
                        className='w-full max-w-60'
                      />
                    </div>
                  )}
                  <p className='text-lg font-bold'>
                    {i18n.language === 'ja'
                      ? `${lobbyUser.displayName}について`
                      : `About ${lobbyUser.displayName}`}
                  </p>

                  {lobbyUser.websiteUrl && (
                    <a
                      href={lobbyUser.websiteUrl}
                      target='_blank'
                      className='text-sm underline'
                    >
                      {i18n.language === 'ja'
                        ? `${lobbyUser.displayName}のサイトに移動`
                        : `Jump to ${lobbyUser.displayName} site`}
                    </a>
                  )}

                  <p className='font-medium'>
                    {lobbyUser?.bio &&
                      parse(lobbyUser?.bio?.replaceAll('\n', '<br />'))}
                  </p>
                  <button
                    className='btn btn-outline btn-sm w-fit'
                    onClick={() => handleCopyLink(true)}
                  >
                    <Square2StackIcon className='h-5 w-5' />
                    <Trans i18nKey={'btn.copy-lobby-link'} />
                  </button>
                  <div className='modal-action'>
                    <form method='dialog'>
                      {/* if there is a button in form, it will close the modal */}
                      <button className='btn'>Close</button>
                    </form>
                  </div>
                </div>
                <form method='dialog' className='modal-backdrop'>
                  <button>Close</button>
                </form>

                {copiedMessage && (
                  <div
                    className='alert alert-success absolute bottom-10 w-11/12 gap-2 bg-white p-3 shadow-lg md:w-1/2 md:gap-4 md:p-3'
                    style={{ display: 'flex' }}
                  >
                    <CheckCircleIcon className='h-6 w-6 text-success' />
                    <span>{copiedMessage}</span>
                  </div>
                )}
              </dialog>
            </>
          )}
        </>
        {/* )} */}
      </div>
    </main>
  );
};

export default Lobby;
