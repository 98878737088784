import React, { useCallback, useState } from 'react';
import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import { dispose, useFrame, useThree } from '@react-three/fiber';
import { SpotLight, useKeyboardControls } from '@react-three/drei';

import { easing } from 'maath';
import Item from './Item';

const Items = ({
  items,
  q = new THREE.Quaternion(),
  p = new THREE.Vector3(),
  currentItemId,
  setCurrentItemId,
  setCurrentItem,
  isActive,
  setIsActive,
  setSelectedItem,
  roomSettings,
  roomTheme,
  setFullScreenItemUrl,
  zoomItemImageUrl,
  setZoomItemImageUrl,
  showInfo,
  selectedItem,
  inquiryItem,
  setInquiryItem,
}) => {
  const ref = useRef();
  const { gl } = useThree();
  const [subscribeKeys, getKeys] = useKeyboardControls();
  const [scrolledNewPosition] = useState(new THREE.Vector3([10, 10, 10]));

  const itemPositionArr =
    items && items?.length > 0
      ? items.map((item, index) => ({
          id: item.id,
          position: 2 * index * 1.3,
        }))
      : [];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [zoomLevel, setZoomLevel] = useState(5.4);
  const [zoomItemId, setZoomItemId] = useState(null);
  const [beforeZoomPosition, setBeforeZoomPosition] = useState(null);

  const [deltaX, setDeltaX] = useState(0);
  const timeoutRef = useRef(null);

  //マウスのwheelによる操作
  const handleWheel = (event) => {
    setDeltaX(event.deltaY);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    //deltaが必ずしも0になるわけではなかったため、強制的にtimeoutで0にする
    timeoutRef.current = setTimeout(() => {
      setDeltaX(0);
    }, 150);
  };

  //arrowLeftKey用の関数
  const showPrevItem = useCallback(() => {
    setIsActive(true);
    if (currentItemId > 0 && !zoomItemId && !showInfo && !selectedItem) {
      setCurrentItemId((prevId) => prevId - 1);
    }
  }, [currentItemId, showInfo, selectedItem, zoomItemId]);

  //arrowRightKey用の関数
  const showNextItem = useCallback(() => {
    setIsActive(true);
    if (
      currentItemId < items?.length - 1 &&
      !zoomItemId &&
      !showInfo &&
      !selectedItem
    ) {
      setCurrentItemId((prevId) => prevId + 1);
    }
  }, [currentItemId, items, showInfo, selectedItem, zoomItemId]);

  useEffect(() => {
    const unsubscribeLeftKey = subscribeKeys(
      (state) => state.leftward,
      (value) => {
        if (value) {
          showPrevItem();
        }
      }
    );

    const unsubscribeRightKey = subscribeKeys(
      (state) => state.rightward,
      (value) => {
        if (value) {
          showNextItem();
        }
      }
    );

    return () => {
      unsubscribeLeftKey();
      unsubscribeRightKey();
    };
  }, [showPrevItem, showNextItem]);

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      gl.dispose();
    };
  }, [gl]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    //widthに応じてzoomLevelを変更
    if (windowWidth <= 320) {
      setZoomLevel(5.4);
    } else if (windowWidth <= 375) {
      setZoomLevel(5);
    } else if (windowWidth <= 425) {
      setZoomLevel(4.8);
    } else if (windowWidth <= 768) {
      setZoomLevel(4.4);
    } else if (windowWidth <= 1024) {
      setZoomLevel(4.2);
    } else if (windowWidth <= 1440) {
      setZoomLevel(4);
    } else if (windowWidth <= 1680) {
      setZoomLevel(3.95);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  useEffect(() => {
    //閲覧中の作品が変わったタイミングでカメラのポジションをセット
    if (
      currentItemId != null &&
      !zoomItemId &&
      !showInfo &&
      !selectedItem &&
      !inquiryItem
    ) {
      scrolledNewPosition.set(
        itemPositionArr[currentItemId]?.position + 0.2,
        0.8893446629166666,
        5.4
      );
    }
  }, [currentItemId]);

  useEffect(() => {
    if (zoomItemId != null) {
      //
      const index = itemPositionArr.findIndex((item) => item.id === zoomItemId);
      setCurrentItemId(index);
    } else {
      setCurrentItem(null);
      if (itemPositionArr[currentItemId]?.position != null) {
        //zoom前のポジションに移動
        scrolledNewPosition.set(
          itemPositionArr[currentItemId]?.position + 0.2,
          0.8893446629166666,
          5.4
        );
      }
    }
  }, [zoomItemId]);

  useEffect(() => {
    if (!zoomItemImageUrl) {
      setZoomItemId(null);
      setCurrentItem(null);
    }
  }, [zoomItemImageUrl]);

  useEffect(() => {
    scrolledNewPosition.set(0, 0.8893446629166666, 5.4);
  }, []);

  useFrame((state, dt) => {
    if (!isActive) {
      //横移動の最大値は最後の作品のポジションまで
      if (!zoomItemId && !showInfo && !selectedItem && !inquiryItem) {
        const moveOffset = deltaX * 0.3;
        if (deltaX > 0) {
          scrolledNewPosition.set(
            state.camera.position.x + moveOffset >=
              itemPositionArr[items?.length - 1]?.position + 0.2
              ? itemPositionArr[items?.length - 1]?.position + 0.2
              : state.camera.position.x + moveOffset,
            0.8893446629166666,
            5.4
          );
        } else {
          scrolledNewPosition.set(
            state.camera.position.x + moveOffset <= 0
              ? 0.2
              : state.camera.position.x + moveOffset,
            0.8893446629166666,
            5.4
          );
        }
      }
    }
    //zoomItemId(作品がクリックされた時)はその作品にfocus&zoom
    if (zoomItemId) {
      const item = itemPositionArr.find((item) => item.id === zoomItemId);

      const position = item ? item?.position : null;
      scrolledNewPosition.set(position + 0.2, 0.8893446629166666, zoomLevel);
    }

    if (Math.abs(deltaX) > 1) {
      //スクロールが始まったら閲覧モードとfocus&zoomは解除する
      setIsActive(false);
      // setZoomItemId(null);
      // setCurrentItem(null);
      // setCurrentItemId(null);
    }

    //auto focus機能
    if (Math.abs(deltaX) == 0 && !isActive) {
      // set position to closest item's
      let value = state.camera.position.x;
      if (itemPositionArr?.length > 0) {
        // positionを比較し、valueに最も近い要素を見つける
        const closest = itemPositionArr.reduce((prev, curr) => {
          return Math.abs(curr.position - value) <
            Math.abs(prev.position - value)
            ? curr
            : prev;
        });

        // 最も近い要素のindexを取得する
        const index = itemPositionArr.findIndex(
          (item) => item.id === closest.id
        );

        if (!isActive && !zoomItemId) {
          //カメラを現在のartworkに近づける
          scrolledNewPosition.set(
            closest.position + 0.2,
            0.8893446629166666,
            5.4
          );
          setCurrentItemId(index);
        }
      }
    }

    easing.damp3(state.camera.position, scrolledNewPosition, 1, dt);

    easing.dampQ(state.camera.quaternion, q, 1, dt);
  });

  return (
    <group
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {items.map((item, index) => (
        <group key={item?.id}>
          <Item
            castShadow
            item={item}
            index={index}
            setSelectedItem={setSelectedItem}
            roomSettings={roomSettings}
            roomTheme={roomTheme}
            setFullScreenItemUrl={setFullScreenItemUrl}
            zoomItemId={zoomItemId}
            setZoomItemId={setZoomItemId}
            setCurrentItem={setCurrentItem}
            zoomItemImageUrl={zoomItemImageUrl}
            setZoomItemImageUrl={setZoomItemImageUrl}
            setInquiryItem={setInquiryItem}
          />
        </group>
      ))}
    </group>
  );
};

export default Items;
