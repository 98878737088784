import React, { useRef } from 'react';
import { useEffect, useState } from 'react';

import Overlay from './Overlay';
import { publicRequest } from '../../requestMethods';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// import CyberPunkRoom from '../../components/r3f/theme/CyberPunk';
import ItemModalInGallery from '../../components/item/ItemModalnGallery';
import { useSelector } from 'react-redux';
import FullScreenImg from '../../components/item/FullScreenImg';
import ClassicRoom from '../../components/r3f/theme/ClassicRoom';
import { Canvas } from '@react-three/fiber';
import { Perf } from 'r3f-perf';
import {
  AdaptiveDpr,
  KeyboardControls,
  PerformanceMonitor,
  useProgress,
} from '@react-three/drei';
import CustomColorRoom from '../../components/r3f/theme/CustomColorRoom';
import { useSwipeable } from 'react-swipeable';
import InquiryItemModal from '../../components/item/InquiryItemModal';
import { errorToast } from '../../utils/toast';
import ReactGA from 'react-ga4';

import ExhibitionPasswordModal from '../../components/ExhibitionPasswordModal';
import { Trans } from 'react-i18next';
import PillarRoom from '../../components/r3f/theme/PillarRoom';
import BluePaintRoom from '../../components/r3f/theme/BluePaintRoom';

const CyberPunkRoom = React.lazy(() =>
  import('../../components/r3f/theme/CyberPunk')
);

const Room = () => {
  const [isLocked, setIsLocked] = useState(false);
  const [galleryUser, setGalleryUser] = useState(null);
  const [currentItemId, setCurrentItemId] = useState(0);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [fullScreenItemUrl, setFullScreenItemUrl] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [room, setRoom] = useState([]);
  const [roomTheme, setRoomTheme] = useState([]);
  const [items, setItems] = useState([]);
  const location = useLocation();
  const [roomSettings, setRoomSettings] = useState(null);

  const [showExitModal, setShowExitModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [skipped, setSkipped] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [zoomItemImageUrl, setZoomItemImageUrl] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [inquiryItem, setInquiryItem] = useState(null);

  const [hasMatureContent, setHasMatureContent] = useState(false);
  const [showMatureContent, setShowMatureContent] = useState(true);
  const [answered, setAnswered] = useState(false);
  const [preference, setPreference] = useState('hide');

  const [dpr, setDpr] = useState(window.devicePixelRatio || 1);
  const [mode, setMode] = useState('HIGH');

  const roomId = location.pathname.split('/')[2];
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const matureContentModalRef = useRef(null);

  useEffect(() => {
    // イベント送信のため初期化
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    const modal = document.getElementById('mature_content_warning_modal');

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault(); // ESCキーのデフォルト動作を無効化
      }
    };

    // モーダルが開いている間だけESCキーを無効化する
    modal.addEventListener('keydown', handleKeyDown);

    // クリーンアップでリスナーを削除
    return () => {
      modal.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getRoomData = async () => {
    const res = await publicRequest.get(`/rooms/roomId/${roomId}`);

    if (
      (res.data.userId == user?.id && !res.data.disabled) ||
      (res.data.isActive && !res.data.disabled && res.data.published)
    ) {
      if (res.data.isSuspended) {
        navigate('/404');
        return;
      }

      const isMyGallery = res.data.userId === user?.id;
      const casUnlocked = JSON.parse(
        localStorage.getItem('cas-exhibition-unlocked')
      );
      const hasUnLocked = casUnlocked && casUnlocked[`${res.data.id}`] === 1;
      //lockedモーダル表示条件
      //- isPrivate:trueの場合
      //- localstorageにunlocked履歴がない場合
      //- 自分自身のexhibitiionではない場合
      if (!res.data.isPrivate || isMyGallery || hasUnLocked) {
        setIsLocked(false);
      } else {
        setIsLocked(true);
      }

      setRoom(res.data);
      setRoomTheme(res.data.roomSettings.theme);

      const foundMatureContent = res.data.items.find(
        (item) => item.isMatureContent == true
      );

      if (foundMatureContent) {
        setHasMatureContent(true);
      } else {
        setHasMatureContent(false);
        setAnswered(true);
      }

      // published: true のアイテムのみをセット
      const publishedItems = res.data.items.filter(
        (item) => item.published === true
      );
      setItems(publishedItems);
      const indices = res.data.roomSettings.silhouettes?.map(
        (silhouette) => silhouette.index
      );
      res.data.roomSettings.silhouettes = indices;
      setRoomSettings(res.data.roomSettings);
    } else {
      navigate('/not-found');
    }
  };

  // 閲覧パスワードが設定されている場合はパスワード入力モーダルを表示
  useEffect(() => {
    setShowExitModal(false);

    getRoomData();
  }, []);

  const canvasRef = useRef();

  useEffect(() => {
    // ピンチズームを無効化
    const handleTouchMove = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    // Canvasコンポーネントがアンマウントされたときにリソースを解放
    return () => {
      if (canvasRef.current) {
        const { gl } = canvasRef.current;
        if (gl) {
          gl.forceContextLoss();
          gl.dispose();
          canvasRef.current = null;
        }
      }
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [roomId]);

  const showPrevItem = () => {
    setIsActive(true);

    if (currentItemId > 0) {
      setCurrentItemId(currentItemId - 1);
    }
  };
  const showNextItem = () => {
    setIsActive(true);

    if (currentItemId < items?.length - 1) {
      setCurrentItemId(currentItemId + 1);
    }
  };

  const handlers = useSwipeable({
    onSwiped: (event) => {
      console.log(event);
      if (event.dir == 'Left') {
        //左にスワイプしたときに発火するイベント
        showNextItem();
      }
      if (event.dir == 'Right') {
        //右にスワイプしたときに発火するイベント
        showPrevItem();
      }
    },

    trackMouse: true,
  });

  const handleUnLockPassword = async (password) => {
    try {
      await publicRequest.post(`/rooms/unlock/${room?.id}`, {
        password,
      });
      const casUnlocked = localStorage.getItem('cas-exhibition-unlocked');

      // すでにlocalStorageある場合とない場合
      if (casUnlocked) {
        // JSONをパースしてオブジェクトに変換
        const casUnlockedObj = JSON.parse(casUnlocked);
        casUnlockedObj[room?.id] = 1;
        localStorage.setItem(
          'cas-exhibition-unlocked',
          JSON.stringify(casUnlockedObj)
        );
      } else {
        localStorage.setItem(
          'cas-exhibition-unlocked',
          JSON.stringify({
            [room?.id]: 1,
          })
        );
      }
      setIsLocked(false);
    } catch (err) {
      console.error(err);
      errorToast(`${err.response.data.message}`);
    }
  };

  //webglのprogressに応じてanimationを管理する
  const { active, progress, errors, item, loaded, total } = useProgress();
  let timeoutId = null;

  useEffect(() => {
    // progressが100になった場合の処理
    if (progress === 100) {
      // 2秒後にチェックするためのタイマーを設定
      timeoutId = setTimeout(() => {
        setSkipped(true);
      }, 2000);
    } else {
      // progressが100以外になったらタイマーをクリアし、skippedをfalseに戻す
      clearTimeout(timeoutId);
      setSkipped(false);
    }

    // クリーンアップ関数でタイマーをクリア
    return () => {
      clearTimeout(timeoutId);
    };
  }, [progress]);

  useEffect(() => {
    console.log('errors=====>', errors);
  }, [errors]);

  const openMatureContentWarningModal = () => {
    if (matureContentModalRef.current) {
      matureContentModalRef.current.showModal();
    }
  };

  useEffect(() => {
    if (hasMatureContent) {
      openMatureContentWarningModal();
    }
  }, [hasMatureContent]);

  useEffect(() => {
    if (!showMatureContent) {
      //mature contentを表示しないと回答したためitemsからisMatureContent:trueのものを除外する
      const filteredItems = items.filter((item) => !item.isMatureContent);
      setItems(filteredItems);
    }
  }, [showMatureContent]);

  return (
    <>
      {isLocked ? (
        <main className='flex h-full flex-col'>
          <div className='flex flex-auto'>
            <ExhibitionPasswordModal
              isShow={true}
              onSubmit={handleUnLockPassword}
              returnUrl={`/lobby/${room?.user?.username}`}
            />
          </div>
        </main>
      ) : (
        <div className='relative h-full w-full bg-black' {...handlers}>
          <div
            className={`absolute inset-0 h-full w-full transition-all duration-[2s] ease-out ${
              skipped && answered ? 'z-0 opacity-0' : 'opacity-1 z-10'
            }`}
          >
            {/* mobile */}
            {room?.animationType && (
              <video
                playsInline
                loop
                autoPlay
                controls={false}
                preload='auto'
                muted
                className={`h-full w-full object-cover md:hidden`}
                src={`/assets/transition_animation/cyberpunk${room?.animationType}_faster_mobile.mp4`}
                onLoadedData={() => setVideoLoaded(true)}
              >
                not support
              </video>
            )}
            {/* desktop */}
            {room?.animationType && (
              <video
                playsInline
                loop
                autoPlay
                controls={false}
                preload='auto'
                muted
                className={`hidden h-full w-full object-cover md:block`}
                src={`/assets/transition_animation/cyberpunk${room?.animationType}_faster.mp4`}
                onLoadedData={() => setVideoLoaded(true)}
              >
                not support
              </video>
            )}

            <div className='absolute left-0 right-0 top-[49%] text-center font-bold text-white'>
              Loading<span className='animate-dot-1'>.</span>
              <span className='animate-dot-2'>.</span>
              <span className='animate-dot-3'>.</span>
            </div>
          </div>

          <div
            className={`fixed h-full w-full overflow-hidden transition-all duration-[3s] ease-in ${
              skipped && answered ? 'opacity-1 z-10' : 'z-0 opacity-0'
            } ${
              zoomItemImageUrl
                ? 'animate-fade animate-delay-[500ms] animate-reverse animate-duration-[2500ms] animate-once animate-ease-out'
                : ''
            }`}
          >
            <KeyboardControls
              map={[
                { name: 'leftward', keys: ['ArrowLeft'] },
                { name: 'rightward', keys: ['ArrowRight'] },
              ]}
            >
              <Canvas
                ref={canvasRef}
                shadows
                dpr={dpr}
                camera={{ fov: 70, position: [0, 2, 15] }}
                gl={{ preserveDrawingBuffer: true }}
                // performance={{
                //   current: 1, // 現在のパフォーマンス指標（通常は1）
                //   min: 0.1, // 最低DPRは0.5　フレームレートが低い時にどこまでパフォーマンスを落とすか
                //   max: 0.5, // 最高DPRは1　最大パフォーマンスの時のフレームレート
                //   debounce: 300, // 300ms毎にパフォーマンスをチェックして調整
                // }}
              >
                <PerformanceMonitor
                  bounds={
                    (refreshRate) =>
                      refreshRate > 90
                        ? [50, 90] // 高リフレッシュレートデバイス向け
                        : [50, 60] // 通常のデバイス向け
                  }
                  // bounds={(refreshRate) => [70, 80]} //debug
                  threshold={0.8} // 80%の計測が上下限に達すると発火
                  iterations={10} // 10回計測
                  onIncline={() => {
                    // パフォーマンスが良くなったとき
                    setMode('HIGH');
                    setDpr(window.devicePixelRatio || 1);
                  }}
                  onDecline={() => {
                    // パフォーマンスが悪くなったとき
                    setMode('LOW');
                    setDpr(
                      window.devicePixelRatio
                        ? window.devicePixelRatio * 0.6
                        : 0.6
                    ); // ピクセル比がundefinedなら0.6
                  }}
                />
                {/* シーンが重くなるとDPR（解像度）を下げ、軽くなるとDPRを戻す */}
                {/* <AdaptiveDpr pixelated minDpr={0.1} maxDpr={0.5} /> */}
                {process.env.REACT_APP_ENV === 'development' && (
                  <Perf position='top-center' />
                )}
                {(() => {
                  switch (roomSettings?.theme) {
                    case 'DARK':
                      return (
                        <CustomColorRoom
                          bgColor={'#191920'}
                          room={room}
                          roomSettings={roomSettings}
                          items={items}
                          currentItemId={currentItemId}
                          setCurrentItemId={setCurrentItemId}
                          isActive={isActive}
                          setIsActive={setIsActive}
                          setSelectedItem={setSelectedItem}
                          setCurrentItem={setCurrentItem}
                          setIsLoading={setIsLoading}
                          zoomItemImageUrl={zoomItemImageUrl}
                          setZoomItemImageUrl={setZoomItemImageUrl}
                          showInfo={showInfo}
                          selectedItem={selectedItem}
                          inquiryItem={inquiryItem}
                          setInquiryItem={setInquiryItem}
                          mode={mode}
                        />
                      );

                    case 'CYBERPUNK':
                      return (
                        <CyberPunkRoom
                          room={room}
                          roomSettings={roomSettings}
                          items={items}
                          currentItemId={currentItemId}
                          setCurrentItemId={setCurrentItemId}
                          isActive={isActive}
                          setIsActive={setIsActive}
                          setSelectedItem={setSelectedItem}
                          setCurrentItem={setCurrentItem}
                          setIsLoading={setIsLoading}
                          setFullScreenItemUrl={setFullScreenItemUrl}
                          zoomItemImageUrl={zoomItemImageUrl}
                          setZoomItemImageUrl={setZoomItemImageUrl}
                          showInfo={showInfo}
                          selectedItem={selectedItem}
                          inquiryItem={inquiryItem}
                          setInquiryItem={setInquiryItem}
                          mode={mode}
                        />
                      );
                    case 'CLASSIC':
                      return (
                        <ClassicRoom
                          room={room}
                          roomSettings={roomSettings}
                          items={items}
                          currentItemId={currentItemId}
                          setCurrentItemId={setCurrentItemId}
                          isActive={isActive}
                          setIsActive={setIsActive}
                          setSelectedItem={setSelectedItem}
                          setCurrentItem={setCurrentItem}
                          setIsLoading={setIsLoading}
                          zoomItemImageUrl={zoomItemImageUrl}
                          setZoomItemImageUrl={setZoomItemImageUrl}
                          showInfo={showInfo}
                          selectedItem={selectedItem}
                          inquiryItem={inquiryItem}
                          setInquiryItem={setInquiryItem}
                          mode={mode}
                        />
                      );
                    case 'CUSTOM':
                      return (
                        <CustomColorRoom
                          bgColor={roomSettings?.colorCode}
                          room={room}
                          roomSettings={roomSettings}
                          items={items}
                          currentItemId={currentItemId}
                          setCurrentItemId={setCurrentItemId}
                          isActive={isActive}
                          setIsActive={setIsActive}
                          setSelectedItem={setSelectedItem}
                          setCurrentItem={setCurrentItem}
                          setIsLoading={setIsLoading}
                          zoomItemImageUrl={zoomItemImageUrl}
                          setZoomItemImageUrl={setZoomItemImageUrl}
                          showInfo={showInfo}
                          selectedItem={selectedItem}
                          inquiryItem={inquiryItem}
                          setInquiryItem={setInquiryItem}
                          mode={mode}
                        />
                      );
                    case 'PILLAR':
                      return (
                        <PillarRoom
                          bgColor={'#191920'}
                          room={room}
                          roomSettings={roomSettings}
                          items={items}
                          currentItemId={currentItemId}
                          setCurrentItemId={setCurrentItemId}
                          isActive={isActive}
                          setIsActive={setIsActive}
                          setSelectedItem={setSelectedItem}
                          setCurrentItem={setCurrentItem}
                          setIsLoading={setIsLoading}
                          zoomItemImageUrl={zoomItemImageUrl}
                          setZoomItemImageUrl={setZoomItemImageUrl}
                          showInfo={showInfo}
                          selectedItem={selectedItem}
                          inquiryItem={inquiryItem}
                          setInquiryItem={setInquiryItem}
                          mode={mode}
                        />
                      );
                    case 'BLUE_PAINT':
                      return (
                        <BluePaintRoom
                          bgColor={'#191920'}
                          room={room}
                          roomSettings={roomSettings}
                          items={items}
                          currentItemId={currentItemId}
                          setCurrentItemId={setCurrentItemId}
                          isActive={isActive}
                          setIsActive={setIsActive}
                          setSelectedItem={setSelectedItem}
                          setCurrentItem={setCurrentItem}
                          setIsLoading={setIsLoading}
                          zoomItemImageUrl={zoomItemImageUrl}
                          setZoomItemImageUrl={setZoomItemImageUrl}
                          showInfo={showInfo}
                          selectedItem={selectedItem}
                          inquiryItem={inquiryItem}
                          setInquiryItem={setInquiryItem}
                          mode={mode}
                        />
                      );
                    default:
                      return (
                        <CustomColorRoom
                          bgColor={'#191920'}
                          room={room}
                          roomSettings={roomSettings}
                          items={items}
                          currentItemId={currentItemId}
                          setCurrentItemId={setCurrentItemId}
                          isActive={isActive}
                          setIsActive={setIsActive}
                          setSelectedItem={setSelectedItem}
                          setCurrentItem={setCurrentItem}
                          setIsLoading={setIsLoading}
                          zoomItemImageUrl={zoomItemImageUrl}
                          setZoomItemImageUrl={setZoomItemImageUrl}
                          showInfo={showInfo}
                          selectedItem={selectedItem}
                          inquiryItem={inquiryItem}
                          setInquiryItem={setInquiryItem}
                          mode={mode}
                        />
                      );
                  }
                })()}
              </Canvas>
            </KeyboardControls>

            {(videoLoaded || room.animationType == 0) &&
              skipped &&
              answered && (
                <Overlay
                  maxIndex={items?.length - 1}
                  currentItemId={currentItemId}
                  setCurrentItemId={setCurrentItemId}
                  currentItem={currentItem}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  room={room}
                  roomSettings={roomSettings}
                  showExitModal={showExitModal}
                  setShowExitModal={setShowExitModal}
                  items={items}
                  roomTheme={roomTheme}
                  setSelectedItem={setSelectedItem}
                  showInfo={showInfo}
                  setShowInfo={setShowInfo}
                  setInquiryItem={setInquiryItem}
                />
              )}

            {selectedItem && (
              <ItemModalInGallery
                item={selectedItem}
                setSelectedItem={setSelectedItem}
                setInquiryItem={setInquiryItem}
                roomId={roomId}
              />
            )}
          </div>
          {zoomItemImageUrl && (
            <FullScreenImg
              onClose={() => {
                setZoomItemImageUrl(null);
              }}
              imgUrl={zoomItemImageUrl}
              delay={true}
            />
          )}

          <InquiryItemModal
            user={room?.user}
            item={inquiryItem}
            setInquiryItem={setInquiryItem}
          />

          <dialog
            ref={matureContentModalRef}
            id='mature_content_warning_modal'
            className='modal'
          >
            <div className='modal-box flex max-w-xl flex-col gap-4'>
              <div className='text-lg font-bold'>
                <Trans i18nKey={'mature.ttl'} />
              </div>
              <div className='flex flex-col gap-5'>
                <p>
                  <Trans i18nKey={'mature.desc'} />
                </p>
              </div>

              <div className='inline-flex items-center justify-start'>
                <div className='text-base font-bold leading-normal'>
                  <Trans i18nKey={'mature.select'} />
                </div>
              </div>
              <div className='flex gap-2'>
                <input
                  type='radio'
                  id='hide'
                  name='preference'
                  className='radio '
                  checked={preference === 'hide'}
                  onChange={() => setPreference('hide')}
                />
                <label htmlFor='hide' className='text-md cursor-pointer'>
                  <Trans i18nKey={'mature.hide'} />
                </label>
              </div>
              <div className='flex gap-2'>
                <input
                  type='radio'
                  id='show'
                  name='preference'
                  className='radio'
                  checked={preference === 'show'}
                  onChange={() => setPreference('show')}
                />
                <label htmlFor='show' className='text-md cursor-pointer'>
                  <Trans i18nKey={'mature.show'} />
                </label>
              </div>
              <div>
                <form method='dialog'>
                  {/* if there is a button in form, it will close the modal */}
                  <button
                    className='inline-flex h-12 w-full items-center justify-center gap-2 rounded-lg bg-[#2b3440] px-4 py-[26px] text-sm font-medium leading-tight text-[#d7dde4]'
                    onClick={() => {
                      if (preference === 'hide') {
                        setShowMatureContent(false);
                      } else if (preference === 'show') {
                        setShowMatureContent(true);
                      }
                      setAnswered(true);
                    }}
                  >
                    <Trans i18nKey={'btn.older'} />
                  </button>
                  <div className='divider'></div>
                  <button
                    className='inline-flex h-12 w-full items-center justify-center gap-2 rounded-lg bg-[#f2f2f2] px-4 py-[26px] text-sm font-medium leading-tight'
                    onClick={() => {
                      setShowMatureContent(false);
                      setAnswered(true);
                    }}
                  >
                    <Trans i18nKey={'btn.under'} />
                  </button>
                  <div className=' mb-4 mt-2 text-center text-xs font-medium leading-none text-neutral-500'>
                    <Trans i18nKey={'mature.hide-note'} />
                  </div>
                  <div className='flex items-center justify-center pt-6'>
                    <Link
                      to={`/lobby/${room?.user?.username}`}
                      className='btn btn-ghost'
                    >
                      <Trans i18nKey={'btn.back-lobby'} />
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </dialog>
        </div>
      )}
    </>
  );
};

export default Room;
