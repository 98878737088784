import { publicRequest } from "../requestMethods";

export const fetchLobbyUser = async (username) => {
  try {
    const response = await publicRequest.get(`/users/username/${username}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
