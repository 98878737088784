import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getRegionCountries } from '../../../const';

const CountryRegionModal = ({ handleSubmit }) => {
  const [country, setCountry] = useState('United States');
  const [region, setRegion] = useState('');
  const [regionList, setRegionList] = useState();
  const { i18n } = useTranslation();
  const { COUNTRIES, COUNTRY_REGION } = getRegionCountries(i18n.language);

  useEffect(() => {
    const selectedData = COUNTRY_REGION.find(
      (item) => item.country === country
    );
    if (selectedData) {
      setRegionList(selectedData.region);
      setRegion(selectedData.region[0]);
    }
  }, [country]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(country, region);
  };

  useEffect(() => {
    const modal = document.getElementById('country_region_modal');

    // モーダルの属性を監視
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'open'
        ) {
          if (!modal.hasAttribute('open')) {
          }
        }
      }
    });

    // 監視の開始
    observer.observe(modal, { attributes: true });

    // クリーンアップ関数で監視を停止
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <dialog id='country_region_modal' className='modal'>
      <div className='modal-box'>
        <h3 className='mb-6 text-lg font-bold'>
          <Trans i18nKey={'country-region-modal.ttl'} />
        </h3>
        <p className='mb-6 font-medium'>
          <Trans i18nKey={'country-region-modal.desc'} />
        </p>

        {/* Country */}
        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text'>
              <Trans i18nKey={'profile.country'} />
            </span>
          </div>
          <select
            className='select select-bordered'
            onChange={(e) => setCountry(e.target.value)}
            value={country}
          >
            {COUNTRIES.map((country) => {
              return (
                <option
                  value={country}
                  className='select select-bordered'
                  key={country}
                >
                  {country}
                </option>
              );
            })}
          </select>
        </label>

        {/* Region */}
        <label className='form-control mt-6 w-full'>
          <div className='label'>
            <span className='label-text'>
              <Trans i18nKey={'profile.region'} />
            </span>
          </div>
          <select
            className='select select-bordered'
            onChange={(e) => setRegion(e.target.value)}
            value={region}
          >
            {regionList?.map((region) => {
              return (
                <option value={region} key={region}>
                  {region}
                </option>
              );
            })}
          </select>
        </label>

        <div className='modal-action mt-12'>
          <form method='dialog'>
            <button
              className='btn'
              onClick={() => {
                setRegion('');
                setCountry('');
              }}
            >
              <Trans i18nKey={'btn.cancel'} />
            </button>
          </form>
          <button
            className='btn btn-primary'
            onClick={handleFormSubmit}
            disabled={!country || !region}
          >
            <Trans i18nKey={'btn.continue'} />
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default CountryRegionModal;
