import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { CEILINGS } from '../../../data/ceilings';
import { invalidate } from '@react-three/fiber';

const CustomCeiling = ({ id }) => {
  const [texturePaths, setTexturePaths] = useState({});
  const [ceiling, setCeiling] = useState(null);

  useEffect(() => {
    const selectedCeiling = CEILINGS.find((ceiling) => ceiling.id === id);
    setCeiling(selectedCeiling);

    if (selectedCeiling) {
      const newTexturePaths = {};

      if (selectedCeiling.images.map)
        newTexturePaths.map = selectedCeiling.images.map;
      if (selectedCeiling.images.normalMap)
        newTexturePaths.normalMap = selectedCeiling.images.normalMap;
      if (selectedCeiling.images.roughnessMap)
        newTexturePaths.roughnessMap = selectedCeiling.images.roughnessMap;
      if (selectedCeiling.images.aoMap)
        newTexturePaths.aoMap = selectedCeiling.images.aoMap;
      if (selectedCeiling.images.displacementMap)
        newTexturePaths.displacementMap =
          selectedCeiling.images.displacementMap;
      if (selectedCeiling.images.metalnessMap)
        newTexturePaths.metalnessMap = selectedCeiling.images.metalnessMap;
      if (selectedCeiling.images.alphaMap)
        newTexturePaths.alphaMap = selectedCeiling.images.alphaMap;

      setTexturePaths(newTexturePaths);
    }
  }, [id]);

  const textureProps = useTexture(texturePaths);

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(120, 120);
    });
    //このdisposeコードがあるとCPUの使用時間が跳ね上がる
    //react three fiberはdispose処理を勝手に行うため不要
    // return () => {
    //   Object.values(textureProps).forEach((texture) => {
    //     texture.dispose();
    //   });
    // };
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current, ceiling]);

  useEffect(() => {
    invalidate(); // idが変わるたびに再レンダリングを強制する
  }, [id]);

  if (!ceiling) {
    return null; // ceilingがまだロードされていないときは何も表示しない
  }

  return (
    <mesh
      key={`ceiling-${ceiling.title}`}
      receiveShadow
      rotation={[Math.PI / 2, 0, Math.PI]}
      ref={mesh}
      position={[0, 5, 0]}
    >
      <planeGeometry args={[200, 200]} />
      {/* <meshStandardMaterial
        displacementScale={ceiling?.settings.displacementScale}
        aoMapIntensity={ceiling?.settings.aoMapIntensity}
        roughness={ceiling?.settings.roughness}
        normalScale={
          ceiling?.settings.normalScale
            ? ceiling?.settings.normalScale
            : new THREE.Vector2(1, 1)
        }
        metalness={
          ceiling?.settings?.metalness ? ceiling?.settings.metalness : 0.0
        }
        {...textureProps}
      /> */}

      <meshStandardMaterial
        displacementScale={ceiling.settings.displacementScale}
        aoMapIntensity={ceiling.settings.aoMapIntensity}
        roughness={ceiling.settings.roughness}
        normalScale={
          ceiling.settings.normalScale
            ? ceiling.settings.normalScale
            : new THREE.Vector2(1, 1)
        }
        metalness={
          ceiling.settings?.metalness ? ceiling.settings.metalness : 0.0
        }
        {...textureProps}
      />
    </mesh>
  );
};

export default CustomCeiling;
