import { useEffect, useMemo, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authRequest, publicRequest } from '../../requestMethods';
import dayjs from 'dayjs';
import { errorToast, successToast } from '../../utils/toast';
import { loginSuccess, logout, updateProfile } from '../../redux/userRedux';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Trans, useTranslation } from 'react-i18next';
import CountryRegionModal from './components/CountryRegionModal';
import CancelSubscriptionModal from './components/CancelSubscriptionModal';

const ManageSubscription = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading } = useSelector((state) => state.isLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const CAS1_ID = process.env.REACT_APP_STRIPE_CAS1_PRODUCT_ID;
  const CAS5_ID = process.env.REACT_APP_STRIPE_CAS5_PRODUCT_ID;
  const VIP_SUPPORT_ID = process.env.REACT_APP_STRIPE_CAS_VIP_PRODUCT_ID;
  const user = useSelector((state) => state.user.currentUser);

  const [plans, setPlans] = useState(null);
  const [userSubscription, setUserSubscription] = useState(null);
  const [subscription, setSubscription] = useState({
    plan: {
      id: '',
      name: '',
    },
    option: {
      id: '',
      name: '',
    },
  });

  const CAS1 = plans?.find((item) => item.product == CAS1_ID);
  const VIP = plans?.find((item) => item.product == VIP_SUPPORT_ID);
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const { i18n } = useTranslation();

  const fetchPrices = async () => {
    const res = await publicRequest.get('/stripe/prices');
    setPlans(res.data);
  };

  const fetchUserSubscription = async (subscriptionId) => {
    const res = await publicRequest.get(`/stripe/${subscriptionId}`);
    setUserSubscription(res.data);
    // console.log(res.data);
    // if (res.data.status == 'trialing') {
    //   setIsFreeTrial(true);
    // } else {
    //   setIsFreeTrial(false);
    // }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  // 加入プラン名、オプション名、サブスク期限を取得
  // FIXME: user.userPlansにVIPが含まれていないためVIP Supportありなしの判定を行えない
  useEffect(() => {
    const subscriptionPlans = user.userPlans.map(
      (item) => item.SubscriptionPlans
    );
    const plan = subscriptionPlans.find(
      (plan) => plan.productId === CAS1_ID || plan.productId === CAS5_ID
    );
    const option = subscriptionPlans.find(
      (plan) => plan.productId === VIP_SUPPORT_ID
    );

    if (plan) {
      setSubscription((prev) => ({
        ...prev,
        plan: { id: plan.productId, name: plan.name },
      }));
    } else {
      setIsFreeTrial(true);
    }
    if (option) {
      setSubscription((prev) => ({
        ...prev,
        option: { id: option.productId, name: option.name },
      }));
    } else {
      setSubscription((prev) => ({
        ...prev,
        option: {
          id: '',
          name: '',
        },
      }));
    }
    if (user) {
      fetchUserSubscription(user.userSubscriptionId);
    }
  }, [user]);

  // 加入中プランの価格
  const currentPlanPrice = useMemo(() => {
    return userSubscription ? userSubscription.amountTotal / 100 : 'NOT FOUND';
  }, [userSubscription]);

  // 次回支払日
  const expireDate = useMemo(() => {
    if (userSubscription) {
      const unix = dayjs.unix(userSubscription.currentPeriodEnd);
      return i18n.language === 'ja'
        ? unix.format('YYYY年M月D日')
        : unix.format('MMM D, YYYY');
    }
    return 'NOT FOUND';
  }, [userSubscription, i18n.language]);

  const handleUpdatePayment = async () => {
    try {
      const res = await authRequest.post('/stripe/setup-payment');
      const checkoutUrl = res.data.url;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const loadLatestUserData = async () => {
    try {
      const res = await authRequest.get(`/auth/latest`);
      console.log('latestData:', res);
      // ログイン状態にする
      dispatch(loginSuccess(res.data));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // ページが完全に読み込まれた後に実行する
    window.onload = () => {
      const paymentUpdate = searchParams.get('payment_update');

      const subscriptionUpdate = searchParams.get('subscription_update');
      if (subscriptionUpdate == 'success') {
        successToast(t('message.success.t25'));
        loadLatestUserData();
      }

      if (paymentUpdate === 'success') {
        successToast(t('message.success.t26'));
      } else if (paymentUpdate === 'failed') {
        errorToast(t('message.error.t13'));
      }
      searchParams.delete('payment_update');
      searchParams.delete('subscription_update');
      setSearchParams(searchParams);
    };
  }, [searchParams]);

  const handleAddVipSupport = async (pricesIds) => {
    const body = {
      priceIds: pricesIds,
      userId: user.id,
    };

    try {
      const res = await publicRequest.post('/stripe/checkout/upgrade', body);
      const checkoutUrl = res.data.url;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveVipSupport = async () => {
    try {
      dispatch(loadStart());
      await authRequest.post('/stripe/delete-vip-support');

      loadLatestUserData();
      successToast(t('message.success.t25'));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadEnd());
    }
  };

  const handleCancelSubscription = async () => {
    try {
      dispatch(loadStart());
      await authRequest.post('/stripe/subscriptions/cancel');

      document.getElementById('cancel_subscription_modal').close();

      dispatch(logout());
      navigate('/signin');
      //logoutする
      successToast(t('message.success.t27'), 'Infinity');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadEnd());
    }
  };

  const navigatePlanPage = () => {
    if (!user.country && !user.region) {
      document.getElementById('country_region_modal').show();
    } else {
      navigate('/subscription');
    }
  };

  const handleSubmit = async (country, region) => {
    dispatch(loadStart());
    try {
      //countryとregionを更新
      const res = await authRequest.patch('/users', {
        country,
        region,
      });

      //frontのuserデータを更新
      dispatch(updateProfile(res.data));
      //subscriptionページに遷移
      document.getElementById('country_region_modal').close();
      navigate('/subscription');
      successToast(t('message.success.t23'));
    } catch (err) {
      console.error(err);
      errorToast(t('message.error.t1'));
    } finally {
      dispatch(loadEnd());
    }
  };

  return (
    <>
      <h2 className='mb-6 text-2xl font-bold'>
        <Trans i18nKey={'manage-subscription.ttl'} />
      </h2>
      <div className='flex min-h-44 flex-col gap-10'>
        {CAS1 && VIP ? (
          <>
            <section>
              {isFreeTrial ? (
                <>
                  <button
                    className='btn btn-primary'
                    onClick={navigatePlanPage}
                  >
                    <Trans
                      i18nKey={'manage-subscription.start-subscription-btn'}
                    />
                  </button>
                </>
              ) : (
                <>
                  <p>
                    <Trans i18nKey={'manage-subscription.desc-1'} />
                  </p>

                  <button className='btn mt-5' onClick={handleUpdatePayment}>
                    <Trans i18nKey={'manage-subscription.update-payment-btn'} />
                  </button>
                </>
              )}
            </section>

            <section>
              <h3 className='m-0 text-xl font-semibold'>
                <Trans i18nKey={'manage-subscription.plan'} />
              </h3>
              {isFreeTrial ? (
                <p className='mt-3 text-error'>
                  {i18n.language === 'ja' ? (
                    <>
                      フリートライアルは
                      <span className='font-bold'>
                        {dayjs(user.trialEndAt).format('YYYY年M月D日')}
                      </span>
                      に終了します
                    </>
                  ) : (
                    <>
                      Your free trial expires on{' '}
                      <span className='font-bold'>
                        {dayjs(user?.trialEndAt).format('MMM DD, YYYY')}
                      </span>
                    </>
                  )}
                </p>
              ) : (
                <div className='inline-flex flex-col'>
                  <div className='mt-3 flex gap-3'>
                    <div>
                      {subscription.plan.name}{' '}
                      {!!subscription.option.name && (
                        <span>+ {subscription.option.name}</span>
                      )}
                    </div>
                    <span className='text-neutral-500'>
                      {i18n.language === 'ja'
                        ? `$${currentPlanPrice}/月`
                        : `$${currentPlanPrice}/month`}
                    </span>
                  </div>
                  {!isFreeTrial && (
                    <div className='inline-flex flex-col items-start gap-2 md:flex-row'>
                      {!!subscription.option.id ? (
                        <button
                          className='btn btn-outline mb-5 mt-5'
                          onClick={() => handleRemoveVipSupport()}
                        >
                          {i18n.language === 'ja'
                            ? `${VIP.productName}を解約`
                            : `Remove ${VIP.productName}`}
                        </button>
                      ) : subscription.plan.id === CAS5_ID ? (
                        <button
                          className='btn btn-outline mb-5 mt-5'
                          onClick={() => {
                            handleAddVipSupport([VIP.priceId]);
                          }}
                        >
                          {i18n.language === 'ja'
                            ? `${VIP.productName}に加入`
                            : `Add ${VIP.productName}`}
                        </button>
                      ) : (
                        <>
                          {/* <Link to={'/subscription/upgrade'} className={`btn`}>
                       Upgrade plan
                      </Link> */}
                        </>
                      )}
                      {/* {subscription.plan.id === CAS5_ID && (
                      <Link
                        to={'/subscription/downgrade'}
                        className='btn btn-ghost'
                      >
                        Downgrade plan to {CAS1.productName}
                      </Link>
                    )} */}
                    </div>
                  )}
                  {subscription.plan.id == CAS5_ID && (
                    <div className='inline-flex w-fit flex-col gap-2 rounded-2xl bg-yellow-50 p-4 shadow-md'>
                      <div className='font-bold'>
                        {i18n.language === 'ja'
                          ? `${VIP.productName}の特典`
                          : `${VIP.productName} Benefits`}
                      </div>
                      <div>
                        {i18n.language === 'ja'
                          ? `${VIP.productName}では以下の特典が受けられます： `
                          : ` With ${VIP.productName}, you get:`}{' '}
                        <ul className='list-inside list-disc'>
                          <li>
                            <Trans
                              i18nKey={'manage-subscription.vip-benefit-1'}
                            />
                          </li>
                          <li>
                            <Trans
                              i18nKey={'manage-subscription.vip-benefit-2'}
                            />
                          </li>
                          <li>
                            <Trans
                              i18nKey={'manage-subscription.vip-benefit-3'}
                            />
                          </li>
                          <li>
                            <Trans
                              i18nKey={'manage-subscription.vip-benefit-4'}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  <p className='my-5 text-sm'>
                    {i18n.language === 'ja' ? (
                      <>
                        プランの変更をご希望の場合は、
                        <Link to={'/contact'} className='underline'>
                          お問い合わせ
                        </Link>
                        ください。
                      </>
                    ) : (
                      <>
                        If you want to change your plan,{' '}
                        <Link to={'/contact'} className='underline'>
                          contact us
                        </Link>
                        .
                      </>
                    )}
                  </p>
                </div>
              )}
            </section>
            {!isFreeTrial && (
              <section>
                <h3 className='m-0 text-xl font-semibold'>
                  <Trans i18nKey={'manage-subscription.next-payment'} />
                </h3>
                <p className='mt-3'>{expireDate}</p>
                <div role='alert' className='alert mt-5 max-w-[602px]'>
                  <InformationCircleIcon className='h-6 w-6 text-info' />
                  <span>
                    <Trans i18nKey={'manage-subscription.notice'} />
                  </span>
                </div>
              </section>
            )}
            <div className='divider m-0'></div>
            <div className=''>
              {isFreeTrial ? (
                <button
                  className='btn btn-outline btn-error w-auto'
                  onClick={() =>
                    document
                      .getElementById('cancel_subscription_modal')
                      .showModal()
                  }
                >
                  <Trans i18nKey={'manage-subscription.deactivate-btn'} />
                </button>
              ) : (
                <button
                  className='btn btn-outline btn-error w-auto'
                  onClick={() =>
                    document
                      .getElementById('cancel_subscription_modal')
                      .showModal()
                  }
                >
                  <Trans i18nKey={'manage-subscription.cancel-btn'} />
                </button>
              )}
            </div>
          </>
        ) : (
          <span className='loading loading-dots loading-md text-primary'></span>
        )}

        <CancelSubscriptionModal isFreeTrial={isFreeTrial} />
      </div>
      {isLoading && <LoadingSpinner />}
      <CountryRegionModal handleSubmit={handleSubmit} />
    </>
  );
};

export default ManageSubscription;
