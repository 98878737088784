import React, { useEffect, useState } from 'react';
import { authRequest, publicRequest } from '../../requestMethods';
import { useSearchParams } from 'react-router-dom';
import ItemCardWithMenu from '../../components/item/ItemCardWithMenu';
import RoomCardWithMenu from '../../components/room/RoomCardWithMenu';
import { useSelector } from 'react-redux';
import SkeletonRoomCard from '../../components/room/SkeletonRoomCard';
import { Trans, useTranslation } from 'react-i18next';
import { errorToast, successToast } from '../../utils/toast';

const Drafts = ({ setSelectedItem }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.currentUser);
  const [draftItems, setDraftItems] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const type = searchParams.get('type');
  const {
    REACT_APP_STRIPE_CAS1_PRODUCT_ID: CAS1_ID,
    REACT_APP_STRIPE_CAS5_PRODUCT_ID: CAS5_ID,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_CAS1: MAX_PUBLISHED_EXHIBITION_CAS1,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_CAS5: MAX_PUBLISHED_EXHIBITION_CAS5,
  } = process.env;

  const getData = async () => {
    const draftRoomData = await authRequest.get(`/rooms/drafts/${user?.id}`);
    const draftItemData = await authRequest.get(`/items/draft`);
    setDraftItems([...draftRoomData.data, ...draftItemData.data]);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [loading]);

  const changeType = (type) => {
    searchParams.set('type', type);
    setSearchParams(searchParams);
  };

  const publishItem = async (itemId) => {
    try {
      setLoading(true);
      await authRequest.post(`/items/publish/${itemId}`);
      getData();
      successToast(t('message.success.t10'));
    } catch (err) {
      console.log(err);
      errorToast(t('message.error.t1'));
    } finally {
      setLoading(false);
    }
  };

  const publishExhibition = async (id) => {
    try {
      //plan確認
      // 初期値を設定するための変数
      let foundPlan = '';
      if (user?.userPlans?.length > 0) {
        // userPlans配列の各要素をチェック
        user.userPlans.forEach((el) => {
          if (el.SubscriptionPlans.productId === CAS1_ID) {
            foundPlan = 'CAS-1';
          } else if (el.SubscriptionPlans.productId === CAS5_ID) {
            foundPlan = 'CAS-5';
          }
        });
      }

      const galleryData = await publicRequest.get(`/rooms/users/${user.id}`);

      //publishの上限に達しているか確認
      if (
        ((foundPlan == 'CAS-1' || !foundPlan) &&
          galleryData.data.length >= Number(MAX_PUBLISHED_EXHIBITION_CAS1)) ||
        (foundPlan == 'CAS-5' &&
          galleryData.data.length >= Number(MAX_PUBLISHED_EXHIBITION_CAS5))
      ) {
        errorToast(t('message.error.t10'));
        return;
      }

      setLoading(true);
      await authRequest.post(`/rooms/publish/${id}`);
      successToast(t('message.success.t15'));
    } catch (err) {
      console.log(err);
      errorToast(t('message.error.t1'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <select
          id='toggleFavorites'
          defaultValue='all'
          className='select select-primary select-sm mb-8 w-32 rounded-full bg-white'
          onChange={(e) => changeType(e.target.value)}
        >
          <option value='all'>
            <Trans i18nKey={'dashboard.all'} />
          </option>
          <option value='artworks'>
            <Trans i18nKey={'dashboard.artworks'} />
          </option>
          <option value='galleries'>
            <Trans i18nKey={'dashboard.exhibitions'} />
          </option>
        </select>

        {draftItems.length === 0 ? (
          <div className='min-h-44 w-full font-bold'>
            <Trans i18nKey={'dashboard.no-unpublished-artworks'} />
          </div>
        ) : (
          <div
            id='artworks'
            className='grid gap-[1.5rem] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
          >
            {loading ? (
              <>
                {Array(8)
                  .fill()
                  .map((_, index) => (
                    <SkeletonRoomCard key={index} />
                  ))}
              </>
            ) : (
              <>
                {draftItems.map((item, index) => {
                  if (item?.ownerId) {
                    if (!type || type === 'all' || type === 'artworks') {
                      return (
                        <ItemCardWithMenu
                          key={`item_${index}`}
                          item={item}
                          setSelectedItem={setSelectedItem}
                          togglePublish={publishItem}
                        />
                      );
                    }
                  } else {
                    if (!type || type === 'all' || type === 'galleries') {
                      return (
                        <RoomCardWithMenu
                          key={`room_${index}`}
                          room={item}
                          togglePublish={publishExhibition}
                          isPublished={false}
                        />
                      );
                    }
                  }
                })}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Drafts;
