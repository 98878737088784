import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';

export const ArtworkViewsFromAllCountries = ({
  GA4ArtworkData,
  isLoading,
  isVIP,
}) => {
  const [rankedData, setRankedData] = useState([]);

  useEffect(() => {
    if (GA4ArtworkData) {
      // 表示データを設定
      const flatData = [];
      GA4ArtworkData.forEach((item) => {
        item.artworks.forEach((artwork) => {
          const fullScreenEvent = artwork.events.find(
            (item) => item.name.toLowerCase() === 'enter_fullscreen'
          );
          const openModalEvent = artwork.events.find(
            (item) => item.name.toLowerCase() === 'open_modal'
          );
          if (!fullScreenEvent || !openModalEvent) {
            return;
          }
          // 必要に応じてフィルタリング
          if (artwork.totalViews > 0 || artwork.totalUsers > 0) {
            flatData.push({
              country: item.country,
              artworkTitle: artwork.title,
              totalViews: artwork.totalViews,
              fullScreenEventCount: fullScreenEvent.views,
              openModalEventCount: openModalEvent.views,
              totalUsers: artwork.totalUsers,
            });
          }
        });
      });
      // ビュー数でソート（降順）
      const sortedData = flatData.sort((a, b) => b.totalViews - a.totalViews);
      // ランクを割り当て
      const ranked = sortedData.map((item, index) => ({
        rank: index + 1,
        ...item,
      }));
      setRankedData(ranked);
    }
  }, [GA4ArtworkData]);

  return (
    <div className='mt-6 overflow-x-auto'>
      <table className='table'>
        {/* head */}
        <thead>
          <tr>
            <th>
              <Trans i18nKey={'analytics.rank'} />
            </th>
            <th className='min-w-[180px]'>
              <Trans i18nKey={'analytics.artwork'} />
            </th>
            <th>
              <Trans i18nKey={'analytics.country'} />
            </th>
            <th>
              <Trans i18nKey={'analytics.artwork-details'} />
            </th>
            <th>
              <Trans i18nKey={'analytics.fullscreen'} />
            </th>
            {isVIP && (
              <th>
                <Trans i18nKey={'analytics.uu'} />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {!isLoading ? (
            rankedData.length > 0 ? (
              rankedData.map((row, index) => {
                return (
                  <tr
                    key={`ArtworkViewsFromAllCountries-${row.country}-${row.artworkTitle}_${index}`}
                  >
                    <td>{row.rank}</td>
                    <td>{row.artworkTitle}</td>
                    <td>{row.country}</td>
                    <td>{row.openModalEventCount.toLocaleString()}</td>
                    <td>{row.fullScreenEventCount.toLocaleString()}</td>
                    {isVIP && <td>{row.totalUsers.toLocaleString()}</td>}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3} className='text-center'>
                  <Trans i18nKey={'analytics.no-data'} />
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td className='loading loading-spinner loading-xs text-primary'></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
