import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  Square2StackIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { InformationCircleIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import OGP from '../../components/OGP';
import { Trans, useTranslation } from 'react-i18next';
import { successToast } from '../../utils/toast';

const Overlay = ({
  currentItemId,
  setCurrentItemId,
  currentItem,
  setIsActive,
  maxIndex,
  room,
  roomSettings,
  items,
  showExitModal,
  setShowExitModal,
  roomTheme,
  setSelectedItem,
  showInfo,
  setShowInfo,
  setInquiryItem,
}) => {
  const user = useSelector((state) => state.user.currentUser);
  const [itemTitle, setItemTitle] = useState('');

  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);
  const showPrevItem = () => {
    setIsActive(true);

    if (currentItemId > 0) {
      setCurrentItemId(currentItemId - 1);
    }
  };
  const showNextItem = () => {
    setIsActive(true);

    if (currentItemId < maxIndex) {
      setCurrentItemId(currentItemId + 1);
    }
  };

  useEffect(() => {
    setItemTitle(items[currentItemId]?.title);
  }, [currentItemId, items]);

  const audioSrc = useMemo(() => {
    if (!roomSettings?.music) {
      return null;
    }
    const randomBgmIndex = Math.floor(Math.random() * 3) + 1;
    const musicIndex =
      roomSettings?.music === 100 ? randomBgmIndex : roomSettings?.music;
    return `/assets/music/sample${musicIndex}.mp3`;
  }, [roomSettings.music]);

  useEffect(() => {
    const handlePlayMusic = () => {
      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
        });
      }
    };
    document.body.addEventListener('click', handlePlayMusic, { once: true });
    return () => {
      document.body.removeEventListener('click', handlePlayMusic);
    };
  }, []);

  const toggleMute = () => {
    setIsMuted((prev) => !prev);
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
    }
  };

  return (
    <div className={`${currentItem ? 'pointer-events-none invisible' : ''}`}>
      {/* top */}
      <p
        className={`bg-gallery-ui-2 absolute top-0 w-full px-[25px] py-2 text-center text-xs font-bold text-white backdrop-blur-[32px] md:text-sm`}
      >
        {room?.user?.displayName?.toUpperCase()}: {room.title}
      </p>
      <div className='absolute top-14 flex w-full items-center justify-between px-3 md:px-10'>
        {!room.isPrivate ? (
          <Link
            to={`/lobby/${room.user.username}`}
            className={`bg-gallery-ui-2 flex items-center rounded-full border border-[#868686] px-5 py-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50
          ${
            roomTheme === 'LIGHT'
              ? 'bg-gallery-ui-light border-gray-300'
              : 'bg-gallery-ui border-gray-400'
          }
            `}
          >
            <ArrowLeftOnRectangleIcon className='mr-1 h-5 w-5' />
            <Trans i18nKey={'btn.gallery-return'} />
          </Link>
        ) : (
          <div className='flex' />
        )}
        <div className='flex items-center space-x-2'>
          {user?.id === room?.userId && (
            <Link
              to={`/gallery/edit/${room?.roomId}`}
              className='bg-gallery-ui-2 rounded-full border border-[#868686] p-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50'
            >
              <PencilSquareIcon className='h-5 w-5' />
            </Link>
          )}
          <button
            className={`bg-gallery-ui-2 rounded-full border p-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50 ${
              !roomSettings?.music && 'hidden'
            }
            ${
              roomTheme === 'LIGHT'
                ? 'bg-gallery-ui-light border-gray-300'
                : 'bg-gallery-ui border-gray-400'
            }
              `}
            onClick={toggleMute}
          >
            {isMuted ? (
              <SpeakerXMarkIcon className='h-5 w-5' />
            ) : (
              <SpeakerWaveIcon className='h-5 w-5' />
            )}
          </button>
          <button
            className={`bg-gallery-ui-2 rounded-full border p-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50 md:hidden ${
              roomTheme === 'LIGHT'
                ? 'bg-gallery-ui-light border-gray-300'
                : 'bg-gallery-ui border-gray-400'
            }
            `}
            onClick={() => setShowInfo(true)}
          >
            <Bars3Icon className='h-5 w-5' />
          </button>
          <button
            className={`bg-gallery-ui-2 hidden rounded-full border border-[#868686] px-5 py-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50 md:block ${
              roomTheme === 'LIGHT'
                ? 'bg-gallery-ui-light border-gray-300'
                : 'bg-gallery-ui border-gray-400'
            }`}
            onClick={() => setShowInfo(true)}
          >
            <Trans i18nKey={'btn.gallery-about'} />
          </button>
        </div>
      </div>
      <div className='absolute left-1 top-1/2 hidden -translate-y-1/2 sm:!left-3 md:block'>
        <button
          className={`bg-gallery-ui-2 flex h-[54px] w-[54px] items-center justify-center rounded-l-[2rem] rounded-r-md border-2 !border-[#9CA3AF] hover:border-gray-50 ${
            currentItemId == 0 || currentItem ? 'invisible' : 'visible'
          }`}
          onClick={showPrevItem}
          disabled={currentItemId == 0 || currentItem}
        >
          <ChevronLeftIcon className='h-7 w-7 text-white' strokeWidth={4} />
        </button>
      </div>
      <div className='absolute right-1 top-1/2 hidden -translate-y-1/2 sm:!right-3 md:block'>
        <button
          className={`bg-gallery-ui-2 flex h-[54px] w-[54px] items-center justify-center rounded-l-md rounded-r-[2rem] border-2 !border-[#9CA3AF] hover:border-gray-50 ${
            currentItemId == maxIndex || currentItem ? 'invisible' : 'visible'
          }`}
          onClick={showNextItem}
          disabled={currentItemId == maxIndex || currentItem}
        >
          <ChevronRightIcon className='h-7 w-7 text-white' strokeWidth={4} />
        </button>
      </div>
      {/* middle */}
      <div className='absolute bottom-24 left-1/2 -translate-x-1/2 transform md:hidden'>
        <div className='flex flex-1 items-start justify-center space-x-2'>
          <button
            className={`bg-gallery-ui-2 flex h-[54px] w-[54px] items-center justify-center rounded-l-[2rem] rounded-r-md border-2 !border-[#9CA3AF] hover:border-gray-50 ${
              currentItemId == 0 || currentItem ? 'invisible' : 'visible'
            }`}
            onClick={showPrevItem}
            disabled={currentItemId == 0 || currentItem}
          >
            <ChevronLeftIcon className='h-7 w-7 text-white' strokeWidth={4} />
          </button>
          {itemTitle && (
            <div>
              <div
                className={`bg-gallery-ui-2 flex w-40 cursor-pointer flex-col rounded border-[1.5px] border-[#868686] p-4 backdrop-blur-[32px]`}
                onClick={() => {
                  setSelectedItem(items[currentItemId]);
                }}
              >
                <p className={`line-clamp-2 font-inter text-xs text-white`}>
                  {itemTitle}
                </p>
              </div>
              <div className='mt-2 flex items-center justify-center gap-4'>
                <button
                  className={`bg-gallery-ui-2 flex h-[49px] w-[49px] items-center justify-center rounded-full border border-[#868686] backdrop-blur-[32px] hover:border-gray-50 md:hidden`}
                  onClick={() => {
                    setSelectedItem(items[currentItemId]);
                  }}
                >
                  <InformationCircleIcon className='h-[26px] w-[26px] text-white' />
                </button>
                {items[currentItemId]?.creator?.inquiryEmail && (
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      document.getElementById('inquiry_item_modal').showModal();
                      setInquiryItem(items[currentItemId]);
                    }}
                    className={`bg-gallery-ui-2 flex h-[49px] w-[49px] items-center justify-center rounded-full border border-[#868686] backdrop-blur-[32px] hover:border-gray-50 md:hidden`}
                  >
                    <EnvelopeIcon className='h-5 w-5 text-white' />
                  </button>
                )}
              </div>
            </div>
          )}
          <button
            className={`bg-gallery-ui-2 flex h-[54px] w-[54px] items-center justify-center rounded-l-md rounded-r-[2rem] border-2 !border-[#9CA3AF] hover:border-gray-50 ${
              currentItemId == maxIndex || currentItem ? 'invisible' : 'visible'
            }`}
            onClick={showNextItem}
            disabled={currentItemId == maxIndex || currentItem}
          >
            <ChevronRightIcon className='h-7 w-7 text-white' strokeWidth={4} />
          </button>
        </div>
      </div>
      {/* bottom */}
      {room?.items?.length > 0 && (
        <>
          {user && (
            <div
              className={`absolute bottom-10 ml-2 w-11/12 md:flex-1 ${
                !currentItem?.allowComments && 'cursor-not-allowed'
              }`}
            ></div>
          )}
        </>
      )}

      <a
        href='/'
        target='_blank'
        rel='noopener noreferrer'
        className='absolute bottom-3 right-1/2 translate-x-1/2 transform rounded-[62rem] bg-black px-3 py-1 text-xs font-semibold text-white md:right-3 md:hidden md:translate-x-0'
      >
        Powered by CAS
      </a>
      <a
        href='/'
        target='_blank'
        rel='noopener noreferrer'
        className='absolute bottom-3 right-5 hidden rounded-[62rem] bg-black px-3 py-1 text-xs font-semibold text-white md:block'
      >
        Powered by CAS
      </a>
      {/* blur */}
      {(showInfo || showExitModal) && (
        <div
          onClick={() => setShowInfo(false)}
          className={`test bg-gallery-ui absolute left-0 top-0 z-10 flex h-full w-full flex-col p-10 px-10 backdrop-blur-xl ${
            roomTheme === 'LIGHT' ? 'bg-gallery-ui-light' : 'bg-gallery-ui'
          }`}
        />
      )}
      {showInfo && (
        <RoomInfo setShowInfo={setShowInfo} room={room} RoomInfo={RoomInfo} />
      )}

      {!room?.items?.length > 0 && <NoItemMessage roomTheme={roomTheme} />}
      <OGP />
      {audioSrc && <audio ref={audioRef} loop src={audioSrc}></audio>}
    </div>
  );
};

export default Overlay;

const RoomInfo = ({ setShowInfo, room, roomTheme }) => {
  const user = useSelector((state) => state.user.currentUser);
  const { t } = useTranslation();

  const handleCopyLink = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const currentUrl = window.location.href; // 現在のページURLを取得
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        successToast(t('message.success.t24'));
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div
      onClick={() => setShowInfo(false)}
      className='absolute top-0 z-50 flex h-full w-screen flex-col overflow-scroll bg-[#161616] p-8 text-white md:right-0 md:w-2/3 md:p-20'
    >
      <button
        className='self-end max-md:mr-0 max-md:mt-0 md:-mr-8 md:-mt-8'
        onClick={() => setShowInfo(false)}
      >
        <XMarkIcon className='h-8 w-8' />
      </button>
      <p
        className={`mb-8 text-3xl font-[800] ${
          roomTheme === 'LIGHT' ? 'text-gray' : 'text-white'
        }`}
      >
        {room?.title}
      </p>
      <div className='my-2 flex items-center space-x-3'>
        <img
          className='h-10 w-10 rounded-full object-cover'
          src={`${
            room?.user?.profileImageUrl
              ? room?.user?.profileImageUrl
              : '/assets/img/avatar/dummy_avatar.svg'
          }`}
        />
        <div>
          <p className='text-sm font-bold text-white'>
            {room?.user?.displayName}
          </p>
          <p className='text-sm text-white'>
            <Trans i18nKey={'gallery-about.title-desc'} />
          </p>
        </div>
      </div>
      <div className='my-2 flex space-x-2 self-end'>
        {user?.id === room?.userId && (
          <Link
            to={`/gallery/edit/${room?.roomId}`}
            className='hover:opacity-75'
          >
            <PencilSquareIcon className='h-5 w-5' />
          </Link>
        )}
      </div>
      <div className='mb-6 mt-10'>{room?.description}</div>
      <ul className='flex list-disc flex-col gap-1 pl-5'>
        {room?.items?.map((item) => (
          <li className='text-white'>{item?.title}</li>
        ))}
      </ul>
      <button
        className='btn btn-outline btn-sm mt-10 w-fit border-white text-white'
        onClick={handleCopyLink}
      >
        <Square2StackIcon className='h-5 w-5' />
        <Trans i18nKey={'btn.copy-exhibition-link'} />
      </button>
    </div>
  );
};

const NoItemMessage = (roomTheme) => {
  return (
    <div
      className={`hover:border-gray-50-500 absolute left-1/2 top-1/2 flex -translate-x-1/2  -translate-y-1/2  transform items-center justify-center rounded-sm border  bg-opacity-60 p-16 backdrop-filter before:absolute before:z-[-1] before:h-full before:w-full before:overflow-hidden before:rounded-xl before:backdrop-blur-3xl before:content-[""] ${
        roomTheme === 'LIGHT'
          ? 'bg-gallery-ui-light border-gray-300'
          : 'bg-gallery-ui border-gray-400'
      }`}
    >
      <p className='text-sm text-white'>
        <Trans i18nKey={'gallery.empty'} />
      </p>
    </div>
  );
};
