import PictureFrame1 from './PictureFrame1.jsx';
import PictureFrame2 from './PictureFrame2.jsx';
import PictureFrame6 from './PictureFrame6.jsx';
import PictureFrame5 from './PictureFrame5.jsx';
import PictureFrame4 from './PictureFrame4.jsx';
import PictureFrame3 from './PictureFrame3.jsx';
import PictureFrame7 from './PictureFrame7.jsx';
import PictureFrame8 from './PictureFrame8.jsx';
import PictureFrame9 from './PictureFrame9.jsx';
import PictureFrame10 from './PictureFrame10.jsx';
import PictureFrame11 from './PictureFrame11.jsx';
import PictureFrame12 from './PictureFrame12.jsx';
import PictureFrame13 from './PictureFrame13.jsx';
import PictureFrame14 from './PictureFrame14.jsx';
import PictureFrame15 from './PictureFrame15.jsx';
import PictureFrame16 from './PictureFrame16.jsx';
import PictureFrame17 from './PictureFrame17.jsx';
import PictureFrame18 from './PictureFrame18.jsx';
import PictureFrame19 from './PictureFrame19.jsx';
import PictureFrame40 from './PictureFrame40.jsx';
import PictureFrame20 from './PictureFrame20.jsx';
import PictureFrame21 from './PictureFrame21.jsx';
import PictureFrame22 from './PictureFrame22.jsx';
import PictureFrame23 from './PictureFrame23.jsx';
import PictureFrame24 from './PictureFrame24.jsx';
import PictureFrame25 from './PictureFrame25.jsx';
import PictureFrame26 from './PictureFrame26.jsx';
import PictureFrame27 from './PictureFrame27.jsx';
import PictureFrame28 from './PictureFrame28.jsx';
import PictureFrame29 from './PictureFrame29.jsx';
import PictureFrame30 from './PictureFrame30.jsx';
import PictureFrame31 from './PictureFrame31.jsx';
import PictureFrame32 from './PictureFrame32.jsx';
import PictureFrame33 from './PictureFrame33.jsx';
import PictureFrame34 from './PictureFrame34.jsx';
import PictureFrame35 from './PictureFrame35.jsx';
import PictureFrame36 from './PictureFrame36.jsx';
import PictureFrame37 from './PictureFrame37.jsx';
import PictureFrame38 from './PictureFrame38.jsx';
import PictureFrame39 from './PictureFrame39.jsx';
import PictureFrame41 from './PictureFrame41.jsx';
import PictureFrame42 from './PictureFrame42.jsx';
import PictureFrame43 from './PictureFrame43.jsx';
import PictureFrame44 from './PictureFrame44.jsx';
import PictureFrame45 from './PictureFrame45.jsx';
import PictureFrame46 from './PictureFrame46.jsx';
import PictureFrame47 from './PictureFrame47.jsx';
import PictureFrame48 from './PictureFrame48.jsx';
import PictureFrame49 from './PictureFrame49.jsx';
import PictureFrame50 from './PictureFrame50.jsx';
import PictureFrame51 from './PictureFrame51.jsx';
import PictureFrame52 from './PictureFrame52.jsx';
import PictureFrame53 from './PictureFrame53.jsx';
import PictureFrame54 from './PictureFrame54.jsx';
import PictureFrame55 from './PictureFrame55.jsx';
import PictureFrame56 from './PictureFrame56.jsx';
import PictureFrame57 from './PictureFrame57.jsx';
import PictureFrame58 from './PictureFrame58.jsx';
import PictureFrame60 from './PictureFrame60.jsx';
import PictureFrame59 from './PictureFrame59.jsx';
import PictureFrame61 from './PictureFrame61.jsx';
import PictureFrame62 from './PictureFrame62.jsx';
import CustomFrame1 from './CustomFrame1.jsx';
import CustomFrame2 from './CustomFrame2.jsx';
import CustomFrame3 from './CustomFrame3.jsx';
import CustomFrame4 from './CustomFrame4.jsx';
import CustomFrame5 from './CustomFrame5.jsx';
import PictureFrame63 from './PictureFrame63.jsx';
import PictureFrame64 from './PictureFrame64.jsx';
import PictureFrame65 from './PictureFrame65.jsx';
import PictureFrame66 from './PictureFrame66.jsx';
import PictureFrame67 from './PictureFrame67.jsx';
import PictureFrame68 from './PictureFrame68.jsx';
import PictureFrame69 from './PictureFrame69.jsx';
import PictureFrame70 from './PictureFrame70.jsx';
import PictureFrame71 from './PictureFrame71.jsx';
import PictureFrame72 from './PictureFrame72.jsx';
import PictureFrame73 from './PictureFrame73.jsx';
import PictureFrame74 from './PictureFrame74.jsx';
import PictureFrame75 from './PictureFrame75.jsx';
import PictureFrame76 from './PictureFrame76.jsx';

const PictureFrameGroup = ({
  item,
  frame,
  GOLDENRATIO,
  aspectRatio,
  frameColor,
  matColor,
}) => {
  const scaleAdjustment = 0.7916667; //0.95÷1.2 縮小スケール÷標準scale

  return (
    <>
      {((!item?.frame && frame == 'custom1') || item?.frame == 'custom1') && (
        <CustomFrame1
          frameColor={item?.frame == 'custom1' ? item?.frameColor : frameColor}
          matColor={item?.frame == 'custom1' ? item?.matColor : matColor}
          scale={[
            aspectRatio < 1 ? 3.8 * scaleAdjustment : 3.8,
            aspectRatio < 1
              ? (2.9 * scaleAdjustment) / aspectRatio
              : 2.9 / aspectRatio,
            30,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.64]}
        />
      )}
      {((!item?.frame && frame == 'custom2') || item?.frame == 'custom2') && (
        <CustomFrame2
          frameColor={item?.frame == 'custom2' ? item?.frameColor : frameColor}
          matColor={item?.frame == 'custom2' ? item?.matColor : matColor}
          scale={[
            aspectRatio < 1 ? 3.1 * scaleAdjustment : 3.1,
            aspectRatio < 1
              ? (2.32 * scaleAdjustment) / aspectRatio
              : 2.32 / aspectRatio,
            30,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.64]}
        />
      )}
      {((!item?.frame && frame == 'custom3') || item?.frame == 'custom3') && (
        <CustomFrame3
          frameColor={item?.frame == 'custom3' ? item?.frameColor : frameColor}
          matColor={item?.frame == 'custom3' ? item?.matColor : matColor}
          scale={[
            aspectRatio < 1 ? 3.1 * scaleAdjustment : 3.1,
            aspectRatio < 1
              ? (2.32 * scaleAdjustment) / aspectRatio
              : 2.32 / aspectRatio,
            30,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.74]}
        />
      )}
      {((!item?.frame && frame == 'custom4') || item?.frame == 'custom4') && (
        <CustomFrame4
          frameColor={item?.frame == 'custom4' ? item?.frameColor : frameColor}
          matColor={item?.frame == 'custom4' ? item?.matColor : matColor}
          scale={[
            aspectRatio < 1 ? 3.1 * scaleAdjustment : 3.1,
            aspectRatio < 1
              ? (2.32 * scaleAdjustment) / aspectRatio
              : 2.32 / aspectRatio,
            30,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.74]}
        />
      )}
      {((!item?.frame && frame == 'custom5') || item?.frame == 'custom5') && (
        <CustomFrame5
          frameColor={item?.frame == 'custom5' ? item?.frameColor : frameColor}
          matColor={item?.frame == 'custom5' ? item?.matColor : matColor}
          scale={[
            aspectRatio < 1 ? 3.1 * scaleAdjustment : 3.1,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.44]}
        />
      )}
      {/* itemにframeを優先表示、itemにセットされていない場合はroomSettingsのframe表示 */}
      {((!item?.frame && frame == 'black') || item?.frame == 'black') && (
        <PictureFrame17
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.575 * scaleAdjustment) / aspectRatio
              : 1.575 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
          color={'#000'}
        />
      )}
      {((!item?.frame && frame == 'white') || item?.frame == 'white') && (
        <PictureFrame17
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.575 * scaleAdjustment) / aspectRatio
              : 1.575 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
          color={'#fff'}
        />
      )}
      {((!item?.frame && frame == 'gold') || item?.frame == 'gold') && (
        <PictureFrame1
          scale={[
            aspectRatio < 1 ? 0.71 * scaleAdjustment : 0.71,
            aspectRatio < 1
              ? (0.71 * scaleAdjustment) / aspectRatio
              : 0.71 / aspectRatio,
            1.8,
          ]}
          position={[0, -GOLDENRATIO * 0.005 * aspectRatio, 0.68]}
          color='#af9615'
        />
      )}
      {((!item?.frame && frame == 'frame2') || item?.frame == 'frame2') && (
        <PictureFrame2
          scale={[
            aspectRatio < 1 ? 0.025 * scaleAdjustment : 0.025,
            aspectRatio < 1
              ? (0.0175 * scaleAdjustment) / aspectRatio
              : 0.0175 / aspectRatio,
            0.3,
          ]}
          position={[0, (-GOLDENRATIO * 0.33) / aspectRatio, 0.4]}
          color='gold'
        />
      )}
      {((!item?.frame && frame == 'frame3') || item?.frame == 'frame3') && (
        <PictureFrame3
          scale={[
            aspectRatio < 1 ? 1.41 * scaleAdjustment : 1.41,
            aspectRatio < 1
              ? (2.1 * scaleAdjustment) / aspectRatio
              : 2.1 / aspectRatio,
            1.5,
          ]}
          position={[0, (-GOLDENRATIO * 0.01) / aspectRatio, 0.6]}
          color='gold'
        />
      )}
      {((!item?.frame && frame == 'frame4') || item?.frame == 'frame4') && (
        <PictureFrame4
          scale={[
            aspectRatio < 1 ? 10.5 * scaleAdjustment : 10.5,
            aspectRatio < 1
              ? (10.5 * scaleAdjustment) / aspectRatio
              : 10.5 / aspectRatio,
            1.5,
          ]}
          position={[0, (GOLDENRATIO * 0.01) / aspectRatio, 0.69]}
          color='#a28d21'
        />
      )}
      {((!item?.frame && frame == 'frame5') || item?.frame == 'frame5') && (
        <PictureFrame5
          scale={[
            aspectRatio < 1 ? 0.014 * scaleAdjustment : 0.014,
            aspectRatio < 1
              ? (0.0115 * scaleAdjustment) / aspectRatio
              : 0.0115 / aspectRatio,
            0.5,
          ]}
          position={[0, (-GOLDENRATIO * 0.001) / aspectRatio, 1.4]}
          color='#af9615'
        />
      )}
      {((!item?.frame && frame == 'frame6') || item?.frame == 'frame6') && (
        <PictureFrame6
          scale={[
            aspectRatio < 1 ? 2.88 * scaleAdjustment : 2.88,
            aspectRatio < 1
              ? (2.78 * scaleAdjustment) / aspectRatio
              : 2.78 / aspectRatio,
            1.6,
          ]}
          position={[-0.04, (GOLDENRATIO * 0.001) / aspectRatio + 0.035, 0.65]}
          color='#af9615'
        />
      )}
      {((!item?.frame && frame == 'frame7') || item?.frame == 'frame7') && (
        <PictureFrame7
          scale={[
            aspectRatio < 1 ? 0.345 * scaleAdjustment : 0.345,
            aspectRatio < 1
              ? (0.345 * scaleAdjustment) / aspectRatio
              : 0.345 / aspectRatio,
            1,
          ]}
          position={[0.0, (GOLDENRATIO * 0.001) / aspectRatio - 0.01, 0.65]}
          color='black'
        />
      )}
      {((!item?.frame && frame == 'frame8') || item?.frame == 'frame8') && (
        <PictureFrame8
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.58 * scaleAdjustment) / aspectRatio
              : 1.58 / aspectRatio,
            60,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.145, 0.8]}
        />
      )}
      {((!item?.frame && frame == 'frame9') || item?.frame == 'frame9') && (
        <PictureFrame9
          color={'#686b77'}
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.58 * scaleAdjustment) / aspectRatio
              : 1.58 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
        />
      )}
      {((!item?.frame && frame == 'frame10') || item?.frame == 'frame10') && (
        <PictureFrame10
          scale={[2, 1.575 / aspectRatio, 30]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
        />
      )}
      {((!item?.frame && frame == 'frame11') || item?.frame == 'frame11') && (
        <PictureFrame11
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.575 * scaleAdjustment) / aspectRatio
              : 1.575 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
        />
      )}
      {((!item?.frame && frame == 'frame12') || item?.frame == 'frame12') && (
        <PictureFrame12
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.575 * scaleAdjustment) / aspectRatio
              : 1.575 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
          color='#0423a0'
        />
      )}
      {((!item?.frame && frame == 'frame13') || item?.frame == 'frame13') && (
        <PictureFrame13
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.575 * scaleAdjustment) / aspectRatio
              : 1.575 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
        />
      )}
      {((!item?.frame && frame == 'frame14') || item?.frame == 'frame14') && (
        <PictureFrame14
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.575 * scaleAdjustment) / aspectRatio
              : 1.575 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
        />
      )}
      {((!item?.frame && frame == 'frame15') || item?.frame == 'frame15') && (
        <PictureFrame15
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.575 * scaleAdjustment) / aspectRatio
              : 1.575 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
        />
      )}
      {((!item?.frame && frame == 'frame16') || item?.frame == 'frame16') && (
        <PictureFrame16
          scale={[
            aspectRatio < 1 ? 2 * scaleAdjustment : 2,
            aspectRatio < 1
              ? (1.575 * scaleAdjustment) / aspectRatio
              : 1.575 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.8]}
        />
      )}
      {((!item?.frame && frame == 'frame18') || item?.frame == 'frame18') && (
        <PictureFrame18
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
          color={'#00479c'}
        />
      )}
      {((!item?.frame && frame == 'frame19') || item?.frame == 'frame19') && (
        <PictureFrame19
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
          color={'#00479c'}
        />
      )}
      {((!item?.frame && frame == 'frame20') || item?.frame == 'frame20') && (
        <PictureFrame20
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame21') || item?.frame == 'frame21') && (
        <PictureFrame21
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame22') || item?.frame == 'frame22') && (
        <PictureFrame22
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame23') || item?.frame == 'frame23') && (
        <PictureFrame23
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame24') || item?.frame == 'frame24') && (
        <PictureFrame24
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame25') || item?.frame == 'frame25') && (
        <PictureFrame25
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame26') || item?.frame == 'frame26') && (
        <PictureFrame26
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame27') || item?.frame == 'frame27') && (
        <PictureFrame27
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame28') || item?.frame == 'frame28') && (
        <PictureFrame28
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame29') || item?.frame == 'frame29') && (
        <PictureFrame29
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame30') || item?.frame == 'frame30') && (
        <PictureFrame30
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame31') || item?.frame == 'frame31') && (
        <PictureFrame31
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame32') || item?.frame == 'frame32') && (
        <PictureFrame32
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame33') || item?.frame == 'frame33') && (
        <PictureFrame33
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame34') || item?.frame == 'frame34') && (
        <PictureFrame34
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame35') || item?.frame == 'frame35') && (
        <PictureFrame35
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame36') || item?.frame == 'frame36') && (
        <PictureFrame36
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame37') || item?.frame == 'frame37') && (
        <PictureFrame37
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame38') || item?.frame == 'frame38') && (
        <PictureFrame38
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (3.1 * scaleAdjustment) / aspectRatio
              : 3.1 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, -0.7]}
        />
      )}
      {((!item?.frame && frame == 'frame39') || item?.frame == 'frame39') && (
        <PictureFrame39
          scale={[
            aspectRatio < 1 ? 3.6 * scaleAdjustment : 3.6,
            aspectRatio < 1
              ? (2.8 * scaleAdjustment) / aspectRatio
              : 2.8 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.3]}
        />
      )}
      {((!item?.frame && frame == 'frame40') || item?.frame == 'frame40') && (
        <PictureFrame40
          scale={[
            aspectRatio < 1 ? 3.6 * scaleAdjustment : 3.6,
            aspectRatio < 1
              ? (2.8 * scaleAdjustment) / aspectRatio
              : 2.8 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.45]}
        />
      )}
      {((!item?.frame && frame == 'frame41') || item?.frame == 'frame41') && (
        <PictureFrame41
          scale={[
            aspectRatio < 1 ? 3.6 * scaleAdjustment : 3.6,
            aspectRatio < 1
              ? (2.8 * scaleAdjustment) / aspectRatio
              : 2.8 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.42]}
        />
      )}
      {((!item?.frame && frame == 'frame42') || item?.frame == 'frame42') && (
        <PictureFrame42
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (2.8 * scaleAdjustment) / aspectRatio
              : 2.8 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.42]}
        />
      )}
      {((!item?.frame && frame == 'frame43') || item?.frame == 'frame43') && (
        <PictureFrame43
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (2.8 * scaleAdjustment) / aspectRatio
              : 2.8 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.42]}
        />
      )}
      {((!item?.frame && frame == 'frame44') || item?.frame == 'frame44') && (
        <PictureFrame44
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (2.8 * scaleAdjustment) / aspectRatio
              : 2.8 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.42]}
        />
      )}
      {((!item?.frame && frame == 'frame45') || item?.frame == 'frame45') && (
        <PictureFrame45
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (2.8 * scaleAdjustment) / aspectRatio
              : 2.8 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.42]}
        />
      )}
      {((!item?.frame && frame == 'frame46') || item?.frame == 'frame46') && (
        <PictureFrame46
          scale={[
            aspectRatio < 1 ? 3.9 * scaleAdjustment : 3.9,
            aspectRatio < 1
              ? (2.8 * scaleAdjustment) / aspectRatio
              : 2.8 / aspectRatio,
            30,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.42]}
        />
      )}
      {((!item?.frame && frame == 'frame47') || item?.frame == 'frame47') && (
        <PictureFrame47
          scale={[
            aspectRatio < 1 ? 3 * scaleAdjustment : 3,
            aspectRatio < 1
              ? (2.3 * scaleAdjustment) / aspectRatio
              : 2.3 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.42]}
        />
      )}
      {((!item?.frame && frame == 'frame48') || item?.frame == 'frame48') && (
        <PictureFrame48
          scale={[
            aspectRatio < 1 ? 3.3 * scaleAdjustment : 3.3,
            aspectRatio < 1
              ? (2.5 * scaleAdjustment) / aspectRatio
              : 2.5 / aspectRatio,
            20,
          ]}
          position={[-0.03, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.42]}
        />
      )}
      {((!item?.frame && frame == 'frame49') || item?.frame == 'frame49') && (
        <PictureFrame49
          scale={[
            aspectRatio < 1 ? 3.3 * scaleAdjustment : 3.3,
            aspectRatio < 1
              ? (2.5 * scaleAdjustment) / aspectRatio
              : 2.5 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.42]}
        />
      )}
      {((!item?.frame && frame == 'frame50') || item?.frame == 'frame50') && (
        <PictureFrame50
          scale={[
            aspectRatio < 1 ? 2.7 * scaleAdjustment : 2.7,
            aspectRatio < 1
              ? (2.05 * scaleAdjustment) / aspectRatio
              : 2.05 / aspectRatio,
            10,
          ]}
          position={[0.007, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.52]}
        />
      )}
      {((!item?.frame && frame == 'frame51') || item?.frame == 'frame51') && (
        <PictureFrame51
          scale={[
            aspectRatio < 1 ? 2.68 * scaleAdjustment : 2.68,
            aspectRatio < 1
              ? (2.04 * scaleAdjustment) / aspectRatio
              : 2.04 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame52') || item?.frame == 'frame52') && (
        <PictureFrame52
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.01, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame53') || item?.frame == 'frame53') && (
        <PictureFrame53
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio + 0.01, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame54') || item?.frame == 'frame54') && (
        <PictureFrame54
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame55') || item?.frame == 'frame55') && (
        <PictureFrame55
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame56') || item?.frame == 'frame56') && (
        <PictureFrame56
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame57') || item?.frame == 'frame57') && (
        <PictureFrame57
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame58') || item?.frame == 'frame58') && (
        <PictureFrame58
          scale={[
            aspectRatio < 1 ? 2.68 * scaleAdjustment : 2.68,
            aspectRatio < 1
              ? (2.04 * scaleAdjustment) / aspectRatio
              : 2.04 / aspectRatio,
            10,
          ]}
          position={[0.006, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.5]}
        />
      )}
      {((!item?.frame && frame == 'frame59') || item?.frame == 'frame59') && (
        <PictureFrame59
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame60') || item?.frame == 'frame60') && (
        <PictureFrame60
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame61') || item?.frame == 'frame61') && (
        <PictureFrame61
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame62') || item?.frame == 'frame62') && (
        <PictureFrame62
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.45 * scaleAdjustment) / aspectRatio
              : 2.45 / aspectRatio,
            10,
          ]}
          position={[0, (GOLDENRATIO * 0.001) / aspectRatio - 0.0, 0.54]}
        />
      )}
      {((!item?.frame && frame == 'frame63') || item?.frame == 'frame63') && (
        <PictureFrame63
          scale={[
            aspectRatio < 1 ? 3.3 * scaleAdjustment : 3.3,
            aspectRatio < 1
              ? (2.5 * scaleAdjustment) / aspectRatio
              : 2.5 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.1]}
        />
      )}
      {((!item?.frame && frame == 'frame64') || item?.frame == 'frame64') && (
        <PictureFrame64
          scale={[
            aspectRatio < 1 ? 3.3 * scaleAdjustment : 3.3,
            aspectRatio < 1
              ? (2.5 * scaleAdjustment) / aspectRatio
              : 2.5 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame65') || item?.frame == 'frame65') && (
        <PictureFrame65
          scale={[
            aspectRatio < 1 ? 3.3 * scaleAdjustment : 3.3,
            aspectRatio < 1
              ? (2.5 * scaleAdjustment) / aspectRatio
              : 2.5 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame66') || item?.frame == 'frame66') && (
        <PictureFrame66
          scale={[
            aspectRatio < 1 ? 3.3 * scaleAdjustment : 3.3,
            aspectRatio < 1
              ? (2.5 * scaleAdjustment) / aspectRatio
              : 2.5 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame67') || item?.frame == 'frame67') && (
        <PictureFrame67
          scale={[
            aspectRatio < 1 ? 3.3 * scaleAdjustment : 3.3,
            aspectRatio < 1
              ? (2.5 * scaleAdjustment) / aspectRatio
              : 2.5 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame68') || item?.frame == 'frame68') && (
        <PictureFrame68
          scale={[
            aspectRatio < 1 ? 3.3 * scaleAdjustment : 3.3,
            aspectRatio < 1
              ? (2.5 * scaleAdjustment) / aspectRatio
              : 2.5 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame69') || item?.frame == 'frame69') && (
        <PictureFrame69
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.6 * scaleAdjustment) / aspectRatio
              : 2.6 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame70') || item?.frame == 'frame70') && (
        <PictureFrame70
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.6 * scaleAdjustment) / aspectRatio
              : 2.6 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame71') || item?.frame == 'frame71') && (
        <PictureFrame71
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.6 * scaleAdjustment) / aspectRatio
              : 2.6 / aspectRatio,
            20,
          ]}
          position={[0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame72') || item?.frame == 'frame72') && (
        <PictureFrame72
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.6 * scaleAdjustment) / aspectRatio
              : 2.6 / aspectRatio,
            20,
          ]}
          position={[-0.05, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame73') || item?.frame == 'frame73') && (
        <PictureFrame73
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.6 * scaleAdjustment) / aspectRatio
              : 2.6 / aspectRatio,
            20,
          ]}
          position={[-0.02, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}

      {((!item?.frame && frame == 'frame74') || item?.frame == 'frame74') && (
        <PictureFrame74
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.6 * scaleAdjustment) / aspectRatio
              : 2.6 / aspectRatio,
            20,
          ]}
          position={[-0.005, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame75') || item?.frame == 'frame75') && (
        <PictureFrame75
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.6 * scaleAdjustment) / aspectRatio
              : 2.6 / aspectRatio,
            20,
          ]}
          position={[-0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
      {((!item?.frame && frame == 'frame76') || item?.frame == 'frame76') && (
        <PictureFrame76
          scale={[
            aspectRatio < 1 ? 3.2 * scaleAdjustment : 3.2,
            aspectRatio < 1
              ? (2.6 * scaleAdjustment) / aspectRatio
              : 2.6 / aspectRatio,
            20,
          ]}
          position={[-0.001, (GOLDENRATIO * 0.001) / aspectRatio - 0.001, 0.25]}
        />
      )}
    </>
  );
};

export default PictureFrameGroup;
