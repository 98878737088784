import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { FLOORS } from '../../../data/floors';
import { invalidate } from '@react-three/fiber';

const CustomFloor = ({ id }) => {
  const [texturePaths, setTexturePaths] = useState({});
  const [floor, setFloor] = useState(null);

  useEffect(() => {
    const selectedFloor = FLOORS.find((floor) => floor.id === id);
    setFloor(selectedFloor);

    if (selectedFloor) {
      const newTexturePaths = {};

      if (selectedFloor.images.map)
        newTexturePaths.map = selectedFloor.images.map;
      if (selectedFloor.images.normalMap)
        newTexturePaths.normalMap = selectedFloor.images.normalMap;
      if (selectedFloor.images.roughnessMap)
        newTexturePaths.roughnessMap = selectedFloor.images.roughnessMap;
      if (selectedFloor.images.aoMap)
        newTexturePaths.aoMap = selectedFloor.images.aoMap;
      if (selectedFloor.images.displacementMap)
        newTexturePaths.displacementMap = selectedFloor.images.displacementMap;
      if (selectedFloor.images.metalnessMap)
        newTexturePaths.metalnessMap = selectedFloor.images.metalnessMap;
      if (selectedFloor.images.alphaMap)
        newTexturePaths.alphaMap = selectedFloor.images.alphaMap;

      setTexturePaths(newTexturePaths);
    }
  }, [id]);

  const textureProps = useTexture(texturePaths);

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(120, 120);
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current, floor]);

  useEffect(() => {
    invalidate(); // idが変わるたびに再レンダリングを強制する
  }, [id]);

  if (!floor) {
    return null; // floorがまだロードされていないときは何も表示しない
  }

  return (
    <mesh
      key={`floor-${floor.title}`}
      receiveShadow
      rotation={[-Math.PI / 2, 0, 0]}
      ref={mesh}
      position={[0, -0.2, 0]}
    >
      <planeGeometry args={[200, 200]} />
      <meshStandardMaterial
        displacementScale={floor.settings.displacementScale}
        aoMapIntensity={floor.settings.aoMapIntensity}
        roughness={floor.settings.roughness}
        normalScale={
          floor.settings.normalScale
            ? floor.settings.normalScale
            : new THREE.Vector2(1, 1)
        }
        metalness={floor.settings?.metalness ? floor.settings.metalness : 0.0}
        {...textureProps}
      />
    </mesh>
  );
};

export default CustomFloor;
