export const FRAMES = [
  {
    value: '',
    title: 'Use gallery default',
    isPro: false,
    imgUrl: '/assets/img/gallery.png',
  },
  {
    value: 'unframed',
    title: 'Unframed',
    isPro: false,
    imgUrl: '/assets/img/ban.png',
  },
  {
    value: 'black',
    title: 'Black',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/new_black.png',
  },
  {
    value: 'white',
    title: 'White',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/new_white.png',
  },
  {
    value: 'gold',
    title: 'Classic',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/classic.png',
  },
  {
    value: 'frame2',
    title: 'Wooden',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/wooden.png',
  },
  {
    value: 'frame3',
    title: 'Victorian',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/victorian.png',
  },
  {
    value: 'frame4',
    title: 'Gold',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/gold.png',
  },
  {
    value: 'frame5',
    title: 'Old Brass',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/old_brass.png',
  },
  {
    value: 'frame6',
    title: 'Neoclassical',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/neoclassical.png',
  },
  {
    value: 'frame7',
    title: 'carbonfiber',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/carbonfiber.png',
  },
  {
    value: 'frame8',
    title: 'copper',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/copper.png',
  },
  {
    value: 'frame9',
    title: 'aluminum',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/aluminum.png',
  },
  {
    value: 'frame10',
    title: 'ancient terracotta',
    isPro: false,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/ancient_terracotta.png',
  },
  {
    value: 'frame11',
    title: 'black paint',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/black_paint.png',
  },
  {
    value: 'frame12',
    title: 'blue paint',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/blue_paint.png',
  },
  {
    value: 'frame13',
    title: 'dark cork',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/dark_cork.png',
  },
  {
    value: 'frame14',
    title: 'dark ribwood',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/dark_ribwood.png',
  },
  {
    value: 'frame15',
    title: 'nickel',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/nickel.png',
  },
  {
    value: 'frame16',
    title: 'red clay',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/red_clay.png',
  },
  {
    value: 'frame18',
    title: 'Bluespray Black',
    isPro: true,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/bluesprayblack.png',
  },
  {
    value: 'frame19',
    title: 'Bluespray White',
    isPro: true,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/bluespraywhite.png',
  },
  {
    value: 'frame20',
    title: 'Cream',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/cream.png',
  },
  {
    value: 'frame21',
    title: 'Darkgray',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/darkgray.png',
  },
  {
    value: 'frame22',
    title: 'Glass',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/glass.png',
  },
  {
    value: 'frame23',
    title: 'Metallicspots',
    isPro: true,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/metallicspots.png',
  },
  {
    value: 'frame24',
    title: 'Old Basalt',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/old_basalt.png',
  },
  {
    value: 'frame25',
    title: 'Oxidized',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/oxidized.png',
  },
  {
    value: 'frame26',
    title: 'Pink',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/pink.png',
  },
  {
    value: 'frame27',
    title: 'Smooth Rubber',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/smoothrubber.png',
  },
  {
    value: 'frame28',
    title: 'Warmwood',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/warmwood.png',
  },
  {
    value: 'frame29',
    title: 'Woodplain',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/woodplain.png',
  },
  {
    value: 'frame30',
    title: 'Yellow Plasticine',
    isPro: true,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/yellowplasticine.png',
  },
  {
    value: 'frame31',
    title: 'Beige White',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/beigewhite.png',
  },
  {
    value: 'frame32',
    title: 'Blue Silver',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/blue-silver.png',
  },
  {
    value: 'frame33',
    title: 'Bronze Black',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/bronzeblack.png',
  },
  {
    value: 'frame34',
    title: 'Bronze White',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/bronzewhite.png',
  },
  {
    value: 'frame35',
    title: 'Gray Green Brown',
    isPro: true,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/gray-green-brown.png',
  },
  {
    value: 'frame36',
    title: 'Gray Green',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/gray-green.png',
  },
  {
    value: 'frame37',
    title: 'Green Pink',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/greenpink.png',
  },
  {
    value: 'frame38',
    title: 'Heavymetal',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/heavymetal.png',
  },
  {
    value: 'frame39',
    title: 'Midnight',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/midnight.png',
  },
  {
    value: 'frame40',
    title: 'Orange',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/orange.png',
  },
  {
    value: 'frame41',
    title: 'Perforated',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/perforated.png',
  },
  {
    value: 'frame42',
    title: 'Purple Edge',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/purple-edge.png',
  },
  {
    value: 'frame43',
    title: 'Sequins',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/sequins.png',
  },
  {
    value: 'frame44',
    title: 'Sparkle',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/sparkle.png',
  },
  {
    value: 'frame45',
    title: 'Wet Concrete',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/wetconcrete.png',
  },
  {
    value: 'frame46',
    title: 'Yellow Blue',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/yellowblue.png',
  },
  // {
  //   value: 'frame47',
  //   title: 'Jenaframe 1',
  //   isPro: true,
  //   imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/jenaframe1.png',
  // },
  // {
  //   value: 'frame48',
  //   title: 'Jenaframe 2',
  //   isPro: false,
  //   imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/jenaframe2.png',
  // },
  // {
  //   value: 'frame49',
  //   title: 'Jenaframe 3',
  //   isPro: true,
  //   imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/jenaframe3.png',
  // },
  {
    value: 'frame50',
    title: 'Oddframe 1',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/oddframe1.png',
  },
  {
    value: 'frame51',
    title: 'Oddframe 2',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/oddframe2.png',
  },
  {
    value: 'frame52',
    title: 'Slimframe 1',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe1.png',
  },
  {
    value: 'frame53',
    title: 'Slimframe 2',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe2.png',
  },
  {
    value: 'frame54',
    title: 'Slimframe 3',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe3.png',
  },
  {
    value: 'frame55',
    title: 'Slimframe 4',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe4.png',
  },
  {
    value: 'frame56',
    title: 'Slimframe 5',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe5.png',
  },
  {
    value: 'frame57',
    title: 'Slimframe 6',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe6.png',
  },
  {
    value: 'frame58',
    title: 'Oddframe 3',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/oddframe3.png',
  },
  {
    value: 'frame59',
    title: 'Slimframe 7',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe7.png',
  },
  {
    value: 'frame60',
    title: 'Slimframe 8',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe8.png',
  },
  {
    value: 'frame61',
    title: 'Slimframe 9',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe9.png',
  },
  {
    value: 'frame62',
    title: 'Slimframe 10',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/slimframe10.png',
  },
  {
    value: 'frame63',
    title: 'Jenaframe 1',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/jenaframe1.png',
  },
  {
    value: 'frame64',
    title: 'Jenaframe 2',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/jenaframe2.png',
  },
  {
    value: 'frame65',
    title: 'Jenaframe 3',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/jenaframe3.png',
  },
  {
    value: 'frame66',
    title: 'Jenaframe 4',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/jenaframe4.png',
  },
  {
    value: 'frame67',
    title: 'Jenaframe 5',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/jenaframe5.png',
  },
  {
    value: 'frame68',
    title: 'Jenaframe 6',
    isPro: false,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/jenaframe6.png',
  },
  {
    value: 'frame69',
    title: 'Koiframe 1',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/koiframe1.png',
  },
  {
    value: 'frame70',
    title: 'Koiframe 2',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/koiframe2.png',
  },
  {
    value: 'frame71',
    title: 'Koiframe 3',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/koiframe3.png',
  },
  {
    value: 'frame72',
    title: 'Koiframe 4',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/koiframe4.png',
  },
  {
    value: 'frame73',
    title: 'Koiframe 5',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/koiframe5.png',
  },
  {
    value: 'frame74',
    title: 'Koiframe 6',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/koiframe6.png',
  },
  {
    value: 'frame75',
    title: 'Koiframe 7',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/koiframe7.png',
  },
  {
    value: 'frame76',
    title: 'Koiframe 8',
    isPro: true,
    imgUrl: 'https://data.curatedartshow.com/frame_thumbnails/koiframe8.png',
  },
];
