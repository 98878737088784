import axios from 'axios';
import { store } from './redux/store';
import { logout, refreshToken } from './redux/userRedux';
import { errorToast } from './utils/toast';

const BASE_URL = process.env.REACT_APP_API_BASEURL;

const persistedData = localStorage.getItem('persist:root');
const user = persistedData ? JSON.parse(JSON.parse(persistedData).user) : null;
// const TOKEN = user?.tokens?.access_token || '';
const REFRESH_TOKEN = user?.tokens?.refresh_token || '';

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const authRequest = axios.create({
  baseURL: BASE_URL,
});

export const refreshRequest = axios.create({
  baseURL: BASE_URL,
  headers: { Authorization: `Bearer ${REFRESH_TOKEN}` },
});

authRequest.interceptors.request.use(
  function (config) {
    const token = JSON.parse(
      JSON.parse(localStorage.getItem('persist:root')).user
    ).tokens?.access_token;
    const ACCESS_TOKEN = localStorage.getItem('access_token') ?? '';
    const at = ACCESS_TOKEN ? ACCESS_TOKEN : token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + at;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authRequest.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const prevRequest = error?.config;
    //accessToken is expired

    if (error?.response?.status === 401 && !prevRequest._retry) {
      prevRequest._retry = true;
      // refresh token
      try {
        let url = '/auth/refresh';
        if (user?.currentUser?.isAdmin) {
          url = '/admin/refresh';
        }
        console.log('refreshToken:', REFRESH_TOKEN);
        const res = await refreshRequest.post(
          url,
          {},
          {
            headers: { Authorization: `Bearer ${REFRESH_TOKEN}` },
          }
        );

        store.dispatch(refreshToken(res.data));
        prevRequest.headers[
          'Authorization'
        ] = `Bearer ${res.data.access_token}`;
        localStorage.setItem('access_token', res.data.access_token);
        // retry
        return authRequest(prevRequest);
      } catch (err) {
        localStorage.removeItem('access_token');
        //refresh token is also expired
        store.dispatch(logout());
        const lang = localStorage.getItem('i18nextLng');
        lang == 'ja' ?
          errorToast('ログインセッションの有効期限が切れました。') : errorToast('Login session has expired.');
        window.location.href = '/signin';
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
