import React, { useEffect, useState, useMemo, useRef } from 'react';
import { PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { authRequest, publicRequest } from '../../requestMethods';
import { updateProfile } from '../../redux/userRedux';
import { errorToast, successToast } from '../../utils/toast';
import LoadingSpinner from '../../components/LoadingSpinner';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import { useDropzone } from 'react-dropzone';
import {
  validateDisplayname,
  validateAboutOnLobby,
  validateWebsiteURL,
  validateUsername,
  validateInquiryEmail,
} from '../../utils/validation';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { getRegionCountries } from '../../const';

const EditProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  // フォーム
  const [profile, setProfile] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [username, setUsername] = useState('');
  const [galleryLogo, setGalleryLogo] = useState(null);
  const [aboutOnLobby, setAboutOnLobby] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [inquiryEmail, setInquiryEmail] = useState('');
  // フォームエラー
  const [displayNameError, setDisplayNameError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [galleryLogoError, setGalleryLogoError] = useState(null);
  const [aboutOnLobbyError, setAboutOnLobbyError] = useState('');
  const [websiteUrlError, setWebsiteUrlError] = useState('');
  const [profileError, setProfileError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [profilePreview, setProfilePreview] = useState(null);
  const [galleryLogoPreview, setGalleryLogoPreview] = useState(null);
  const [regionList, setRegionList] = useState();
  const galleryLogoRef = useRef(null);
  const { isLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { COUNTRIES, COUNTRY_REGION } = getRegionCountries(i18n.language);

  useEffect(() => {
    setDisplayName(user?.displayName);
    setAboutOnLobby(user?.bio ?? '');
    setWebsiteUrl(user?.websiteUrl);
    setProfileImageUrl(user?.profileImageUrl);
    if (user?.profileImageUrl) {
      setProfilePreview(user?.profileImageUrl);
    }
    if (user?.logoImg) {
      setGalleryLogoPreview(user?.logoImg);
    }
    setInquiryEmail(user?.inquiryEmail || '');
    setUsername(user?.username);
    setCountry(user.country);
    const selectedData = COUNTRY_REGION.find(
      (item) => item.country === user.country
    );
    if (selectedData) {
      setRegionList(selectedData.region);
    }
    setRegion(user.region);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    let s3ProfileRes = null;
    if (profile) {
      s3ProfileRes = await publicRequest.post(
        `/s3/`,
        {
          file: profile,
          key: `${user.id}/profile/`,
          fileType: profile.name.split('.').pop(),
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    }

    let s3GalleryLogoRes = null;
    if (galleryLogo) {
      s3GalleryLogoRes = await publicRequest.post(
        `/s3`,
        {
          file: galleryLogo,
          key: `${user.id}/galleryLogo/`,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    }

    try {
      const res = await authRequest.patch('/users', {
        displayName: displayName,
        username,
        bio: aboutOnLobby,
        inquiryEmail,
        websiteUrl,
        profileImageUrl: s3ProfileRes
          ? s3ProfileRes.data.replace(
              'curatedartshow-data.s3.amazonaws.com',
              'data.curatedartshow.com'
            )
          : profileImageUrl,
        logoImg: s3GalleryLogoRes
          ? s3GalleryLogoRes.data.replace(
              'curatedartshow-data.s3.amazonaws.com',
              'data.curatedartshow.com'
            )
          : galleryLogoPreview,
        country,
        region,
      });

      dispatch(loadEnd());
      dispatch(updateProfile(res.data));
      navigate('/dashboard');
      successToast(t('message.success.t1'));
    } catch (err) {
      dispatch(loadEnd());
      console.log(err);
      if (err.response.data.message.includes('Lobby Title')) {
        setDisplayNameError(err.response.data.message);
      } else if (err.response.data.message.includes('lobby ID')) {
        setUsernameError(err.response.data.message);
      } else {
        errorToast(err.response.data.message);
      }
    }
  };

  // ファイルバリデーション - 拡張子
  const isAcceptableFile = (file) => {
    const acceptableExtensions = {
      image: ['gif', 'jpg', 'jpeg', 'png', 'webp'],
    };
    const fileExtension = file.split('.').pop().toLowerCase();
    return acceptableExtensions.image.indexOf(fileExtension) !== -1;
  };

  // ファイルバリデーション - サイズ
  const isAcceptableFileSize = (file) => {
    const maxImageSize = 10000000;
    return file.size < maxImageSize;
  };

  // Profile プレビュー
  const previewImage = async (obj, kind) => {
    let fileReader = new FileReader();

    fileReader.onload = function () {
      if (kind == 'profile') {
        setProfilePreview(fileReader.result);
      } else {
        setGalleryLogoPreview(fileReader.result);
      }
    };
    try {
      fileReader.readAsDataURL(obj);
    } catch (err) {
      console.log(err);
    }
  };

  // Profile
  const onDrop = async (draggedFile) => {
    const isValidFile = isAcceptableFile(draggedFile[0].name);
    const isValidFileSize = isAcceptableFileSize(draggedFile[0]);
    if (!isValidFile) {
      const message =
        i18n.language == 'ja'
          ? '許可されていないファイルです'
          : 'File type not allowed';
      return setProfileError(message);
    }
    if (!isValidFileSize) {
      const message =
        i18n.language == 'ja'
          ? 'ファイルサイズが最大制限の10MBを超えています'
          : 'File size exceeds maximum limit 10 MB';
      return setProfileError(message);
    }
    await previewImage(draggedFile[0], 'profile');
    setProfile(draggedFile[0]);
    setProfileError('');
  };
  const {
    getRootProps: getRootPropsProfile,
    getInputProps: getInputPropsProfile,
  } = useDropzone({ onDrop, multiple: false });

  // Display Name
  const reflectDisplayName = (displayname) => {
    const result = validateDisplayname(displayname);
    result.isValid
      ? setDisplayNameError('')
      : setDisplayNameError(result.message);
    setDisplayName(displayname);
  };

  // Username for lobby URL
  const reflectUsername = (username) => {
    const result = validateUsername(username);
    result.isValid ? setUsernameError('') : setUsernameError(result.message);
    setUsername(username);
  };

  const handleGalleryLogoInput = async (event) => {
    const file = event.target.files[0];
    const isValidFile = isAcceptableFile(file.name);
    const isValidFileSize = isAcceptableFileSize(file);
    if (!isValidFile) {
      const message =
        i18n.language == 'ja'
          ? '許可されていないファイルです'
          : 'File type not allowed';
      galleryLogoRef.current.value = '';
      return setGalleryLogoError(message);
    }
    if (!isValidFileSize) {
      const message =
        i18n.language == 'ja'
          ? 'ファイルサイズが最大制限の10MBを超えています'
          : 'File size exceeds maximum limit 10 MB';
      galleryLogoRef.current.value = '';
      return setGalleryLogoError(message);
    }
    setGalleryLogo(file);
    setGalleryLogoError('');

    await previewImage(file, 'galleryLogo');
  };

  // About on lobby
  const reflectAboutOnLobby = (aboutOnLobby) => {
    const result = validateAboutOnLobby(aboutOnLobby);
    setAboutOnLobbyError(result.message);
    setAboutOnLobby(aboutOnLobby);
  };

  // Website URL
  const reflectWebsiteURL = (url) => {
    const result = validateWebsiteURL(url);
    result.isValid
      ? setWebsiteUrlError('')
      : setWebsiteUrlError(result.message);
    setWebsiteUrl(url);
  };

  // Inquiry Email
  const reflectEmail = (inquiryEmail) => {
    const result = validateInquiryEmail(inquiryEmail);
    result.isValid ? setEmailError('') : setEmailError(result.message);
    setInquiryEmail(inquiryEmail);
  };

  const handleCountryChange = (country) => {
    setCountry(country);
    const selectedData = COUNTRY_REGION.find(
      (item) => item.country === country
    );
    if (selectedData) {
      setRegionList(selectedData.region);
    }
  };

  const hasError = useMemo(() => {
    // requiredを満たしているか
    const isFilled = !!displayName && !!username;
    const hasErrorMessage =
      !!displayNameError ||
      !!usernameError ||
      !!aboutOnLobbyError ||
      !!websiteUrlError ||
      !!profileError ||
      !!emailError;

    return hasErrorMessage || !isFilled ? true : false;
  }, [displayName, username, aboutOnLobby, websiteUrl, inquiryEmail]);

  return (
    <>
      <div className='mx-auto max-w-screen-sm px-4 pb-32 pt-10 lg:px-0 lg:pb-44 lg:pt-[4.5rem]'>
        <h2 className='text-3xl font-extrabold text-base-content'>
          <Trans i18nKey={'profile.edit-ttl'} />
        </h2>
        {/* Profile Picture */}
        <div className='mt-8'>
          <p className='px-1 py-2 text-sm font-medium'>
            <Trans i18nKey={'profile.pic'} /> (
            <Trans i18nKey={'label.option'} />)
          </p>
          <div className='flex items-center gap-4'>
            {profilePreview || profileImageUrl ? (
              <div className='avatar'>
                <div className='w-24 rounded-full'>
                  <img
                    src={`${profilePreview ? profilePreview : profileImageUrl}`}
                    alt={displayName}
                  />
                </div>
              </div>
            ) : (
              <div className='bg-custom-gradient-purple flex h-24 w-24 items-center justify-center rounded-full'>
                <PhotoIcon className='h-[55px] w-[55px] stroke-1' />
              </div>
            )}
            <button className='btn btn-sm' {...getRootPropsProfile()}>
              <Trans i18nKey={'profile.change-photo'} />
            </button>
          </div>
          <p className='mt-4 text-sm text-[#2B2C34]'>
            <Trans i18nKey={'profile.pic-desc'} />
          </p>
          <p className='text-sm text-error'>{profileError}</p>
        </div>
        <div className='mt-10 flex flex-col gap-8'>
          {/* Lobby Title */}
          <label className='form-control w-full max-w-[22.5rem]'>
            <div className='label'>
              <span className='label-text font-medium'>
                <Trans i18nKey={'profile.lobby-title'} />
              </span>
            </div>
            <input
              type='text'
              placeholder={
                i18n.language === 'ja' ? 'ロビータイトル' : 'Lobby Title'
              }
              className={`input input-bordered w-full max-w-[22.5rem] text-sm placeholder:text-sm ${
                displayNameError ? 'input-error' : ''
              }`}
              maxLength='46'
              value={displayName}
              onChange={(e) => reflectDisplayName(e.target.value)}
            />
            <div className='label flex-col items-start'>
              <span className='label-text-alt text-error'>
                {displayNameError}
              </span>
              <p className='label-text-alt'>
                <Trans i18nKey={'profile.lobby-title-desc'} />
              </p>
            </div>
          </label>

          {/* Lobby ID */}
          <label className='form-control w-full max-w-[22.5rem]'>
            <div className='label'>
              <span className='label-text font-medium'>
                <Trans i18nKey={'profile.lobby-id'} />
              </span>
            </div>
            <input
              type='text'
              placeholder={i18n.language === 'ja' ? 'ロビーID' : 'Lobby ID'}
              className={`input input-bordered w-full max-w-[22.5rem] text-sm placeholder:text-sm ${
                usernameError ? 'input-error' : ''
              }`}
              maxLength='20'
              value={username}
              onChange={(e) => reflectUsername(e.target.value)}
            />
            <div className='label flex-col items-start'>
              <span className='label-text-alt text-error'>{usernameError}</span>
              <p className='label-text-alt'>
                <Trans i18nKey={'profile.lobby-id-desc'} />
              </p>
            </div>
          </label>

          {/* About You */}
          <label className='form-control'>
            <div className='label'>
              <span className='label-text font-medium'>
                <Trans i18nKey={'profile.about'} />
              </span>
            </div>
            <textarea
              className={`textarea textarea-bordered text-sm placeholder:text-sm ${
                aboutOnLobbyError ? 'textarea-error' : ''
              }`}
              rows='6'
              maxLength='3000'
              placeholder={t('placeholder.type-here')}
              value={aboutOnLobby}
              onChange={(e) => reflectAboutOnLobby(e.target.value)}
            ></textarea>
            <div className='label'>
              <span className='label-text-alt text-error'>
                {aboutOnLobbyError}
              </span>
              <span className='label-text-alt'>
                {aboutOnLobby?.length ?? 0}/3000
              </span>
            </div>
          </label>

          {/* Country */}
          <label className='form-control w-full max-w-[22.5rem]'>
            <div className='label'>
              <span className='label-text font-medium'>
                <Trans i18nKey={'profile.country'} />
              </span>
            </div>
            <select
              className='select select-bordered'
              onChange={(e) => handleCountryChange(e.target.value)}
              value={country}
            >
              {COUNTRIES.map((country) => {
                return (
                  <option value={country} key={country}>
                    {country}
                  </option>
                );
              })}
            </select>
          </label>

          {/* Region */}
          <label className='form-control w-full max-w-[22.5rem]'>
            <div className='label'>
              <span className='label-text font-medium'>
                <Trans i18nKey={'profile.region'} />
              </span>
            </div>
            <select
              className='select select-bordered'
              onChange={(e) => setRegion(e.target.value)}
              value={region}
            >
              {regionList?.map((region) => {
                return (
                  <option value={region} key={region}>
                    {region}
                  </option>
                );
              })}
            </select>
          </label>

          {/* Gallery Logo */}
          <div>
            {galleryLogoPreview ? (
              <>
                <p className='px-1 py-2 text-sm font-medium'>
                  <Trans i18nKey={'profile.logo'} /> (
                  <Trans i18nKey={'label.option'} />)
                </p>
                <div className='flex items-center gap-4'>
                  <div className='px-6 py-5 lg:px-12'>
                    <img
                      src={galleryLogoPreview}
                      className='h-28 w-40 object-contain'
                    />
                  </div>
                  <div className='flex flex-col gap-4'>
                    <button
                      className='btn btn-sm'
                      onClick={() => {
                        setGalleryLogoPreview(null);
                        setGalleryLogoError('');
                      }}
                    >
                      <TrashIcon className='h-5 w-5' />
                      <Trans i18nKey={'btn.delete'} />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <label className='form-control w-full max-w-[25rem]'>
                  <div className='label'>
                    <span className='label-text font-medium'>
                      <Trans i18nKey={'profile.logo'} /> (
                      <Trans i18nKey={'label.option'} />)
                    </span>
                  </div>
                  <input
                    ref={galleryLogoRef}
                    type='file'
                    className='file-input file-input-bordered w-full'
                    onChange={handleGalleryLogoInput}
                    accept='.jpg, .jpeg, .png, .webp'
                  />
                  <div className='label block'>
                    <p className='label-text-alt'>
                      <Trans i18nKey={'profile.logo-desc'} />
                    </p>
                    <p className='label-text-alt text-error'>
                      {galleryLogoError}
                    </p>
                  </div>
                </label>
              </>
            )}
          </div>

          {/* Website URL */}
          <label className='form-control w-full max-w-[25rem]'>
            <div className='label'>
              <span className='label-text font-medium'>
                <Trans i18nKey={'profile.website'} /> (
                <Trans i18nKey={'label.option'} />)
              </span>
            </div>
            <input
              type='url'
              maxLength='255'
              placeholder={
                i18n.language === 'ja'
                  ? 'あなたのウェブサイトURL'
                  : 'Your website URL'
              }
              className={`input input-bordered w-full max-w-[25rem] text-sm placeholder:text-sm ${
                websiteUrlError ? 'input-error' : ''
              }`}
              value={websiteUrl?.length > 0 ? websiteUrl : ''}
              onChange={(e) => reflectWebsiteURL(e.target.value)}
            />
            <div className='label'>
              <span className='label-text-alt text-error'>
                {websiteUrlError}
              </span>
            </div>
          </label>

          {/* Inquiry Email */}
          <label className='form-control w-full'>
            <div className='label'>
              <span className='label-text font-medium'>
                <Trans i18nKey={'profile.inquiry-email'} />
              </span>
            </div>
            <input
              type='text'
              placeholder={t('placeholder.type-here')}
              className={`input input-bordered w-full max-w-xs text-sm placeholder:text-sm ${
                emailError ? 'input-error' : ''
              }`}
              maxLength='255'
              value={inquiryEmail}
              onChange={(e) => reflectEmail(e.target.value)}
            />
            <div className='label block'>
              <p className='label-text-alt text-error'>{emailError}</p>
              <p className='label-text-alt'>
                <Trans i18nKey={'profile.inquiry-email-desc'} />
              </p>
            </div>
          </label>
        </div>
        <div className='divider my-8'></div>
        <div className='flex justify-end'>
          <button
            className={`btn btn-primary`}
            disabled={hasError}
            onClick={handleSubmit}
          >
            <Trans i18nKey={'btn.save'} />
          </button>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default EditProfile;
