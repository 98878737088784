import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';

export const ExhibitionViewsFromAllCountries = ({
  GA4ExhibitionData,
  isLoading,
  isVIP,
}) => {
  const [rankedData, setRankedData] = useState([]);

  useEffect(() => {
    if (GA4ExhibitionData) {
      // 表示データを設定
      const flatData = [];
      GA4ExhibitionData.forEach((item) => {
        item.exhibitions.forEach((exhibition) => {
          // 必要に応じてフィルタリング
          if (exhibition.totalViews > 0 || exhibition.totalUsers > 0) {
            flatData.push({
              country: item.country,
              title: exhibition.title,
              totalViews: exhibition.totalViews,
              totalUsers: exhibition.totalUsers,
            });
          }
        });
      });
      // ビュー数でソート（降順）
      const sortedData = flatData.sort((a, b) => b.totalViews - a.totalViews);
      // ランクを割り当て
      const ranked = sortedData.map((item, index) => ({
        rank: index + 1,
        ...item,
      }));
      setRankedData(ranked);
    }
  }, [GA4ExhibitionData]);

  return (
    <div className='mt-6 overflow-x-auto'>
      <table className='table'>
        {/* head */}
        <thead>
          <tr>
            <th>
              <Trans i18nKey={'analytics.rank'} />
            </th>
            <th className='min-w-[180px]'>
              <Trans i18nKey={'analytics.exhibition'} />
            </th>
            <th>
              <Trans i18nKey={'analytics.country'} />
            </th>
            <th>
              <Trans i18nKey={'analytics.views'} />
            </th>
            {isVIP && (
              <th>
                <Trans i18nKey={'analytics.uu'} />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {!isLoading ? (
            rankedData.length > 0 ? (
              rankedData.map((row, index) => {
                return (
                  <tr
                    key={`ExhibitionViewsFromAllCountries-${row.country}-${row.title}_${index}`}
                  >
                    <td className='font-bold'>{row.rank}</td>
                    <td className='font-bold'>{row.title}</td>
                    <td>{row.country}</td>
                    <td>{row.totalViews.toLocaleString()}</td>
                    {isVIP && <td>{row.totalUsers.toLocaleString()}</td>}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3} className='text-center'>
                  <Trans i18nKey={'analytics.no-data'} />
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td className='loading loading-spinner loading-xs text-primary'></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
