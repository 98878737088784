import { Clone, useGLTF } from '@react-three/drei';
import { useEffect, useLayoutEffect } from 'react';

const WhitePillar = ({ ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/whitePillar.glb'
  );

  useEffect(() => {
    return () => {
      //リソースのクリーンアップ処理
      useGLTF.clear('https://data.curatedartshow.com/models/whitePillar.glb');
    };
  }, []);

  useLayoutEffect(() => {
    // materials['Street_Light_Mat'].color.set('#555');
    // materials['Glass'].color.set('#fff');

    materials['Rough White Stitch_material'].roughness = 0.5;
    materials['Rough White Stitch_material'].metalness = 0.5;
    // materials['Glass'].emissive.set('white');

    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });

    // return () => {
    //   // マテリアルの解放
    //   Object.values(materials).forEach((material) => {
    //     material.dispose();
    //   });

    //   // シーンからオブジェクトの削除
    //   scene.traverse((obj) => {
    //     if (obj.isMesh) {
    //       obj.geometry.dispose();
    //       obj.material.dispose();
    //       scene.remove(obj);
    //     }
    //   });
    // };
  }, []);

  return <Clone object={scene} {...props} castShadow />;
};

export default WhitePillar;
