import { ArrowsPointingOutIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import { publicRequest } from '../../../requestMethods';
import parse from 'html-react-parser';
import FullScreenImg from '../../../components/item/FullScreenImg';

const ArtworkModal = ({
  item,
  setShowModal,
  openMatureContentModal,
  removeMatureContentLabel,
}) => {
  const [galleries, setGalleries] = useState([]);
  const [showFullScreenImg, setShowFullScreenImg] = useState(null);

  const itemModalRef = useRef();
  const closeModal = () => {
    document.body.classList.remove('is-scrollLocked');
    setShowModal(false);
  };

  useEffect(() => {
    const getGalleries = async () => {
      const galleryData = await publicRequest.get(
        `/rooms/galleries-has-item/${item?.id}`
      );
      setGalleries(galleryData.data);
    };
    getGalleries();
  }, []);

  return (
    <>
      <div
        className='fixed left-0 top-0 z-[1000] h-full w-full bg-transparent'
        onClick={(e) => {
          e.stopPropagation();
          closeModal();
        }}
      ></div>

      <div ref={itemModalRef}>
        <div className='fixed bottom-0 left-1/2 z-[1001] flex h-[95%] w-full -translate-x-1/2 transform flex-col items-center rounded-xl border-2 border-gray-300 bg-white/70 bg-opacity-60 p-1 text-black before:absolute before:top-0 before:z-[-1] before:h-full before:w-full before:overflow-hidden before:rounded-xl before:backdrop-blur-2xl before:content-[""] md:top-1/2 md:!h-[90vh] md:!w-[90vw] md:-translate-y-1/2 md:p-5'>
          <div className='flex w-full flex-col overflow-hidden md:flex-row'>
            {/* left */}
            <div className='cas-scrollbar hidden w-full flex-col items-center overflow-y-scroll px-4 py-2 md:flex md:w-1/3'>
              <div
                className='relative mb-5 max-h-[90%]'
                onClick={(event) => {
                  event.stopPropagation();
                  item?.itemUrl
                    ? setShowFullScreenImg(item.itemUrl)
                    : setShowFullScreenImg('/assets/img/artwork_dummy.png');
                }}
              >
                <img
                  className='m-full w-full max-w-full cursor-pointer object-contain lg:max-h-full'
                  src={
                    item?.itemUrl
                      ? item.itemUrl
                      : '/assets/img/artwork_dummy.png'
                  }
                  alt=''
                />
                {item?.itemUrl && (
                  <ArrowsPointingOutIcon
                    className='absolute bottom-0 right-0 h-7 w-7 cursor-pointer bg-primary p-1 text-white'
                    onClick={() =>
                      item?.itemUrl
                        ? setShowFullScreenImg(item.itemUrl)
                        : setShowFullScreenImg('/assets/img/artwork_dummy.png')
                    }
                  />
                )}
              </div>
              <div className='grid w-full grid-cols-3 gap-4'>
                {item.otherImage1Url && (
                  <div className='flex flex-col gap-2'>
                    <div
                      className='aspect-square cursor-pointer overflow-hidden'
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowFullScreenImg(item.otherImage1Url);
                      }}
                    >
                      <img
                        src={item.otherImage1Url}
                        className='h-full w-full object-cover'
                      />
                    </div>
                    <p className='line-clamp-1 text-xs'>
                      {item.otherImage1Title}
                    </p>
                  </div>
                )}
                {item.otherImage2Url && (
                  <div className='flex flex-col gap-2'>
                    <div
                      className='aspect-square cursor-pointer overflow-hidden'
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowFullScreenImg(item.otherImage2Url);
                      }}
                    >
                      <img
                        src={item.otherImage2Url}
                        className='h-full w-full object-cover'
                      />
                    </div>
                    <p className='line-clamp-1 text-xs'>
                      {item.otherImage2Title}
                    </p>
                  </div>
                )}
                {item.otherImage3Url && (
                  <div className='flex flex-col gap-2'>
                    <div
                      className='aspect-square cursor-pointer overflow-hidden'
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowFullScreenImg(item.otherImage3Url);
                      }}
                    >
                      <img
                        src={item.otherImage3Url}
                        className='h-full w-full object-cover'
                      />
                    </div>
                    <p className='line-clamp-1 text-xs'>
                      {item.otherImage3Title}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* right */}
            <div className='col-span-2 flex h-full w-full flex-col px-4 py-2 md:w-2/3'>
              <div className='flex flex-col p-2'>
                {item?.isMatureContent && (
                  <div className='badge !badge-warning'>Mature Content</div>
                )}
                <h1 className='text-4xl font-bold'>
                  {item?.title ? item.title : 'Untitled Artwork'}
                </h1>
              </div>
              <div className='cas-scrollbar overflow-y-auto overflow-x-hidden overscroll-contain p-3'>
                {/* mobile only start */}
                <div className='my-5 flex justify-center md:hidden'>
                  <div
                    className='relative'
                    onClick={(event) => {
                      event.stopPropagation();
                      item?.itemUrl
                        ? setShowFullScreenImg(item.itemUrl)
                        : setShowFullScreenImg('/assets/img/artwork_dummy.png');
                    }}
                  >
                    <img
                      className='pointer-events-none w-full object-contain'
                      src={
                        item?.itemUrl
                          ? item.itemUrl
                          : '/assets/img/artwork_dummy.png'
                      }
                      alt=''
                    />
                    {item?.itemUrl && (
                      <ArrowsPointingOutIcon
                        className='absolute bottom-0 right-0 h-7 w-7 cursor-pointer bg-primary p-1 text-white'
                        onClick={(event) => {
                          event.stopPropagation();
                          item?.itemUrl
                            ? setShowFullScreenImg(item.itemUrl)
                            : setShowFullScreenImg(
                                '/assets/img/artwork_dummy.png'
                              );
                        }}
                      />
                    )}
                  </div>
                </div>
                {/* Other Images */}
                <div className='grid w-full grid-cols-3 gap-4 md:hidden'>
                  {item.otherImage1Url && (
                    <div className='flex flex-col gap-2'>
                      <div
                        className='aspect-square cursor-pointer overflow-hidden'
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowFullScreenImg(item.otherImage1Url);
                        }}
                      >
                        <img
                          src={item.otherImage1Url}
                          className='h-full w-full object-cover'
                        />
                      </div>
                      <p className='line-clamp-1 text-xs'>
                        {item.otherImage1Title}
                      </p>
                    </div>
                  )}
                  {item.otherImage2Url && (
                    <div className='flex flex-col gap-2'>
                      <div
                        className='aspect-square cursor-pointer overflow-hidden'
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowFullScreenImg(item.otherImage2Url);
                        }}
                      >
                        <img
                          src={item.otherImage2Url}
                          className='h-full w-full object-cover'
                        />
                      </div>
                      <p className='line-clamp-1 text-xs'>
                        {item.otherImage2Title}
                      </p>
                    </div>
                  )}
                  {item.otherImage3Url && (
                    <div className='flex flex-col gap-2'>
                      <div
                        className='aspect-square cursor-pointer overflow-hidden'
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowFullScreenImg(item.otherImage3Url);
                        }}
                      >
                        <img
                          src={item.otherImage3Url}
                          className='h-full w-full object-cover'
                        />
                      </div>
                      <p className='line-clamp-1 text-xs'>
                        {item.otherImage3Title}
                      </p>
                    </div>
                  )}
                </div>
                {/* Description */}
                <p className='py-4 text-sm text-black'>
                  {parse(item?.description.replaceAll('\n', '<br />'))}
                </p>
                {/* Original Created */}
                {item.createdYear && (
                  <div className='mb-6'>
                    <p className='font-bold text-black'>Original Created</p>
                    <p className='text-black'>{item.createdYear}</p>
                  </div>
                )}
                {/* Location */}
                <div className='mb-6'>
                  <p className='font-bold text-black'>Location</p>
                  <p className='text-black'>{item?.owner?.displayName}</p>
                </div>
                {item?.isMatureContent ? (
                  <div className='mt-6'>
                    <button
                      className='btn !btn-warning'
                      onClick={() => removeMatureContentLabel(item.id)}
                    >
                      Remove mature content label
                    </button>
                  </div>
                ) : (
                  <div className='mt-6'>
                    <button
                      className='btn !btn-warning'
                      onClick={() => openMatureContentModal(item.id)}
                    >
                      Mark as mature content
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            className='btn absolute bottom-5 right-5'
            onClick={closeModal}
          >
            Close
          </button>
        </div>
        {showFullScreenImg && (
          <FullScreenImg
            onClose={() => setShowFullScreenImg(null)}
            imgUrl={showFullScreenImg}
          />
        )}
      </div>
    </>
  );
};

export default ArtworkModal;
