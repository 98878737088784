import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';

const BluePaintRoomFloor = ({ id }) => {
  const textureProps = useTexture({
    map: `https://data.curatedartshow.com/texture/floors/PaleBlueBrushPaint/basecolor.png`,
    normalMap: `https://data.curatedartshow.com/texture/floors/PaleBlueBrushPaint/normal.png`,
    roughnessMap: `https://data.curatedartshow.com/texture/floors/PaleBlueBrushPaint/roughness.png`,
    metalnessMap: `https://data.curatedartshow.com/texture/floors/PaleBlueBrushPaint/metallic.png`,
    displacementMap: `https://data.curatedartshow.com/texture/floors/PaleBlueBrushPaint/height.png`,
    aoMap: `https://data.curatedartshow.com/texture/floors/PaleBlueBrushPaint/ao.png`,
  });

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(120, 120);
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current]);

  return (
    <mesh
      receiveShadow
      rotation={[-Math.PI / 2, 0, 0]}
      ref={mesh}
      position={[0, -0.2, 0]}
    >
      <planeGeometry args={[200, 200]} />
      <meshStandardMaterial
        displacementScale={0.5}
        aoMapIntensity={5}
        roughness={3}
        normalScale={new THREE.Vector2(6, 1)}
        metalness={0}
        {...textureProps}
      />
    </mesh>
  );
};

export default BluePaintRoomFloor;
