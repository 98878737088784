import { Trans } from 'react-i18next';
const ANIMATION = [
  {
    value: 0,
    isFree: true,
    imgUrl: 'transition1.png',
    name: 'default',
  },
  {
    value: 1,
    isFree: true,
    imgUrl: 'transition1.png',
    name: '1',
  },
  {
    value: 2,
    isFree: false,
    imgUrl: 'transition2.png',
    name: '2',
  },
  {
    value: 3,
    isFree: false,
    imgUrl: 'transition3.png',
    name: '3',
  },
  {
    value: 4,
    isFree: false,
    imgUrl: 'transition4.png',
    name: '4',
  },
];

const TransitionAnimationSettings = ({ animationType, setAnimationType }) => {
  return (
    <div className='flex flex-col gap-2'>
      {/* Picture Frame */}
      <div className='collapse collapse-arrow bg-base-200'>
        <input type='checkbox' className='peer' />
        <div className='collapse-title font-bold text-xl'>
          <Trans i18nKey={'gallery.transition-animation'} />
        </div>
        <div className='collapse-content '>
          <div className='mb-5'>
            {ANIMATION.map((item) => (
              <div className='form-control' key={item.name}>
                <label className='label cursor-pointer justify-start gap-2'>
                  <input
                    type='radio'
                    className='radio'
                    id={item.name}
                    value={item.value}
                    onChange={(e) => setAnimationType(Number(e.target.value))}
                    name='animation'
                    checked={item.value == animationType}
                  />
                  <span className='label-text'>{item.name}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransitionAnimationSettings;
