import {
  BakeShadows,
  Cloud,
  Environment,
  OrbitControls,
  useHelper,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useRef } from 'react';
import Loader from '../Loader';
import Items from '../Items';

import Woman1 from '../models/Woman1';
import Kids1 from '../models/Kids1';
import Woman2 from '../models/Woman2';
import Woman3 from '../models/Woman3';
import Man1 from '../models/Man1';
import Man2 from '../models/Man2';
import Man3 from '../models/Man3';
import Kids2 from '../models/Kids2';
import Kids3 from '../models/Kids3';
import BasicFloor from '../floors/BasicFloor';
import Light1 from '../lights/Light1';
import Light2 from '../lights/Light2';
import Light3 from '../lights/Light3';
import Light4 from '../lights/Light4';
import Light5 from '../lights/Light5';
import Light6 from '../lights/Light6';
import Light7 from '../lights/Light7';
import Light8 from '../lights/Light8';
import Light9 from '../lights/Light9';
import ObjectLight1 from '../models/objectLights/ObjectLight1';
import ObjectLight2 from '../models/objectLights/ObjectLight2';
import ObjectLight3 from '../models/objectLights/ObjectLight3';
import CustomFloor from '../floors/CustomFloor';
import CustomWall from '../walls/CustomWall';
import CustomCeiling from '../ceilings/CustomCeiling';
import MovingSpotLight from '../lights/MovingSpotLight';
import * as THREE from 'three';
import CyberPunkFloor from '../floors/CyberPunkFloor';
import ClassicFloor from '../floors/ClassicFloor';

// import MovingSpotLight from '../lights/MovingSpotLight';

const CustomColorRoom = ({
  bgColor,
  room,
  roomSettings,
  items,
  currentItemId,
  setCurrentItemId,
  isActive,
  setIsActive,
  setSelectedItem,
  setCurrentItem,
  setIsLoading,
  zoomItemImageUrl,
  setZoomItemImageUrl,
  showInfo,
  selectedItem,
  inquiryItem,
  setInquiryItem,
  mode,
}) => {
  const floorColor = '#333333';
  const directLightIntensity =
    roomSettings?.floor == 4 ||
    roomSettings?.floor == 6 ||
    roomSettings?.floor == 11
      ? 1
      : 3.5;
  const ambientLightIntensity = 1;

  //lightのdebug用
  // const dirLight = useRef(null);
  // useHelper(dirLight, THREE.DirectionalLightHelper, '#fff');

  return (
    <>
      {/* <OrbitControls /> */}
      <BakeShadows />
      <EffectComposer>
        <Bloom intensity={0.1} />
      </EffectComposer>
      <Suspense fallback={<Loader />}>
        <color attach='background' args={[`${bgColor}`]} />
        <fog attach='fog' args={[`${bgColor}`, 6, 15]} />
        <directionalLight
          // ref={dirLight}
          position={[0, 2, 20]}
          target-position={[0, 2.5, 0]}
          intensity={
            roomSettings?.directionalLightIntensity != null
              ? roomSettings?.directionalLightIntensity * 0.5
              : directLightIntensity
          }
          color={
            roomSettings?.directionalLightColorCode &&
            roomSettings?.directionalLightIntensity > 0
              ? roomSettings.directionalLightColorCode
              : '#ffffff'
          }
          castShadow
        />
        <ambientLight
          intensity={
            roomSettings?.directionalLightIntensity != null
              ? roomSettings?.directionalLightIntensity * 0.1
              : ambientLightIntensity
          }
          color={
            roomSettings?.directionalLightColorCode &&
            roomSettings?.directionalLightIntensity > 0
              ? roomSettings.directionalLightColorCode
              : '#ffffff'
          }
        />
        {roomSettings?.light === 1 && (
          <Light1 color={roomSettings.lightColor} length={items?.length} />
        )}
        {roomSettings?.light === 2 && (
          <Light2 color={roomSettings.lightColor} length={items?.length} />
        )}
        {roomSettings?.light === 3 && (
          <Light3 color={roomSettings.lightColor} length={items?.length} />
        )}
        {roomSettings?.light === 4 && (
          <Light4 color={roomSettings.lightColor} length={items?.length} />
        )}
        {roomSettings?.light === 5 && (
          <Light5 color={roomSettings.lightColor} length={items?.length} />
        )}
        {roomSettings?.light === 6 && (
          <Light6 color={roomSettings.lightColor} length={items?.length} />
        )}
        {roomSettings?.light === 7 && (
          <Light7 color={roomSettings.lightColor} length={items?.length} />
        )}
        {roomSettings?.light === 8 && (
          <Light8 color={roomSettings.lightColor} length={items?.length} />
        )}
        {roomSettings?.light === 9 && (
          <Light9 color={roomSettings.lightColor} length={items?.length} />
        )}
        {roomSettings?.objectLight == 1 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight1
              key={index}
              position={[-1.5 + index * 8, 1.7, 0.6]}
              scale={1}
            />
          ))}
        {roomSettings?.objectLight == 2 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight2
              key={index}
              position={[-1.5 + index * 8, 4, 0.2]}
              scale={1.2}
            />
          ))}
        {roomSettings?.objectLight == 3 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight3
              key={index}
              position={[-1.5 + index * 8, 4.4, 0.12]}
              scale={1}
            />
          ))}
        {mode == 'HIGH' && currentItemId != null && (
          <MovingSpotLight
            currentItemId={currentItemId}
            opacity={
              roomSettings?.spotLightIntensity != null
                ? 0.1 * roomSettings.spotLightIntensity
                : 0.1 * 2
            }
            anglePower={1}
            angle={0.35}
            color={roomSettings?.spotLightColorCode}
          />
        )}
        <group position={[0.2, -0.5, 0]}>
          <Items
            items={items}
            currentItemId={currentItemId}
            setCurrentItemId={setCurrentItemId}
            isActive={isActive}
            setIsActive={setIsActive}
            setSelectedItem={setSelectedItem}
            setCurrentItem={setCurrentItem}
            roomSettings={roomSettings}
            zoomItemImageUrl={zoomItemImageUrl}
            setZoomItemImageUrl={setZoomItemImageUrl}
            showInfo={showInfo}
            selectedItem={selectedItem}
            inquiryItem={inquiryItem}
            setInquiryItem={setInquiryItem}
          />
          {items.length > 0 && roomSettings?.silhouette && (
            <>
              {roomSettings?.silhouettes.includes(1) && (
                <Man1
                  position={[1, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(2) && (
                <Man2
                  position={[20, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(3) && (
                <Man3
                  position={[41.5, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(4) && (
                <Woman1
                  position={[32, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(5) && (
                <Woman2
                  position={[27, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(6) && (
                <Woman3
                  position={[7.5, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(7) && (
                <Kids1
                  position={[8, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(8) && (
                <Kids2
                  position={[11, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
              {roomSettings?.silhouettes.includes(9) && (
                <Kids3
                  position={[36.5, 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              )}
            </>
          )}

          {/* ceiling */}
          {roomSettings?.ceiling && (
            <CustomCeiling id={roomSettings?.ceiling} />
          )}

          {/* wall */}
          {roomSettings?.wall && <CustomWall id={roomSettings?.wall} />}
          {/* floor */}
          {roomSettings?.floor !== undefined &&
            (roomSettings?.floor ? (
              <CustomFloor id={roomSettings?.floor} />
            ) : (
              <BasicFloor floorColor={floorColor} />
            ))}
        </group>
        <Environment
          files={'https://data.curatedartshow.com/hdri/studio_small_07_1k.hdr'}
        />
      </Suspense>
    </>
  );
};

export default CustomColorRoom;
