import { ArrowPathIcon, Square2StackIcon } from '@heroicons/react/24/outline';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { successToast } from '../../utils/toast';

const BasicInfo = ({
  title,
  setTitle,
  titleError,
  description,
  setDescription,
  descriptionError,
  roomId,
  setRoomId,
  roomIdError,
  isPrivate,
  setIsPrivate,
  hasVipSupport,
  password,
  setPassword,
  passwordError,
  editMode,
  hasPassword,
  newPassword,
  setNewPassword,
  newPasswordError,
  setNewPasswordError,
}) => {
  const { t } = useTranslation();

  const [showNewPasswordInput, setShowNewPasswordInput] = useState(false);

  const exhibitionLink = useMemo(() => {
    return roomId ? `https://curatedartshow.com/galleries/${roomId}` : '';
  }, [roomId]);

  return (
    <>
      <div className='flex flex-col gap-6'>
        {/* Exhibition Name */}
        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text'>
              <Trans i18nKey={'label.basic-exhib-name'} />
            </span>
            <span className='label-text-alt'>
              <Trans i18nKey={'label.required'} />
            </span>
          </div>
          <input
            className={`input input-bordered w-full text-sm placeholder:text-sm ${
              titleError && 'input-error'
            }`}
            maxLength='100'
            type='text'
            placeholder={t('placeholder.type-here')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className='label'>
            <span className='label-text-alt text-error'>{titleError}</span>
            <span className='label-text-alt'>{title?.length || 0}/100</span>
          </div>
        </label>

        {/* Exhibition Overview */}
        <label className='form-control'>
          <div className='label'>
            <span className='label-text'>
              <Trans i18nKey={'label.basic-exhib-overview'} />
            </span>
            <span className='label-text-alt'>
              <Trans i18nKey={'label.required'} />
            </span>
          </div>
          <textarea
            className={`textarea textarea-bordered text-sm placeholder:text-sm ${
              descriptionError && 'textarea-error'
            }`}
            placeholder={t('placeholder.type-here')}
            maxLength='1000'
            rows='7'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          <div className='label'>
            <span className='label-text-alt text-error'>
              {descriptionError}
            </span>
            <span className='label-text-alt'>
              {description?.length || 0}/1000
            </span>
          </div>
        </label>

        {/* Exhibition ID */}
        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text'>
              <Trans i18nKey={'label.basic-exhib-id'} />
            </span>
            <span className='label-text-alt'>
              <Trans i18nKey={'label.required'} />
            </span>
          </div>
          <p className='px-1 pb-3 text-xs'>
            <Trans i18nKey={'label.basic-exhib-desc'} />
          </p>
          <input
            className={`input input-bordered w-full text-sm placeholder:text-sm ${
              roomIdError && 'input-error'
            }`}
            maxLength='30'
            type='text'
            placeholder={t('placeholder.type-here')}
            value={roomId}
            onChange={(e) => setRoomId(e.target.value)}
          />
          <div className='label'>
            <span className='label-text-alt text-error'>{roomIdError}</span>
            <span className='label-text-alt'>{roomId?.length || 0}/30</span>
          </div>
        </label>
      </div>

      {/* Private Mode */}
      {hasVipSupport && (
        <label className='form-control mt-2 w-full'>
          <div className='label'>
            <span className='label-text'>
              <Trans i18nKey={'label.basic-exhib-private'} />
            </span>
          </div>
          <p className='px-1 text-xs'>
            <Trans i18nKey={'label.basic-exhib-private-description'} />
          </p>

          <div className='label items-center justify-start gap-2'>
            <span className='label-text w-[7.125rem] text-sm !font-medium'>
              Set Private
            </span>
            <input
              checked={isPrivate}
              type='checkbox'
              className='toggle'
              onChange={() => {
                setIsPrivate(!isPrivate);
              }}
            />
          </div>
        </label>
      )}

      {!editMode && isPrivate && (
        <div>
          <label className='form-control w-full'>
            <div className='label'>
              <span className='label-text'>Set Password</span>
              <span className='label-text-alt'>Required</span>
            </div>
            <input
              className={`input input-bordered w-full text-sm placeholder:text-sm ${
                passwordError && 'input-error'
              }`}
              maxLength='20'
              type='password'
              placeholder={t('placeholder.type-here')}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required={isPrivate}
            />
            <div className='label'>
              <span className='label-text-alt text-error'>{passwordError}</span>
              <span className='label-text-alt'>{password?.length ?? 0}/20</span>
            </div>
          </label>
        </div>
      )}

      {editMode && isPrivate && (
        <div className='w-full'>
          {hasPassword ? (
            <>
              {showNewPasswordInput ? (
                <div className='mt-4 flex flex-col gap-4'>
                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text text-sm font-medium'>
                        New Password
                      </span>
                      <span className='label-text-alt'>Required</span>
                    </div>
                    <input
                      className={`input input-bordered w-full text-sm placeholder:text-sm ${
                        newPasswordError && 'input-error'
                      }`}
                      maxLength='20'
                      type='password'
                      placeholder={t('placeholder.type-here')}
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      required={isPrivate}
                    />
                    <div className='label'>
                      <span className='label-text-alt text-error'>
                        {newPasswordError}
                      </span>
                      <span className='label-text-alt'>
                        {newPassword?.length ?? 0}/20
                      </span>
                    </div>
                  </label>
                  <button
                    className='btn btn-ghost self-end text-sm font-[600]'
                    onClick={() => {
                      setShowNewPasswordInput(false);
                      setNewPassword('');
                      setNewPasswordError('');
                    }}
                  >
                    Cancel Change Password
                  </button>
                </div>
              ) : (
                <div className='mt-4 flex items-center gap-6'>
                  <p className='text-sm font-medium'>
                    Password has already been set.
                  </p>
                  <button
                    className='btn btn-outline !btn-neutral btn-sm w-fit border text-sm font-[600] !text-neutral-content'
                    onClick={() => {
                      setShowNewPasswordInput(true);
                    }}
                  >
                    <ArrowPathIcon className='h-5 w-5' />
                    Change Password
                  </button>
                </div>
              )}
            </>
          ) : (
            <label className='form-control w-full'>
              <div className='label'>
                <span className='label-text text-sm font-medium'>
                  Set Password
                </span>
                <span className='label-text-alt'>Required</span>
              </div>
              <input
                className={`input input-bordered w-full text-sm placeholder:text-sm ${
                  passwordError && 'input-error'
                }`}
                maxLength='20'
                type='password'
                placeholder={t('placeholder.type-here')}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required={isPrivate}
              />
              <div className='label'>
                <span className='label-text-alt text-error'>
                  {passwordError}
                </span>
                <span className='label-text-alt'>
                  {password?.length ?? 0}/20
                </span>
              </div>
            </label>
          )}
        </div>
      )}
      <div className='divider my-6' />
      <button
        className='btn btn-outline btn-sm'
        onClick={() => {
          navigator.clipboard.writeText(exhibitionLink);
          successToast(t('message.success.t24'));
        }}
        disabled={!roomId}
      >
        <Square2StackIcon className='h-5 w-5' />
        <Trans i18nKey={'btn.copy-exhibition-link'} />
      </button>
    </>
  );
};

export default BasicInfo;
