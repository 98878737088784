import React, { useEffect, useMemo, useState } from 'react';
import { authRequest, publicRequest } from '../../requestMethods';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BasicInfo from './BasicInfo';
import TransitionAnimationSettings from './TransitionAnimationSettings';
import DecorationSettings from './DecorationSettings';
import { errorToast, successToast } from '../../utils/toast';
import LoadingSpinner from '../../components/LoadingSpinner';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import {
  validateGalleryName,
  validateGalleryDescription,
  validateRoomId,
  validateExhibitionPassword,
} from '../../utils/validation';
import RoomPreviewCard from '../../components/room/RoomPreviewCard';
import Preview from '../../components/r3f/Preview';
import { PlusIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import { ReactSortable } from 'react-sortablejs';
import AvailableItemsModal from '../../components/AvailableItemsModal';
import { Trans, useTranslation } from 'react-i18next';
import { formatMMMDYYYY } from '../../utils/DateUtils';
import { usePreventLeave } from '../../hooks/usePreventLeave';
import { DiscardExhibitionModal } from '../../components/item/DiscardExhibitionModal';

const CreateRoom = () => {
  const [hasVipSupport, setHasVipSupport] = useState(false);
  const {
    REACT_APP_STRIPE_CAS1_PRODUCT_ID: CAS1_ID,
    REACT_APP_STRIPE_CAS5_PRODUCT_ID: CAS5_ID,
    REACT_APP_STRIPE_CAS_VIP_PRODUCT_ID: VIP_SUPPORT_ID,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_CAS1: MAX_PUBLISHED_EXHIBITION_CAS1,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_CAS5: MAX_PUBLISHED_EXHIBITION_CAS5,
    REACT_APP_MAX_EXHIBITION_CAS1: MAX_EXHIBITION_CAS1,
    REACT_APP_MAX_EXHIBITION_CAS5: MAX_EXHIBITION_CAS5,
  } = process.env;

  const [plan, setPlan] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [frame, setFrame] = useState('');
  const [theme, setTheme] = useState('CYBERPUNK');
  const [silhouette, setSilhouette] = useState(false);
  const [light, setLight] = useState(null);
  const [lightColor, setLightColor] = useState('WHITE');
  const [music, setMusic] = useState('');
  const [snapshot, setSnapshot] = useState('');
  const [animationType, setAnimationType] = useState(0);
  const [roomId, setRoomId] = useState(null);
  const [checkedSilhouettes, setCheckedSilhouettes] = useState([]);
  const [colorCode, setColorCode] = useState('#ffffff');
  const [ceiling, setCeiling] = useState(null);
  const [wall, setWall] = useState(null);
  const [floor, setFloor] = useState(null);
  const [objectLight, setObjectLight] = useState(null);
  const [randomMusic, setRandomMusic] = useState(false);
  const [directionalLightIntensity, setDirectionalLightIntensity] = useState(1);
  const [directionalLightColorTheme, setDirectionalLightColorTheme] =
    useState('WHITE');
  const [directionalLightColorCode, setDirectionalLightColorCode] =
    useState('#ffffff');
  const [spotLightIntensity, setSpotLightIntensity] = useState(1);
  const [spotLightColorTheme, setSpotLightColorTheme] = useState('WHITE');
  const [spotLightColorCode, setSpotLightColorCode] = useState('#ffffff');
  const [hasReachedMaxPublish, setHasReachedMaxPublish] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [presetName, setPresetName] = useState('');
  const [showOnHome, setShowOnHome] = useState(true);
  const [isAgreedTerms, setIsAgreedTerms] = useState(false);
  const [password, setPassword] = useState('');
  const [frameColor, setFrameColor] = useState('#525252');
  const [matColor, setMatColor] = useState('#525252');

  // エラー
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [roomIdError, setGalleryIdError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [addedItems, setAddedItems] = useState([]);
  const [availableItems, setAvailableItems] = useState([]);
  const { isLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  usePreventLeave();

  const user = useSelector((state) => state.user.currentUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const { i18n, t } = useTranslation();

  // 上限数に達しているか確認し達していればdashboardなどに返す
  const checkIsReachedLimit = async () => {
    //plan確認
    // 初期値を設定するための変数
    let foundPlan = '';
    let vipSupportFound = false;

    if (user?.userPlans?.length > 0) {
      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === CAS1_ID) {
          foundPlan = 'CAS-1';
        } else if (el.SubscriptionPlans.productId === CAS5_ID) {
          foundPlan = 'CAS-5';
        }

        // "CAS VIP Support" があるか確認
        if (el.SubscriptionPlans.productId === VIP_SUPPORT_ID) {
          vipSupportFound = true;
        }
      });

      setHasVipSupport(vipSupportFound);
    } else {
      setHasVipSupport(false);
    }

    const galleryData = await publicRequest.get(`/rooms/users/${user.id}`);
    const draftRoomData = await authRequest.get(`/rooms/drafts/${user?.id}`);

    // 上限値に達しているか確認
    if (
      galleryData.data.length + draftRoomData.data.length >=
        Number(MAX_EXHIBITION_CAS1) &&
      (foundPlan == 'CAS-1' || !foundPlan)
    ) {
      navigate('/dashboard');
      errorToast(
        t('message.error.t8', {
          limit: MAX_EXHIBITION_CAS1,
        })
      );
    }

    if (
      galleryData.data.length + draftRoomData.data.length >=
        Number(MAX_EXHIBITION_CAS5) &&
      foundPlan == 'CAS-5'
    ) {
      navigate('/dashboard');
      errorToast(
        t('message.error.t8', {
          limit: MAX_EXHIBITION_CAS5,
        })
      );
    }

    //publishの上限に達しているか確認
    if (
      ((foundPlan == 'CAS-1' || !foundPlan) &&
        galleryData.data.length >= Number(MAX_PUBLISHED_EXHIBITION_CAS1)) ||
      (foundPlan == 'CAS-5' &&
        galleryData.data.length >= Number(MAX_PUBLISHED_EXHIBITION_CAS5))
    ) {
      setHasReachedMaxPublish(true);
    }

    setPlan(foundPlan ? foundPlan : 'Free Trial');
  };

  useEffect(() => {
    if (!user) {
      navigate('/signin');
    }
    const getAvailableItems = async () => {
      //get owned items
      const itemData = await publicRequest.get(`/items/owner/${user?.id}`);

      // 重複を除いたアイテムデータを作成
      const uniqueItems = [...itemData.data].reduce((result, item) => {
        // アイテムのIDが既に存在する追加しない
        if (!result.find((existingItem) => existingItem.id === item.id)) {
          result.push(item);
        }
        return result;
      }, []);
      //set availableItems
      setAvailableItems(uniqueItems);
    };
    checkIsReachedLimit();
    getAvailableItems();
  }, []);

  const reflectGalleryName = (name) => {
    const result = validateGalleryName(name);
    result.isValid ? setTitleError('') : setTitleError(result.message);
    setTitle(name);
  };

  const reflectGalleryDescription = (description) => {
    const result = validateGalleryDescription(description);
    result.isValid
      ? setDescriptionError('')
      : setDescriptionError(result.message);
    setDescription(description);
  };

  const reflectRoomId = (id) => {
    const result = validateRoomId(id);
    result.isValid ? setGalleryIdError('') : setGalleryIdError(result.message);
    setRoomId(id);
  };

  const reflectPassword = (text) => {
    const result = validateExhibitionPassword(text);
    result.isValid ? setPasswordError('') : setPasswordError(result.message);
    setPassword(text);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    try {
      const s3PreviewImgRes = await publicRequest.post(
        `/s3/base64`,
        {
          file: snapshot,
          key: `${user.id}/previewImg/`,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const addedItemIds = [];
      addedItems.forEach((addedItem) => {
        addedItemIds.push(addedItem.id);
      });

      const res = await authRequest.post('/rooms', {
        title,
        description,
        previewImg: s3PreviewImgRes.data.replace(
          'curatedartshow-data.s3.amazonaws.com',
          'data.curatedartshow.com'
        ),
        animationType: Number(animationType),
        roomId,
        isPrivate,
        password,
        showOnHome,
      });

      await authRequest.patch(`/rooms/edit-items/${res.data.id}`, {
        items: addedItemIds,
      });

      await authRequest.patch(`/rooms/settings/${res.data.id}`, {
        frame,
        theme,
        silhouette,
        music: randomMusic ? 100 : music ? music : null,
        light,
        lightColor,
        silhouettes: checkedSilhouettes,
        colorCode,
        ceiling,
        wall,
        floor,
        objectLight,
        directionalLightIntensity,
        directionalLightColorTheme,
        directionalLightColorCode,
        spotLightIntensity,
        spotLightColorTheme,
        spotLightColorCode,
        frameColor,
        matColor,
      });
      dispatch(loadEnd());
      navigate('/dashboard?tab=galleries');
      successToast(t('message.success.t14'));
    } catch (err) {
      dispatch(loadEnd());
      console.error(err);
      const data = err.response.data;
      if (data.statusCode === 400) {
        errorToast(data.message);
      }
    }
  };

  const handleCreatePreset = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    try {
      const s3PreviewImgRes = await publicRequest.post(
        `/s3/base64`,
        {
          file: snapshot,
          key: `${user.id}/previewImg/`,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      await authRequest.post(`/presets`, {
        userId: user?.id,
        frame,
        theme,
        silhouette,
        music: randomMusic ? 100 : music ? music : null,
        light,
        lightColor,
        silhouettes: checkedSilhouettes,
        colorCode,
        ceiling,
        wall,
        floor,
        objectLight,
        directionalLightIntensity,
        directionalLightColorTheme,
        directionalLightColorCode,
        spotLightIntensity,
        spotLightColorTheme,
        spotLightColorCode,
        name: presetName,
        imageUrl: s3PreviewImgRes.data.replace(
          'curatedartshow-data.s3.amazonaws.com',
          'data.curatedartshow.com'
        ),
        status: 'PRIVATE',
        frameColor,
        matColor,
      });
      dispatch(loadEnd());
      successToast(t('message.success.t18'));
      setPresetName('');
      document.getElementById('save_preset_modal').close();
    } catch (err) {
      dispatch(loadEnd());
      console.error(err);
      const data = err.response.data;
      if (data.statusCode === 400) {
        errorToast(data.message);
      }
    }
  };

  const saveDraft = async (e) => {
    e.preventDefault();

    dispatch(loadStart());
    try {
      const s3PreviewImgRes = await publicRequest.post(
        `/s3/base64`,
        {
          file: snapshot,
          key: `${user.id}/previewImg/`,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const addedItemIds = [];
      addedItems.forEach((addedItem) => {
        addedItemIds.push(addedItem.id);
      });

      const res = await authRequest.post('/rooms', {
        title,
        description,
        previewImg: s3PreviewImgRes.data.replace(
          'curatedartshow-data.s3.amazonaws.com',
          'data.curatedartshow.com'
        ),
        animationType,
        published: false,
        roomId,
        isPrivate,
        password,
        showOnHome,
      });

      await authRequest.patch(`/rooms/edit-items/${res.data.id}`, {
        items: addedItemIds,
      });

      await authRequest.patch(`/rooms/settings/${res.data.id}`, {
        frame,
        theme,
        silhouette,
        music: randomMusic ? 100 : music ? music : null,
        light,
        lightColor,
        silhouettes: checkedSilhouettes,
        colorCode,
        ceiling,
        wall,
        floor,
        objectLight,
        directionalLightIntensity,
        directionalLightColorTheme,
        directionalLightColorCode,
        spotLightIntensity,
        spotLightColorTheme,
        spotLightColorCode,
        frameColor,
        matColor,
      });
      dispatch(loadEnd());
      navigate('/dashboard?tab=drafts');
      successToast(t('message.success.t12'));
    } catch (err) {
      dispatch(loadEnd());
      console.error(err);
      const data = err.response.data;
      if (data.statusCode === 400) {
        errorToast(data.message);
      }
    }
  };

  const resetSettings = () => {
    setFrame('');
    setTheme('CYBERPUNK');
    setSilhouette(false);
    setLight(null);
    setLightColor('WHITE');
    setMusic('');
    setSnapshot('');
    setCheckedSilhouettes([]);
    setColorCode('#ffffff');
    setCeiling(null);
    setWall(null);
    setFloor(null);
    setObjectLight(null);
    setRandomMusic(false);
    setDirectionalLightIntensity(1);
    setDirectionalLightColorTheme('WHITE');
    setDirectionalLightColorCode('#ffffff');
    setSpotLightIntensity(1);
    setSpotLightColorTheme('WHITE');
    setSpotLightColorCode('#ffffff');
  };

  const loadPreset = (preset) => {
    setFrame(preset.frame);
    setTheme(preset.theme);
    setSilhouette(preset.silhouette);
    setLight(preset.light);
    setLightColor(preset.lightColor);
    setMusic(preset.music);

    setCheckedSilhouettes([]);
    setColorCode(preset.colorCode);
    setCeiling(preset.ceiling);
    setWall(preset.wall);
    setFloor(preset.floor);
    setObjectLight(preset.objectLight);
    setRandomMusic(false);
    setDirectionalLightIntensity(preset.directionalLightIntensity);
    setDirectionalLightColorTheme(preset.directionalLightColorTheme);
    setDirectionalLightColorCode(preset.directionalLightColorCode);
    setSpotLightIntensity(preset.spotLightIntensity);
    setSpotLightColorTheme(preset.spotLightColorTheme);
    setSpotLightColorCode(preset.spotLightColorCode);

    //silhouettesデータを同期
    const indices = preset.PresetSilhouettes?.map(
      (silhouette) => silhouette.index
    );
    setCheckedSilhouettes(indices);
  };

  const hasError = useMemo(() => {
    // requiredを満たしているか
    const isFilled =
      !!title &&
      !!description &&
      !!roomId &&
      ((isPrivate && !!password) || !isPrivate);
    const hasErrorMessage =
      !!titleError ||
      !!descriptionError ||
      !!roomIdError ||
      (isPrivate && !!passwordError);
    return hasErrorMessage || !isFilled ? true : false;
  }, [title, description, roomId, password, isPrivate]);

  const removeItem = (item) => {
    // 選択した作品を削除
    const newAddedItems = addedItems.filter(
      (addedItem) => addedItem.id !== item.id
    );
    setAddedItems(newAddedItems);
    setAvailableItems([...availableItems, item]);
  };

  return (
    <div className='flex h-full flex-col'>
      <div className='flex flex-auto flex-col-reverse lg:flex-row'>
        {/* 左カラム */}
        <div className='flex w-full flex-grow flex-col px-5 pt-5 lg:max-w-[510px]'>
          <div
            role='tablist'
            className='no-scrollbar tabs tabs-bordered overflow-x-scroll whitespace-nowrap'
          >
            <Link
              role='tab'
              className={`tab border-b-2 border-solid border-gray-200 !text-[#1F2A37] ${
                (!tab || tab === 'basic-info') && 'tab-active'
              }`}
              to={`?tab=basic-info`}
            >
              <Trans i18nKey={'gallery.tab-basic'} />
            </Link>
            <Link
              className={`tab border-b-2 border-solid border-gray-200 !text-[#1F2A37] ${
                tab === 'artworks' && 'tab-active'
              }`}
              to={`?tab=artworks`}
            >
              <Trans i18nKey={'gallery.tab-artworks'} />
            </Link>
            <Link
              className={`tab border-b-2 border-solid border-gray-200 !text-[#1F2A37] ${
                tab === 'decoration' && 'tab-active'
              }`}
              to={`?tab=decoration`}
            >
              <Trans i18nKey={'gallery.tab-decoration'} />
            </Link>
            <Link
              className={`tab border-b-2 border-solid border-gray-200 !text-[#1F2A37] ${
                tab === 'animation' && 'tab-active'
              }`}
              to={`?tab=animation`}
            >
              <Trans i18nKey={'gallery.tab-animation'} />
            </Link>
          </div>
          <div className='mt-5 flex-auto'>
            {(!tab || tab === 'basic-info') && (
              <BasicInfo
                title={title}
                setTitle={reflectGalleryName}
                titleError={titleError}
                description={description}
                setDescription={reflectGalleryDescription}
                descriptionError={descriptionError}
                roomId={roomId}
                setRoomId={reflectRoomId}
                roomIdError={roomIdError}
                isPrivate={isPrivate}
                setIsPrivate={setIsPrivate}
                hasVipSupport={hasVipSupport}
                password={password}
                setPassword={reflectPassword}
                passwordError={passwordError}
                editMode={false}
              />
            )}
            {(!tab || tab === 'basic-info') && hasReachedMaxPublish && (
              <div role='alert' className='alert mt-6'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  className='h-6 w-6 shrink-0 stroke-info'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                  ></path>
                </svg>
                {i18n.language == 'ja' ? (
                  <span>
                    {plan == 'CAS-5'
                      ? `現在のプラン（CAS-5）で同時に公開できる展示会の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にすることを検討してください。CAS-5では5つの展示会の同時公開をサポートしていますが、さらに多くの展示会を公開したい場合は、お問い合わせフォームよりお問い合わせください。`
                      : `現在のプラン（${
                          plan == 'Free Trial' ? 'フリートライアル' : plan
                        }）での同時公開展示の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にするか、５つの展示会を同時に公開できるCAS-5にアップグレードしましょう。`}
                  </span>
                ) : (
                  <span>
                    {plan == 'CAS-5'
                      ? `You have reached the limit for the number of concurrent published exhibitions under your plan: CAS-5.You may wish to unpublish this exhibition and publish one of your unpublished exhibitions. CAS-5 supports five concurrent published exhibitions, but if you need even more, please contact us.`
                      : `You have reached the limit for the number of concurrent published exhibitions under your plan: ${plan}.
You may wish to unpublish this exhibition and publish one of your unpublished exhibitions, or upgrade to CAS-5, which supports five concurrent published exhibitions.`}
                  </span>
                )}
              </div>
            )}
            {(!tab || tab === 'basic-info') && (
              <div className='my-4'>
                <p className='mb-5 mt-16 text-sm text-error'>
                  <Trans i18nKey={'gallery.basic-explanation'} />
                </p>
                <div className='form-control'>
                  <label className='label flex cursor-pointer items-start justify-start gap-3'>
                    <input
                      checked={showOnHome}
                      type='checkbox'
                      onChange={() => setShowOnHome(!showOnHome)}
                      className='checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20'
                    />
                    <p className='text-xs font-normal'>
                      <Trans
                        i18nKey={'exhibition-agreement'}
                        components={{
                          link1: (
                            <a
                              href='/'
                              target='_blank'
                              className='underline'
                            ></a>
                          ),
                        }}
                      />
                    </p>
                  </label>
                </div>
                <div className='form-control'>
                  <label className='label flex cursor-pointer items-start gap-3'>
                    <input
                      checked={isAgreedTerms}
                      type='checkbox'
                      onChange={() => setIsAgreedTerms(!isAgreedTerms)}
                      className='checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20'
                    />
                    <p className='text-xs font-normal'>
                      <Trans
                        i18nKey={'exhibition-term'}
                        components={{
                          link1: (
                            <a
                              href='/terms-privacy'
                              target='_blank'
                              className='underline'
                            ></a>
                          ),
                        }}
                      />
                    </p>
                  </label>
                </div>
              </div>
            )}

            {tab === 'artworks' && (
              <div className='flex h-full'>
                <p className='m-auto hidden w-full max-w-60 text-center text-sm md:block'>
                  <Trans i18nKey={'gallery.artworks-note'} />
                </p>
                <p className='m-auto block w-full max-w-60 text-center text-sm md:hidden'>
                  <Trans i18nKey={'gallery.artworks-note'} />
                </p>
              </div>
            )}
            {tab === 'decoration' && (
              <DecorationSettings
                saveRoomSettings={handleCreate}
                frame={frame}
                setFrame={setFrame}
                theme={theme}
                setTheme={setTheme}
                silhouette={silhouette}
                setSilhouette={setSilhouette}
                music={music}
                setMusic={setMusic}
                randomMusic={randomMusic}
                setRandomMusic={setRandomMusic}
                light={light}
                setLight={setLight}
                snapshot={snapshot}
                setSnapshot={setSnapshot}
                addedItems={addedItems}
                lightColor={lightColor}
                setLightColor={setLightColor}
                checkedSilhouettes={checkedSilhouettes}
                setCheckedSilhouettes={setCheckedSilhouettes}
                colorCode={colorCode}
                setColorCode={setColorCode}
                ceiling={ceiling}
                setCeiling={setCeiling}
                wall={wall}
                setWall={setWall}
                floor={floor}
                setFloor={setFloor}
                objectLight={objectLight}
                setObjectLight={setObjectLight}
                directionalLightIntensity={directionalLightIntensity}
                setDirectionalLightIntensity={setDirectionalLightIntensity}
                directionalLightColorTheme={directionalLightColorTheme}
                setDirectionalLightColorTheme={setDirectionalLightColorTheme}
                directionalLightColorCode={directionalLightColorCode}
                setDirectionalLightColorCode={setDirectionalLightColorCode}
                spotLightIntensity={spotLightIntensity}
                setSpotLightIntensity={setSpotLightIntensity}
                spotLightColorTheme={spotLightColorTheme}
                setSpotLightColorTheme={setSpotLightColorTheme}
                spotLightColorCode={spotLightColorCode}
                setSpotLightColorCode={setSpotLightColorCode}
                presetName={presetName}
                setPresetName={setPresetName}
                handleCreatePreset={handleCreatePreset}
                resetSettings={resetSettings}
                loadPreset={loadPreset}
                frameColor={frameColor}
                setFrameColor={setFrameColor}
                matColor={matColor}
                setMatColor={setMatColor}
              />
            )}
            {tab === 'animation' && (
              <TransitionAnimationSettings
                animationType={animationType}
                setAnimationType={setAnimationType}
              />
            )}
          </div>

          <div className='mb-6 mt-10 flex justify-between'>
            <button
              className='btn'
              onClick={() =>
                document.getElementById('discard_exhibition_modal').showModal()
              }
            >
              <Trans i18nKey={'btn.cancel'} />
            </button>
            <>
              {!tab || tab === 'basic-info' ? (
                <div className='flex gap-3'>
                  <button
                    type='button'
                    onClick={saveDraft}
                    className={`btn btn-primary`}
                    disabled={!snapshot || hasError}
                  >
                    <Trans i18nKey={'btn.save-unpublished'} />
                  </button>
                  <button
                    className='btn btn-primary'
                    onClick={handleCreate}
                    disabled={
                      hasError || hasReachedMaxPublish || !isAgreedTerms
                    }
                  >
                    <Trans i18nKey={'btn.publish'} />
                  </button>
                </div>
              ) : (
                <div className='flex gap-3'>
                  <button
                    type='button'
                    disabled={!snapshot || hasError}
                    onClick={saveDraft}
                    className='btn btn-primary'
                  >
                    <Trans i18nKey={'btn.save-unpublished'} />
                  </button>
                  {/* <Link
                    to={proceedLink}
                    className='btn btn-primary !capitalize'
                  >
                    <Trans i18nKey={'btn.continue'} />
                    <ArrowRightIcon className='h-6 w-6' />
                  </Link> */}
                </div>
              )}
            </>
          </div>
        </div>

        {/* 右カラム */}
        <div className='w-full bg-base-300 px-4 py-10 lg:max-w-[calc(100%-440px)] lg:px-6 lg:py-12 lg:pb-16 xl:px-10'>
          {tab === 'artworks' && 0 < addedItems.length && (
            <div className='mb-3 flex flex-col gap-1 text-sm lg:mb-5 lg:gap-5 lg:text-base'>
              <p>
                <span className='font-bold'>{addedItems.length}</span> artworks
                in this exhibition.
              </p>
              <p>Click and drag to change order.</p>
            </div>
          )}
          {(tab === 'basic-info' || !tab) && (
            <>
              <div className='mb-4 text-center text-xl font-bold text-gray-500 sm:text-left lg:mb-6 lg:text-2xl'>
                <Trans i18nKey={'gallery.preview'} />
              </div>
              <RoomPreviewCard img={snapshot} title={title} />
            </>
          )}

          {tab === 'artworks' && (
            <>
              {addedItems.length === 0 ? (
                <div className='rounded-lg bg-base-200 px-4 py-10'>
                  <div className='m-auto max-w-[640px] rounded-lg border border-dashed border-[#2B3440] bg-white p-10'>
                    <p className='text-center'>
                      <Trans i18nKey={'label.artworks-empty'} />
                    </p>
                    <div className='mt-6 flex items-center justify-center'>
                      {availableItems.length > 0 && (
                        <button
                          className='btn btn-primary'
                          onClick={() =>
                            document
                              .getElementById('available_item_modal')
                              .showModal()
                          }
                        >
                          <Trans i18nKey={'btn.add-artwork'} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='flex max-h-[650px] flex-col gap-4 rounded-lg bg-base-200 px-4 py-6'>
                    <ReactSortable
                      list={addedItems}
                      setList={setAddedItems}
                      handle='.sort-handle'
                      className='flex h-full flex-col gap-[0.625rem] overflow-scroll'
                    >
                      {addedItems.map((item) => (
                        <div
                          className='flex items-center justify-between gap-2 rounded-xl border border-gray-200 bg-white p-[1rem]'
                          key={item.id}
                        >
                          <div className='flex w-full items-center gap-1 overflow-scroll whitespace-nowrap lg:max-w-[calc(100%-110px)] lg:gap-4'>
                            <div className='sort-handle relative cursor-grab'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <g id='Drag Icon'>
                                  <g id='Vector'>
                                    <path
                                      fill-rule='evenodd'
                                      clip-rule='evenodd'
                                      d='M13.5 6C13.5 5.17157 14.1716 4.5 15 4.5C15.8284 4.5 16.5 5.17157 16.5 6C16.5 6.82843 15.8284 7.5 15 7.5C14.1716 7.5 13.5 6.82843 13.5 6ZM13.5 12C13.5 11.1716 14.1716 10.5 15 10.5C15.8284 10.5 16.5 11.1716 16.5 12C16.5 12.8284 15.8284 13.5 15 13.5C14.1716 13.5 13.5 12.8284 13.5 12ZM13.5 18C13.5 17.1716 14.1716 16.5 15 16.5C15.8284 16.5 16.5 17.1716 16.5 18C16.5 18.8284 15.8284 19.5 15 19.5C14.1716 19.5 13.5 18.8284 13.5 18Z'
                                      fill='#1F2A37'
                                    />
                                    <path
                                      fill-rule='evenodd'
                                      clip-rule='evenodd'
                                      d='M7 6C7 5.17157 7.67157 4.5 8.5 4.5C9.32843 4.5 10 5.17157 10 6C10 6.82843 9.32843 7.5 8.5 7.5C7.67157 7.5 7 6.82843 7 6ZM7 12C7 11.1716 7.67157 10.5 8.5 10.5C9.32843 10.5 10 11.1716 10 12C10 12.8284 9.32843 13.5 8.5 13.5C7.67157 13.5 7 12.8284 7 12ZM7 18C7 17.1716 7.67157 16.5 8.5 16.5C9.32843 16.5 10 17.1716 10 18C10 18.8284 9.32843 19.5 8.5 19.5C7.67157 19.5 7 18.8284 7 18Z'
                                      fill='#1F2A37'
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className='h-16 w-16 flex-shrink-0 lg:h-20 lg:w-20'>
                              <Link
                                to={`/items/edit/${item.id}`}
                                target='_blank'
                              >
                                <img
                                  src={item.itemUrl}
                                  alt={item.title}
                                  className='mx-auto h-full max-h-[5rem] w-full max-w-[5rem] object-contain'
                                />
                              </Link>
                            </div>
                            {/* SP表示 */}
                            <div className='lg:hidden'>
                              <p className='text-xs font-bold'>{item.title}</p>
                              <p className='text-xs'>
                                {item?.creator?.displayName}
                              </p>
                              <p className='text-xs'>{item?.createdYear}</p>
                              <p className='flex gap-2 text-xs'>
                                <span className='font-semibold text-[#1F2A3799]/60'>
                                  Added:
                                </span>
                                {formatMMMDYYYY(item.createdAt)}
                              </p>
                            </div>
                            {/* PC表示 */}
                            <p className='hidden text-sm font-bold lg:block'>
                              {item.title}
                            </p>
                            <p className='hidden text-sm lg:block'>
                              {item?.creator?.displayName}
                            </p>
                            <p className='hidden text-sm lg:block'>
                              {item?.createdYear}
                            </p>
                            <p className='hidden text-sm lg:block'>
                              {formatMMMDYYYY(item.createdAt)}
                            </p>
                          </div>

                          <button
                            type='button'
                            className='btn btn-circle btn-sm lg:w-auto lg:px-3'
                            onClick={() => removeItem(item)}
                          >
                            <TrashIcon className='h-5 w-5' />
                            <span className='hidden lg:block'>
                              <Trans i18nKey={'btn.remove'} />
                            </span>
                          </button>
                        </div>
                      ))}
                    </ReactSortable>
                    <button
                      className='btn btn-outline btn-neutral btn-sm self-center lg:btn-md lg:self-start'
                      onClick={() =>
                        document
                          .getElementById('available_item_modal')
                          .showModal()
                      }
                    >
                      <PlusIcon className='h-4 w-4' />
                      <Trans i18nKey={'btn.add-artwork'} />
                    </button>
                  </div>
                </>
              )}
            </>
          )}

          {tab === 'animation' && (
            <div className='relative'>
              <div className='mb-4 text-center text-xl font-bold text-gray-500 lg:mb-6 lg:text-2xl'>
                <Trans i18nKey={'gallery.preview'} />
              </div>
              {animationType == 0 ? (
                <div className='flex aspect-video items-center justify-center bg-black text-center'>
                  <p className='font-bold text-white'>
                    Loading<span className='animate-dot-1'>.</span>
                    <span className='animate-dot-2'>.</span>
                    <span className='animate-dot-3'>.</span>
                  </p>
                </div>
              ) : (
                <video
                  src={`/assets/transition_animation/cyberpunk${animationType}_faster.mp4`}
                  className='object-cover'
                  autoPlay
                  loop
                  playsInline
                  controls={false}
                  preload='auto'
                  muted
                >
                  not support
                </video>
              )}
            </div>
          )}

          {tab === 'decoration' && (
            <div className='relative'>
              <div className='mb-4 text-center text-xl font-bold text-gray-500 lg:mb-6 lg:text-2xl'>
                <Trans i18nKey={'gallery.preview'} />
              </div>
              <Preview
                theme={theme}
                frame={frame}
                showSilhouette={silhouette}
                setSnapshot={setSnapshot}
                light={light}
                items={addedItems}
                lightColor={lightColor}
                ceiling={ceiling}
                wall={wall}
                floor={floor}
                objectLight={objectLight}
                colorCode={colorCode}
                directionalLightIntensity={directionalLightIntensity}
                directionalLightColorCode={directionalLightColorCode}
                spotLightIntensity={spotLightIntensity}
                spotLightColorCode={spotLightColorCode}
                frameColor={frameColor}
                matColor={matColor}
              />
            </div>
          )}

          <div className='absolute top-0 z-[-1] opacity-0'>
            <Preview
              theme={theme}
              frame={frame}
              showSilhouette={silhouette}
              setSnapshot={setSnapshot}
              light={light}
              items={addedItems}
              lightColor={lightColor}
              ceiling={ceiling}
              wall={wall}
              floor={floor}
              objectLight={objectLight}
              colorCode={colorCode}
              directionalLightIntensity={directionalLightIntensity}
              directionalLightColorCode={directionalLightColorCode}
              spotLightIntensity={spotLightIntensity}
              spotLightColorCode={spotLightColorCode}
              frameColor={frameColor}
              matColor={matColor}
            />
          </div>
        </div>
      </div>
      <AvailableItemsModal
        availableItems={availableItems}
        addedItems={addedItems}
        setAddedItems={setAddedItems}
        setAvailableItems={setAvailableItems}
      />
      <DiscardExhibitionModal
        title={
          <Trans i18nKey={'gallery-modal.discard-create-exhibition-ttl'} />
        }
        desc={<Trans i18nKey={'gallery-modal.discard-desc'} />}
      />
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default CreateRoom;
