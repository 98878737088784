import { useState, useEffect } from 'react';
import { authRequest } from '../../../requestMethods';
import dayjs from 'dayjs';
import ArtworkModal from '../components/ArtworkModal';
import { successToast } from '../../../utils/toast';
import MatureContentModal from '../components/MatureContentModal';

const AdminArtworkReports = () => {
  const [reports, setReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [artworkModalItem, setArtworkModalItem] = useState(null);
  const [matureContentId, setMatureContentId] = useState(null);

  const openModal = (item) => {
    setArtworkModalItem(item);
    document.body.classList.add('is-scrollLocked');
    setShowModal(true);
  };

  const getArtworkReports = async () => {
    try {
      //全ての公開済み作品を取得
      const res = await authRequest.get('/admin/item-reports');
      setReports(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpenMatureContentModal = (id) => {
    setMatureContentId(id);
    document.getElementById('mature_content_modal').showModal();
  };

  const handleMarkAsMatureContent = async () => {
    try {
      await authRequest.post(`/admin/mark-mature/${matureContentId}`);
      document.getElementById('mature_content_modal').close();
      getArtworkReports();
      setShowModal(false);
      successToast(
        'The artwork has been successfully marked as mature content'
      );
    } catch (err) {
      console.err(err);
    }
  };

  useEffect(() => {
    getArtworkReports();
  }, []);

  return (
    <>
      <h2 className='text-3xl font-extrabold'>Reports</h2>
      <div className='mt-10 overflow-x-auto'>
        <table className='table table-auto'>
          {/* head */}
          <thead>
            <tr>
              <th>Id</th>
              <th>Image</th>
              <th>Artwork Title</th>
              <th>Date Reported</th>
              <th>Reporter</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reports?.map((report) => (
              <tr key={`report-${report.id}`}>
                <th>{report.id}</th>
                <td>
                  <img
                    className='h-24 w-24 object-cover'
                    src={report.reportedItem.itemUrl}
                    alt=''
                  />
                </td>
                <td className='max-w-48'>
                  {report.reportedItem.isMatureContent && (
                    <>
                      <div className='badge !badge-warning'>Mature Content</div>
                      <br />
                    </>
                  )}
                  {report.reportedItem.title}
                </td>
                <td>{dayjs(report.createdAt).format('MMM D, YYYY')}</td>
                <td className='max-w-48 '>
                  <div className='flex items-center gap-3'>
                    <img
                      className='border-dark h-10 w-10 flex-shrink-0 rounded-full border-2 object-cover md:h-8 md:w-8'
                      src={`${
                        report.reporter?.profileImageUrl
                          ? report.reporter?.profileImageUrl
                          : '/assets/img/avatar/dummy_avatar.svg'
                      }`}
                      alt=''
                    />
                    {report.reporter.displayName}
                  </div>
                </td>
                <td className='min-w-40'>
                  <button
                    className='btn btn-sm'
                    onClick={() => openModal(report.reportedItem)}
                  >
                    View Detail
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <ArtworkModal
          item={artworkModalItem}
          setShowModal={setShowModal}
          openMatureContentModal={handleOpenMatureContentModal}
        />
      )}

      <MatureContentModal confirmMark={() => handleMarkAsMatureContent()} />
    </>
  );
};

export default AdminArtworkReports;
