import SubscriptionPlan from '../../components/SubscriptionPlan';
import { useState, useEffect } from 'react';
import { publicRequest } from '../../requestMethods';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

const Subscription = () => {
  const [plans, setPlans] = useState(null);
  const user = useSelector((state) => state.user.currentUser);
  const fetchPrices = async () => {
    const res = await publicRequest.get('/stripe/prices');
    setPlans(res.data);
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  const handleGetStarted = async (pricesIds, isFreeTrial = false) => {
    const body = {
      priceIds: pricesIds,
      userId: user.id,
    };

    try {
      const res = await publicRequest.post('/stripe/checkout', body);
      const checkoutUrl = res.data.url;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className='w-full flex-auto px-4 pb-20 pt-10 md:pb-56'>
        <div className='mx-auto max-w-4xl'>
          <Link
            to={'/dashboard?tab=manage-subscription'}
            className='btn btn-ghost'
          >
            <ArrowLeftIcon className='h-6 w-6' />
            <Trans i18nKey={'btn.back-to-dashboard'} />
          </Link>
          <div className='mt-6'>
            <SubscriptionPlan
              plans={plans}
              userPlans={user.userPlans}
              getStarted={handleGetStarted}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
